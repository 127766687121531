import Config from "config";

import { HttpClient } from "services";

import { IUser } from "types";
import isSuccessResponse from "../helper/v2-helpers/isSuccessResponse";
import {
  IAuthInfo,
  IMerchantAuthResponseJson,
  ROLE,
} from "../types/auth.interface";
import httpClient from "./http-client";
import httpClientV2 from "./http-clientV2";

const AUTH_KEY_LOCALSTORAGE = "swaypay_auth";

class AuthService {
  _auth: IAuthInfo;
  error: string;
  isRedirectedURL: string | null = null;
  constructor() {
    const authString = localStorage.getItem(AUTH_KEY_LOCALSTORAGE);
    if (authString) {
      this._auth = JSON.parse(authString);
    }
  }

  public isAuthenticated(role?: ROLE) {
    if (!this._auth) {
      return false;
    }
    if (Date.now() / 1000 > this._auth.user.exp) {
      localStorage.removeItem(AUTH_KEY_LOCALSTORAGE);
      this._auth = undefined;
      return false;
    }
    if (role && !(this._auth.role == role)) {
      return false;
    }
    return true;
  }

  getToken() {
    if (!this._auth) {
      return null;
    }

    return this._auth.token;
  }
  getAndResetRedirectURL() {
    const url = this.isRedirectedURL;
    if (url) {
      this.isRedirectedURL = null;
      return url;
    }
  }
  public async emailCheck({
    email,
    userType,
  }): Promise<false | "magic" | "password"> {
    try {
      const result = await HttpClient(`/auth/email-check`, {
        method: "POST",
        body: { email, userType },
      });

      if (!result.success) {
        this.error = result.message;
        return false;
      }

      return result.data.loginType === "magic" ? "magic" : "password";
    } catch {
      return false;
    }
  }

  public async merchantLogin({ email, password, userType }): Promise<boolean> {
    try {
      const result = await HttpClient("/auth/merchants/login", {
        method: "POST",
        body: { email, password, userType },
        apiVersion: "v2",
      });

      if (!result.success) {
        this.error = result.message;
        return false;
      }

      this._auth = result.data;
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
      return true;
    } catch {
      return false;
    }
  }

  /**
   * adminLogin
   * login admin
   * @param Object { email, password, otp}
   * @returns
   */
  public async adminLogin({
    email,
    password,
    userType,
    otp,
  }): Promise<boolean> {
    try {
      const result = await HttpClient("/admin-users/auth/login", {
        method: "POST",
        body: { email, password, userType, otp },
        apiVersion: "v2",
      });

      if (!result.success) {
        this.error = result.message;
        return false;
      }

      this._auth = result.data;
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
      return true;
    } catch {
      return false;
    }
  }

  /**
   * getAdminLoginOtp
   * get login otp for admin
   * @param email
   * @returns
   */
  public async getAdminLoginOtp(email: string) {
    try {
      const result = await HttpClient(`/admin-users/auth/send-otp`, {
        method: "POST",
        body: {
          email,
        },
        apiVersion: "v2",
      });
      if (isSuccessResponse(result)) {
        return [undefined, result.data];
      } else {
        return [new Error(result.message), undefined];
      }
    } catch (e) {
      return [e, undefined];
    }
  }

  public async merchantSignUp({
    name,
    email,
    password,
    domain,
  }): Promise<boolean | "addRole"> {
    try {
      const result = await HttpClient(`/auth/merchants/sign-up`, {
        method: "POST",
        body: {
          name,
          email,
          password,
          domain,
        },
        apiVersion: "v2",
      });

      if (!result.success) {
        this.error = result.message;
        return false;
      }
      if (result.data.shouldAddRole) {
        return "addRole";
      }
      this._auth = result.data;
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
      return true;
    } catch {
      return false;
    }
  }

  public async signup({
    name,
    firstName,
    lastName,
    email,
    password,
    role,
    areMarketingEmailsAllowed = null,
    addingRole = false,
  }): Promise<boolean | "addRole"> {
    try {
      const result = await HttpClient(`/auth/signup`, {
        method: "POST",
        body: {
          name,
          firstName,
          lastName,
          email,
          password,
          role,
          areMarketingEmailsAllowed,
          addingRole,
        },
      });

      if (!result.success) {
        this.error = result.message;
        return false;
      }
      if (result.data.shouldAddRole) {
        return "addRole";
      }
      this._auth = result.data;
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
      return true;
    } catch {
      return false;
    }
  }

  public async googleLogin({ tokenId, role }): Promise<boolean> {
    try {
      const result = await HttpClient(`/google-login`, {
        method: "POST",
        body: { tokenId, role },
      });

      if (!result.success) {
        return false;
      }

      this._auth = result.data;
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
      return true;
    } catch {
      return false;
    }
  }

  public async facebookLogin(data, role) {
    try {
      if (!data) {
        throw new Error("Error while login with facebook");
      }
      if (!data.accessToken) {
        throw new Error(
          "Access token not found while login with facebook.Please try again"
        );
      }
      const result = await HttpClient(`/facebook-login`, {
        method: "POST",
        body: {
          token: data.accessToken,
          userId: data.userID,
          facebookData: data,
          role,
        },
      });

      if (!result.success) {
        this.error = result.message;
        return false;
      }

      this._auth = result.data;
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
      return true;
    } catch (err) {
      return false;
    }
  }

  async logout() {
    // Changed local storage to clear everything on logout
    localStorage.clear();
    this._auth = undefined;
  }
  public async signupMerchant(fromData: {
    email: string;
    password: string;
    domain: string;
    source?: string;
  }) {
    const response = await httpClientV2("/merchant-users/auth/sign-up", {
      method: "POST",
      apiVersion: "v2",
      body: fromData,
    });
    if (!isSuccessResponse(response)) {
      return { error: new Error(response.message) };
    }
    this._auth = response.data;
    localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
    return {
      authInfo: response.data,
    };
  }

  /**
   * loginMerchant
   * @param fromData
   * @returns
   */
  public async loginMerchant(fromData: { email: string; password: string }) {
    const response = await httpClientV2("/merchant-users/auth/login", {
      method: "POST",
      apiVersion: "v2",
      body: fromData,
    });
    if (!isSuccessResponse(response)) {
      return { error: new Error(response.message) };
    }
    const data: IMerchantAuthResponseJson = response.data;
    this._auth = data;
    localStorage.setItem(AUTH_KEY_LOCALSTORAGE, JSON.stringify(this._auth));
    return {
      authInfo: response.data,
    };
  }

  /**
   * forgotPasswordMerchant
   * @param formData
   * @returns
   */
  public async forgotPasswordMerchant(formData: { email: string }) {
    const response = await httpClientV2(`/merchant-users/auth/password/reset`, {
      method: "POST",
      body: formData,
      apiVersion: "v2",
    });
    if (!isSuccessResponse(response)) {
      return { error: new Error(response.message) };
    }
    return {
      message: response.message,
    };
  }
  /**
   *
   * @param formData
   * @returns
   */
  public resetPasswordMerchant(formData: { token: string; password: string }) {
    return httpClientV2(`/merchant-users/auth/password/verify`, {
      method: "POST",
      body: formData,
      apiVersion: "v2",
    });
  }
}

export default new AuthService();
