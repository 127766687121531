import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider from "../MerchantBaseProvider";
import PurchaseList from "./components/PurchaseList/PurchaseList";

const PurchasePage: React.FC = () => {
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar>
        <PurchaseList />
      </DashboardBase>
    </MerchantBaseProvider>
  );
};

export default PurchasePage;
