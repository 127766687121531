import { Input } from "antd";
import React, { ComponentProps, ComponentPropsWithRef } from "react";
import { SPACING } from "../constants";

type Props = ComponentPropsWithRef<typeof Input> & {};

export default function SwayTextInput(props: Props) {
  const commonStyle = {
    borderRadius: SPACING,
    height: 50,
  };
  if (props.type === "password") {
    return <Input.Password style={commonStyle} {...props} />;
  }
  return <Input style={commonStyle} {...props} />;
}
