import React, { useState } from 'react';
import {
  Button,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
  Paper,
} from '@material-ui/core';
import customSnacker from 'component/snackbar';
import { useHistory } from 'react-router-dom';
import useStyles from '../login/style';
import { FormattedMessage } from 'react-intl';
import validateEmail from 'helper/validate_email';
import httpClient from 'services/http-client';
import Config from 'config';
import Header from '../../../component/basic-header';
import Alert from '@material-ui/lab/Alert';

const userDataSchema = {
  email: '',
};
const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = customSnacker();
  const [userData, setUserData] = useState(userDataSchema);
  const [isLinkSend, setIsLinkSend] = useState(false);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(userData.email)) {
      snackbar('Please enter a valid Email Address', 'error');
      return;
    }
    try {
      const res = await httpClient(`/admin-users/auth/forgot`, {
        apiVersion: "v2",
        method: 'POST',
        body: { email: userData.email, role: 'admin' },
      });
      setIsLinkSend(true);
    } catch {
      snackbar('Some Error Occured', 'error');
    }
  };
  return (
    <>
      <Header link='/admin' />
      <Container component='main' maxWidth='xs'>
        <Paper>
          <div className={classes.paper}>
            <Typography component='h1' variant='h5'>
              <FormattedMessage id='modules.admin.forget_password.heading' />
            </Typography>
            <Typography
              component='h3'
              variant='h6'
              className={classes.subHeading}
            >
              <FormattedMessage id='modules.admin.forget_password.sub_heading' />
            </Typography>
            <form className={classes.form} noValidate>
              {!isLinkSend ? (
                <>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    value={userData.email}
                    onChange={handleChange}
                    label={
                      <FormattedMessage id='modules.admin.forget_password.email.label' />
                    }
                    name='email'
                    autoComplete='email'
                    autoFocus
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onSubmit={handleSubmit}
                    onClick={handleSubmit}
                    className={classes.submit}
                  >
                    <FormattedMessage id='modules.admin.forget_password.submit_button.text' />
                  </Button>
                </>
              ) : (
                <>
                  <Alert
                    icon={false}
                    severity='success'
                    style={{
                      fontSize: 20,
                      textAlign: 'center',
                      lineHeight: 1.2,
                      marginBottom: 15,
                      marginTop: 10,
                    }}
                  >
                    Password reset link has been sent to your email.
                  </Alert>
                  <div style={{ textAlign: 'center' }}>
                    <Typography
                      style={{ fontSize: 14, color: 'rgba(0,0,0,0.54)' }}
                      variant='caption'
                      gutterBottom
                    >
                      Didn’t receive an email?
                    </Typography>
                    <Button
                      onClick={handleSubmit}
                      style={{ textDecoration: 'underline' }}
                      color='primary'
                    >
                      RESEND
                    </Button>
                  </div>
                </>
              )}
              <Grid container style={{ marginTop: '15px' }} justify='center'>
                <Grid item>
                  <Link
                    style={{ cursor: 'pointer' }}
                    variant='body2'
                    onClick={() => history.push('/admin/login')}
                  >
                    <FormattedMessage id='modules.admin.forget_password.login_link.text' />
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPassword;
