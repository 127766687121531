import React from "react";
import AdminBase from "modules/admin/admin-base";
import BrandDetails from "./BrandDetails";
interface IProps {}
const BrandDetailsPage: React.FC<IProps> = (props) => {
  return (
    <AdminBase>
      <BrandDetails />
    </AdminBase>
  );
};
export default BrandDetailsPage;
