import { Form, message } from "antd";
import { SPACING, SPACING_1_5 } from "../constants";
import React, { useEffect, useState } from "react";
import SwayButton from "../components/SwayButton";
import SwayCard from "../components/SwayCard";
import SwayLink from "../components/SwayLink";
import SwaySelect, { Option } from "../components/SwaySelect";
import SwayText from "../components/SwayText";
import SwayTextInput from "../components/SwayTextInput";
import useColors from "../hooks/useColors";
import useOnboardingBaseStyles from "./useOnboardingBaseStyles";
import OnboardingBase from "./OnboardingBase";
import useSearchParams from "../hooks/useSearchParams";
import authService from "services/auth.service";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useAnalytics } from "../AnalyticsProvider";
interface IProps {}
const hearAboutOptions = [
  "Google",
  "Word of Mouth",
  "Facebook",
  "Instagram",
  "Blog Post",
  "In-Person Events",
];
const SignupPage: React.FC<IProps> = (props) => {
  const colors = useColors();
  const params = useSearchParams();
  const classes = useOnboardingBaseStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const analytics = useAnalytics();
  const onFinish = async (values) => {
    setLoading(true);
    const { error, authInfo } = await authService.signupMerchant({
      ...values,
      domain: params.get("shop"),
      retailerId: params.get("retailerId"),
    });
    setLoading(false);
    if (!error) {
      history.push("/merchant/content");
    } else {
      message.error(error.message);
    }
  };

  useEffect(() => {
    analytics.track("Signup Page", {
      createdAt: new Date().toISOString(),
    });
  }, []);
  return (
    <OnboardingBase>
      <div className={classes.header}>
        <SwayText
          type="title"
          style={{
            display: "inline-block",
            marginRight: SPACING,
            marginBottom: 0,
          }}
          size="small"
          weight="bold"
        >
          Already have an account?
        </SwayText>
        <SwayButton
          onClick={() => history.push("/merchant/login")}
          classType="secondary"
        >
          Login
        </SwayButton>
      </div>
      <SwayCard className={classes.card}>
        <SwayText
          type="title"
          style={{ marginBottom: SPACING_1_5, textAlign: "center" }}
        >
          Sign up for your account
        </SwayText>
        <Form
          form={form}
          name="basic"
          style={{ display: "flex", flexDirection: "column" }}
          onFinish={onFinish}
          // autoComplete="off"
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
              },
            ]}
          >
            <SwayTextInput placeholder="Your first name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input your last name!",
              },
            ]}
          >
            <SwayTextInput placeholder="Your last name" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <SwayTextInput placeholder="Your email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password length should be more than 14 characters",
                min: 14,
              },
            ]}
          >
            <SwayTextInput placeholder="Create Password" type="password" />
          </Form.Item>
          <Form.Item name="source">
            <SwaySelect
              size="large"
              placeholder="Where did you hear about Swaypay"
            >
              {hearAboutOptions.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </SwaySelect>
          </Form.Item>
          <SwayText
            size="small"
            color={colors.textGray}
            style={{
              padding: "0px 25px",
              textAlign: "center",
              display: "block",
            }}
          >
            By signing up, you confirm that you’ve read and accepted our{" "}
            <SwayLink
              style={{ color: colors.purple }}
              color={colors.purple}
              href="https://www.swaypayit.com/terms-of-service"
              target="_blank"
            >
              User Notice
            </SwayLink>{" "}
            and{" "}
            <SwayLink
              style={{ color: colors.purple }}
              href="https://www.swaypayit.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </SwayLink>
          </SwayText>
          <SwayButton
            loading={loading}
            disabled={loading}
            htmlType="submit"
            style={{ height: 49, marginTop: SPACING }}
          >
            Create your account
          </SwayButton>
        </Form>
      </SwayCard>
      <div />
    </OnboardingBase>
  );
};
export default SignupPage;
