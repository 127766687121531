import {
  Container, Grid,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import customSnacker from 'component/snackbar';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './style';



const Error = () => {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = customSnacker();

  return (
    <>
      <Container component='main' maxWidth='md'>
        <Grid container style={{ marginTop: 100 }} justify='center'>
          <Alert severity='error' variant='filled'>
            <Typography variant='h6'>
              Something went wrong while connecting your store. Please try again
              after some time. If the issue persists, contact
              service@swaypayit.com
            </Typography>
          </Alert>
        </Grid>

      </Container>
    </>
  );
};

export default Error;
