import Text from "antd/lib/typography/Text";
import React from "react";
interface IProps {
  children?: React.ReactNode;
}
const Label: React.FC<IProps> = (props) => {
  return (
    <Text
      type="secondary"
      style={{ display: "block", marginBottom: 4, fontSize: 13 }}
      strong
    >
      {props.children}
    </Text>
  );
};
export default Label;
