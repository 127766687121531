import * as React from "react";

const SwayPhoneHeartSvg = (props: { active?: boolean }) => {
  return (
    <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 4.138a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-16Z"
        fill={props.active ? "var(--primary)" : "#fff"}
      />
      <path
        d="M19 4.138a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-2M12 18.138h.01"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.306 7.846a2.365 2.365 0 0 0-.768-.524 2.33 2.33 0 0 0-2.582.524l-.456.465-.456-.465a2.346 2.346 0 0 0-1.675-.707c-.629 0-1.231.254-1.675.707A2.438 2.438 0 0 0 13 9.553c0 .64.25 1.254.694 1.706l.456.466 3.35 3.413 3.35-3.413.456-.466c.22-.224.395-.49.514-.783a2.453 2.453 0 0 0-.514-2.63Z"
        fill="#DDF7BB"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SwayPhoneHeartSvg;
