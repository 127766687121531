import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 6C2.25 3.92893 3.92893 2.25 6 2.25H12C14.0711 2.25 15.75 3.92893 15.75 6V12C15.75 14.0711 14.0711 15.75 12 15.75H6C3.92893 15.75 2.25 14.0711 2.25 12V6Z"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.19453 8.49185C7.09184 8.49185 6.19794 9.37511 6.19794 10.4647C6.19794 11.5542 7.09184 12.4375 8.19453 12.4375C9.29721 12.4375 10.1911 11.5542 10.1911 10.4647V4.875"
        stroke="black"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 7.15642C11.2248 7.15642 10.1911 6.135 10.1911 4.875"
        stroke="black"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
