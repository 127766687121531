import {
  Visa,
  Amex,
  Mastercard,
  Discover,
  CartesBancaires,
  Diners,
  Jcb,
  UnionPay,
} from "react-pay-icons";
import { CreditCardIcon } from "../assets/icons";

const getCardImage = (cardType: string) => {
  let cardImage;
  switch (cardType) {
    case "amex":
      cardImage = <Amex />;
      break;
    case "cartes_bancaires":
      cardImage = <CartesBancaires />;
      break;
      break;
    case "diners":
      cardImage = <Diners />;
      break;
    case "discover":
      cardImage = <Discover />;
      break;
    case "jcb":
      cardImage = <Jcb />;
      break;
    case "mastercard":
      cardImage = <Mastercard />;
      break;
    case "visa":
      cardImage = <Visa />;
      break;
    case "unionpay":
      cardImage = <UnionPay />;
      break;
    default:
      cardImage = <CreditCardIcon />;
      break;
  }

  return cardImage;
};
export default getCardImage;
