import { ColumnsType } from "antd/lib/table";
import { DefaultRecordType } from "rc-table/lib/interface";
import moment from "moment";
import Config from "config";
import React from "react";
import { CaretDownOutlined } from "@ant-design/icons";
export default (sortInfo): ColumnsType<DefaultRecordType> => {
  return [
    {
      title: (
        <span>
          Date Created <CaretDownOutlined style={{ color: "#8CE41D" }} />
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) =>
        value && moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "User name",
      dataIndex: ["user", "username"],
      key: "user.brand.name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Expire date",
      dataIndex: "expireAt",
      key: "expireAt",
      render: (value) =>
        value && moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "Viewed date",
      dataIndex: "viewedAt",
      key: "viewedAt",
      render: (value) =>
        value && moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "Claimed date",
      dataIndex: "claimedAt",
      key: "claimedAt",
      render: (value) =>
        value && moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "Locked date",
      dataIndex: "lockedAt",
      key: "lockedAt",
      render: (value) =>
        value && moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "Completed date",
      dataIndex: "completedAt",
      key: "completedAt",
      render: (value) =>
        value && moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
  ];
};
