import React, { useState } from "react";
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Container,
  Paper,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import customSnacker from "component/snackbar";
import { useHistory } from "react-router-dom";
import validateEmail from "helper/validate_email";
import { AuthService } from "services";
import useStyles from "./style";
import { FormattedMessage } from "react-intl";
import Header from "../../../component/basic-header";
import clsx from "clsx";

const userDataSchema = {
  email: "",
  password: "",
  otp: "",
  rememberMe: false,
  userType: "admin",
};
const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = customSnacker();
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState(userDataSchema);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [handleSubmitLoading, setHandleSubmitLoading] = useState(false);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [name]: value });
  };
  const handleCheckboxes = ({
    target: { checked, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [name]: checked });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setHandleSubmitLoading(true);
    e.preventDefault();
    if (userData.email.trim() === "" || userData.password.trim() === "") {
      snackbar("Please enter a valid Email and password", "error");
      return;
    }
    if (!validateEmail(userData.email)) {
      snackbar("Please enter a valid Email Address", "error");
      return;
    }
    setIsLogin(true);
    const res: any = await AuthService.adminLogin(userData);
    if (res) {
      history.push("/admin/purchases");
    } else {
      snackbar(AuthService.error, "error");
    }
    setIsLogin(false);
    setHandleSubmitLoading(false);
  };

  /**
   * handler to call on send otp button click
   */
  const onClickSendOtpButton = async () => {
    setSendOtpLoading(true);
    const [error, data] = await AuthService.getAdminLoginOtp(userData.email);
    if (error) {
      snackbar(error.message, "error");
    }
    setSendOtpLoading(false);
  };

  return (
    <>
      <Header link="/admin" />

      <Container component="main" maxWidth="xs">
        <Paper>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              <FormattedMessage id="modules.admin.login.heading" />
            </Typography>
            <form
              className={clsx(classes.form, "see-admin-login-form")}
              noValidate
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                value={userData.email}
                onChange={handleChange}
                label={
                  <FormattedMessage id="modules.admin.login.email.label" />
                }
                name="email"
                autoComplete="email"
                autoFocus
                className={clsx("see-admin-login-form__email-input")}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                value={userData.password}
                onChange={handleChange}
                name="password"
                label={
                  <FormattedMessage id="modules.admin.login.password.label" />
                }
                type="password"
                autoComplete="current-password"
                className={clsx("see-admin-login-form__password-input")}
              />
              <Grid container justify="space-around" alignItems="center">
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={userData.otp}
                  onChange={handleChange}
                  name="otp"
                  label={
                    <FormattedMessage id="modules.admin.login.otp.label" />
                  }
                  type="otp"
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={sendOtpLoading}
                  onClick={onClickSendOtpButton}
                  className={clsx(classes.sendOtpButton)}
                >
                  <FormattedMessage id="modules.admin.login.button_send_otp.text" />
                </Button>
              </Grid>
              <Grid container justify="flex-end">
                {/* <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='rememberMe'
                        checked={userData.rememberMe}
                        onChange={handleCheckboxes}
                        value='remember'
                        color='primary'
                      />
                    }
                    label={
                      <FormattedMessage id='modules.admin.login.checkbox.label' />
                    }
                  />
                </Grid> */}
                <Grid style={{ marginTop: 11 }} item>
                  <Link
                    style={{ cursor: "pointer" }}
                    variant="body2"
                    onClick={() => history.push("/admin/forgot-password")}
                  >
                    <FormattedMessage id="modules.admin.login.forget_password.text" />
                  </Link>
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={handleSubmitLoading || userData.otp.length < 6}
                onSubmit={handleSubmit}
                onClick={handleSubmit}
                className={clsx(
                  classes.submit,
                  "see-admin-login-form__submit-button"
                )}
              >
                <FormattedMessage id="modules.admin.login.button_sign_in.text" />
                {isLogin && "..."}
              </Button>
            </form>
          </div>
        </Paper>
      </Container>
    </>
  );
};

export default Login;
