import { Avatar, Col, Row, Tabs, Pagination } from "antd";
import CalendarIcon from "assets/images/calendar.png";
import { SPACING, SPACING_0_5 } from "modules/merchantV2/constants";
import useColors from "modules/merchantV2/hooks/useColors";
import moment from "moment";
import { useEffect, useState } from "react";
import postService from "services/post.service";
import {
  COMMISSION_PAYMENT_STATUS,
  IContentPost,
  IContentPostPricingTier,
  IPostService,
} from "types/post.interface";
import Config from "../../../../../config";
import { capitalizeFirstLetter } from "../../../../../helper/capitalizeFirstLetter";
import { formatCurrency } from "../../../../../helper/locale.helper";
import { formatCentsToDollars } from "../../../../../helper/v2-helpers/currency";
import SwayTable from "../../../components/SwayTable";
import SwayTag from "../../../components/SwayTag";
import SwayText from "../../../components/SwayText";
import SwayDemoSelect from "../../../content-page/components/SwayDemoSelect";
import PurchaseListEmptyState from "./PurchaseListEmptyState";
import ViewPostIcon from "modules/merchantV2/icons/ViewPost";
import UserProfileIcon from "modules/merchantV2/icons/UserProfile";
import SwayButton from "modules/merchantV2/components/SwayButton";
import PurchasePost from "modules/merchantV2/content-page/components/PurchasePost/PurchasePost";
const PurchaseList: React.FC = () => {
  const colors = useColors();
  const service: IPostService = postService;

  const items = [
    { label: "All", key: "all" }, // remember to pass the key prop
    { label: "Pending", key: "pending" },
    { label: "Paid", key: "paid" },
    //{ label: "Freebie", key: "freebie" },
  ];
  const [filters, setFilters] = useState(["Pending", "Paid", "Freebie"]);
  const [loading, setLoading] = useState(false);
  const [pricingTiers, setPricingTiers] = useState<IContentPostPricingTier[]>(
    []
  );
  const [posts, setPosts] = useState<IContentPost[]>([]);
  const [selectedPost, setSelectedPost] = useState<IContentPost>();

  const [selectedPostInfo, setSelectedPostInfo] = useState<{
    post: IContentPost;
    isSamplePost: boolean;
  }>({ post: undefined, isSamplePost: false });

  /**
   * We need 5 columns for the post list at the large size, which isn't possible with ant design,
   * so we need conditional classing for the column node to make sure that it
   * can still gracefully go down to lower sizes.
   */
  const lgColStyles = {
    flexBasis: "20%",
    width: "20%",
  };

  useEffect(() => {
    loadPosts();
  }, [filters]);

  const onTabsChange = (key) => {
    if (key?.toLowerCase() === "all") {
      setFilters(["Pending", "Paid", "Freebie"]);
    } else {
      setFilters([key]);
    }
  };

  const loadPosts = async () => {
    // Grab current filter state, load from api, update current list
    // Refer to table service for url extraction helper method, need to set up for actual filtering once components complete.
    setLoading(true);
    const { error: tiersError, pricingTiers: responseTiers } =
      await service.getPricingTiers();
    if (tiersError) {
      setLoading(false);
      return;
    }

    setPricingTiers(responseTiers);
    const queryString =
      "?commissionPaymentStatus=" + filters.join(",")?.toLowerCase();
    // Using the response tiers so we don't get any weird race conditions with useState
    const { posts: responsePosts, error: postsError } = await service.getPosts(
      queryString
    );
    setLoading(false);

    if (postsError) {
      return;
    }

    setPosts(responsePosts);
  };

  const postClicked = (post: IContentPost) => {
    setSelectedPostInfo({ post, isSamplePost: true });
  };

  const postPurchaseCanceled = () => {
    setSelectedPostInfo({ post: undefined, isSamplePost: false });
  };

  const columns = [
    {
      title: <span style={{ color: colors.gray }}>TikTok Handle</span>,
      dataIndex: ["userTiktokInfo"],
      key: "tiktokHandle",
      render: (value) => {
        return (
          <>
            <Avatar
              size={40}
              icon={<UserProfileIcon userImg={value.avatarUrl} />}
              style={{
                borderWidth: 2,
                backgroundColor: colors.background,
                borderStyle: "solid",
                marginRight: SPACING_0_5,
              }}
            />
            <SwayText size="small" weight="bold">
              {value.tiktokHandle ? value.tiktokHandle : `user${value.id}`}
            </SwayText>
          </>
        );
      },
    },
    {
      title: <span style={{ color: colors.gray }}>Purchase Date</span>,
      dataIndex: ["purchase", "createdAt"],
      key: "createdAt",
      render: (value) => {
        return moment.utc(value).format(Config.DEFAULT_DATE_FORMAT);
      },
    },
    {
      title: <span style={{ color: colors.gray }}>Order Amount</span>,
      dataIndex: ["purchase", "totalPriceInCents"],
      key: "orderAmount",
      render: (value) => formatCurrency(formatCentsToDollars(value)),
    },
    {
      title: <span style={{ color: colors.gray }}>Your Cost</span>,
      dataIndex: "paymentPriceInCents",
      key: "paymentPriceInCents",
      render: (value) => formatCurrency(formatCentsToDollars(value)),
    },
    {
      title: <span style={{ color: colors.gray }}>Billing Status</span>,
      dataIndex: "commissionPaymentStatus",
      key: "commissionPaymentStatus",
      render: (value) => {
        let color;
        switch (value) {
          case COMMISSION_PAYMENT_STATUS.PAID:
            color = "green";
            break;
          default:
            color = "white";
        }
        return (
          <SwayTag color={color}>
            {capitalizeFirstLetter(value?.toLowerCase(value))}
          </SwayTag>
        );
      },
    },
    {
      render: (value) => {
        // return (
        //   <a
        //     href={value}
        //     target="_blank"
        //     style={{
        //       display: "flex",
        //       color: "inherit",
        //     }}
        //   >
        //     <SwayText size="small" style={{ marginRight: SPACING_0_5 }}>
        //       View Post
        //     </SwayText>
        //     <ViewPostIcon />
        //   </a>
        // );

        return (
          <SwayButton
            disabled={false}
            classType={null}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: SPACING,
              paddingRight: SPACING,
              marginRight: SPACING_0_5,
              borderWidth: 2,
              height: 25,
              fontSize: 14,
              color: colors.backgroundInverted,
              backgroundColor: colors.primary,
              borderColor: colors.backgroundInverted,
            }}
            onClick={() => postClicked(value)}
          >
            View
          </SwayButton>
        );
      },
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {selectedPostInfo.post ? (
        <PurchasePost
          post={selectedPostInfo.post}
          isSamplePost={selectedPostInfo.isSamplePost}
          onCancel={() => {
            postPurchaseCanceled();
          }}
        />
      ) : null}
      {/* <Row
        style={{
          width: "100%",
          padding: `${SPACING}px ${SPACING}px 0`,
          flex: 0.05,
        }}
      >
        <SwayDemoSelect
          icon={<img src={CalendarIcon} style={{ width: 20, height: 20 }} />}
          title="Today"
        />
      </Row> */}

      <Row
        style={{
          width: "100%",
          padding: SPACING,
          flex: 0.95,
        }}
      >
        <SwayTable
          filterSection={<Tabs onChange={onTabsChange} items={items} />}
          rowKey="id"
          columns={columns}
          pagination={false}
          dataSource={posts}
          locale={{
            emptyText: <PurchaseListEmptyState></PurchaseListEmptyState>,
          }}
          style={{ width: "100%", height: "100%" }}
          size="small"
        />
      </Row>
    </div>
  );
};

export default PurchaseList;
