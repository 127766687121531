import { SearchOutlined } from "@ant-design/icons";
import { Container, Grid } from "@material-ui/core";
import {
  Card,
  DatePicker,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import Input from "antd/lib/input";
import Loading from "component/loading";
import ProgressBar from "component/progressBar";
import { AdminContext } from "modules/admin/admin-base";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import tableV2Service from "services/table-v2.service";
import useStyles from "modules/common/commonAntTableStyles";
import getColumns from "./getColumns";
import Text from "antd/lib/typography/Text";
import Label from "component/Label/Label";
import Config from "config";
import numberFormatter from "helper/numberFormatter";
import { SPACING_1_5, SPACING_2 } from "../../../modules/merchantV2/constants";
import TreeSelect from "component/treeSelect/TreeSelect";
import UserDetails from "modules/admin/users/UserDetails";
import CustomModal from "component/customModal";
import PurchaseDetails from "modules/admin/purchases/PurchaseDetails";
import offerStatusMapping from "helper/v2-helpers/offerStatusMapping";

const { RangePicker } = DatePicker;
const userFilterSchema = {
  applicationStatus: [],
  offerStatus: "action_required",
  applyStartDate: "" as any,
  applyEndDate: "" as any,
  approvalStartDate: "" as any,
  approvalEndDate: "" as any,
  search: "",
  source: [],
  campaign: [],
  followersCountMin: "",
  followersCountMax: "",
  likesCountMin: "",
  likesCountMax: "",
  currentBalanceMin: "",
  currentBalanceMax: "",
  tags: [],
};
const allStatus = [
  // { id: "pending", name: "Applied" },
  { id: "accepted", name: "Accepted" },
  // { id: "rejected", name: "Rejected" },
  // { id: "waiting_list", name: "Waitlist" },
  { id: "waiting_tiktok_linking", name: "Waitlist Tiktok Linking" },
  { id: "banned", name: "Banned" },
];
interface Props {}
const keys = Object.keys(userFilterSchema);
const formatUrlQuery = tableV2Service.formatUserUrl(keys);
const defaultSort = {
  order: "descend",
  columnKey: "createdAt",
};
const UserTableAnt: React.FC<Props> = (props) => {
  const [data, setData] = React.useState([]);
  const history = useHistory();
  const [pagination, setPagination] = React.useState<any>({
    current: 1,
    pageSize: 5,
  });
  const [visted, setVisted] = React.useState({});
  const [selectedUserId, setSelectedUserId] = React.useState();
  const [selectedPurhcaseId, setSelectedPurhcaseId] = React.useState();
  const [sortInfo, setSortInfo] = React.useState<any>(defaultSort);
  const [loading, setLoading] = React.useState(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const [waiting, setWaiting] = React.useState(false);
  const [initData, setInitData] = React.useState({
    distinctSource: [],
    distinctCampaign: [],
  });
  const [userStats, setUserStats] = React.useState({
    totalUsers: 0,
    totalFollowersCount: 0,
    userStatsBreakUp: [],
  });
  const [filters, setFilters] = React.useState(userFilterSchema);
  const adminContext = React.useContext(AdminContext);
  const defaultFilterMapping = keys.reduce(
    (prev, next) => ({ ...prev, [next]: filters[next] }),
    {}
  );
  const classes = useStyles();
  const columns = getColumns(sortInfo);
  const getData = async ({ query, init = false }) => {
    setLoading(true);
    const res = await tableV2Service.getUserListAdmin({
      query,
      init,
    });
    setLoading(false);
    setUserStats(res.data.stats);
    if (initLoading) {
      setInitData({
        ...initData,
        distinctSource: res.data.distinctSource,
        distinctCampaign: res.data.distinctCampaign,
      });
      setInitLoading(false);
    }
    setPagination((pagination) => ({
      ...pagination,
      total: res.data.totalCount,
    }));
    setData(res.data.users);
  };
  React.useEffect(() => {
    if (!initLoading && !waiting) {
      const updatedPagination = { ...pagination, current: 1 };
      getData({
        query: tableV2Service.formatQueryAPI(
          sortInfo,
          updatedPagination,
          defaultFilterMapping
        ),
      });
      setPagination(updatedPagination);
      const url = formatUrlQuery(sortInfo, { ...filters }, updatedPagination);
      window.history.replaceState({ path: url }, "", url);
    }
  }, [filters]);
  React.useEffect(() => {
    tableV2Service.onMount({
      sortInfo,
      setSortInfo,
      pagination,
      setPagination,
      filters,
      setFilters,
      getData,
      defaultFilterMapping,
    });
  }, []);
  const handleTableChange = tableV2Service.handleTableChange({
    sortInfo,
    formatUrlQuery,
    filters,
    setSortInfo,
    setPagination,
    getData,
    defaultFilterMapping,
  });
  const handleSearch = tableV2Service.handleSearch({
    waiting,
    setWaiting,
    setFilters,
  });
  const handleChangeSelectAnt = tableV2Service.handleChangeSelectAnt({
    setFilters,
  });
  const handleDateRangeChange = tableV2Service.handleDateRangeChange({
    setFilters,
  });
  return (
    <Container
      style={{
        marginBottom: 60,
        margin: 0,
        maxWidth: "100%",
      }}
      className={loading && classes.muiTableLoading}
    >
      {initLoading ? <Loading /> : null}
      {loading ? <ProgressBar /> : null}
      <Grid container>
        <Grid md={12} style={{ paddingRight: 8 }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Label>Apply Date</Label>
              <RangePicker
                format={Config.DEFAULT_DATE_FORMAT}
                onChange={handleDateRangeChange(
                  "applyStartDate",
                  "applyEndDate"
                )}
                value={[filters.applyStartDate, filters.applyEndDate]}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={3}>
              <Label>First name or Email or Phone or Tiktok or UserID.</Label>
              <Input
                prefix={<SearchOutlined />}
                value={filters.search}
                placeholder="Search on First name or Email or Phone or Tiktok or UserID."
                allowClear
                onChange={handleSearch("search")}
              />
            </Grid>
            <Grid item md={3}>
              <Label>Offer Status</Label>
              <Select
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.offerStatus}
                style={{ width: "100%" }}
                placeholder="Offer Status"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(allStatus, "offerStatus")}
              >
                {offerStatusMapping.map((status) => (
                  <Select.Option key={status.id}>{status.name}</Select.Option>
                ))}
              </Select>
            </Grid>
            {/* <Grid item md={3}>
              <Label>Approval Date</Label>
              <RangePicker
                format={Config.DEFAULT_DATE_FORMAT}
                onChange={handleDateRangeChange(
                  "approvalStartDate",
                  "approvalEndDate"
                )}
                value={[filters.approvalStartDate, filters.approvalEndDate]}
                style={{ width: "100%" }}
              />
            </Grid> */}
            <Grid item md={3}>
              <Label>Application Status</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.applicationStatus}
                style={{ width: "100%" }}
                placeholder="Application Status"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(allStatus, "applicationStatus")}
              >
                {allStatus.map((status) => (
                  <Select.Option key={status.id}>{status.name}</Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item md={3}>
              <Label>User Tags</Label>
              <TreeSelect
                tags={adminContext.userTags}
                handleChange={handleChangeSelectAnt(
                  adminContext.userTags,
                  "tags"
                )}
                placeholder="User Tags"
              />
            </Grid>

            <Grid item md={3}>
              <Label>Source</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.source}
                style={{ width: "100%" }}
                placeholder="Source"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(
                  initData.distinctSource,
                  "source"
                )}
              >
                {initData.distinctSource.map((source) => (
                  <Select.Option key={source}>{source}</Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item md={3}>
              <Label>Campaign</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.campaign}
                style={{ width: "100%" }}
                placeholder="Campaign"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(
                  initData.distinctCampaign,
                  "campaign"
                )}
              >
                {initData.distinctCampaign.map((campaign) => (
                  <Select.Option key={campaign}>{campaign}</Select.Option>
                ))}
              </Select>
            </Grid>
            {/* <Grid item md={3}>
              <Label>Follower Count</Label>
              <Space>
                <Input
                  type="number"
                  value={filters.followersCountMin}
                  onChange={handleSearch("followersCountMin")}
                  style={{ width: "100%" }}
                  placeholder="min"
                />
                <Input
                  type="number"
                  value={filters.followersCountMax}
                  onChange={handleSearch("followersCountMax")}
                  style={{ width: "100%" }}
                  placeholder="max"
                />
              </Space>
            </Grid>
            <Grid item md={3}>
              <Label>Profile Likes Count</Label>
              <Space>
                <Input
                  type="number"
                  value={filters.likesCountMin}
                  onChange={handleSearch("likesCountMin")}
                  style={{ width: "100%" }}
                  placeholder="min"
                />
                <Input
                  type="number"
                  value={filters.likesCountMax}
                  onChange={handleSearch("likesCountMax")}
                  style={{ width: "100%" }}
                  placeholder="max"
                />
              </Space>
            </Grid> */}
            <Grid item md={3}>
              <Label>Current Bal</Label>
              <Space>
                <Input
                  type="number"
                  value={filters.currentBalanceMin}
                  onChange={handleSearch("currentBalanceMin")}
                  style={{ width: "100%" }}
                  placeholder="min"
                />
                <Input
                  type="number"
                  value={filters.currentBalanceMax}
                  onChange={handleSearch("currentBalanceMax")}
                  style={{ width: "100%" }}
                  placeholder="max"
                />
              </Space>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid md={2}>
          <Card size="small" style={{ marginRight: SPACING_2 }}>
            <ul
              style={{
                paddingInlineStart: SPACING_1_5,
                marginBottom: 0,
                marginTop: 4,
              }}
            >
              <li>
                Total Users: <strong>{userStats.totalUsers}</strong>
              </li>
              <li>
                Total Followers:{" "}
                <strong>
                  {numberFormatter(userStats.totalFollowersCount)}
                </strong>
              </li>
              {userStats.userStatsBreakUp.map((item) => (
                <li>
                  {allStatus.find((status) => status.id === item.status)?.name}{" "}
                  : <strong>{item.count}</strong>
                </li>
              ))}
            </ul>
          </Card>
        </Grid> */}
      </Grid>

      <div style={{ marginBottom: 10 }}></div>
      <Grid container spacing={1}>
        <Grid item md={4}>
          {React.useMemo(
            () => (
              <Table
                rowKey="id"
                style={{ position: "sticky", top: 80 }}
                rowClassName={(record, index) =>
                  visted[record.id] ? "table-row-visted" : "table-row-light"
                }
                onRow={(record) => ({
                  onClick: (e) => {
                    const route = `/admin/users/${record.id}`;
                    // check for both windows or mac
                    setVisted({ ...visted, [record.id]: true });
                    setSelectedUserId(record.id);
                    // window.open(route, "_blank");

                    // if (e.ctrlKey || e.metaKey) {

                    // } else {
                    // }
                  },
                })}
                onChange={handleTableChange}
                columns={columns}
                dataSource={data}
                pagination={pagination}
                loading={loading}
              />
            ),
            [data, pagination, visted]
          )}
        </Grid>
        <Grid item md={8}>
          {selectedUserId && (
            <UserDetails
              key={selectedUserId}
              setPurchase={(id) => setSelectedPurhcaseId(id)}
              userId={selectedUserId}
            />
          )}
        </Grid>
      </Grid>
      <Modal
        width={"95%"}
        footer={null}
        onCancel={() => setSelectedPurhcaseId(null)}
        open={selectedPurhcaseId}
      >
        <PurchaseDetails
          key={selectedPurhcaseId}
          purchaseId={selectedPurhcaseId}
        />
      </Modal>
    </Container>
  );
};

export default UserTableAnt;
