import { Checkbox, Divider, message, Modal } from "antd";
import React, { useState } from "react";
import { CommonFormProps, formSteps } from "./formConstants";
import BrandPledgeImg from "assets/images/brand-pledge-main.png";
import SwayText from "../components/SwayText";
import { SPACING, SPACING_2 } from "../constants";
import SwayButton from "../components/SwayButton";
import onboardingFormsService from "services/onboarding-forms.service";

const commonTextStyle = { display: "block", marginBottom: SPACING };

const BrandPledge = (props: CommonFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async () => {
    setLoading(true);
    const { error } = await onboardingFormsService.updateOnboardingForm(
      props.retailer.id,
      props.retailer.onboardingForm.id,
      { [formSteps.signContract]: true }
    );
    setLoading(false);
    if (!error) {
      props.updateFormProgress(formSteps.signContract);
      setIsModalOpen(false);
    } else {
      message.error(error.message);
    }
  };
  return (
    <div>
      <Modal
        footer={null}
        bodyStyle={{
          width: "100%",
        }}
        width={700}
        afterClose={props.handleClose}
        title="The Swaypay promise"
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <img
          src={BrandPledgeImg}
          style={{ minHeight: 480, width: "100%", marginBottom: SPACING_2 }}
        />
        <SwayText style={commonTextStyle}>
          Gen Z has a lot of creativity and sway ;) but not a lot of money.
          You're a brand hungry for better ways to market...especially on
          TikTok.
        </SwayText>
        <SwayText style={commonTextStyle}>
          Swaypay connects the dots with our mobile app that rewards Gen Z
          shoppers for TikToking their favorite purchases.
        </SwayText>
        <SwayText style={commonTextStyle}>
          Let Swaypay put your marketing dollars where they belong: in your
          shoppers' pockets.
        </SwayText>
        <Divider />
        <Checkbox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        >
          You have read and agree to this brand agreement
        </Checkbox>

        <SwayButton
          onClick={onFinish}
          disabled={!checked || loading}
          loading={loading}
          style={{ display: "block", marginTop: SPACING }}
        >
          Agree
        </SwayButton>
      </Modal>
    </div>
  );
};

export default BrandPledge;
