import { PaymentMethod } from "@stripe/stripe-js";
import { Row } from "antd";
import React, { useEffect, useState } from "react";
import paymentService from "../../../services/payment.service";
import { SPACING_2 } from "../constants";
import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider, { MerchantContext } from "../MerchantBaseProvider";
import InvoiceList from "./components/InvoiceList/InvoiceList";
import Payment from "./components/Payment/Payment";
import PendingCharges from "./components/PendingCharges/PendingCharges";
import PricingInfo from "./components/PricingInfo/PricingInfo";

const BillingPage: React.FC = () => {
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar>
        <Row
          style={{
            width: "100%",
            paddingRight: SPACING_2,
            paddingBottom: SPACING_2,
            paddingLeft: SPACING_2,
            paddingTop: SPACING_2,
            flexDirection: "column",
          }}
          gutter={[0, SPACING_2]}
        >
          {/* <PricingInfo></PricingInfo> */}
          <PendingCharges></PendingCharges>

          <Payment></Payment>

          <InvoiceList></InvoiceList>
        </Row>
      </DashboardBase>
    </MerchantBaseProvider>
  );
};
export default BillingPage;
