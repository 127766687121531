import { Container, Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import { Card, Select, Table, DatePicker, Space, Input } from "antd";
import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import tableV2Service from "services/table-v2.service";
import offerV2Service from "services/offer.service";
import getColumns from "../../../component/admin-v2-tables/UsersByOffer/getColumns";
import Loading from "component/loading";
import ProgressBar from "component/progressBar";
import Label from "component/Label/Label";
import { SearchOutlined } from "@material-ui/icons";
import clsx from "clsx";
import useStyles from "modules/admin/styles/commonDetailsPageStyle";

const statusList = [
  { id: "READY_TO_CLAIM", name: "READY_TO_CLAIM" },
  { id: "CLAIMED", name: "CLAIMED" },
  { id: "VIEWED", name: "VIEWED" },
  { id: "LOCKED", name: "LOCKED" },
  { id: "EXPIRED", name: "EXPIRED" },
  { id: "COMPLETED", name: "COMPLETED" },
];

const offerFilterSchema = {
  status: [],
  user: "",
  completedAtStartDate: "" as any,
  completedAtEndDate: "" as any,
  lockedAtStartDate: "" as any,
  lockedAtEndDate: "" as any,
  claimedAtStartDate: "" as any,
  claimedAtEndDate: "" as any,
  viewedAtStartDate: "" as any,
  viewedAtEndDate: "" as any,
  expireAtStartDate: "" as any,
  expireAtEndDate: "" as any,
};

interface IProps {
  offerId?: string;
}

const defaultSort = {
  order: "descend",
  columnKey: "createdAt",
};

const { RangePicker } = DatePicker;
const keys = Object.keys(offerFilterSchema);
const formatUrlQuery = tableV2Service.formatUserUrl(keys);

const OfferDetails: React.FC<IProps> = (props) => {
  const params = useParams<any>();
  const offerId = props.offerId ? props.offerId : params.offerId;

  const [pagination, setPagination] = React.useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [data, setData] = React.useState([]);
  const [offer, setOffer] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(null);
  const [waiting, setWaiting] = React.useState(false);
  const [sortInfo, setSortInfo] = React.useState<any>(defaultSort);
  const [loading, setLoading] = React.useState(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const [filters, setFilters] = React.useState(offerFilterSchema);
  const classes = useStyles();

  const columns = getColumns(sortInfo);
  const defaultFilterMapping = keys.reduce(
    (prev, next) => ({ ...prev, [next]: filters[next] }),
    {}
  );

  const getData = async ({ query, init = false }) => {
    setLoading(true);
    const res = await tableV2Service.getUsersByOfferListAdmin({
      offerId,
      query,
      init,
    });
    setLoading(false);
    if (initLoading) {
      setInitLoading(false);
    }
    setPagination((pagination) => ({
      ...pagination,
      total: res.data.totalCount,
    }));
    setData(res.data.offers);
    setTotalCount(res.data.totalCount);
  };

  const getOffer = async () => {
    const offerData = await offerV2Service.getOffer(offerId);
    setOffer(offerData.offer);
  }

  React.useEffect(() => {
    if (!initLoading && !waiting) {
      getData({
        query: tableV2Service.formatQueryAPI(
          sortInfo,
          pagination,
          defaultFilterMapping
        ),
      });

      const url = formatUrlQuery(sortInfo, { ...filters });
      window.history.replaceState({ path: url }, "", url);
    }
  }, [filters]);
  React.useEffect(() => {
    tableV2Service.onMount({
      sortInfo,
      setSortInfo,
      pagination,
      setPagination,
      filters,
      setFilters,
      getData,
      defaultFilterMapping,
    });
    getOffer();
  }, []);

  const handleTableChange = tableV2Service.handleTableChange({
    sortInfo,
    formatUrlQuery,
    filters,
    setSortInfo,
    setPagination,
    getData,
    defaultFilterMapping,
  });

  const handleDateRangeChange = tableV2Service.handleDateRangeChange({
    setFilters,
  });

  const handleSearch = tableV2Service.handleSearch({
    waiting,
    setWaiting,
    setFilters,
  });

  const handleChangeSelectAnt = tableV2Service.handleChangeSelectAnt({
    setFilters,
  });

  return (
    <Container
      style={{
        marginBottom: 60,
      }}
    >
      {initLoading ? <Loading /> : null}
      {loading ? <ProgressBar /> : null}
      <Paper>
        <Grid className={clsx(classes.purchaseItemsDiv)} container>
          <Grid item md={6}>
            {offer &&
              <>
                <Typography>
                  Retailer: {offer.brandRetailerAssociation.retailer.name}
                </Typography>
                <Typography>
                  Brand: {offer.brandRetailerAssociation.brand.name}
                </Typography>
                <Typography>
                  Offer Name: {offer.name}
                </Typography>
                <Typography>
                  Balance Amount In Cents: {offer.balanceAmountInCents}
                </Typography>
                <Typography>
                  Balance Claim Count: {offer.balanceClaimCount}
                </Typography>
                <Typography>
                  Max Cashback In Cents: {offer.maxCashbackInCents}
                </Typography>
                <Typography>
                  Max Claim Count: {offer.maxClaimCount}
                </Typography>
                <Typography>
                  Max Purchase Value In Cents: {offer.maxPurchaseValueInCents}
                </Typography>
                <Typography>
                  Offer Sub Type: {offer.offerSubType}
                </Typography>
                <Typography>
                  Offer Type: {offer.offerType}
                </Typography>
                <Typography>
                  Percentage Cashback: {offer.percentageCashback}%
                </Typography>
                <Typography>
                  Total Amount In Cents: {offer.totalAmountInCents}
                </Typography>
                <Typography>
                  Current users: {totalCount}
                </Typography>
              </>
            }
          </Grid>
        </Grid>
        <div style={{ paddingBottom: "10px" }}></div>
      </Paper>
      <div style={{ marginTop: "20px" }}></div>
      <Paper>
        <Grid spacing={2} className={clsx(classes.purchaseItemsDiv)} container>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Label>Completed Date</Label>
                <RangePicker
                  onChange={handleDateRangeChange("completedAtStartDate", "completedAtEndDate")}
                  style={{ width: "100%" }}
                  value={[filters.completedAtStartDate, filters.completedAtEndDate]}
                />
              </Grid>
              <Grid item md={4}>
                <Label>Locked Date</Label>
                <RangePicker
                  onChange={handleDateRangeChange("lockedAtStartDate", "lockedAtEndDate")}
                  style={{ width: "100%" }}
                  value={[filters.lockedAtStartDate, filters.lockedAtEndDate]}
                />
              </Grid>
              <Grid item md={4}>
                <Label>Claimed Date</Label>
                <RangePicker
                  onChange={handleDateRangeChange("claimedAtStartDate", "claimedAtEndDate")}
                  style={{ width: "100%" }}
                  value={[filters.claimedAtStartDate, filters.claimedAtEndDate]}
                />
              </Grid>
              <Grid item md={4}>
                <Label>Viewed Date</Label>
                <RangePicker
                  onChange={handleDateRangeChange("viewedAtStartDate", "viewedAtEndDate")}
                  style={{ width: "100%" }}
                  value={[filters.viewedAtStartDate, filters.viewedAtEndDate]}
                />
              </Grid>
              <Grid item md={4}>
                <Label>Expire Date</Label>
                <RangePicker
                  onChange={handleDateRangeChange("expireAtStartDate", "expireAtEndDate")}
                  style={{ width: "100%" }}
                  value={[filters.expireAtStartDate, filters.expireAtEndDate]}
                />
              </Grid>
              <Grid item md={4}>
                <Label>User name</Label>
                <Input
                  prefix={<SearchOutlined />}
                  placeholder="User name"
                  value={filters.user}
                  allowClear
                  onChange={handleSearch("user")}
                />
              </Grid>
              <Grid item md={4}>
                <Label>Status type</Label>
                <Select
                  mode="multiple"
                  allowClear
                  showArrow
                  getPopupContainer={(trigger) => trigger.parentNode}
                  value={filters.status}
                  style={{ width: "100%" }}
                  placeholder="Status type"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleChangeSelectAnt(
                    statusList,
                    "status"
                  )}
                >
                  <Select.Option key={"ALL"}>ALL</Select.Option>
                  {statusList.map((status) => (<Select.Option key={status.id}>{status.name}</Select.Option>))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ marginBottom: 20 }}></div>
        {React.useMemo(
          () => (
            <Table
              rowKey="id"
              onChange={handleTableChange}
              columns={columns}
              dataSource={data}
              pagination={pagination}
              loading={loading}
            />
          ),
          [data, pagination]
        )}
      </Paper>
    </Container>
  );
};
export default OfferDetails;
