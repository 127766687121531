import { loadStripe } from "@stripe/stripe-js";
import { Col, Row, Skeleton } from "antd";
import CreditCardIcon1 from "assets/images/credit-card-icon-1.png";
import Config from "config";
import React from "react";
import Loading from "../../../../../../component/loading";
import stripeService from "../../../../../../services/stripe.service";
import SwayButton from "../../../../components/SwayButton";
import SwayCard from "../../../../components/SwayCard";
import { MerchantContext } from "../../../../MerchantBaseProvider";

const stripePromise = loadStripe(Config.STRIPE_CLIENT_ID);
interface Props {
  onClickAddCard: () => void;
}

const AddPaymentMethod: React.FC<Props> = (props) => {
  const { onClickAddCard } = props;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState(null);
  const { retailer, loading: isMerchantContextLoading } =
    React.useContext(MerchantContext);
  const getClientSecret = async () => {
    if (isMerchantContextLoading) return;
    setLoading(true);

    const { error: stripeIntentError, stripeIntent } =
      await stripeService.getStripeIntent(retailer.id);
    if (stripeIntentError) {
      setError(true);
    }
    setClientSecret(stripeIntent.client_secret);
    setLoading(false);
  };

  React.useEffect(() => {
    getClientSecret();
  }, []);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  if (loading) {
    return <Loading></Loading>;
  }
  return (
    <>
      <Row style={{ flexDirection: "column" }}>
        <SwayCard style={{ width: 400 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={4}>
              <img
                src={CreditCardIcon1}
                style={{
                  width: 40,
                  height: 40,
                }}
              />
            </Col>

            <Col xs={10}>
              <Skeleton
                active
                title={false}
                paragraph={{
                  rows: 2,
                }}
                style={{ paddingTop: 10 }}
              />
            </Col>
            <Col xs={8}>
              <SwayButton onClick={(e) => onClickAddCard()}>
                Add card
              </SwayButton>
            </Col>
          </Row>
        </SwayCard>
      </Row>
    </>
  );
};

export default AddPaymentMethod;
