import { IRetailer } from "./retailer.interface";

export enum TransactionType {
  credit,
  debit,
}

export enum TransactionSubType {
  cashback,
  swaypay_101_reward,
  cashout,
}
export const TransactionSubTypeMapping = {
  0: "Cashback",
  1: "101 Rewards",
  2: "Cashout",
};

export interface IMerchantTransaction {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  amountInCents: number;
  transactionRemarks: string;
  type: TransactionType;
  retailer?: IRetailer;
}

export interface IMerchantTransactionsResponseJson {
  transactions?: IMerchantTransaction[];
  totalCount?: number;
}

export interface IMerchantTransactionsResponse {
  transactions?: IMerchantTransaction[];
  totalCount?: number;
  error?: Error;
}

export interface ITransactionService {
  getMerchantTransactions: () => Promise<IMerchantTransactionsResponse>;
}
