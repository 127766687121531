import { IMerchantSidebarStats, IStatsService } from "types/stats.interface";
import httpClient from "./http-client";

class StatsService implements IStatsService {
  /**
   * Pass in date range. How is that handled elsewhere?
   *
   * How to preserve previous stats fetches with sidebar shared across pages
   */
  public getSidebarStats = async (queryString: string) => {
    try {
      const response = await httpClient(
        `/merchant-users/me/statistics${queryString}`,
        {
          apiVersion: "v2",
          method: "GET",
          auth: true,
        }
      );
      const stats: IMerchantSidebarStats = response.data;
      return { stats };
    } catch (error) {
      return { error };
    }
  };
}

export default new StatsService();
