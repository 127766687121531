import SuisseIntlRegular from "assets/fonts/SuisseIntl-Regular.woff2";
import SuisseIntlBold from "assets/fonts/SuisseIntl-Bold.woff2";
import SpaceMonoRegular from "assets/fonts/SpaceMono-Regular.woff2";
import SpaceMonoBold from "assets/fonts/SpaceMono-Bold.woff2";
import { createTheme, ThemeOptions } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    black: Palette["primary"];
  }
  interface PaletteOptions {
    black: PaletteOptions["primary"];
  }
}

const suisseIntlRegular = {
  fontFamily: "SuisseIntl-Regular",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    url(${SuisseIntlRegular}) format('woff2')
  `,
};

const suisseIntlBold = {
  fontFamily: "SuisseIntl-Bold",
  fontStyle: "bold",
  fontWeight: 700,
  src: `
    url(${SuisseIntlBold}) format('woff2')
  `,
};

const spaceMonoRegular = {
  fontFamily: "Space Mono",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    url(${SpaceMonoRegular}) format('woff2')
  `,
};

const spaceMonoBold = {
  fontFamily: "Space Bold",
  fontStyle: "bold",
  fontWeight: 700,
  src: `
    url(${SpaceMonoBold}) format('woff2')
  `,
};

const theme = createTheme({
  typography: {
    fontFamily: ['"SuisseIntl-Regular"'].join(",") + " !important",
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          suisseIntlRegular,
          suisseIntlBold,
          spaceMonoBold,
          spaceMonoRegular,
        ],
      },
    },
    MuiButton: {
      root: {
        fontFamily: "SuisseIntl-Regular !important",
      },
      containedPrimary: {
        color: "#000000",
        fontWeight: 700,
        backgroundColor: "#8CE41D !important",
        "&:hover": {
          color: "#000000",
          backgroundColor: "#81D30B !important",
        },
      },
      textSecondary: {
        color: "#8C0CF1 !important",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.4)",
        },
      },
    },
  },
  palette: {
    black: {
      main: "#000000",
    },
    primary: {
      light: "#aeff5b",
      main: "#8CE41D",
      dark: "#3cb100",
    },
    secondary: {
      main: "#8C0CF1",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});

export default theme;
