import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  sendOtpButton: {
    marginLeft: theme.spacing(1),
    fontSize: 14,
    height: 50,
  },

  subHeading: {
    textAlign: "center",
    fontSize: 14,
    margin: "10px 0px 10px 0px",
  },
}));
export default useStyles;
