import { SPACING_0_25 } from "modules/merchantV2/constants";
import useColors from "modules/merchantV2/hooks/useColors";
import React from "react";

interface IProps {
  width?: number | string;
  backgroundColor?: string;
}

export enum ViewKey {}

const SwayPostCardLoadingBlock: React.FC<IProps> = (props) => {
  const colors = useColors();
  const { width, backgroundColor = colors.inactiveButtonBg } = props;
  return (
    <div
      style={{
        width,
        flexGrow: width ? 0 : 1,
        backgroundColor,
        marginLeft: SPACING_0_25,
        height: 6,
        borderRadius: 2,
      }}
    />
  );
};

export default SwayPostCardLoadingBlock;
