import { Col, Row } from "antd";
import { SPACING, SPACING_0_75 } from "modules/merchantV2/constants";
import useColors from "modules/merchantV2/hooks/useColors";
import { useContext, useState } from "react";
import postService from "services/post.service";
import { IPostService } from "types/post.interface";
import SwayCard from "../../../components/SwayCard";
import SwayText from "../../../components/SwayText";
import DollarIcon1 from "assets/images/dollar-icon-1.png";
import transactionService from "../../../../../services/transaction.service";
import { IMerchantTransaction } from "../../../../../types/transaction.interface";
import { IRetailer } from "../../../../../types/retailer.interface";
import React from "react";
import { formatCentsToDollars } from "../../../../../helper/v2-helpers/currency";
import { MerchantContext } from "../../../MerchantBaseProvider";
import { formatCurrency } from "../../../../../helper/locale.helper";

interface IProps {}

const PendingCharges: React.FC = (props: IProps) => {
  const colors = useColors();
  const { retailer, loading: isMerchantContextLoading } =
    useContext(MerchantContext);

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Row>
        <Row style={{ width: "100%" }}>
          <SwayCard style={{ width: 250 }}>
            <Row justify="space-between" align="middle">
              <Col sm={24} lg={6}>
                <img
                  src={DollarIcon1}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                />
              </Col>
              <Col sm={24} lg={18}>
                <Row>
                  <SwayText
                    size="tiny"
                    type="title"
                    style={{ textAlign: "center" }}
                  >
                    Pending Charges
                  </SwayText>
                </Row>
                <Row>
                  <SwayText
                    size="medium"
                    type="body"
                    style={{ fontWeight: 700 }}
                  >
                    {retailer.balanceInCents === 0
                      ? "-"
                      : formatCurrency(
                          formatCentsToDollars(retailer.balanceInCents)
                        )}
                  </SwayText>
                </Row>
                {/* <Row>
                  <SwayText
                    size="tiny"
                    type="body"
                    style={{ textAlign: "center" }}
                    color={colors.gray}
                  >
                    Charge Daily
                  </SwayText>
                </Row> */}
              </Col>
            </Row>
          </SwayCard>
        </Row>
      </Row>
    </>
  );
};

export default PendingCharges;
