import { Container, LinearProgress, Link, Chip } from "@material-ui/core";
import Loading from "component/loading";
import ProgressBar from "component/progressBar";
import { formatCurrency } from "helper/locale.helper";
import parseSortParams from "helper/parse-sort-params";
import moment, { Moment } from "moment";
import MUIDataTable, {
  //@ts-ignore
  DebounceTableSearch,
  MUIDataTableColumn,
  MUIDataTableOptions,
} from "mui-datatables";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import httpClient from "services/http-client";
import useStyles from "modules/common/commonAntTableStyles";
export const DATE_FORMAT = "MM/DD/YYYY";

const ALL_STORES = { _id: "ALL", name: "SELECT ALL" };
const orderFiltersSchema = {
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  postStatus: "all",
  selectedStores: [],
};
interface Props {
  title: string;
  localStorageKey: string;
}

const RequestedRetailersTableComponent: React.FC<Props> = (props) => {
  const [data, setData] = React.useState([]);
  const history = useHistory();
  const [totalCount, setTotalCount] = React.useState(0);
  const [currentSort, setCurrentSort] = React.useState(
    "sortBy=desc(createdAt)"
  );
  const [loading, setLoading] = React.useState(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const [allChecked, setAllChecked] = React.useState(true);
  const searchRef = React.useRef(null);
  const [filters, setFilters] = React.useState(orderFiltersSchema);
  const [stores, setStores] = React.useState([]);
  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (searchRef.current) {
      searchRef.current.searchField.value = "";
    }
    setFilters({ ...filters, [name]: value });
  };
  const handleDate = (m: Moment, key: string) => {
    if (searchRef.current) {
      searchRef.current.searchField.value = "";
    }
    setFilters({ ...filters, [key]: m });
  };
  const classes = useStyles();
  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      options: {
        display: "false",
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        print: false,
      },
    },
    {
      name: "createdAt",
      label: "Date Created",
      options: {
        customBodyRender: (value: string) => {
          return moment.utc(value).format(DATE_FORMAT);
        },
      },
    },
    {
      name: "name",
      label: "Brand",
    },
    {
      name: "count",
      label: "Times Requested",
    },
    {
      name: "status",
      label: "Brand Status",
      options: {
        customBodyRender: (value: string) => {
          return <Chip className={`chipStatusHeight`} label={value} />;
        },
      },
    },
  ];
  const currentSortValue = parseSortParams(currentSort);
  const options: MUIDataTableOptions = {
    count: totalCount,
    //@ts-ignore
    sortOrder: currentSortValue,
    onRowClick: ([_id]) => {
      // setOrderDetailsModal(_id);
    },
    setTableProps: () => ({
      size: "small",
      className: classes.rightAlignTable,
    }),
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      handleSearch.current = handleSearch;
      return (
        <DebounceTableSearch
          searchText={searchText}
          ref={searchRef}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          debounceWait={300}
        />
      );
    },
    print: false,
    searchPlaceholder: "Search on Brand name.",
    searchOpen: true,
    downloadOptions: {
      filename: "brands.csv",
    },
    filter: false,
    selectableRows: "none",
    pagination: false,
  };
  const getData = async (query = currentSort, reset = false) => {
    setLoading(true);
    const skip = reset ? 0 : data.length;
    const res = await httpClient(
      `/admin/requested-retailers?${query}&skip=${skip}&limit=25`,
      {
        auth: true,
        method: "GET",
        apiVersion: "v2",
        headers: {},
      }
    );
    setLoading(false);
    if (initLoading) {
      setInitLoading(false);
    }
    if (reset) {
      setData(res.data.retailers);
    } else {
      setData([...data, ...res.data]);
    }
    setTotalCount(res.data.length);
  };
  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    getData();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <Container
      style={{
        marginBottom: 60,
      }}
      className={loading && classes.muiTableLoading}
    >
      {initLoading ? <Loading /> : null}
      {loading ? <ProgressBar /> : null}

      <div style={{ marginBottom: 10 }}></div>
      {React.useMemo(
        () => (
          <MUIDataTable
            title={props.title}
            data={data}
            columns={columns}
            options={options}
          />
        ),
        [data, totalCount]
      )}
    </Container>
  );
};

export default RequestedRetailersTableComponent;
