import React from "react";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import ApplicationError from "services/application-error.service";
export declare namespace Snackbar {
  export type msg = string | Error | ApplicationError;
  export type variant = "success" | "error";
  export type vertical = "top" | "bottom";
  export type horizontal = "right" | "left" | "center";
}
type variant = "success" | "error";
const snackbar = () => {
  const { enqueueSnackbar } = useSnackbar.call(this);
  return (
    msg: Snackbar.msg,
    variant: Snackbar.variant = "success",
    vertical: Snackbar.vertical = "top",
    horizontal: Snackbar.horizontal = "right",
    dismissText: string = "Dismiss"
  ) => {
    let displayMessage = msg;
    if (typeof msg === 'object') {
      if (msg instanceof ApplicationError) {
        if (msg.code === 201) {
          displayMessage = 'Custom error message';
        } else {
          displayMessage = msg.message;
        }
      } else {
        displayMessage = msg.message;
      }
    }

    enqueueSnackbar(displayMessage, {
      variant,
      anchorOrigin: {
        vertical,
        horizontal,
      },
      action: (
        <Button style={{ color: "#fff" }} size="small">
          {dismissText}
        </Button>
      ),
    });
  };
};
export default snackbar;
