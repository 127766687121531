import React from "react";
import { Button, Table } from "antd";
import httpClient from "services/http-client";
import { Typography, Divider } from "antd";
import moment from "moment";

import useStyles from "./styles";
import { AdminContext } from "modules/admin/admin-base";
import { Tag } from "antd";
import tagsService from "services/tags.service";
import HandleUserStatus from "component/user/HandleUserStatus";
import urgentTableService from "services/urgent-table.service";
import showStandardNotficationError from "helper/v2-helpers/showStandardNotficationError";
import TagSelector from "component/TagSelector/TagSelector";
import Config from "config";
import TagSelectorNew from "component/TagSelector/TagSelectorNew";

const { CheckableTag } = Tag;
interface IProps {}
const Users: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const adminContext = React.useContext(AdminContext);
  const handleClickUserApplication = (user) => {
    setData(data.filter((data) => data.id !== user.id));
  };
  const handleChangeTag = async (user, tagId, checked: boolean) => {
    try {
      const tag = adminContext.userTags.find((tag) => tag.id === tagId);
      setLoading(true);
      let res = null;
      const updatedUser = { ...user };
      if (checked) {
        res = await tagsService.userAddTag(user.id, tag.id);
        updatedUser.tags = [...user.tags, tag];
      } else {
        res = await tagsService.userDeleteTag(user.id, tag.id);
        updatedUser.tags = user.tags.filter(
          (localTag) => localTag.id != tag.id
        );
      }

      const updatedUsers = data.map((item) => {
        if (item.id === user.id) {
          return updatedUser;
        } else {
          return item;
        }
      });
      setData(updatedUsers);
      setLoading(false);
    } catch (e) {}
  };
  const columns = [
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "Tiktok",
      dataIndex: "tiktokHandle",
      key: "tiktokHandle",
      render: (handle) => (
        <Button
          onClick={() => window.open(`https://www.tiktok.com/@${handle}`)}
          type="link"
        >
          {handle}
        </Button>
      ),
    },
    { title: "Username", dataIndex: "username", key: "username" },

    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phoneNo", key: "phoneNo" },
    { title: "Source", dataIndex: "source", key: "source" },
    { title: "Campaign", dataIndex: "campaign", key: "campaign" },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "30%",
      render: (tags, user) => {
        return (
          <TagSelectorNew
            options={adminContext.userTags}
            selected={user.userTags.map((userTags) => ({
              id: userTags.userTag.id,
              name: userTags.userTag.name,
              description: userTags.userTag.description,
              tagGroupType: userTags.userTag.tagGroupType,
            }))}
            onChange={(tagId, isSelected) =>
              handleChangeTag(user, tagId, isSelected)
            }
          />
        );
      },
    },
  ];
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [error, users] = await urgentTableService.getUrgentUsers();
      if (error) {
        showStandardNotficationError(error);
      } else {
        setData(users);
      }
      setLoading(false);
    };
    getData();
  }, []);
  const classes = useStyles();
  return (
    <div>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        pagination={{ defaultPageSize: 1, pageSizeOptions: [1, 10, 20, 30] }}
        expandable={{
          expandedRowKeys: data.map((user) => user.id),
          expandedRowRender: (user) => (
            <div style={{ textAlign: "center" }}>
              <HandleUserStatus
                user={user}
                updateCallback={() => handleClickUserApplication(user)}
              />
            </div>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={data}
      />
    </div>
  );
};
export default Users;
