import * as React from "react";

const SwayReceiptSvg = (props: { active?: boolean }) => (
  <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 2.138h12v18l-2.5 2-2.5-2-2.5 2-2.5-2-2.5 2-2.5-2v-15a3 3 0 0 1 3-3Z"
      fill={props.active ? "var(--primary)" : "#fff"}
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 2.138H6a3 3 0 0 0-3 3v15l2.5 2 2.5-2 2.5 2 2.5-2 2.5 2 2.5-2v-12M14.5 15.138h-8M14.5 11.138h-8M8.5 7.138h-2"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 4.138a2 2 0 1 1 4 0v4h-4v-4Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SwayReceiptSvg;
