import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./ant-custom.css";
import "./react-datetime.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";
import "polyfills";

ReactDOM.render(
  <SnackbarProvider hideIconVariant maxSnack={3}>
    <Router>
      <App />
    </Router>
  </SnackbarProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();
