import {
  Modal,
  Select,
  Row,
  Col,
  Carousel,
  Button,
  Form,
  Input,
  DatePicker,
  message,
  Image,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

import Config from "config";
import { formatDollarsToCents } from "helper/v2-helpers/currency";
import moment from "moment";
import React from "react";
import purchaseService from "services/purchase.service";
import { RECEIPT_STATUS } from "types/post.interface";

export default function ReceiptModal({
  purchase,
  handleClose,
  handleSuccess,
  readOnly = false,
}) {
  const [form] = Form.useForm();
  const [rejectModal, setRejectModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState("inappropriate");
  const [customRejectReason, setCustomRejectReason] = React.useState("");

  const handleReceiptConfirm = async (formData) => {
    const payload: any =
      formData.receiptStatus === RECEIPT_STATUS.REJECTED
        ? formData
        : {
            createdAt: formData.createdAt.format().substr(0, 10),
            totalPriceInCents: formatDollarsToCents(formData.totalPrice),
            receiptStatus: RECEIPT_STATUS.VERIFIED,
            orderNo: formData.orderNo,
          };
    setLoading(true);
    const [err, data] = await purchaseService.updatePurchase(
      purchase.id,
      payload
    );
    setLoading(false);
    if (err) return message.error(err.message);
    form.resetFields();
    setRejectModal(false);
    handleSuccess(data);
  };
  return (
    <Modal
      width={"90%"}
      style={{
        top: 20,
        // width: "95%",
      }}
      open={purchase}
      onOk={() => form.submit()}
      okText="Accept Receipt"
      cancelText="Reject Receipt"
      onCancel={handleClose}
      cancelButtonProps={{
        onClick: () => setRejectModal(true),
        // @ts-ignore
        type: "danger",
        loading,
        disabled: loading,
      }}
      okButtonProps={{
        loading,
        disabled: loading,
      }}
      {...(readOnly && {
        footer: null,
      })}
    >
      {rejectModal && (
        <Modal
          open={rejectModal}
          onOk={() =>
            handleReceiptConfirm({
              receiptStatus: RECEIPT_STATUS.REJECTED,
              receiptRejectReason:
                rejectReason === "others" ? customRejectReason : rejectReason,
            })
          }
          title="Select Reject Reason"
          okText="Reject"
          onCancel={() => setRejectModal(false)}
          okButtonProps={{
            // @ts-ignore
            type: "danger",
            loading,
            disabled: loading,
          }}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            value={rejectReason}
            style={{ width: "100%" }}
            onChange={(val) => {
              setRejectReason(val);
            }}
            options={[
              {
                label: "duplicate",
                value: "duplicate",
              },
              {
                label: "missing info",
                value: "missing info",
              },
              {
                label: "doesn’t match brand",
                value: "doesn’t match brand",
              },
              {
                label: "not a receipt",
                value: "not a receipt",
              },

              {
                label: "others",
                value: "others",
              },
            ]}
          />
          {rejectReason === "others" && (
            <TextArea
              style={{ marginTop: 15 }}
              rows={4}
              placeholder="Please enter a Reject Reason"
              onChange={(e) => setCustomRejectReason(e.target.value)}
            />
          )}
        </Modal>
      )}
      <Row>
        <Col md={18} style={{ paddingRight: 10 }}>
          <Carousel>
            {purchase?.receiptS3Urls?.map((receiptS3Url) => {
              return (
                <Image
                  preview={false}
                  width="100%"
                  height="80vh"
                  rootClassName="ant-receipt-upload"
                  src={receiptS3Url}
                />
              );
            })}
          </Carousel>
        </Col>
        <Col md={6}>
          <Row align="middle" style={{ marginBottom: 10 }}>
            <Col>User:</Col>
            <Col>
              <Button
                onClick={() => window.open(`/admin/users/${purchase.user.id}`)}
                type="link"
              >
                {purchase.user?.firstName}
              </Button>
            </Col>
          </Row>

          <Form
            form={form}
            name="brand-intake-form"
            layout="vertical"
            onFinish={handleReceiptConfirm}
            autoComplete="off"
          >
            <Form.Item label="Brand">
              <Input disabled value={purchase?.retailer?.name} />
            </Form.Item>
            <Form.Item label="Uploaded At">
              <Input
                disabled
                value={
                  moment
                    .utc(purchase?.createdAt)
                    .format(Config.DEFAULT_DATE_FORMAT + " HH:mm") + " (UTC)"
                }
              />
            </Form.Item>
            <Form.Item
              name="createdAt"
              label="Purchase Date"
              rules={[
                {
                  required: true,
                  message: "Purchase Date is required",
                },
              ]}
            >
              <DatePicker
                disabled={readOnly}
                style={{ width: "100%" }}
                //@ts-ignore
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                placeholder="Purchase Date"
              />
            </Form.Item>

            <Form.Item
              name="totalPrice"
              label="Order Value"
              rules={[
                {
                  required: true,
                  message: "Order value is required",
                },
              ]}
            >
              <Input
                disabled={readOnly}
                prefix="$"
                type="number"
                placeholder="Order Value"
              />
            </Form.Item>
            <Form.Item name="orderNo" label="Order No">
              <Input disabled={readOnly} placeholder="Order Number" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}
