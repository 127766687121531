// globals dataLayer

import TagMangager from 'react-gtm-module';
import Config from 'config';
import { AuthService } from 'services';

interface IPageViewOptions {
  pagePath?: string;
  pageTitle?: string;
}

interface ICustomEventOptions {
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: string;
  nonInteractiveHit: boolean;
  userId?: string;
}

class TagManagerService {
  private gtmId: string;
  constructor(gtmId: string) {
    this.gtmId = gtmId;
  }

  public init() {
    TagMangager.initialize({ gtmId: this.gtmId });
  }

  firePageView(options: IPageViewOptions) {
    (<any>window).dataLayer.push({ event: 'pageView', page: { ...options } });
  }

  fireCustomEvent(options: ICustomEventOptions) {
    if (AuthService._auth?.user?._id) {
      options.userId = AuthService._auth.user._id;
    }
    (<any>window).dataLayer.push({ event: 'customEvent', ...options });
  }
}

export default new TagManagerService(Config.GTAG_ID);
