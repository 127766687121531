import { ROLE } from "../../types/auth.interface";

/**
 * getUserIdForSegment
 * Generate ids to be used with segment
 *
 * Because we do not use uuids at present and users, merchant-users and admin-users are different entities,
 * we need to append prefix to make the id unique
 * For now, we are not going to append "cus" for customer ids because the will require migration
 *
 * @param id
 * @param role
 * @returns
 */
const getUserIdForSegment = (id: number | string, role: ROLE) => {
  let userId = "";
  switch (role) {
    case ROLE.MERCHANT:
      userId = `mer${id}`;
      break;
    case ROLE.ADMIN:
      userId = `adm${id}`;
      break;
    case ROLE.CUSTOMER:
      userId = `${id}`; // not required to append "cus" for customer yet
      break;
    default:
      userId = `${id}`;
  }
  return userId;
};

export default getUserIdForSegment;
