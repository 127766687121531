import numberFormatter from "helper/numberFormatter";
import moment from "moment";
import {
  IPostUserJson,
  IContentPostJson,
  IPostService,
  IContentPostPricingTierJson,
  IContentPost,
  IPostUser,
  IContentPostPurchaseInfo,
  IPostStat,
  IContentPostPricingTier,
  IContentPostListResponseJson,
  IDownloadListResponse,
  IDownloadListResponseJson,
  POST_PURCHASE_STATUS,
  ISamplePostListResponseJson,
} from "types/post.interface";
import isSuccessResponse from "../helper/v2-helpers/isSuccessResponse";
import httpClient from "./http-client";

class PostService implements IPostService {
  updatePost = async (postId: number, payload: any) => {
    try {
      const response = await httpClient(`/admin/posts/${postId}`, {
        auth: true,
        apiVersion: "v2",
        method: "PATCH",
        body: payload,
      });
      if (isSuccessResponse(response)) {
        return [undefined, response.data.post];
      } else {
        return [new Error(response.message), undefined];
      }
    } catch (e) {
      return [e, undefined];
    }
  };

  public getPricingTiers = async () => {
    try {
      const response = await httpClient(
        `/merchant-users/me/posts/purchase/pricing-tiers`,
        {
          auth: true,
          apiVersion: "v2",
        }
      );

      if (!isSuccessResponse(response)) {
        return { error: new Error(response.error) };
      }

      const tierJson: IContentPostPricingTierJson[] =
        response.data.pricingTiers;
      const pricingTiers = this.decodeContentPricingTiers(tierJson);
      return { pricingTiers };
    } catch (error) {
      return { error };
    }
  };

  public getPosts = async (queryString?: string) => {
    try {
      const response = await httpClient(
        `/merchant-users/me/posts${queryString}`,
        {
          auth: true,
          apiVersion: "v2",
          method: "GET",
        }
      );

      if (!isSuccessResponse(response)) {
        return { error: new Error(response.message) };
      }

      const data: IContentPostListResponseJson = response.data;
      const posts = this.decodeContentPosts(data.posts || []);
      return { posts };
    } catch (error) {
      return { error };
    }
  };

  public getSamplePosts = async (queryString?: string) => {
    try {
      const response = await httpClient(
        `/merchant-users/me/example-posts${queryString}`,
        {
          auth: true,
          apiVersion: "v2",
          method: "GET",
        }
      );

      if (!isSuccessResponse(response)) {
        return { error: new Error(response.message) };
      }

      const data: ISamplePostListResponseJson = response.data;
      const posts = data.examplePosts?.map((p) => p.post);
      const examplePosts = this.decodeContentPosts(posts || []) || [];
      return { examplePosts };
    } catch (error) {
      return { error };
    }
  };

  public purchasePost = async (postId: number) => {
    try {
      const response = await httpClient(
        `/merchant-users/me/posts/${postId}/purchase`,
        {
          auth: true,
          apiVersion: "v2",
          method: "POST",
        }
      );

      if (!isSuccessResponse(response)) {
        return { error: new Error(response.message) };
      }

      const data: { downloadUrl: string } = response.data;
      const downloadUrl = data.downloadUrl;
      return { downloadUrl };
    } catch (error) {
      return { error };
    }
  };

  public getDownloads = async (page: number) => {
    try {
      const response = await httpClient(`/merchant-users/me/downloads`, {
        auth: true,
        apiVersion: "v2",
        method: "GET",
      });

      if (!isSuccessResponse(response)) {
        return { error: new Error(response.message) };
      }

      const data: IDownloadListResponseJson = response.data;
      // // Pricing tiers aren't actually used from response at this point
      const downloads = this.decodeContentPosts(data.downloads);
      return { downloads };
    } catch (error) {
      return { error };
    }
  };

  private decodeContentPricingTiers = (
    pricingTiers: IContentPostPricingTierJson[]
  ) => {
    const tiers: IContentPostPricingTier[] = pricingTiers.map((t) => {
      return {
        ...t,
        title: t.name.charAt(0).toUpperCase() + t.name.slice(1),
      };
    });
    return tiers;
  };

  private decodeContentPosts = (jsonPosts: IContentPostJson[]) => {
    const posts: IContentPost[] = jsonPosts.map((p) => {
      const {
        id,
        createdAt,
        coverImageUrl,
        postUrl,
        postLongUrl,
        description,
        commissionPaymentStatus,
        userTiktokInfo: tiktokJson,
        purchaseInfo: { status, purchasePrice, currencySymbol, pricingTier },
        latestPostStats: latestPostStatsJson,
        downloadInfo,
        purchase,
        user,
        paymentPriceInCents,
      } = p;

      const userTiktokInfo: IPostUser = {
        ...tiktokJson,
        followingCountString: numberFormatter(tiktokJson?.followingCount || 0),
        followerCountString: numberFormatter(tiktokJson?.followerCount || 0),
        likeCountString: numberFormatter(tiktokJson?.likeCount || 0),
      };

      // TODO: Circle back to this once localization is more figured out
      const priceString = currencySymbol + purchasePrice;
      const purchaseInfo: IContentPostPurchaseInfo = {
        status,
        priceString,
        pricingTier,
      };

      const latestPostStats: IPostStat = {
        ...latestPostStatsJson,
        viewCountString: latestPostStatsJson.viewCount
          ? numberFormatter(latestPostStatsJson.viewCount)
          : "-",
        likeCountString: latestPostStatsJson.likeCount
          ? numberFormatter(latestPostStatsJson.likeCount)
          : "-",
        commentCountString: latestPostStatsJson.commentCount
          ? numberFormatter(latestPostStatsJson.commentCount)
          : "-",
        shareCountString: latestPostStatsJson.shareCount
          ? numberFormatter(latestPostStatsJson.shareCount)
          : "-",
      };

      const createdAtString = moment(createdAt).format("MM-DD");

      return {
        id,
        createdAt,
        createdAtString,
        coverImageUrl,
        postUrl,
        postLongUrl,
        description,
        userTiktokInfo,
        purchaseInfo,
        latestPostStats,
        downloadInfo,
        purchase,
        commissionPaymentStatus,
        user,
        paymentPriceInCents,
      };
    });

    return posts;
  };

  private testPostJson = () => {
    const testPoster: IPostUserJson = {
      id: 1,
      tiktokHandle: "mrzack123",
      tiktokProfileUrl: "https://www.tiktok.com/@danegerardregnier",
      displayName: "Chandler",
      profileImage:
        "https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1647596478025734~c5_100x100.jpeg?x-expires=1664650800&x-signature=lWpXxMIA5kaCJ%2FO79NVrVrrNHnk%3D",
      followingCount: 262,
      followerCount: 195900,
      likeCount: 3900000,
      tiktokBio:
        "In West Philadelphia, born and raised. On the playground is where I spent most of my days.\nChillin' out maxin', relaxin' all cool.\nAnd all shootin' some b-ball outside of the school",
    };

    const lowValue: IContentPostJson = {
      id: 1,
      createdAt: new Date(),
      userTiktokInfo: testPoster,
      postUrl: "https://www.tiktok.com/t/ZTRmjjngV/",
      coverImageUrl:
        "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/7abcedc744e24adca6f605e0ac7c8aa5_1664466825~tplv-r00ih4996s-1:480:480.jpeg?x-expires=1664499600&x-signature=5AJm046pSRUzCLEJAuepcyip2Fo%3D",
      latestPostStats: {
        viewCount: 1000,
        likeCount: 500,
        commentCount: 50,
        shareCount: 888,
      },
      purchaseInfo: {
        status: POST_PURCHASE_STATUS.PURCHASED,
        purchasePrice: 100,
        purchasePriceInCents: 10000,
        currencyCode: "USD",
        currencySymbol: "$",
        pricingTier: {
          createdAt: new Date(),
          updatedAt: new Date(),
          id: 1,
          amountInCents: 10000,
          name: "bronze",
          amount: 100,
        },
      },
      description:
        "Too early for me 😴 Instagram @CollinJB_ #fyp #comedy #collinjb #swaypay #nike @swaypay @nike",
    };
    const midValue: IContentPostJson = {
      id: 2,
      createdAt: new Date(),
      userTiktokInfo: testPoster,
      postUrl: "https://www.tiktok.com/t/ZTRmjjngV/",
      coverImageUrl:
        "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/0df3f8bcd2ba414d9259ebc1f68c99ae_1657826283~tplv-r00ih4996s-1:480:480.jpeg?x-expires=1664499600&x-signature=q05SIpunVR9hTYwxk4SnYfWeqmM%3D",
      latestPostStats: {
        viewCount: 10000,
        commentCount: 500,
      },
      purchaseInfo: {
        status: POST_PURCHASE_STATUS.AVAILABLE,
        purchasePrice: 200,
        purchasePriceInCents: 20000,
        currencyCode: "USD",
        currencySymbol: "$",
        pricingTier: {
          createdAt: new Date(),
          updatedAt: new Date(),
          id: 2,
          amountInCents: 20000,
          name: "silver",
          amount: 200,
        },
      },
    };
    const highValue: IContentPostJson = {
      id: 3,
      createdAt: new Date(),
      userTiktokInfo: testPoster,
      postUrl: "https://www.tiktok.com/t/ZTRmjjngV/",
      coverImageUrl:
        "https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/239a2dc041e64e29978e51b998d3bb33_1658161918~tplv-r00ih4996s-1:480:480.jpeg?x-expires=1664499600&x-signature=v0efBFdQnWLH6Aq68C8GU0soTsE%3D",
      latestPostStats: {
        viewCount: 1000000,
        likeCount: 100000,
        commentCount: 50000,
      },
      purchaseInfo: {
        status: POST_PURCHASE_STATUS.NOT_AVAILABLE,
        purchasePrice: 400,
        purchasePriceInCents: 40000,
        currencyCode: "USD",
        currencySymbol: "$",
        pricingTier: {
          createdAt: new Date(),
          updatedAt: new Date(),
          id: 3,
          amountInCents: 40000,
          name: "gold",
          amount: 400,
        },
      },
    };

    const testPostsJson: IContentPostJson[] = [lowValue, midValue, highValue];
    const samplePostsJson: IContentPostJson[] = testPostsJson.map((p) => {
      const newId = p.id + 1000;
      return { id: newId, ...p };
    });
    return { testPostsJson, samplePostsJson };
  };
}

export default new PostService();
