import { Select } from "antd";
import { Tag } from "modules/admin/admin-base";
import React from "react";
interface IProps {
  options: Tag[];
  selected: any[];
  onChange: (tadId: number, isSelected: boolean) => void;
  disabled?: boolean;
}
const TagSelector: React.FC<IProps> = ({
  options,
  selected = [],
  onChange,
  disabled,
}) => {
  const selectedIds = selected?.map((item) => item.id.toString());
  const handleOnChange = (newSelectedTagIds) => {
    let tagId;
    const isSelected = newSelectedTagIds.length > selected.length;
    if (isSelected) {
      tagId = newSelectedTagIds.find((id) => !selectedIds.includes(id));
    } else {
      tagId = selectedIds.find((id) => !newSelectedTagIds.includes(id));
    }
    onChange(parseInt(tagId), isSelected);
  };
  return (
    <Select
      mode="multiple"
      allowClear
      //   loading
      //   disabled
      showArrow
      getPopupContainer={(trigger) => trigger.parentNode}
      value={selectedIds}
      style={{ width: "100%" }}
      placeholder="Search for Tag"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      onChange={handleOnChange}
    >
      {options.map((status) => (
        <Select.Option key={status.id}>{status.name}</Select.Option>
      ))}
    </Select>
  );
};
export default TagSelector;
