import { Input, Modal } from "antd";
import React from "react";
import Label from "../../../component/Label/Label";
interface IProps {
  title: string;
  label: string;
  show: boolean;
  onOkHandler: (value: string) => void;
  onCancelHandler: (value: string) => void;
  value: string;
  children?: React.ReactNode;
}
const UpdateAttributeModal: React.FC<IProps> = (props) => {
  const { title, label, value, onOkHandler, onCancelHandler, show } = props;
  const [tempValue, setTempValue] = React.useState(value);
  return (
    <Modal
      title={title}
      visible={show}
      onCancel={() => onCancelHandler("")}
      onOk={() => {
        onOkHandler(tempValue);
      }}
    >
      <Label>{label}</Label>
      <Input
        placeholder="title"
        value={tempValue}
        style={{ marginBottom: 20 }}
        onChange={(e) => setTempValue(e.target.value?.trim())}
      />
    </Modal>
  );
};
export default UpdateAttributeModal;
