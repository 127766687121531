/**
 * Converts dollars to cents
 * @param value
 * @returns
 */
const formatDollarsToCents = (value: number | string | undefined) => {
  value = (value + "").replace(/[^\d.-]/g, "");
  if (value && value.includes(".")) {
    value = value.substring(0, value.indexOf(".") + 3);
  }

  return value ? Math.round(parseFloat(value) * 100) : 0;
};

/**
 * Converts cents to dollars
 * @param value
 * @returns
 */
const formatCentsToDollars = (value: number | string | undefined) => {
  value = (value + "").replace(/[^\d.-]/g, "");
  value = parseFloat(value);
  return value ? value / 100 : 0;
};

export { formatDollarsToCents, formatCentsToDollars };
