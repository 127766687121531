import {
  Button,
  Carousel,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import { DefaultRecordType } from "rc-table/lib/interface";
import moment from "moment";
import React from "react";
import Config from "config";
import { formatDollarsToCents } from "helper/v2-helpers/currency";
import showStandardNotficationError from "helper/v2-helpers/showStandardNotficationError";
import { AdminContext } from "modules/admin/admin-base";
import purchaseService from "services/purchase.service";
import urgentTableService from "services/urgent-table.service";
import { RECEIPT_STATUS } from "types/post.interface";
import tableV2Service from "services/table-v2.service";
import { ColumnsType } from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";
import ReceiptModal from "../receipt-modal/ReceiptModal";

interface IProps {}
const defaultSort = {
  order: "ascend",
  columnKey: "createdAt",
};
const ReceiptUpload: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState([]);
  const [selectedPurchase, setSelectedPurchase] = React.useState(null);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [sortInfo, setSortInfo] = React.useState<any>(defaultSort);
  const [rejectReason, setRejectReason] = React.useState("inappropriate");
  const [customRejectReason, setCustomRejectReason] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const adminContext = React.useContext(AdminContext);
  const sortStr = `sortBy=${tableV2Service.directionMapping[sortInfo.order]}(${
    sortInfo.columnKey
  })`;
  const [form] = Form.useForm();
  const handleReciptModalOpen = async (purchaseId) => {
    setLoading(true);
    const [err, purchase] = await purchaseService.getPurchase(purchaseId);
    setLoading(false);
    if (purchase) {
      setSelectedPurchase(purchase);
    } else {
      message.error(err);
    }
  };
  const columns: ColumnsType<DefaultRecordType> = [
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: sortInfo.columnKey === "createdAt" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value) =>
        moment.utc(value).format(Config.DEFAULT_DATE_FORMAT + " HH:mm") +
        " (UTC)",
    },
    {
      title: "User",
      dataIndex: "user",
      // key: "user",
      sorter: false,
      key: "user.firstName",
      sortOrder: sortInfo.columnKey === "user.firstName" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (user) => (
        <Button
          onClick={() => window.open(`/admin/users/${user.id}`)}
          type="link"
        >
          {user?.firstName}
        </Button>
      ),
    },
    {
      title: "Brand",
      dataIndex: "retailer",
      key: "retailer.name",
      sortOrder: sortInfo.columnKey === "retailer.name" && sortInfo.order,
      sorter: false,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (retailer) => (
        <Button
          onClick={() => window.open(`/admin/users/${retailer.id}`)}
          type="link"
        >
          {retailer?.name}
        </Button>
      ),
    },
    {
      title: "Actions",
      dataIndex: "tags",
      key: "tags",
      width: "20%",
      render: (_, purchase) => {
        return (
          <Button
            type="primary"
            onClick={() => handleReciptModalOpen(purchase.id)}
          >
            View Receipt
          </Button>
        );
      },
    },
  ];
  const getData = async (query = "") => {
    setLoading(true);
    const [error, purchases] = await urgentTableService.getPurchaseReceipts(
      query
    );
    if (error) {
      showStandardNotficationError(error);
    } else {
      setData(purchases);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getData(sortStr);
  }, []);

  const handleReceiptConfirm = async (formData) => {
    const payload: any =
      formData.receiptStatus === RECEIPT_STATUS.REJECTED
        ? formData
        : {
            createdAt: formData.createdAt.format().substr(0, 10),
            totalPriceInCents: formatDollarsToCents(formData.totalPrice),
            receiptStatus: RECEIPT_STATUS.VERIFIED,
            orderNo: formData.orderNo,
          };
    setLoading(true);
    const [err] = await purchaseService.updatePurchase(
      selectedPurchase.id,
      payload
    );
    setLoading(false);
    if (err) return message.error(err.message);
    const filterdData = data.filter((data) => data.id !== selectedPurchase.id);
    setData(filterdData);
    if (filterdData.length === 0) {
      setSelectedPurchase(null);
      getData(sortStr);
    } else {
      handleReciptModalOpen(filterdData[0].id);
    }
    form.resetFields();
    setRejectModal(false);
  };
  const handleSuccess = () => {
    const filterdData = data.filter((data) => data.id !== selectedPurchase.id);
    if (filterdData.length === 0) {
      setSelectedPurchase(null);
      getData(sortStr);
    } else {
      handleReciptModalOpen(filterdData[0].id);
    }
    // form.resetFields();
    // setRejectModal(false);
  };
  const handleClose = () => {
    setSelectedPurchase(null);
    form.resetFields();
  };
  const handleTableChange = (
    newPagination: any,
    antFilters: any,
    sorter: any
  ) => {
    const updatedSortInfo = {
      order: sorter.order,
      columnKey: sorter.columnKey,
    };

    setSortInfo(updatedSortInfo);

    getData(
      `sortBy=${tableV2Service.directionMapping[updatedSortInfo.order]}(${
        updatedSortInfo.columnKey
      })`
    );
  };

  return (
    <div>
      {selectedPurchase && (
        <ReceiptModal
          purchase={selectedPurchase}
          handleClose={handleClose}
          handleSuccess={handleSuccess}
        />
      )}
      {/* {selectedPurchase && (
        <Modal
          width={"90%"}
          style={{
            top: 20,
            // width: "95%",
          }}
          open={selectedPurchase}
          onOk={() => form.submit()}
          okText="Accept Receipt"
          cancelText="Reject Receipt"
          onCancel={handleClose}
          cancelButtonProps={{
            onClick: () => setRejectModal(true),
            // @ts-ignore
            type: "danger",
            loading,
            disabled: loading,
          }}
          okButtonProps={{
            loading,
            disabled: loading,
          }}
        >
          {rejectModal && (
            <Modal
              open={rejectModal}
              onOk={() =>
                handleReceiptConfirm({
                  receiptStatus: RECEIPT_STATUS.REJECTED,
                  receiptRejectReason:
                    rejectReason === "others"
                      ? customRejectReason
                      : rejectReason,
                })
              }
              title="Select Reject Reason"
              okText="Reject"
              onCancel={() => setRejectModal(false)}
              okButtonProps={{
                // @ts-ignore
                type: "danger",
                loading,
                disabled: loading,
              }}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                value={rejectReason}
                style={{ width: "100%" }}
                onChange={(val) => {
                  setRejectReason(val);
                }}
                options={[
                  {
                    label: "duplicate",
                    value: "duplicate",
                  },
                  {
                    label: "missing info",
                    value: "missing info",
                  },
                  {
                    label: "doesn’t match brand",
                    value: "doesn’t match brand",
                  },
                  {
                    label: "not a receipt",
                    value: "not a receipt",
                  },

                  {
                    label: "others",
                    value: "others",
                  },
                ]}
              />
              {rejectReason === "others" && (
                <TextArea
                  style={{ marginTop: 15 }}
                  rows={4}
                  placeholder="Please enter a Reject Reason"
                  onChange={(e) => setCustomRejectReason(e.target.value)}
                />
              )}
            </Modal>
          )}
          <Row>
            <Col md={18} style={{ paddingRight: 10 }}>
              <Carousel>
                {selectedPurchase?.receiptS3Urls?.map((receiptS3Url) => {
                  return (
                    <Image
                      preview={false}
                      width="100%"
                      height="80vh"
                      rootClassName="ant-receipt-upload"
                      src={receiptS3Url}
                    />
                  );
                })}
              </Carousel>
            </Col>
            <Col md={6}>
              <Row align="middle" style={{ marginBottom: 10 }}>
                <Col>User:</Col>
                <Col>
                  <Button
                    onClick={() =>
                      window.open(`/admin/users/${selectedPurchase.user.id}`)
                    }
                    type="link"
                  >
                    {selectedPurchase.user?.firstName}
                  </Button>
                </Col>
              </Row>

              <Form
                form={form}
                name="brand-intake-form"
                layout="vertical"
                onFinish={handleReceiptConfirm}
                autoComplete="off"
              >
                <Form.Item label="Brand">
                  <Input disabled value={selectedPurchase?.retailer?.name} />
                </Form.Item>
                <Form.Item label="Uploaded At">
                  <Input
                    disabled
                    value={
                      moment
                        .utc(selectedPurchase?.createdAt)
                        .format(Config.DEFAULT_DATE_FORMAT + " HH:mm") +
                      " (UTC)"
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="createdAt"
                  label="Purchase Date"
                  rules={[
                    {
                      required: true,
                      message: "Purchase Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    //@ts-ignore
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    placeholder="Purchase Date"
                  />
                </Form.Item>

                <Form.Item
                  name="totalPrice"
                  label="Order Value"
                  rules={[
                    {
                      required: true,
                      message: "Order value is required",
                    },
                  ]}
                >
                  <Input prefix="$" type="number" placeholder="Order Value" />
                </Form.Item>
                <Form.Item name="orderNo" label="Order No">
                  <Input placeholder="Order Number" />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      )} */}
      <Table
        rowKey="id"
        onChange={handleTableChange}
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={data}
      />
    </div>
  );
};
export default ReceiptUpload;
