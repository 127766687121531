import { notification } from "antd";
import Login from "modules/admin/login";
import UrgentTables from "modules/admin/urgent-tables";
import React, { useLayoutEffect } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from "react-router-dom";
import authService from "services/auth.service";
import httpClient from "services/http-client";
import { PrivateRoute, RestrictedRoute } from "../routing.component";
import Brands from "./brands";
import BrandDetailsPage from "./brands/BrandDetailsPage";
import ForgotPassword from "./forgot-password";
import Purchases from "./purchases";
import PurchaseDetailsPage from "./purchases/PurchaseDetailsPage";
import RequestRetailers from "./request-retailers/RequestRetailers";
import ResetPassword from "./reset-password";
import Retailer from "./retailer";
import RetailerDetailsPage from "./retailer/RetailerDetailsPage";
import Tags from "./tags";
import Users from "./users";
import UserDetailsPage from "./users/UserDetailsPage";
import Offers from "./offers";
import OfferDetailsPage from "./offers/OfferDetailsPage";

const Admin: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  useLayoutEffect(() => {
    const handleLogoutFlow = () => {
      authService.logout();
      history.push("/admin/login");
      notification.error({
        message: "Session Expried",
        description: "Please login again.",
      });
    };
    //check if token is valid else clear local storage & redirect to login
    (async () => {
      if (authService._auth) {
        try {
          const res = await httpClient("/admin/token-valid", {
            auth: true,
            apiVersion: "v2",
          });
          if (!res.success) {
            handleLogoutFlow();
          }
        } catch (e) {
          handleLogoutFlow();
        }
      }
    })();
  }, []);
  return (
    <Switch>
      <Redirect exact path="/admin/" to="/admin/purchases" />
      <RestrictedRoute
        exact
        path="/admin/login"
        component={Login}
        role={["admin"]}
        authRedirect="/admin/purchases"
      />
      {/* <PrivateRoute
        path='/admin/dashboard'
        component={Dashboard}
        exact
        role={["admin"]}
        authRedirect='/admin/login'
      /> */}

      <PrivateRoute
        path="/admin/purchases"
        component={Purchases}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/purchases/:purchaseId"
        component={PurchaseDetailsPage}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/retailers"
        component={Retailer}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/retailers/:retailerId"
        component={RetailerDetailsPage}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/brands"
        component={Brands}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/brands/:brandId"
        component={BrandDetailsPage}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/requested-brands"
        component={RequestRetailers}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/urgent/:table"
        component={UrgentTables}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/users"
        component={Users}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/users/:userId"
        component={UserDetailsPage}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/tags/:tag"
        component={Tags}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <Route
        path="/admin/forgot-password"
        component={ForgotPassword}
        exact
        role={["admin"]}
      />
      <Route
        path="/admin/reset-password"
        component={ResetPassword}
        exact
        role={["admin"]}
      />
      <PrivateRoute
        path="/admin/offers"
        component={Offers}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
      <PrivateRoute
        path="/admin/offer/users/:offerId"
        component={OfferDetailsPage}
        exact
        role={["admin"]}
        authRedirect="/admin/login"
      />
    </Switch>
  );
};

export default Admin;
