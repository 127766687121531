import React from "react";
import AdminBase from "modules/admin/admin-base";
import PurchaseDetails from "./PurchaseDetails";
interface IProps {}
const PurchaseDetailsPage: React.FC<IProps> = (props) => {
  return (
    <AdminBase>
      <PurchaseDetails />
    </AdminBase>
  );
};
export default PurchaseDetailsPage;
