import React from "react";
import AdminBase from "modules/admin/admin-base";
import UserDetails from "./UserDetails";
interface IProps {}
const UserDetailsPage: React.FC<IProps> = (props) => {
  return (
    <AdminBase>
      <UserDetails />
    </AdminBase>
  );
};
export default UserDetailsPage;
