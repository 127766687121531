import { useMediaQuery } from "@material-ui/core";
import { Col, Row } from "antd";
import CashWings from "assets/images/cash-wings.png";
import GreenBg from "assets/images/green-bg.png";
import SwayLogo from "assets/images/SwayLogo.png";
import React from "react";
import theme from "theme";
import SwayText from "../components/SwayText";
import { SPACING } from "../constants";
import useColors from "../hooks/useColors";

type Props = {
  children: React.ReactNode;
};

export default function OnboardingBase(props: Props) {
  const isLargeDevice = useMediaQuery(theme.breakpoints.up("md"));
  const colors = useColors();
  return (
    <div>
      <Row>
        {isLargeDevice && (
          <Col
            span={6}
            style={{
              height: "100vh",
              backgroundColor: "#000",
            }}
          >
            <div
              style={{
                height: "50vh",
                paddingLeft: SPACING * 2,
                paddingRight: SPACING * 2,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <div style={{ paddingTop: 50, paddingBottom: 60 }}>
                  <img style={{ height: 30 }} src={SwayLogo} />
                </div>
                <SwayText type="title" size="large" color={colors.white}>
                  Ignite your brand on TikTok
                </SwayText>
                <SwayText color={colors.lightGray}>
                  Swaypayers shop, TikTok what they bought, and earn on post
                  performance.
                </SwayText>
              </div>
            </div>
            <img
              src={CashWings}
              style={{
                height: "50vh",
                width: "100%",
                objectFit: "cover",
              }}
            ></img>
          </Col>
        )}
        <Col
          span={isLargeDevice ? 18 : 24}
          style={{
            background: `url(${GreenBg}`,
            backgroundRepeat: "no-repeat",
            height: "100vh",
            width: "100%",
            backgroundSize: "cover",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {props.children}
        </Col>
      </Row>
    </div>
  );
}
