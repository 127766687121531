import React, { SetStateAction, useContext, useState } from "react";
import SwayCard from "../components/SwayCard";
import SwayEarth from "../../../assets/images/sway-earth.gif";
import SwayText from "../components/SwayText";
import useColors from "../hooks/useColors";
import SwayButton from "../components/SwayButton";
import { Divider, message, Progress, Radio } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/styles";
import BrandIntakeForm from "./BrandIntakeForm";
import PostGuidelinesForm from "./PostGuidelinesForm";
import PurchasePage from "../purchase-page/PurchasePage";
import BillingPage from "../billing-page/BillingPage";
import { CommonFormProps, formSteps } from "./formConstants";
import { SPACING } from "../constants";
import { IRetailer } from "../../../types/retailer.interface";
import { Redirect, useLocation } from "react-router-dom";
import BrandPledge from "./BrandPledge";
import { MerchantContext } from "../MerchantBaseProvider";
import useOnboardingFormCardStyles from "./useOnboardingFormCardStyles";
import { useAnalytics } from "../AnalyticsProvider";
import getPageNameFromRoute from "../../../helper/getPageNameFromRoute";

type Props = {};
const stepNameMapping: {
  [key in formSteps]: {
    name: string;
    component?: React.FC<CommonFormProps>;
    route?: string;
    type: "popup" | "route";
  };
} = {
  brandIntake: {
    name: "Brand Intake Form",
    component: BrandIntakeForm,
    type: "popup",
  },
  postGuidelines: {
    name: "Post Guidelines Form",
    component: PostGuidelinesForm,
    type: "popup",
  },
  signContract: {
    name: "Brand Pledge",
    component: BrandPledge,
    type: "popup",
  },
  addCard: {
    name: "Add Credit Card",
    route: "/merchant/billing",
    type: "route",
  },
};

const form = [];
export default function OnboardingFormCard({}: Props) {
  const {
    retailer,
    setRetailer,
    loading: isMerchantContextLoading,
  } = useContext(MerchantContext);
  const location = useLocation();
  const analytics = useAnalytics();
  const colors = useColors();
  const classes = useOnboardingFormCardStyles();
  const [currentStep, setCurrentStep] = useState<formSteps | null>(null);
  const handleStepClick = (step, i) => {
    analytics.track("Button Clicked", {
      name: step.stepName,
      buttonLocation: "welcome banner",
      pageLocation: getPageNameFromRoute(location.pathname),
    });
    const previousStep = retailer.progress.steps[i - 1];
    if (step.completed === true) {
      return message.error("This step is already has been completed.");
    }
    if (previousStep?.completed === false) {
      return message.error(
        "Please complete previous step before going to next"
      );
    }
    setCurrentStep(step.stepName);
  };

  const updateFormProgress = (stepName: formSteps) => {
    retailer.progress.info.progressPercentage +=
      100 / retailer.progress.steps.length;
    retailer.progress.steps = retailer.progress.steps.map((step) => {
      if (step.stepName === stepName) {
        return {
          ...step,
          completed: true,
        };
      }
      return step;
    });
    setRetailer(retailer);
  };

  const onClickGettingStartedButtonHandler = () => {
    analytics.track("Button Clicked", {
      name: "getting started",
      buttonLocation: "welcome banner",
      pageLocation: getPageNameFromRoute(location.pathname),
    });
    window.open("https://www.swaypayit.com/brand-getting-started", "_blank");
  };

  return (
    <SwayCard bordered bodyStyle={{ padding: 0 }} className={classes.card}>
      <div className={classes.container}>
        <img src={SwayEarth} className={classes.imgSwayEarth} />
        <SwayText
          type="title"
          className={classes.welcomeText}
          style={{
            color: colors.white,
          }}
        >
          WELCOME
        </SwayText>
        <SwayText className={classes.subText}>
          Please complete the list below to set up your account.
        </SwayText>
        <SwayButton
          classType="secondary"
          size="large"
          style={{ marginTop: 10 }}
          onClick={() => onClickGettingStartedButtonHandler()}
        >
          Getting Started
        </SwayButton>
        <Divider className={classes.divider} />
        <SwayText
          type="title"
          size="large"
          style={{
            fontFamily: "Space Mono",
            marginBottom: 0,
          }}
        >
          {retailer.progress.info.progressPercentage}%
        </SwayText>
        <SwayText size="small" weight="bold" style={{ textAlign: "center" }}>
          COMPLETED
        </SwayText>
        <Progress
          style={{ padding: `${SPACING}px ${SPACING + 5}px` }}
          trailColor="var(--primaryDark)"
          strokeColor={colors.white}
          percent={retailer.progress.info.progressPercentage || 2}
          showInfo={false}
        />
        {retailer.progress.steps.map((step, i) => (
          <div key={i} className={classes.stepContainer}>
            <Divider className={classes.dividerStep} />
            <div
              onClick={() => handleStepClick(step, i)}
              className={classes.step}
            >
              <div>
                <Radio checked={step.completed} className="sway-primary" />
                <SwayText weight="bold">
                  {stepNameMapping[step.stepName].name}
                </SwayText>
              </div>
              <div>
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        ))}
        {currentStep ? (
          stepNameMapping[currentStep].type === "popup" ? (
            React.createElement(stepNameMapping[currentStep].component, {
              retailer,
              updateFormProgress,
              handleClose: () => setCurrentStep(null),
            })
          ) : (
            <Redirect to="/merchant/billing" />
          )
        ) : (
          <></>
        )}
        <div style={{ marginTop: SPACING }}></div>
      </div>
    </SwayCard>
  );
}
