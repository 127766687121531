import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Container,
  Link,
} from '@material-ui/core';
import customSnacker from 'component/snackbar';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from './style';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import httpClient from 'services/http-client';
import Config from 'config';
import Header from '../../../component/basic-header';
import Alert from '@material-ui/lab/Alert';
import { makeErrorHandler, handleError } from 'helper/handle-error';

const userDataSchema = {
  password: '',
  confirmPassword: '',
};
const errorDataSchema = makeErrorHandler(userDataSchema);
const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const snackbar = customSnacker();
  const [interact, setInteract] = useState([]);
  const [passwordResetError, setPasswordResetError] = useState(false);
  const [userData, setUserData] = useState(userDataSchema);
  const [errorState, setErrorState] = useState(errorDataSchema);
  const [
    isPasswordChangeSuccessfully,
    setIsPasswordChangeSuccessfully,
  ] = useState(false);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (interact.length < 2) {
      if (!interact.includes(name)) {
        setInteract([...interact, name]);
      }
    }
    handleError(name, value, errorState, setErrorState, userData);
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (userData.password.trim() !== userData.confirmPassword.trim()) {
      snackbar('Password Do Not Match', 'error');
      return;
    }
    const params = new URLSearchParams(location.search);
    try {
      const res = await httpClient(`/admin-users/auth/reset`, {
        apiVersion: "v2",
        method: 'POST',
        body: {
          token: params.get('token'),
          password: userData.password,
        },
      });
      if (!res.success) {
        setPasswordResetError(true);
        return;
      }
      setIsPasswordChangeSuccessfully(true);
      snackbar('Password changed successfully');
    } catch {
      snackbar('Some Error Occured', 'error');
    }
  };
  return (
    <>
      <Header link='/admin' />
      <Container component='main' maxWidth='xs'>
        <div className={classes.paper}>
          <Typography component='h1' variant='h5'>
            <FormattedMessage id='modules.admin.reset_password.heading' />
          </Typography>
          {passwordResetError && (
            <Alert
              icon={false}
              severity='error'
              style={{
                fontSize: 20,
                textAlign: 'center',
                lineHeight: 1.2,
                marginBottom: 15,
                marginTop: 10,
              }}
            >
              Some error occured while changing your password please try again
              after sometime.
            </Alert>
          )}
          <form className={classes.form} noValidate>
            <TextField
              {...(errorState.password.error && {
                error: true,
                helperText: errorState.password.msg,
              })}
              variant='outlined'
              margin='normal'
              fullWidth
              name='password'
              type='password'
              value={userData.password}
              onChange={handleChange}
              label={
                <FormattedMessage id='modules.admin.reset_password.password.label' />
              }
              autoFocus
            />
            {!isPasswordChangeSuccessfully ? (
              <>
                <TextField
                  {...(errorState.confirmPassword.error && {
                    error: true,
                    helperText: errorState.confirmPassword.msg,
                  })}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  type='password'
                  name='confirmPassword'
                  value={userData.confirmPassword}
                  onChange={handleChange}
                  label={
                    <FormattedMessage id='modules.admin.reset_password.confirm_password.label' />
                  }
                />
                <Typography
                  style={{
                    fontSize: 14,
                    color: 'rgba(0,0,0,0.54)',
                    marginTop: 4,
                    textAlign: 'center',
                  }}
                  gutterBottom
                >
                  The password MUST include at least one letter, one symbol
                  (allowed symbols - @$!%*#?) and one number
                </Typography>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={
                    errorState.password.error ||
                    errorState.confirmPassword.error ||
                    interact.length !== 2
                  }
                  onSubmit={handleSubmit}
                  onClick={handleSubmit}
                  className={classes.submit}
                >
                  <FormattedMessage id='modules.admin.reset_password.submit_button.text' />
                </Button>
              </>
            ) : (
              <>
                <Alert
                  icon={false}
                  severity='success'
                  style={{
                    fontSize: 20,
                    textAlign: 'center',
                    lineHeight: 1.2,
                    marginBottom: 15,
                    marginTop: 10,
                  }}
                >
                  Your password has been changed successfully
                </Alert>
                <div style={{ textAlign: 'center' }}>
                  <Link
                    component={RouterLink}
                    to='/admin/login'
                    style={{ textDecoration: 'underline' }}
                    color='primary'
                  >
                    LOG IN
                  </Link>
                </div>
              </>
            )}
          </form>
        </div>
      </Container>
    </>
  );
};

export default ResetPassword;
