import { string } from "yargs";
import { IPurchase } from "./purchase.interface";

export enum COMMISSION_PAYMENT_STATUS {
  PENDING = "PENDING",
  PAID = "PAID",
  FREEBIE = "FREEBIE",
}

export enum POST_STATUS {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
  CLOSED = "CLOSED",
}

export enum POST_PURCHASE_STATUS {
  NOT_AVAILABLE = "NOT_AVAILABLE",
  AVAILABLE = "AVAILABLE",
  PURCHASED = "PURCHASED",
}

export enum RECEIPT_STATUS {
  UPLOADED = "UPLOADED",
  MISSING = "MISSING",
  VERIFIED = "VERIFIED",
  NOT_REQUIRED = "NOT_REQUIRED",
  REJECTED = "REJECTED",
}

export enum POST_TYPE {
  D7 = "D7",
  PURCHASE_SPECIFIC = "PURCHASE_SPECIFIC",
  NON_PURCHASE_SPECIFIC = "NON_PURCHASE_SPECIFIC",
}

export interface IPostStatJson {
  viewCount?: number;
  likeCount?: number;
  commentCount?: number;
  shareCount?: number;
}

export interface IPostStat extends IPostStatJson {
  viewCountString: string;
  likeCountString: string;
  commentCountString: string;
  shareCountString: string;
}

export interface IPostUserJson {
  id: number;
  tiktokHandle: string;
  tiktokProfileUrl: string;
  displayName: string;
  profileImage?: string;
  avatarUrl?: string;
  followingCount: number;
  followerCount: number;
  likeCount: number;
  tiktokBio?: string;
  profileDeepLink?: string;
}

export interface IPostUser extends IPostUserJson {
  followingCountString: string;
  followerCountString: string;
  likeCountString: string;
}

export interface IContentPostPricingTierJson {
  id: number;
  name: string;
  imageUrl?: string;
  amount: number;
  amountInCents: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IContentPostPricingTier extends IContentPostPricingTierJson {
  title: string;
}

export type ContentPostPricingRecord = Record<
  string,
  IContentPostPricingTierJson
>;

export interface IContentPostPurchaseInfoJson {
  status: POST_PURCHASE_STATUS;
  purchasePrice: number;
  purchasePriceInCents: number;
  currencyCode: string;
  currencySymbol: string;
  pricingTier: IContentPostPricingTierJson;
}

export interface IContentPostPurchaseInfo {
  status: POST_PURCHASE_STATUS;
  priceString: string;
  pricingTier: IContentPostPricingTierJson;
}

export interface IContentPostDownloadInfo {
  downloadUrl: string;
}

interface IContentPostBase {
  id: number;
  createdAt: Date;
  coverImageUrl?: string;
  postUrl?: string;
  postLongUrl?: string;
  description?: string;
  commissionPaymentStatus?: COMMISSION_PAYMENT_STATUS;
  paymentPriceInCents?: number;
}

export interface IContentPostJson extends IContentPostBase {
  userTiktokInfo: IPostUserJson;
  purchaseInfo?: IContentPostPurchaseInfoJson;
  downloadInfo?: IContentPostDownloadInfo;
  latestPostStats?: IPostStatJson;
  purchase?: IPurchase;
  user?: IAppPostUser;
}

export interface IContentPost extends IContentPostBase {
  createdAtString: string;
  userTiktokInfo: IPostUser;
  purchaseInfo?: IContentPostPurchaseInfo;
  downloadInfo?: IContentPostDownloadInfo;
  latestPostStats?: IPostStat;
  user?: IAppPostUser;
  purchase?: IPurchase;
}

export interface IContentPostListResponseJson {
  posts?: IContentPostJson[];
}

export interface IContentPostListResponse {
  posts?: IContentPost[];
  error?: Error;
}

export interface IAppPostUser {
  id?: string;
  tiktokHandle?: string;
  profileImageUuid?: string;
}
export interface IAppPostData {
  id: number;
  createdAt: Date;
  postUrl: string;
  coverImageUrl: string;
  user: IAppPostUser;
}

export interface ISamplePostBase {
  id: number;
  createdAt: Date;
  applicableType: string;
  applicableValue: number;
  purchase?: IPurchase;
}

export interface ISamplePostJson extends ISamplePostBase {
  post: IContentPostJson;
}

export interface ISamplePost extends ISamplePostBase {
  post: IContentPost;
}

export interface ISamplePostListResponseJson {
  examplePosts?: ISamplePostJson[];
}

export interface ISamplePostListResponse {
  examplePosts?: IContentPost[];
  error?: Error;
}

export interface IContentPostPricingTierResponse {
  pricingTiers?: IContentPostPricingTier[];
  error?: Error;
}

export interface IContentPostPurchaseReponse {
  downloadUrl?: string;
  error?: Error;
}

export interface IDownloadListResponseJson {
  downloads?: IContentPostJson[];
}

export interface IDownloadListResponse {
  downloads?: IContentPost[];
  error?: Error;
}

export interface IPostService {
  getPricingTiers: () => Promise<IContentPostPricingTierResponse>;
  getPosts: (queryString?: string) => Promise<IContentPostListResponse>;
  getSamplePosts: (queryString?: string) => Promise<ISamplePostListResponse>;
  purchasePost: (postId: number) => Promise<IContentPostPurchaseReponse>;
  getDownloads: (page: number) => Promise<IDownloadListResponse>;
}
