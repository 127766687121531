import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider from "../MerchantBaseProvider";
import ContentPostList from "./components/ContentPostList/ContentPostList";

const ContentPage: React.FC = () => {
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar>
        <ContentPostList />
      </DashboardBase>
    </MerchantBaseProvider>
  );
};

export default ContentPage;
