export default [
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "tiktok_uploaded",
    name: "Tiktok Submitted",
  },
  { id: "rejected", name: "Rejected" },
  {
    id: "cashback_earned",
    name: "Earning",
  },
  {
    id: "cashback_claimed",
    name: "Cash back claimed",
  },
];
