import axios, { AxiosError, AxiosResponse } from 'axios';

import ApplicationError from './application-error.service';
import LogService from './log.service';
import { AuthService } from 'services';

const logger = LogService.getInstance('service:network');

const MESSAGES = {
  OFFLINE: 'You are offline, Please connect internet',
  NOT_REACHABLE: 'We are not able to connect to server at the moment',
  UNKNOWN: 'Something went wrong, Please contact to administrator',
};

interface IOptions {
  external?: boolean;
  headers?: { [key: string]: string };
}

class NetworkService {
  private handleError(error: AxiosError | ApplicationError) {
    if (error instanceof ApplicationError) {
      throw error;
    }

    if (error.message === 'Network Error') {
      throw new ApplicationError(MESSAGES.NOT_REACHABLE);
    }

    if (error.response) {
      if (error.response && error.response.status >= 400 && error.response.status < 500 && error.response.data) {
        throw new ApplicationError(error.response.data.message || MESSAGES.UNKNOWN, error.response.data.errorCode);
      }

      logger.error(error.response);
      throw new ApplicationError(MESSAGES.UNKNOWN);
    }

    logger.error(error.response);
    throw new ApplicationError(MESSAGES.UNKNOWN);
  }

  private handleResponse(response: AxiosResponse) {
    if (response.status !== 200 && response.status !== 201) {
      logger.error(response);
      throw new ApplicationError(MESSAGES.UNKNOWN);
    }

    return response.data;
  }

  private getHeader(options?: IOptions) {
    const header: { [key: string]: string } = {};

    if (options && options.headers) {
      Object.assign(header, options.headers);
    }

    if (options && options.external) {
      return header;
    }

    const token = AuthService.getToken();
    if (token) {
      header.Authorization = `Bearer ${token}`;
    }

    return header;
  }

  async get(url: string, options?: IOptions) {
    logger.debug('get', url);
    try {
      const response = await axios.get(url, {
        headers: this.getHeader(options),
      });
      logger.debug('get response', response);
      return this.handleResponse(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  async post(url: string, data: any, options?: IOptions) {
    try {
      const response = await axios.post(url, data, {
        headers: this.getHeader(options),
      });
      logger.debug('post response', response);
      return this.handleResponse(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  async put(url: string, data: any, options?: IOptions) {
    try {
      const response = await axios.put(url, data, {
        headers: this.getHeader(options),
      });
      logger.debug('put response', response);
      return this.handleResponse(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  async delete(url: string, options?: IOptions) {
    logger.debug('delete', url);
    try {
      const response = await axios.delete(url, {
        headers: this.getHeader(options),
      });
      logger.debug('delete response', response);
      return this.handleResponse(response);
    } catch (error) {
      this.handleError(error);
    }
  }
}

export default new NetworkService();
