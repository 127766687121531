export default [
  {
    id: "MISSING",
    name: "Missing",
  },
  {
    id: "UPLOADED",
    name: "Uploaded",
  },
  { id: "VERIFIED", name: "Verified" },
  {
    id: "NOT_REQUIRED",
    name: "Not Required",
  },
  {
    id: "REJECTED",
    name: "Rejected",
  },
] as const;
