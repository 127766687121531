import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider from "../MerchantBaseProvider";
import InsightChartContainer from "./components/InsightChartContainer";
//import AreaChart from "./AreaChart";

const InsightsPage: React.FC = () => {
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar={false}>
        <div style={{ padding: 20 }}>
          <InsightChartContainer />
        </div>
      </DashboardBase>
    </MerchantBaseProvider>
  );
};

export default InsightsPage;
