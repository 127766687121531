import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1.5H5.25C3.59315 1.5 2.25 2.84315 2.25 4.5V15L4.125 16.5L6 15L7.875 16.5L9.75 15L11.625 16.5L13.5 15V6"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 11.25H4.875"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 8.25H4.875"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 5.25H5.625H4.875"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3C13.5 2.17157 14.1716 1.5 15 1.5V1.5C15.8284 1.5 16.5 2.17157 16.5 3V6H13.5L13.5 3Z"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
