import { IRetailer } from "../../../types/retailer.interface";

export enum formSteps {
  brandIntake = "brandIntake",
  postGuidelines = "postGuidelines",
  signContract = "signContract",
  addCard = "addCard",
}
export type CommonFormProps = {
  retailer: IRetailer;
  updateFormProgress: (stepName: formSteps) => void;
  handleClose: () => void;
};
export const spGoalsOptions = [
  "Getting more spending money to shoppers who already know and love our brand so they can buy more from us",
  "Acquiring new customers who don't know about our brand yet",
  "A new distribution channel",
  "Cost effective growth",
  "Influencer marketing alternative",
  "Grow on TikTok",
].map((item) => ({ label: item, value: `${item}` }));

export const digitalMarketingAnnualBudgetOptions = [
  "<$5M annual digital marketing spend",
  "$5M+ annual digital marketing spend",
  "$25M+ annual digital marketing spend",
  "$50M+ annual digital marketing spend",
  "over $250M annual digital marketing spend",
].map((item) => ({ label: item, value: `${item}` }));

export const monthlyOvOptions = [
  "<1k monthly orders",
  "1k+ monthly orders",
  "10k+ monthly orders",
  "100k+ monthly orders",
  "500k+ monthly orders",
].map((item) => ({ label: item, value: `${item}` }));

export const aovOptions = [
  "$0-50 AOV",
  "$51-100 AOV",
  "$101-200 AOV",
  "$201-300 AOV",
  "$300+ AOV",
].map((item) => ({ label: item, value: `${item}` }));

export const avgDiscountOptions = [
  "Up to 10% off",
  "11-40% off",
  "40%+ off",
].map((item) => ({ label: item, value: `${item}` }));

export const arrOptions = [
  "<$10M annual revenue",
  "$10M+ annual revenue",
  "$50M+ annual revenue",
  "$100M+ annual revenue",
  "over $500M annual revenue",
].map((item) => ({ label: item, value: `${item}` }));

export const demoEthnicityOptions = [
  "Hispanic, Latino, or Spanish origin",
  "Native American Indian or Alaska Native",
  "East Asian",
  "South Asian",
  "Black or African American",
  "Native Hawaiian or Other Pacific Islander",
  "White",
  "N/A",
].map((item) => ({ label: item, value: `${item}` }));

export const demoAgeOptions = [
  "14-17",
  "18-24",
  "25-30",
  "31-39",
  "40+",
  "Inclusive",
].map((item) => ({ label: item, value: `${item}` }));

export const demoRegion = ["Northeast", "West", "Midwest", "South", "N/A"].map(
  (item) => ({ label: item, value: `${item}` })
);

export const demoGenderBaseOptions = ["Female", "Male", "Inclusive"].map(
  (item) => ({ label: item, value: `${item}` })
);

export const retailerCat = [
  "food & drink",
  "clothes, shoes, & accessories",
  "health & beauty",
  "electronics",
  "home goods",
  "entertainment",
  "travel",
].map((item) => ({ label: item, value: `${item}` }));

export const preferredContentOptions = [
  "Unboxing",
  "Tutorials",
  "Product Reviews",
  `Product Placement (These tend to be the most creative but they
    are indirect advertising i.e. dancing video with product being
    worn/shown)`,
].map((item) => ({ label: item, value: `${item}` }));
