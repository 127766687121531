import React from "react";
import { Header } from "component";
import { AuthService } from "services";
import clsx from "clsx";
import {
  Drawer,
  Box,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  Grid,
  Button,
  Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import useStyles from "./style";
import RoleChanger from "component/RoleChanger";
import Logo from "assets/images/logo.png";
import { Link as RouterLink } from "react-router-dom";
import Sidebar from "modules/common/Sidebar";
import routes from "../sidebar/routes";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import httpClient from "services/http-client";
import { ROLE } from "../../../types/auth.interface";

interface IProps {
  children?: React.ReactNode;
}
export interface Tag {
  id: number;
  name: string;
  description: string;
  children: any[];
}
export const AdminContext = React.createContext<{
  userTags: Tag[];
  postTags: Tag[];
  retailerTags: Tag[];
  setPostTags: (tags: React.SetStateAction<any[]>) => void;
  setUserTags: (tags: React.SetStateAction<any[]>) => void;
  setRetailerTags: (tags: React.SetStateAction<any[]>) => void;
}>({
  userTags: [],
  postTags: [],
  retailerTags: [],
  setUserTags: () => {},
  setPostTags: () => {},
  setRetailerTags: () => {},
});
const AdminBase: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [userTags, setUserTags] = React.useState([]);
  const [postTags, setPostTags] = React.useState([]);
  const [retailerTags, setRetailerTags] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    AuthService.logout();
    history.replace("/admin/login");
  };
  const currentRoute = routes.find(
    (route) => route.route === window.location.pathname
  );
  React.useEffect(() => {
    (async () => {
      try {
        if (AuthService.isAuthenticated(ROLE.ADMIN)) {
          const [user, post, retailer] = await Promise.all([
            httpClient("/admin/tags/user-tags", {
              auth: true,
              apiVersion: "v2",
            }),
            httpClient("/admin/tags/post-tags", {
              auth: true,
              apiVersion: "v2",
            }),
            httpClient("/admin/tags/retailer-tags", {
              auth: true,
              apiVersion: "v2",
            }),
          ]);
          const formatUserTag = (tags) => {
            const formattedTags = [];
            for (const tag of tags) {
              if (!tag.tagGroupType) continue;
              const hasTag = formattedTags.find(
                (item) => item.name === tag.tagGroupType.name
              );
              if (hasTag) {
                hasTag.children.push(tag);
              } else {
                formattedTags.push({
                  ...tag.tagGroupType,
                  children: [tag],
                });
              }
            }
            return formattedTags;
          };
          setUserTags(formatUserTag(user.data.userTags));
          setPostTags(post.data.postTags);
          setRetailerTags(retailer.data.retailerTags);
        }
      } catch (e) {}
    })();
  }, []);
  return (
    <AdminContext.Provider
      value={{
        userTags,
        setUserTags,
        postTags,
        setPostTags,
        retailerTags,
        setRetailerTags,
      }}
    >
      <div>
        <div className={classes.root}>
          <AppBar
            style={{ background: "var(--appbar)" }}
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {currentRoute
                  ? formatReadbleUnderscoreString(currentRoute.text)
                  : "Dashboard"}
              </Typography>
              <Button onClick={handleLogout} color="inherit">
                LOGOUT
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <Link component={RouterLink} to="/admin">
                <img src={Logo} className={classes.logo} />
              </Link>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />

            <List disablePadding>
              <Sidebar open={open} routes={routes} />
            </List>
          </Drawer>
          <main className={classes.content} id="mui-table-container">
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
              <Grid container spacing={3}>
                {props.children}
              </Grid>
            </Container>
          </main>
        </div>
      </div>
    </AdminContext.Provider>
  );
};

export default AdminBase;
