import enTranslationMsg from "translations/en"
import deTranslationMsg from "translations/de"
const DEFAULT_LOCALE = 'en';

const appLocales = [
  'en',
  'de',
];
const translationMessages = {
	en: enTranslationMsg,
	de: deTranslationMsg
  };
export {DEFAULT_LOCALE, translationMessages, appLocales}
