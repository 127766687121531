import { SearchOutlined } from "@ant-design/icons";
import { Container, Grid, IconButton, Tooltip } from "@material-ui/core";
import RestoreIcon from "@material-ui/icons/Restore";
import { Card, Select, Table, DatePicker, Space } from "antd";
import Input from "antd/lib/input";
import Loading from "component/loading";
import ProgressBar from "component/progressBar";
import { formatCurrency } from "helper/locale.helper";
import StatusMapping from "helper/v2-helpers/StatusMapping";
import { AdminContext } from "modules/admin/admin-base";
import React from "react";
import { useHistory } from "react-router-dom";
import tableV2Service from "services/table-v2.service";
import useStyles from "modules/common/commonAntTableStyles";
import getColumns from "./getColumns";
import StatusChart from "../StatusChart";
import { useSwayLocalize } from "hooks/useSwayLocalize";
import moment from "moment";
import Label from "component/Label/Label";
import { SPACING_1_5, SPACING_2 } from "../../../modules/merchantV2/constants";
import ReceiptStatusMapping from "helper/v2-helpers/ReceiptStatusMapping";
const purchaseFilterSchema = {
  postStatus: "all",
  brands: [],
  search: "",
  status: [],
  receiptStatus: [],
  tags: [],
  purchaseStartDate: "" as any,
  purchaseEndDate: "" as any,
  postStartDate: "" as any,
  postEndDate: "" as any,
  orderValueMin: "",
  orderValueMax: "",
  viewCountMin: "",
  viewCountMax: "",
};
const keys = Object.keys(purchaseFilterSchema);
interface Props {}
const { RangePicker } = DatePicker;
const formatUrlQuery = tableV2Service.formatUserUrl(keys);
const defaultSort = {
  order: "descend",
  columnKey: "createdAt",
};
const PurchaseTableAnt: React.FC<Props> = (props) => {
  const [data, setData] = React.useState([]);
  const history = useHistory();
  const [pagination, setPagination] = React.useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [allBrands, setAllBrands] = React.useState([]);
  const [sortInfo, setSortInfo] = React.useState<any>(defaultSort);
  const [loading, setLoading] = React.useState(false);
  const [postStats, setPostsStats] = React.useState({
    cashbackGivenSoFar: 0,
    totalPurchases: 0,
    purchaseStatsBreakUp: [],
  });
  const [initLoading, setInitLoading] = React.useState(true);
  const [filters, setFilters] = React.useState(purchaseFilterSchema);
  const [waiting, setWaiting] = React.useState(false);
  const adminContext = React.useContext(AdminContext);
  const defaultFilterMapping = keys.reduce(
    (prev, next) => ({ ...prev, [next]: filters[next] }),
    {}
  );
  const intl = useSwayLocalize();
  const classes = useStyles();
  const columns = getColumns(sortInfo);
  const getData = async ({ query, init = false }) => {
    setLoading(true);
    const res = await tableV2Service.getPurchasesListAdmin({
      query,
      init,
    });
    setLoading(false);
    if (init) {
      //To fetch brands other stuff in first request.
      setAllBrands(res.data.retailers);
    }
    if (initLoading) {
      setInitLoading(false);
    }
    setPagination((pagination) => ({
      ...pagination,
      total: res.data.totalCount,
    }));
    setData(res.data.purchases);
    setPostsStats(res.data.stats);
  };
  React.useEffect(() => {
    if (!initLoading && !waiting) {
      const updatedPagination = { ...pagination, current: 1 };
      getData({
        query: tableV2Service.formatQueryAPI(
          sortInfo,
          pagination,
          defaultFilterMapping
        ),
      });
      setPagination(updatedPagination);
      const url = formatUrlQuery(sortInfo, { ...filters });
      window.history.replaceState({ path: url }, "", url);
    }
  }, [filters]);
  React.useEffect(() => {
    tableV2Service.onMount({
      sortInfo,
      setSortInfo,
      pagination,
      setPagination,
      filters,
      setFilters,
      getData,
      defaultFilterMapping,
    });
  }, []);
  const handleTableChange = tableV2Service.handleTableChange({
    sortInfo,
    formatUrlQuery,
    filters,
    setSortInfo,
    setPagination,
    getData,
    defaultFilterMapping,
  });
  const handleSearch = tableV2Service.handleSearch({
    waiting,
    setWaiting,
    setFilters,
  });
  const handleChangeSelectAnt = tableV2Service.handleChangeSelectAnt({
    setFilters,
  });
  const handleDateRangeChange = tableV2Service.handleDateRangeChange({
    setFilters,
  });
  return (
    <Container
      style={{
        marginBottom: 60,
        margin: 0,
      }}
      maxWidth="xl"
      className={loading && classes.muiTableLoading}
    >
      {initLoading ? <Loading /> : null}
      {loading ? <ProgressBar /> : null}
      <Grid container spacing={2}>
        <Grid item md={10}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Label>Purchase Date</Label>
              <RangePicker
                onChange={handleDateRangeChange(
                  "purchaseStartDate",
                  "purchaseEndDate"
                )}
                style={{ width: "100%" }}
                value={[filters.purchaseStartDate, filters.purchaseEndDate]}
              />
            </Grid>
            <Grid item md={3}>
              <Label>Post Submission Date</Label>
              <RangePicker
                onChange={handleDateRangeChange("postStartDate", "postEndDate")}
                style={{ width: "100%" }}
                value={[filters.postStartDate, filters.postEndDate]}
              />
            </Grid>
            <Grid item md={3}>
              <Label>Purchase Status</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.status}
                style={{ width: "100%" }}
                placeholder="Status"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(StatusMapping, "status")}
              >
                <Select.Option key="ALL">ALL</Select.Option>
                {StatusMapping.map((status) => (
                  <Select.Option key={status.id}>{status.name}</Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item md={3}>
              <Label>Receipt Status</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.receiptStatus}
                style={{ width: "100%" }}
                placeholder="Receipt Status"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(StatusMapping, "receiptStatus")}
              >
                {ReceiptStatusMapping.map((status) => (
                  <Select.Option key={status.id}>{status.name}</Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item md={3}>
              <Label>Purchase Tags</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.tags}
                style={{ width: "100%" }}
                placeholder="Purchase Tags"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(adminContext.postTags, "tags")}
              >
                <Select.Option key="ALL">ALL</Select.Option>
                {adminContext.postTags.map((tag) => (
                  <Select.Option key={tag.id}>{tag.name}</Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item md={3}>
              <Label>Firstname or Email or Phone</Label>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search on Firstname, Email or Phone."
                value={filters.search}
                allowClear
                onChange={handleSearch("search")}
              />
            </Grid>
            <Grid item md={3}>
              <Label>Brands</Label>
              <Select
                mode="multiple"
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                showArrow
                value={filters.brands}
                style={{ width: "100%" }}
                placeholder="Brands"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(allBrands, "brands")}
              >
                <Select.Option key="ALL">ALL</Select.Option>
                {allBrands.map((brand) => (
                  <Select.Option key={brand.id}>{brand.name}</Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item md={3}>
              <Label>Order Value</Label>
              <Space>
                <Input
                  type="number"
                  value={filters.orderValueMin}
                  onChange={handleSearch("orderValueMin")}
                  style={{ width: "100%" }}
                  placeholder="min"
                />
                <Input
                  type="number"
                  value={filters.orderValueMax}
                  onChange={handleSearch("orderValueMax")}
                  style={{ width: "100%" }}
                  placeholder="max"
                />
              </Space>
            </Grid>
            <Grid item md={3}>
              <Label>View Count</Label>
              <Space>
                <Input
                  type="number"
                  value={filters.viewCountMin}
                  onChange={handleSearch("viewCountMin")}
                  style={{ width: "100%" }}
                  placeholder="min"
                />
                <Input
                  type="number"
                  value={filters.viewCountMax}
                  onChange={handleSearch("viewCountMax")}
                  style={{ width: "100%" }}
                  placeholder="max"
                />
              </Space>
            </Grid>

            {/* <Grid
              item
              style={{ alignSelf: "center", padding: 0, marginTop: 20 }}
              onClick={() => resetFilters()}
            >
              <Tooltip title="Reset Filters" arrow placement="top">
                <IconButton aria-label="restore">
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item md={2}>
          <Card
            size="small"
            title={
              <p className={classes.cardTitle}>
                {intl.format("modules.admin.purchase_table.purchase_break_up")}
              </p>
            }
            style={{ width: 300, marginRight: SPACING_2 }}
          >
            <ul style={{ paddingInlineStart: SPACING_1_5, marginBottom: 0 }}>
              <li>
                {intl.format("modules.admin.purchase_table.total_purchases")}{" "}
                <strong>{postStats.totalPurchases}</strong>
              </li>
              {postStats.purchaseStatsBreakUp.map((item) => (
                <li>
                  {
                    StatusMapping.find((status) => status.id === item.status)
                      ?.name
                  }{" "}
                  : <strong>{item.count}</strong>
                </li>
              ))}
              {/* <li>
                {intl.format("modules.admin.purchase_table.money_give_so_far")}{" "}
                <strong>{formatCurrency(postStats.cashbackGivenSoFar)}</strong>
              </li> */}
            </ul>
          </Card>
        </Grid>
      </Grid>
      <div style={{ marginBottom: 10 }}></div>
      {React.useMemo(
        () => (
          <Table
            rowKey="id"
            onRow={(record) => ({
              onClick: (e) => {
                const route = `/admin/purchases/${record.id}`;
                // check for both windows or mac
                if (e.ctrlKey || e.metaKey) {
                  window.open(route, "_blank");
                } else {
                  history.push(route);
                }
              },
            })}
            onChange={handleTableChange}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
          />
        ),
        [data, pagination]
      )}
    </Container>
  );
};

export default PurchaseTableAnt;
