import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  heading: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 18,
    margin: '40px 0px 5px 0px',
    fontWeight: 500,
  },
  table: {
    '& tbody tr:hover': {
      cursor: 'pointer',
      background: '#F5F5F5',
    },
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  pending: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
}));

export default useStyles;
