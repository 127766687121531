import { TagManagerService } from 'services';
interface IPageViewOptions {
  pagePath?: string;
  pageTitle?: string;
}
const gTagCustomEvent = (eventCategory: string) => {
  return ({ EC = '', EA, EL, EV = '', nonInteractiveHit = false }) => {
    if (EC) {
      eventCategory = EC;
    }
    TagManagerService.fireCustomEvent({
      eventCategory,
      eventAction: EA,
      eventLabel: EL,
      eventValue: EV,
      nonInteractiveHit,
    });
  };
};
const gTagPageView = (options: IPageViewOptions) => {
  TagManagerService.firePageView(options);
};
const gTagInit = () => {
  TagManagerService.init();
};
export { gTagInit, gTagCustomEvent, gTagPageView };
