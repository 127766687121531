import { Divider } from "antd";
import { SPACING, SPACING_0_25, SPACING_0_5 } from "constants/spaceing";
import SwayButton from "modules/merchantV2/components/SwayButton";
import SwayCard from "modules/merchantV2/components/SwayCard";
import SwayText from "modules/merchantV2/components/SwayText";
import useColors from "modules/merchantV2/hooks/useColors";
import SwayReceipt from "modules/merchantV2/icons/SwayReceipt";
import SwaySms from "modules/merchantV2/icons/SwaySms";
import SwayTiktok from "modules/merchantV2/icons/SwayTiktok";
import React from "react";
import useOnboardingFormCardStyles from "../useOnboardingFormCardStyles";

type Props = {};

const DefaultProTipCard = (props: Props) => {
  const classes = useOnboardingFormCardStyles();
  const tipsList = [
    {
      icon: <SwayReceipt />,
      body: "Plug the Swaypay offering into your email receipts so TikTokers can post what they bought when it arrives",
    },
    {
      icon: <SwaySms />,
      body: "41% of shoppers abandon their cart due to price sensitivity. Adding Swaypay in your cart abandonment emails is the perfect antidote!",
    },
    {
      icon: <SwayTiktok />,
      body: "Post about Swaypay on your socials",
    },
  ];
  const color = useColors();
  return (
    <SwayCard bordered bodyStyle={{ padding: 0 }} className={classes.card}>
      <div
        style={{ paddingBottom: SPACING, paddingTop: SPACING_0_5 }}
        className={classes.container}
      >
        <SwayText type="title" className={classes.heading}>
          Create and grow your cult of Swaypayers
        </SwayText>
        <SwayText size="small" className={classes.subTextProTip}>
          The way to a shopper’s heart is through her wallet.
        </SwayText>
        <SwayText
          style={{
            marginTop: 10,
            padding: "8px 15px",
            background: color.white,
            borderRadius: 16,
            fontWeight: "bold",
          }}
        >
          Swaypay TLDR
        </SwayText>
        {tipsList.map((tip, i) => (
          <div key={i} className={classes.stepContainer}>
            <Divider className={classes.dividerStep} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: `${SPACING_0_25}px ${SPACING}px`,
              }}
            >
              <div style={{ marginRight: SPACING }}>{tip.icon}</div>
              <SwayText size="small" style={{ lineHeight: "20px" }}>
                {tip.body}
              </SwayText>
            </div>
          </div>
        ))}
      </div>
    </SwayCard>
  );
};

export default DefaultProTipCard;
