import {
  Chip,
  Container,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Loading from "component/loading";
import { formatCurrency } from "helper/locale.helper";
import moment from "moment";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import httpClient from "services/http-client";
import useStyles from "modules/admin/styles/commonDetailsPageStyle";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import {
  TransactionSubTypeMapping,
  TransactionType,
} from "types/transaction.interface";
import userV2Service from "services/user-v2.service";
import { AdminContext } from "../admin-base";
import {
  Alert,
  Button,
  Input,
  Modal,
  notification,
  Popover,
  Select,
  Spin,
  Tag,
  Timeline,
} from "antd";
import tagsService from "services/tags.service";
import HandleUserStatus from "component/user/HandleUserStatus";
import { SaveOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TagSelector from "component/TagSelector/TagSelector";
import LineChart from "component/LineChart/LineChart";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
import Config from "config";
import Label from "component/Label/Label";
import UpdateAttributeModal from "./UpdateAttributeModal";

//comment
import Comment from "component/comment";
import TagSelectorNew from "component/TagSelector/TagSelectorNew";
import StatusMapping from "helper/v2-helpers/StatusMapping";
import ReceiptStatusMapping from "helper/v2-helpers/ReceiptStatusMapping";
import { POST_STATUS, RECEIPT_STATUS } from "types/post.interface";
import { SPACING } from "constants/spaceing";
import { PurchaseStatus } from "types/purchase.interface";
import SwayButton from "modules/merchantV2/components/SwayButton";
import { DATE_FORMAT } from "component/admin-v2-tables/requested-retailers";
import ActivityTimeline from "../activity-timline/ActivityTimeline";

const { CheckableTag } = Tag;
interface IProps {
  userId?: string;
  userUpdateCallback?: (user: any) => void;
  setPurchase?: (purchaseId: any) => void;
}
// use later when change type to number

// function nReverseFormatter(input:string) {
//  const  str =  input.match(/[a-zA-Z]+/g);
// }
const UserDetails: React.FC<IProps> = (props) => {
  const params = useParams<any>();
  const userId = props.userId ? props.userId : params.userId;
  const classes = useStyles();
  const [vistedOffer, setVistedOffer] = React.useState({});
  const [data, setData] = React.useState(null);
  const [tempTiktokHandle, setTempTiktokHandle] = useState("");
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const [selectedTags, setSelectedTags] = React.useState([]);
  const adminContext = React.useContext(AdminContext);
  const [showEditTiktokModal, setShowEditTiktokModal] = React.useState(false);
  const [offerStatus, setOfferStatus] = React.useState("action_required");
  const [showEditFirstNameModal, setShowEditFirstNameModal] =
    React.useState(false);
  const fetchUser = async () => {
    setLoading(true);
    const [user, transactionSummary, transactions, linkedAccounts, comments] =
      await Promise.all([
        userV2Service.getUserDetails(userId, `offerStatus=${offerStatus}`),
        userV2Service.getTransactionSummary(userId),
        userV2Service.getTransactions(userId),
        userV2Service.getLinkedAccounts(userId),
        userV2Service.getUserComments(userId),
      ]);

    setData({
      ...user,
      transactionSummary,
      transactions,
      linkedAccounts,
      comments,
    });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchUser();
  }, [props.userId, offerStatus]);
  const handleUserStatusUpdate = async (patchedUser) => {
    const updatedUser = { ...data.user, ...patchedUser };
    if (props.userUpdateCallback) {
      props.userUpdateCallback(updatedUser);
    }
    setData({ ...data, user: updatedUser });
  };
  const formatTransactionAmount = (type: TransactionType, amount: number) => {
    if (type === TransactionType.credit) {
      return (
        <span style={{ color: "var(--success)" }}>
          +{formatCurrency(amount)}
        </span>
      );
    } else {
      return (
        <span style={{ color: "var(--danger)" }}>
          -{formatCurrency(amount)}
        </span>
      );
    }
  };
  const handleChangeTag = async (tagId: number, checked: boolean) => {
    try {
      let res = null;
      setLoading(true);
      if (checked) {
        res = await tagsService.userAddTag(data.user.id, tagId);
      } else {
        res = await tagsService.userDeleteTag(data.user.id, tagId);
      }
      setLoading(false);
      setData({ ...data, user: res.data.user });
    } catch (e) {}
  };
  const handleChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({
        ...data,
        user: {
          ...data.user,
          [key]: e.target.value.trim(),
        },
      });
    };
  const handleTiktokSave = async () => {
    setLoading(true);
    await userV2Service.patchUser(data.user.id, {
      tiktokFollowing: data.user.tiktokFollowing || null,
      tiktokFollowers: data.user.tiktokFollowers || null,
      tiktokLikes: data.user.tiktokLikes || null,
    });
    setLoading(false);
  };

  /**
   * Method to update user's attribute by key
   * @param attributeName
   * @param value
   */
  const handleAttributeUpdate = async (attributeName: string, value) => {
    try {
      setLoading(true);

      const [error, user] = await userV2Service.updateUserAttribute(
        data.user.id,
        attributeName,
        value
      );
      if (error) {
        notification.error({
          message: `${attributeName} Update failed`,
          description: error.message,
        });
      } else {
        setData({
          ...data,
          user: {
            ...data.user,
            ...user,
          },
        });
      }
    } catch (e) {
      notification.error({
        message: `${attributeName} Update failed`,
        description: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Method to delete user's attribute by key
   * @param attributeName
   * @param value
   */
  const handleAttributeDelete = async (attributeName: string) => {
    try {
      setLoading(true);

      const [error, user] = await userV2Service.deleteUserAttribute(
        data.user.id,
        attributeName
      );
      if (error) {
        notification.error({
          message: `${attributeName} Update failed`,
          description: error.message,
        });
      } else {
        setData({
          ...data,
          user: {
            ...data.user,
            ...user,
          },
        });
      }
    } catch (e) {
      notification.error({
        message: `${attributeName} Update failed`,
        description: e.message,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleLinkedAccountDelete = async (
    platform: string,
    linkedAccountId: string,
    platformKey: string
  ) => {
    try {
      setLoading(true);

      const [error, user] = await userV2Service.deleteLinkedAccount(
        data.user.id,
        platform,
        linkedAccountId
      );
      if (error) {
        notification.error({
          message: `${platform} account unlinking failed`,
          description: error.message,
        });
      } else {
        setData({
          ...data,
          linkedAccounts: {
            ...data.linkedAccounts,
            [platformKey]: data.linkedAccounts[platformKey].filter(
              (item) => item.id !== linkedAccountId
            ),
          },
        });
      }
    } catch (e) {
      notification.error({
        message: `${platform} account unlinking failed`,
        description: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      {loading && (
        <div
          style={{
            marginBottom: 60,
            marginTop: 20,
          }}
        ></div>
      )}
      {data && (
        <Container
          style={{
            marginBottom: 60,
            marginTop: 20,
          }}
        >
          <UpdateAttributeModal
            title="Update Tiktok Handle"
            label="Tiktok Handle"
            show={showEditTiktokModal}
            value={data.user.tiktokHandle}
            onOkHandler={(value) => {
              setShowEditTiktokModal(false);
              handleAttributeUpdate("tiktokHandle", value);
            }}
            onCancelHandler={() => setShowEditTiktokModal(false)}
          ></UpdateAttributeModal>

          <UpdateAttributeModal
            title="Update First Name"
            label="First Name"
            show={showEditFirstNameModal}
            value={data.user.firstName}
            onOkHandler={(value) => {
              setShowEditFirstNameModal(false);
              handleAttributeUpdate("firstName", value);
            }}
            onCancelHandler={() => setShowEditFirstNameModal(false)}
          ></UpdateAttributeModal>

          <Paper className={classes.infoPaper} style={{ padding: "20px 10px" }}>
            {props.userId && (
              <Tooltip title="Open in a new tab" placement="top" arrow>
                <Link
                  href={`/admin/users/${props.userId}`}
                  target="_blank"
                  style={{ marginRight: 5 }}
                >
                  <OpenInNewIcon />
                </Link>
              </Tooltip>
            )}
            <Grid container>
              <Grid className={classes.infoPaper} item md={6}>
                <Typography style={{ marginBottom: 4 }}>
                  User ID: {data.user.id}
                </Typography>
                <Typography style={{ marginBottom: 4 }}>
                  Phone No: +{data.user.countryCode}
                  {data.user.phoneNo}
                </Typography>
                <Typography style={{ marginBottom: 4 }}>
                  First Name: {data.user.firstName}
                  <Button
                    onClick={() => setShowEditFirstNameModal(true)}
                    type="primary"
                    shape="circle"
                    style={{ marginRight: 8, marginLeft: 8 }}
                    icon={<EditOutlined />}
                    size="small"
                  />
                </Typography>
                <Typography style={{ marginBottom: 4 }}>
                  Apply Date: (
                  {moment(data.user.createdAt).format(
                    Config.DEFAULT_DATE_FORMAT
                  )}
                  )
                </Typography>
                <Typography style={{ marginBottom: 4 }}>
                  Approved Date: (
                  {moment(data.user.createdAt).format(
                    Config.DEFAULT_DATE_FORMAT
                  )}
                  )
                </Typography>
                <Typography>
                  Email: <b>{data.user.email}</b>
                </Typography>
                <Typography>
                  Total Earnings:{" "}
                  <b>{formatCurrency(data.transactionSummary.totalEarnings)}</b>
                </Typography>
                <Typography>
                  Current Balance:{" "}
                  <b>{formatCurrency(data.user.availableBalance)}</b>
                </Typography>
                <Typography>
                  Campaign: <b>{data.user.campaign}</b>
                </Typography>
                <Typography>
                  Source: <b>{data.user.source}</b>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography>Monthly Earnings</Typography>
                <LineChart
                  datasets={[
                    {
                      label: "Earnings($)",
                      data: data.monthlyStats.map((item) =>
                        formatCentsToDollars(item.earnings)
                      ),
                      backgroundColor: "#8CE41D",
                      borderColor: "#8CE41D",
                    },
                  ]}
                  labels={data.monthlyStats.map((item) => item.monthYearGroup)}
                />
              </Grid>
            </Grid>

            <Typography>
              Application Status: &nbsp;
              {data.user.applicationStatus === "pending" ||
              data.user.applicationStatus === "unclear" ? (
                <HandleUserStatus
                  user={data.user}
                  updateCallback={handleUserStatusUpdate}
                />
              ) : (
                <>
                  <Chip
                    className="chipStatusHeight"
                    style={{ marginRight: 8 }}
                    label={formatReadbleUnderscoreString(
                      data.user.applicationStatus
                    )}
                  />
                  {data.user.reason && ` (${data.user.reason}) `}
                  {data.user.applicationStatus === "accepted" && (
                    <HandleUserStatus
                      user={data.user}
                      updateCallback={handleUserStatusUpdate}
                    />
                  )}
                </>
              )}
            </Typography>
          </Paper>
          <div className={classes.tagDiv}>
            <Typography style={{ marginRight: 16, fontSize: "16px" }}>
              User Tags:
            </Typography>
            <TagSelectorNew
              options={adminContext.userTags}
              selected={data.user.userTags.map((userTags) => ({
                id: userTags.userTag.id,
                name: userTags.userTag.name,
                description: userTags.userTag.description,
                tagGroupType: userTags.userTag.tagGroupType,
              }))}
              onChange={(tagId, isSelected) =>
                handleChangeTag(tagId, isSelected)
              }
            />
          </div>
          <Paper style={{ paddingBottom: 10 }}>
            <Typography className={classes.heading}>
              Linked Tiktok Accounts
            </Typography>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell style={{ textAlign: "right" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(data.linkedAccounts.tiktokAccounts.length) ? (
                  data.linkedAccounts.tiktokAccounts.map((account: any, i) => (
                    <TableRow key={account.id}>
                      <TableCell>
                        <Grid
                          container
                          style={{ alignItems: "center" }}
                          spacing={1}
                        >
                          <Grid item>{account.username}</Grid>
                          <Grid item>
                            <Input
                              readOnly={true}
                              value={account.followingCount}
                              className={classes.tiktokInputWidth}
                              addonAfter="Following"
                            />
                          </Grid>
                          <Grid item>
                            <Input
                              readOnly={true}
                              value={account.followerCount}
                              className={classes.tiktokInputWidth}
                              addonAfter="Followers"
                            />
                          </Grid>
                          <Grid item>
                            <Input
                              readOnly={true}
                              value={account.likesCount}
                              className={classes.tiktokInputWidth}
                              addonAfter="Likes"
                            />
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell style={{ textAlign: "right" }}>
                        <Popover
                          content={
                            <div style={{ textAlign: "center" }}>
                              <Button
                                danger
                                onClick={() =>
                                  handleLinkedAccountDelete(
                                    "tiktok",
                                    account.id,
                                    "tiktokAccounts"
                                  )
                                }
                                size="small"
                              >
                                Delete {account.username}
                              </Button>
                            </div>
                          }
                          title="Delete Tiktok Linked account?"
                          trigger="click"
                        >
                          <Button
                            type="primary"
                            danger
                            shape="circle"
                            icon={<DeleteOutlined />}
                            size="small"
                          />
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ fontSize: 16 }}
                    >
                      No Account Linked yet.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ paddingBottom: 10 }}>
            <Typography className={classes.heading}>
              Linked Gmail Accounts
            </Typography>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell style={{ textAlign: "right" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(data.linkedAccounts.gmailAccounts.length) ? (
                  data.linkedAccounts.gmailAccounts.map((account: any, i) => (
                    <TableRow key={account.id}>
                      <TableCell>{account.username}</TableCell>

                      <TableCell style={{ textAlign: "right" }}>
                        <Popover
                          content={
                            <div style={{ textAlign: "center" }}>
                              <Button
                                danger
                                onClick={() =>
                                  handleLinkedAccountDelete(
                                    "gmail",
                                    account.id,
                                    "gmailAccounts"
                                  )
                                }
                                size="small"
                              >
                                Delete {account.username}
                              </Button>
                            </div>
                          }
                          title="Delete Gmail Linked account? this action is irreversible"
                          trigger="click"
                        >
                          <Button
                            type="primary"
                            danger
                            shape="circle"
                            icon={<DeleteOutlined />}
                            size="small"
                          />
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ fontSize: 16 }}
                    >
                      No Account Linked yet.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
          <Paper>
            <Grid
              container
              alignItems="center"
              style={{ paddingTop: SPACING, paddingBottom: SPACING }}
            >
              <Grid item>
                <Typography
                  className={classes.heading}
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  Offers
                </Typography>
              </Grid>
              <Grid item>
                <Select
                  style={{ minWidth: 200 }}
                  value={offerStatus}
                  onChange={(val) => setOfferStatus(val)}
                  options={[
                    { label: "Active", value: "active" },
                    { label: "Action requried", value: "action_required" },
                    { label: "Expired", value: "expired" },
                    { label: "Completed", value: "completed" },
                  ]}
                />
              </Grid>
            </Grid>
            {data.offers.length === 0 && (
              <Typography style={{ textAlign: "center" }}>
                No Offers found
              </Typography>
            )}
            {data.offers.map((item) => (
              <div
                style={{
                  borderBottom: "2px solid #eee",
                  background: vistedOffer[item.id] && "#eee",
                }}
              >
                <Grid
                  container
                  style={{
                    padding: 20,
                    paddingBottom: 0,
                  }}
                >
                  <Grid item md={4} spacing={2}>
                    <img
                      style={{ height: 100, display: "block" }}
                      src={item.offer.brandRetailerAssociation.retailer.imgUrl}
                    />
                    <Typography style={{ marginTop: SPACING }}>
                      {item.offer.brandRetailerAssociation.retailer.name}
                    </Typography>
                    <div
                      style={{
                        margin: SPACING,
                        marginLeft: 0,
                        marginRight: 50,
                        display: "flex",
                        flexDirection: "column",
                        gap: SPACING,
                      }}
                    >
                      {item.purchase?.receiptStatus ===
                        RECEIPT_STATUS.UPLOADED && (
                        <Alert
                          message="Receipt approval pending"
                          type="warning"
                          showIcon
                        />
                      )}

                      {item.purchase?.posts?.find(
                        (post) => post.postStatus === POST_STATUS.SUBMITTED
                      ) && (
                        <Alert
                          message="Post approval pending"
                          type="warning"
                          showIcon
                        />
                      )}
                      {item.purchase?.posts?.find(
                        (post) =>
                          post.postStatus === POST_STATUS.APPROVED &&
                          moment().diff(moment(post.createdAt), "days") >= 7
                      ) && (
                        <Alert
                          message="Closing post & rewarding user pending"
                          type="warning"
                          showIcon
                        />
                      )}
                    </div>

                    <SwayButton
                      style={{ marginBottom: SPACING }}
                      disabled={!item?.purchase?.id}
                      onClick={() => {
                        setVistedOffer({ ...vistedOffer, [item.id]: true });
                        if (props?.setPurchase) {
                          window.open(
                            `/admin/purchases/${item.purchase.id}`,
                            "_blank"
                          );
                          // props?.setPurchase(item.purchase.id);
                        } else {
                          history.push(`/admin/purchases/${item.purchase.id}`);
                        }
                      }}
                    >
                      View Purchase & Receipt
                    </SwayButton>
                  </Grid>
                  <Grid item md={8}>
                    <ActivityTimeline
                      activities={item?.activities}
                      purchase={item?.purchase}
                    />
                  </Grid>
                </Grid>
              </div>
            ))}
          </Paper>

          <Paper style={{ paddingBottom: 10, marginTop: 20 }}>
            <Typography className={classes.heading}>
              Requested Retailers
            </Typography>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Date Requested</TableCell>
                  <TableCell>Retailer Name</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    Total Request Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(data.requestRetailers.length) ? (
                  data.requestRetailers.map((retailer: any, i) => (
                    <TableRow key={retailer.id}>
                      <TableCell>
                        {moment(retailer.createdAt).format("DD MMM")}
                      </TableCell>
                      <TableCell>{retailer.name}</TableCell>

                      <TableCell style={{ textAlign: "right" }}>
                        {retailer.count}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ fontSize: 16 }}
                    >
                      No Retailer has been request yet.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ paddingBottom: 10, marginTop: 20 }}>
            <Typography className={classes.heading}>
              Transaction History
            </Typography>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Transaction Sub Type</TableCell>
                  <TableCell style={{ textAlign: "right" }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(data.transactions.length) ? (
                  data.transactions.map((transaction: any, i) => (
                    <TableRow key={transaction.id}>
                      <TableCell>
                        {moment(transaction.createdAt).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell>
                        <Chip
                          className="chipStatusHeight"
                          label={TransactionSubTypeMapping[transaction.subType]}
                        />
                      </TableCell>

                      <TableCell style={{ textAlign: "right" }}>
                        {formatTransactionAmount(
                          transaction.type,
                          transaction.amount
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ fontSize: 16 }}
                    >
                      No Transaction has been done yet.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
          <Paper style={{ paddingBottom: 10, marginTop: 20 }}>
            <Typography className={classes.heading}>Comments</Typography>
            <Comment
              commentsDB={data.comments}
              elementId={userId}
              saveService={userV2Service.saveUserComment}
              editService={userV2Service.editUserComment}
            />
          </Paper>
        </Container>
      )}
    </Spin>
  );
};
export default UserDetails;
