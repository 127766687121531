import isSuccessResponse from "../helper/v2-helpers/isSuccessResponse";
import httpClient from "./http-client";

class PurchaseService {
  getPurchase = async (purchaseId: number) => {
    try {
      const response = await httpClient(`/admin/purchases/${purchaseId}`, {
        method: "GET",
        apiVersion: "v2",
        auth: true,
      });
      if (isSuccessResponse(response)) {
        return [undefined, response.data];
      } else {
        return [response.message, undefined];
      }
    } catch (e) {
      return [e, undefined];
    }
  };
  updatePurchase = async (purchaseId: number, payload: any) => {
    try {
      const response = await httpClient(`/admin/purchases/${purchaseId}`, {
        auth: true,
        apiVersion: "v2",
        method: "PATCH",
        body: payload,
      });
      if (isSuccessResponse(response)) {
        return [undefined, response.data.purchase];
      } else {
        return [new Error(response.message), undefined];
      }
    } catch (e) {
      return [e, undefined];
    }
  };
}

export default new PurchaseService();
