export default (props) => (
  <svg
    width={29}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.25 5.126c-.22 0-6.774.036-10.079.012-3.35-.024-.798-.156-4.118-.095A41.907 41.907 0 0 1 4.17 5.02c-.308-.008-.616-.016-.925-.02-.834-.014-1.497.573-1.508 1.294-.033 2.31-.028 1.896.027 4.209.021.879.042 1.757.058 2.635.06 3.303.273 7.488.37 10.072.028.73.713 1.307 1.56 1.317 2.755.034 1.553.225 2.11.215.777-.014 2.664-.025 2.823-.025h.022l7.703.071h.03c.265.008 4.108.114 6.5.07 1.328-.024 1.118-.109 2.41-.303.73-.11 1.253-.663 1.249-1.301-.011-1.656-.126-3.301-.241-4.94-.103-1.467-.206-1.297-.232-2.756-.032-1.732.08-5.077.095-5.496v-.087l-.06-3.326A1.633 1.633 0 0 0 24.5 5.048l-4.234.077h-.016Z"
      fill="#6FD100"
      stroke="#000"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
    <path
      d="M8.135 5.251c3.187.04 9.56.033 9.56.033s3.656.062 4.68.048c1.023-.015 1.006 4.805 1.006 4.805s-.102 3.64-.06 5.463c.034 1.44.14 1.272.246 2.72.118 1.615.237 3.238.257 4.871.007.63-.486 1.173-1.176 1.278-1.224.186-1.025.27-2.283.288-2.242.032-5.829-.086-6.153-.097l-.05-.002-7.303-.105c-2.065.002-2.357-.195-3.826-.221-.802-.014-1.454-.586-1.485-1.307-.107-2.548-.342-6.676-.418-9.933-.02-.866-.045-1.733-.07-2.6-.066-2.281-.068-1.872-.05-4.15.004-.525.345-.976.841-1.17l-.21.003c-.185.003-.04-.002.23-.01.171-.064.36-.098.56-.094.293.006.585.016.877.025.41.014-.83.05-1.437.069a1.312 1.312 0 0 0-.02.007c2.947-.038 3.18.041 6.284.08Z"
      fill="#DDF7BB"
    />
    <path
      d="m1.851 5.172-.21.003c-.185.003-.04-.002.23-.01m-.02.007c2.947-.038 3.18.041 6.284.08 3.187.038 9.56.032 9.56.032s3.656.062 4.68.048c1.023-.015 1.006 4.805 1.006 4.805s-.102 3.64-.06 5.463c.034 1.44.14 1.272.246 2.72.118 1.615.237 3.238.257 4.871.007.63-.486 1.173-1.176 1.278-1.224.186-1.025.27-2.283.288-2.242.032-5.829-.086-6.153-.097l-.05-.002-7.303-.105c-2.065.002-2.357-.195-3.826-.221-.802-.014-1.454-.586-1.485-1.307-.107-2.548-.342-6.676-.418-9.933-.02-.866-.045-1.733-.07-2.6-.066-2.281-.068-1.872-.05-4.15.004-.525.345-.976.841-1.17Zm0 0 .02-.007m0 0c.606-.019 1.847-.055 1.437-.069-.292-.01-.584-.019-.876-.025-.2-.004-.39.03-.561.094Z"
      stroke="#000"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
    <path
      d="M1.849 5.724c.318.387.701.649 1.072.963.585.494 1.187.96 1.79 1.423.437.334.783.696 1.176 1.084.287.285.63.49.92.766.157.15.344.348.52.461.924.594 1.56 1.628 2.43 2.291.624.477 1.247 1.087 1.814 1.66.822.833 2.137-.266 2.863-.748.227-.151.399-.365.623-.519.228-.156.487-.235.709-.413.25-.201.523-.305.786-.471.705-.446 1.383-.894 2.031-1.461 1.327-1.16 4.285-4.148 4.747-5.707"
      stroke="#000"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
  </svg>
);
