import { Select } from "antd";
import { ChevronDown } from "assets/icons";
import React, { ComponentProps } from "react";

const { Option } = Select;
type Props = ComponentProps<typeof Select>;

interface IProps {}

export { Option };
export default function SwaySelect(props: Props & IProps) {
  const suffixIcon = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ChevronDown
        style={{
          width: 12,
          height: 8,
        }}
        viewBox="0 0 12 8"
      />
    </div>
  );
  return (
    <Select
      className="sway-select"
      size="middle"
      style={{ width: "100%", ...props.style }}
      suffixIcon={props.suffixIcon ? props.suffixIcon : suffixIcon}
      {...props}
    >
      {props.children}
    </Select>
  );
}
