import React from "react";
import AdminBase from "modules/admin/admin-base";
import RetailerDetails from "./RetailerDetails";
interface IProps {}
const RetailerDetailsPage: React.FC<IProps> = (props) => {
  return (
    <AdminBase>
      <RetailerDetails />
    </AdminBase>
  );
};
export default RetailerDetailsPage;
