import * as React from "react";
import useColors from "../hooks/useColors";
import { LoadableIconProps } from "./icons.interface";

interface IProps extends LoadableIconProps {}
function StatsHeartIcon(props: IProps) {
  const { loading } = props;
  const colors = useColors();
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_222_4987)">
        <path
          d="M12.79 2.68913C12.4921 2.39105 12.1383 2.15459 11.749 1.99327C11.3596 1.83194 10.9423 1.7489 10.5209 1.7489C10.0994 1.7489 9.68209 1.83194 9.29274 1.99327C8.90338 2.15459 8.54963 2.39105 8.25169 2.68913L7.63336 3.30747L7.01503 2.68913C6.41321 2.08731 5.59696 1.74921 4.74586 1.74921C3.89476 1.74921 3.07851 2.08731 2.47669 2.68913C1.87487 3.29095 1.53677 4.1072 1.53677 4.9583C1.53677 5.8094 1.87487 6.62565 2.47669 7.22747L3.09503 7.8458L7.63336 12.3841L12.1717 7.8458L12.79 7.22747C13.0881 6.92953 13.3246 6.57577 13.4859 6.18642C13.6472 5.79707 13.7303 5.37975 13.7303 4.9583C13.7303 4.53685 13.6472 4.11953 13.4859 3.73018C13.3246 3.34082 13.0881 2.98707 12.79 2.68913V2.68913Z"
          stroke={loading ? "clear" : "black"}
          fill={loading ? colors.inactiveButtonBg : "clear"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_222_4987">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.633362)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StatsHeartIcon;
