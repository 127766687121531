import SwayText from "modules/merchantV2/components/SwayText";
import React from "react";

interface IProps {
  legnedContent: {
    name: string;
    color: string;
  }[];
  legendAlign?: "center" | "end" | "start";
  legendTitle?: string;
}

const ChartLegend: React.FC<IProps> = ({
  legnedContent,
  legendAlign = "center",
  legendTitle,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: legendAlign,
        marginRight: 15,
      }}
    >
      {legendTitle && (
        <SwayText style={{ flexGrow: 1 }} size="small" weight="bold">
          {legendTitle}
        </SwayText>
      )}
      {legnedContent.map((legend) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 15,
              }}
            >
              <div
                style={{
                  width: 12,
                  height: 12,
                  border: `2px solid ${legend.color}`,
                  borderRadius: "50%",
                  marginRight: 5,
                }}
              ></div>
              {legend.name}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default ChartLegend;
