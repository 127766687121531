import isSuccessResponse from "../helper/v2-helpers/isSuccessResponse";
import {
  IPaymentMethodResponse,
  IPaymentMethodResponseJson,
} from "../types/payment.interface";
import httpClient from "./http-client";

class PaymentService {
  /**
   * getPaymentMethodsForRetailer
   * @param retailerId
   * @returns
   */
  public async getPaymentMethodsForRetailer(
    retailerId: number
  ): Promise<IPaymentMethodResponse> {
    try {
      const response = await httpClient(
        `/merchant-users/me/retailers/${retailerId}/payment-methods`,
        {
          apiVersion: "v2",
          method: "GET",
          auth: true,
        }
      );
      if (!isSuccessResponse(response)) {
        return { error: new Error(response.error) };
      }

      const data: IPaymentMethodResponseJson = response.data;
      return { paymentMethods: data.paymentMethods.data || [] };
    } catch (error) {
      return { error };
    }
  }

  public async removePaymentMethodForRetailer(
    retailerId: number,
    paymentMethodId: string
  ) {
    try {
      const response = await httpClient(
        `/merchant-users/me/retailers/${retailerId}/payment-methods/${paymentMethodId}`,
        {
          apiVersion: "v2",
          method: "DELETE",
          auth: true,
        }
      );
      return response.data.paymentMethods;
    } catch (err) {
      return err;
    }
  }
}

export default new PaymentService();
