const parseSortParams = (query: string) => {
  query = query.replace('sortBy=', '');
  const strArr = query.split('(');
  const direction = strArr[0];
  const name = strArr[1].replace(')', '');
  return {
    name,
    direction,
  };
};
export default parseSortParams;
