import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Alert, Row } from "antd";
import Title from "antd/lib/typography/Title";
import Config from "config";
import React from "react";
import Loading from "../../../../../../component/loading";
import stripeService from "../../../../../../services/stripe.service";
import { MerchantContext } from "../../../../MerchantBaseProvider";
import StripeForm from "../../InvoiceList/StripeForm/StripeForm";
const stripePromise = loadStripe(Config.STRIPE_CLIENT_ID);
interface Props {}

const PaymentForm: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState(null);
  const { retailer } = React.useContext(MerchantContext);

  const getClientSecret = async () => {
    setLoading(true);
    const { error: stripeIntentError, stripeIntent } =
      await stripeService.getStripeIntent(retailer.id);
    if (stripeIntentError) {
      setError(true);
    }
    setClientSecret(stripeIntent.client_secret);
    setLoading(false);
  };

  React.useEffect(() => {
    getClientSecret();
  }, []);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  if (loading) {
    return <Loading></Loading>;
  }
  return (
    <>
      {error ? (
        <Alert
          message="Something went wrong. Please contact support"
          type="error"
        />
      ) : (
        <>
          <Title level={4}>Add payment method</Title>

          <Row style={{ margin: "auto" }}>
            <Elements
              stripe={stripePromise}
              options={{
                ...options,
                clientSecret,
                loader: "always",
                appearance: { theme: "stripe" }, // https://stripe.com/docs/elements/appearance-api#theme
              }}
            >
              <StripeForm />
            </Elements>
          </Row>
        </>
      )}
    </>
  );
};

export default PaymentForm;
