import RetailerTable from "component/admin-v2-tables/Retailers/retailer-table-ant";
import AdminBase from "modules/admin/admin-base";
import useStyles from "modules/admin/admin-base/style";
import React from "react";
const Brands: React.FC = () => {
  const classes = useStyles();
  return (
    <AdminBase>
      <RetailerTable />
    </AdminBase>
  );
};

export default Brands;
