import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="50%"
      height="50%"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7892 13.91L13.6192 21.08C13.4335 21.266 13.2129 21.4135 12.9701 21.5141C12.7273 21.6148 12.467 21.6666 12.2042 21.6666C11.9414 21.6666 11.6811 21.6148 11.4383 21.5141C11.1955 21.4135 10.975 21.266 10.7892 21.08L2.19922 12.5V2.5H12.1992L20.7892 11.09C21.1617 11.4647 21.3708 11.9716 21.3708 12.5C21.3708 13.0284 21.1617 13.5353 20.7892 13.91V13.91Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.19922 7.5H7.20922"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
