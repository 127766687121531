/**
 * capitalizeFirstLetter
 * capitalize first letter of a string
 * @param word
 * @returns string
 */
export const capitalizeFirstLetter = (word: string): string => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
};
