import React from 'react';
import NProgress from 'nprogress';
const ProgressBar = () => {
  React.useEffect(() => {
    NProgress.start();
    NProgress.configure({ easing: 'ease', speed: 500 });
    NProgress.configure({ showSpinner: false });
    return () => {
      NProgress.done();
    };
  }, []);
  return <div></div>;
};

export default ProgressBar;
