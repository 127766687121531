import { makeStyles } from "@material-ui/core/styles";
import { SPACING, SPACING_2 } from "../constants";

const useOnboardingBaseStyles = makeStyles((theme) => ({
  header: {
    paddingTop: SPACING_2,
    padding: `${SPACING_2}px ${SPACING_2}px 0px ${SPACING_2}px`,
    textAlign: "right",
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    maxWidth: 450,
    width: `calc(100% - ${SPACING}px)`,
  },
}));

export default useOnboardingBaseStyles;
