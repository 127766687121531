import { Container, Grid, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { Card, Select, Table, DatePicker, Space, Input } from "antd";
import getColumns from "./getColumns";
import tableV2Service from "services/table-v2.service";
import Loading from "component/loading";
import ProgressBar from "component/progressBar";
import Label from "component/Label/Label";
import { SearchOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const offerType = [{ name: "ALL" }, { name: "CATEGORY" }, { name: "USERS" }];
const isActive = [
  { id: true, name: "active" },
  { id: false, name: "inactive" },
];

const offerFilterSchema = {
  isActive: [],
  brand: "",
  retailer: "",
  startDate: "" as any,
  endDate: "" as any,
  defaultArg: true,
  offerType: [],
};

interface Props {}

const defaultSort = {
  order: "descend",
  columnKey: "createdAt",
};
const { RangePicker } = DatePicker;
const keys = Object.keys(offerFilterSchema);
const formatUrlQuery = tableV2Service.formatUserUrl(keys);

const OfferTableAnt: React.FC<Props> = (props) => {
  const history = useHistory();

  const [pagination, setPagination] = React.useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [data, setData] = React.useState([]);
  const [waiting, setWaiting] = React.useState(false);
  const [sortInfo, setSortInfo] = React.useState<any>(defaultSort);
  const [loading, setLoading] = React.useState(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const [filters, setFilters] = React.useState(offerFilterSchema);

  const columns = getColumns(sortInfo);
  const defaultFilterMapping = keys.reduce(
    (prev, next) => ({ ...prev, [next]: filters[next] }),
    {}
  );

  const getData = async ({ query, init = false }) => {
    setLoading(true);
    const res = await tableV2Service.getOfferListAdmin({
      query,
      init,
    });
    setLoading(false);
    if (initLoading) {
      setInitLoading(false);
    }
    setPagination((pagination) => ({
      ...pagination,
      total: res.data.totalCount,
    }));
    setData(res.data.offers);
  };

  React.useEffect(() => {
    if (!initLoading && !waiting) {
      getData({
        query: tableV2Service.formatQueryAPI(
          sortInfo,
          pagination,
          defaultFilterMapping
        ),
      });

      const url = formatUrlQuery(sortInfo, { ...filters });
      window.history.replaceState({ path: url }, "", url);
    }
  }, [filters]);
  React.useEffect(() => {
    tableV2Service.onMount({
      sortInfo,
      setSortInfo,
      pagination,
      setPagination,
      filters,
      setFilters,
      getData,
      defaultFilterMapping,
    });
  }, []);

  const handleTableChange = tableV2Service.handleTableChange({
    sortInfo,
    formatUrlQuery,
    filters,
    setSortInfo,
    setPagination,
    getData,
    defaultFilterMapping,
  });

  const handleSearch = tableV2Service.handleSearch({
    waiting,
    setWaiting,
    setFilters,
  });

  const handleChangeSelectAntNoALLValitacion =
    tableV2Service.handleChangeSelectAntNoALLValitacion({
      setFilters,
    });

  const handleChangeSelectAnt = tableV2Service.handleChangeSelectAnt({
    setFilters,
  });

  const handleDateRangeChange = tableV2Service.handleDateRangeChange({
    setFilters,
  });

  return (
    <Container
      style={{
        marginBottom: 60,
      }}
    >
      {initLoading ? <Loading /> : null}
      {loading ? <ProgressBar /> : null}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Label>Offer Date</Label>
              <RangePicker
                onChange={handleDateRangeChange("startDate", "endDate")}
                style={{ width: "100%" }}
                value={[filters.startDate, filters.endDate]}
              />
            </Grid>
            <Grid item md={4}>
              <Label>Search on Brand</Label>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search Brand"
                value={filters.brand}
                allowClear
                onChange={handleSearch("brand")}
              />
            </Grid>
            <Grid item md={4}>
              <Label>Search on Retailer</Label>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search Retailer"
                value={filters.retailer}
                allowClear
                onChange={handleSearch("retailer")}
              />
            </Grid>
            <Grid item md={4}>
              <Label>Offer type</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.offerType}
                style={{ width: "100%" }}
                placeholder="Offer type"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAntNoALLValitacion(
                  offerType,
                  "offerType"
                )}
              >
                <Select.Option key={"ALL"}>ALL</Select.Option>
                <Select.Option key={"CATEGORY"}>CATEGORY</Select.Option>
                <Select.Option key={"USERS"}>USERS</Select.Option>
              </Select>
            </Grid>
            <Grid item md={4}>
              <Label>Active</Label>
              <Select
                mode="multiple"
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                value={filters.isActive}
                style={{ width: "100%" }}
                placeholder="Active"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChangeSelectAnt(isActive, "isActive")}
              >
                <Select.Option key={"ALL"}>ALL</Select.Option>
                <Select.Option key={"true"}>Yes</Select.Option>
                <Select.Option key={"false"}>No</Select.Option>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginBottom: 20 }}></div>
      {React.useMemo(
        () => (
          <Table
            rowKey="id"
            onRow={(record) => ({
              onClick: (e) => {
                const route = `/admin/offer/users/${record.id}`;
                // check for both windows or mac
                if (e.ctrlKey || e.metaKey) {
                  window.open(route, "_blank");
                } else {
                  history.push(route);
                }
              },
            })}
            onChange={handleTableChange}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
          />
        ),
        [data, pagination]
      )}
    </Container>
  );
};

export default OfferTableAnt;
