import { makeStyles } from "@material-ui/core";
import { SPACING } from "../constants";

export default makeStyles({
  card: {
    background: "var(--primaryLight)",
    maxWidth: 400,
    borderColor: "var(--primary)",
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: SPACING,
  },
  imgSwayEarth: {
    height: 50,
    width: 50,
    marginBottom: 10,
  },
  welcomeText: {
    fontFamily: "Space Mono",
    fontSize: 24,
    textShadow:
      "-1.4px -1.4px 0 #000, 1.4px -1.4px 0 #000, -1.4px 1.4px 0 #000, 1.4px 1.4px 0 #000",
  },
  subText: {
    textAlign: "center",
    fontWeight: "bold",
    paddingLeft: SPACING,
    paddingRight: SPACING,
    lineHeight: "20px",
  },
  heading: {
    textAlign: "center",
    paddingLeft: SPACING,
    paddingRight: SPACING,
    lineHeight: "20px",
  },
  subTextProTip: {
    textAlign: "center",
    paddingLeft: SPACING,
    paddingRight: SPACING,
    lineHeight: "20px",
  },
  divider: {
    background: "var(--primaryDark)",
  },
  stepContainer: { display: "flex", flexDirection: "column", width: "100%" },
  dividerStep: {
    background: "var(--primaryDark)",
    marginTop: SPACING,
    marginBottom: SPACING,
  },
  step: {
    cursor: "pointer",
    paddingLeft: SPACING,
    paddingRight: SPACING,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});
