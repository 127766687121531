import { Pagination, TablePaginationConfig } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import moment from "moment";
import httpClient from "./http-client";

class TableServiceV2 {
  directionMapping = {
    ascend: "asc",
    descend: "desc",
  };
  /**
   * format request query for backend API
   * @param sort
   * @param pagination
   * @param filterParams
   * @returns
   */
  formatQueryAPI(
    sort: SorterResult<any>,
    pagination: TablePaginationConfig,
    filterParams: {
      tiktokUsernameSearch?: string;
      search?: string;
      status?: string[]; //purchases table only
      retailers?: number[]; //purchases table only
      tags?: number[];
    }
  ) {
    const direction = this.directionMapping[sort.order];
    let query = `sortBy=${direction}(${sort.columnKey})`;
    query += `&skip=${(pagination.current - 1) * pagination.pageSize}&limit=${
      pagination.pageSize
    }`;
    for (const [key, val] of Object.entries(filterParams)) {
      query += this.generateQueryStringBasedOnVal(key, val);
    }
    return query;
  }
  private generateQueryStringBasedOnVal(
    key: string,
    val: string | any[],
    checkArrNonZero = false
  ) {
    let str = "";
    if (
      (typeof val === "string" && val.trim() !== "") ||
      typeof val === "number"
    ) {
      str += `&${key}=${val}`;
    } else if (Array.isArray(val) && (checkArrNonZero || val.length !== 0)) {
      str += `&${key}=${JSON.stringify(val)}`;
    } else if (key.toLowerCase().includes("startdate") && val) {
      str += `&${key}=${moment.utc(val).startOf("day").format()}`;
    } else if (key.toLowerCase().includes("enddate") && val) {
      str += `&${key}=${moment.utc(val).endOf("day").format()}`;
    }
    return str;
  }
  /**
   * format request url + query for frontend url to track state via url
   * @param keysToChecks
   * @returns
   */
  formatUserUrl =
    (keysToChecks: any[]) =>
    (
      sort: any,
      filters,
      pagination: TablePaginationConfig = { current: 1, pageSize: 10 }
    ) => {
      let newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?sort=${JSON.stringify(sort)}&pagination=${JSON.stringify(
          pagination
        )}`;
      for (const key of keysToChecks) {
        newUrl += this.generateQueryStringBasedOnVal(key, filters[key], true);
      }
      return newUrl;
    };
  /**
   * Parse JSON string to valid JS object
   * @returns
   */
  getParsedQueryParams: any = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const parsedObj = {};
    for (const [key, val] of urlSearchParams.entries()) {
      try {
        if (key.includes("Date")) {
          parsedObj[key] = moment.utc(val);
        } else {
          parsedObj[key] = JSON.parse(val);
        }
      } catch (e) {
        parsedObj[key] = val;
      }
    }
    return parsedObj;
  };
  getPurchasesListAdmin = ({ query, init }) => {
    return httpClient(`/admin/purchases?${query}&init=${init}`, {
      auth: true,
      method: "GET",
      apiVersion: "v2",
    });
  };
  getPurchasesListMerchant = ({ query, init }) => {
    return httpClient(`/retailer/purchases?${query}&init=${init}`, {
      auth: true,
      method: "GET",
      apiVersion: "v2",
    });
  };
  getUserListAdmin = ({ query, init }) => {
    return httpClient(`/admin/users?${query}&init=${init}`, {
      auth: true,
      method: "GET",
      apiVersion: "v2",
    });
  };
  getRetailerListAdmin = ({ query, init }) => {
    return httpClient(`/admin/retailers?${query}&init=${init}`, {
      auth: true,
      method: "GET",
      apiVersion: "v2",
    });
  };
  getBrandListAdmin = ({ query, init }) => {
    return httpClient(`/admin/brands?${query}&init=${init}`, {
      auth: true,
      method: "GET",
      apiVersion: "v2",
    });
  };
  // @Handlers
  handleTableChange =
    ({
      sortInfo,
      formatUrlQuery,
      filters,
      setSortInfo,
      setPagination,
      getData,
      defaultFilterMapping,
    }) =>
    (newPagination: any, antFilters: any, sorter: any) => {
      const updatedSortInfo = {
        order: sorter.order,
        columnKey: sorter.columnKey,
      };
      // reset pagination if sorting change
      if (
        sortInfo.order !== updatedSortInfo.order ||
        sortInfo.columnKey !== updatedSortInfo.columnKey
      ) {
        newPagination.current = 1;
      }
      const url = formatUrlQuery(
        updatedSortInfo,
        { ...filters },
        newPagination
      );
      window.history.replaceState({ path: url }, "", url);
      setSortInfo(updatedSortInfo);
      setPagination(newPagination);
      getData({
        query: this.formatQueryAPI(sorter, newPagination, defaultFilterMapping),
      });
    };
  handleSearch =
    ({ waiting, setWaiting, setFilters }) =>
    (key: string) =>
    (e) => {
      if (!waiting) {
        setWaiting(true);
        setTimeout(() => {
          setWaiting(false);
          setFilters((filters) => ({ ...filters, [key]: e.target.value }));
        }, 750);
      }
      setFilters((filters) => ({ ...filters, [key]: e.target.value }));
    };
  handleChangeSelectAnt =
    ({ setFilters }) =>
    (completeItemsList: any[], selectedKey: string) =>
    (data) => {
      if (data && data.includes("ALL")) {
        setFilters((filters) => ({
          ...filters,
          [selectedKey]:
            filters[selectedKey].length === completeItemsList.length
              ? []
              : completeItemsList.map((item) => item.id.toString()),
        }));
        return;
      }
      setFilters((filters) => ({ ...filters, [selectedKey]: data }));
    };
  handleChangeSelectAntNoALLValitacion =
    ({ setFilters }) =>
    (completeItemsList: any[], selectedKey: string) =>
    (data) => {
      setFilters((filters) => ({ ...filters, [selectedKey]: data }));
    };
  handleDateRangeChange =
    ({ setFilters }) =>
    (startKey: string, endKey: string) =>
    (dates) => {
      setFilters((filters) => ({
        ...filters,
        [startKey]: dates?.[0],
        [endKey]: dates?.[1],
      }));
    };
  //@Handlers End
  onMount = ({
    sortInfo,
    setSortInfo,
    pagination,
    setPagination,
    filters,
    setFilters,
    getData,
    defaultFilterMapping,
  }) => {
    const parsedParams = this.getParsedQueryParams();
    const sort = parsedParams.sort ? parsedParams.sort : sortInfo;
    const updatedPagination = parsedParams.pagination
      ? parsedParams.pagination
      : pagination;
    getData({
      query: this.formatQueryAPI(sort, updatedPagination, {
        ...defaultFilterMapping,
        ...filters,
        ...parsedParams,
      }),
      init: true,
    });
    setFilters({
      ...filters,
      ...parsedParams,
    });
    setPagination(updatedPagination);
    setSortInfo(sort);
  };

  getOfferListAdmin = ({ query, init }) => {
    return httpClient(`/admin-users/me/offers?${query}&init=${init}`, {
      auth: true,
      method: "GET",
      apiVersion: "v2",
    });
  };

  getUsersByOfferListAdmin = ({ offerId, query, init }) => {
    return httpClient(
      `/admin-users/me/user-offers?${offerId}&${query}&init=${init}`,
      {
        auth: true,
        method: "GET",
        apiVersion: "v2",
      }
    );
  };
}

export default new TableServiceV2();
