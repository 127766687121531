import isSuccessResponse from "../helper/v2-helpers/isSuccessResponse";
import {
  IOnboardingProgress,
  IOnboardingProgressResponse,
  IOnboardingProgressResponseJson,
} from "../types/retailer.interface";
import httpClientV2 from "./http-clientV2";

class OnboardingFormService {
  public async updateOnboardingForm(
    retailerId: number,
    formId: string,
    formData
  ) {
    const response = await httpClientV2(
      `/merchant-users/me/retailers/${retailerId}/onboarding-forms/${formId}`,
      {
        method: "PATCH",
        body: formData,
        apiVersion: "v2",
        auth: true,
      }
    );
    if (!isSuccessResponse(response)) {
      return { error: new Error(response.message) };
    }
    return {};
  }

  public updateOnboardingFormProgress = async (
    retailerId: number,
    payload: Partial<IOnboardingProgress>
  ): Promise<IOnboardingProgressResponse> => {
    try {
      const response = await httpClientV2(
        `/merchant-users/me/retailers/${retailerId}/onboarding-progress`,
        {
          auth: true,
          apiVersion: "v2",
          method: "PATCH",
          body: payload,
        }
      );

      if (!isSuccessResponse(response)) {
        return { error: new Error(response.message) };
      }

      const data: IOnboardingProgressResponseJson = response.data;

      return {
        onboardingProgress: data.onboardingProgress,
        progress: data.progress,
      };
    } catch (error) {
      return { error };
    }
  };
}

export default new OnboardingFormService();
