import { Alert, Form, message } from "antd";
import { ValidEmailRegex } from "helper/validate_email";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import authService from "services/auth.service";
import SwayButton from "../components/SwayButton";
import SwayCard from "../components/SwayCard";
import SwayLink from "../components/SwayLink";
import SwayText from "../components/SwayText";
import SwayTextInput from "../components/SwayTextInput";
import { SPACING } from "../constants";
import useColors from "../hooks/useColors";
import OnboardingBase from "./OnboardingBase";
import useOnboardingBaseStyles from "./useOnboardingBaseStyles";
interface IProps {}

const ForgotPasswordPage: React.FC<IProps> = (props) => {
  const classes = useOnboardingBaseStyles();
  const [loading, setLoading] = useState(false);
  const [isLinkSend, setIsLinkSend] = useState(false);
  const colors = useColors();
  const history = useHistory();
  const onFinish = async (formData) => {
    setLoading(true);
    const { error } = await authService.forgotPasswordMerchant(formData);
    setLoading(false);
    if (!error) {
      setIsLinkSend(true);
    } else {
      message.error(error.message);
    }
  };
  return (
    <OnboardingBase>
      <div className={classes.header}></div>
      <SwayCard className={classes.card}>
        <SwayText type="title" style={{ textAlign: "center" }}>
          Forgot Password
        </SwayText>
        <SwayText
          size="small"
          color={colors.textGray}
          style={{
            textAlign: "center",
            display: "block",
            marginBottom: SPACING,
          }}
        >
          Enter your email address and we will send you instructions on how to
          reset your password.
        </SwayText>
        <Form
          name="basic"
          style={{ display: "flex", flexDirection: "column" }}
          onFinish={onFinish}
        >
          <Form.Item
            style={{ display: !isLinkSend ? "block" : "none" }}
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter a valid email",
                type: "email",
              },
            ]}
          >
            <SwayTextInput placeholder="Your email" />
          </Form.Item>
          {!isLinkSend ? (
            <>
              <SwayButton
                loading={loading}
                disabled={loading}
                style={{ height: 49 }}
                htmlType="submit"
              >
                Submit
              </SwayButton>
            </>
          ) : (
            <>
              <Alert
                style={{
                  textAlign: "center",
                  lineHeight: 1.2,
                  marginBottom: 15,
                }}
                message=" Password reset link has been sent to your email."
                type="success"
              />

              <div style={{ textAlign: "center" }}>
                <SwayText style={{ fontSize: 14, color: "rgba(0,0,0,0.54)" }}>
                  Didn’t receive an email?
                </SwayText>
                <SwayButton
                  type="link"
                  classType={null}
                  htmlType="submit"
                  style={{ textDecoration: "underline" }}
                  color="primary"
                >
                  RESEND
                </SwayButton>
              </div>
            </>
          )}

          <SwayButton
            type="link"
            classType={null}
            onClick={() => history.push("/merchant/login")}
            style={{ textAlign: "center", marginTop: SPACING }}
          >
            Login
          </SwayButton>
        </Form>
      </SwayCard>
      <div />
    </OnboardingBase>
  );
};
export default ForgotPasswordPage;
