import { PaymentMethod } from "@stripe/stripe-js";
import { Divider, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../../../../component/loading";
import getPageNameFromRoute from "../../../../../helper/getPageNameFromRoute";
import onboardingFormsService from "../../../../../services/onboarding-forms.service";
import paymentService from "../../../../../services/payment.service";
import { useAnalytics } from "../../../AnalyticsProvider";
import SwayText from "../../../components/SwayText";
import { MerchantContext } from "../../../MerchantBaseProvider";
import AddPaymentMethod from "./AddPaymentMethod/AddPaymentMethod";
import PaymentForm from "./PaymentForm/PaymentForm";
import PaymentMethodList from "./PaymentMethodList/PaymentMethodList";

interface Props {}

const Payment: React.FC<Props> = (props) => {
  const {
    retailer,
    setRetailer,
    loading: isMerchantContextLoading,
  } = useContext(MerchantContext);
  const location = useLocation();
  const [showAddPaymentForm, setShowAddPaymentForm] = React.useState(false);
  const [error, setError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const analytics = useAnalytics();
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>(
    []
  );
  const [arePaymentMethodsLoading, setArePaymentMethodsLoading] =
    React.useState(true);

  /**
   * getMerchantPaymentMethods
   */
  const getMerchantPaymentMethods = async () => {
    try {
      setArePaymentMethodsLoading(true);
      let { paymentMethods } =
        await paymentService.getPaymentMethodsForRetailer(retailer.id);

      setPaymentMethods(paymentMethods);
    } catch (e) {
      setError({
        hasError: true,
        errorMessage: "Error loading payment methods",
      });
    } finally {
      setArePaymentMethodsLoading(false);
    }
  };

  useEffect(() => {
    getMerchantPaymentMethods();
  }, []);

  const onClickAddPaymentHandler = () => {
    analytics.track("Button Clicked", {
      name: "add card",
      pageLocation: getPageNameFromRoute(location.pathname),
    });
    setShowAddPaymentForm(true);
  };

  const onClickDeletePaymentHandler = async (paymentMethodId) => {
    setArePaymentMethodsLoading(true);
    await deletePaymentMethod(paymentMethodId);
    const { onboardingProgress, progress, error } =
      await onboardingFormsService.updateOnboardingFormProgress(retailer.id, {
        addCreditCardCompleted: false,
      });
    setRetailer({ ...retailer, progress });
    setArePaymentMethodsLoading(false);
  };

  const deletePaymentMethod = async (paymentMethodId) => {
    try {
      await paymentService.removePaymentMethodForRetailer(
        retailer.id,
        paymentMethodId
      );
      const { paymentMethods } =
        await paymentService.getPaymentMethodsForRetailer(retailer.id);

      setPaymentMethods(paymentMethods);
    } catch (e) {}
  };

  if (arePaymentMethodsLoading || isMerchantContextLoading) {
    return <Loading></Loading>;
  }
  return (
    <>
      <Row style={{ flexDirection: "column" }}>
        <SwayText type="title" size="small">
          Payment
        </SwayText>
        {paymentMethods.length === 0 && showAddPaymentForm === false && (
          <>
            <AddPaymentMethod
              onClickAddCard={onClickAddPaymentHandler}
            ></AddPaymentMethod>
            <Divider></Divider>
          </>
        )}
        {showAddPaymentForm && (
          <>
            <PaymentForm></PaymentForm>
            <Divider></Divider>
          </>
        )}
        {paymentMethods.length > 0 && (
          <>
            <PaymentMethodList
              onClickDeleteIconHandler={onClickDeletePaymentHandler}
              paymentMethods={paymentMethods}
              loading={arePaymentMethodsLoading || isMerchantContextLoading}
              setPaymentMethods={setPaymentMethods}
              error={error}
            ></PaymentMethodList>
          </>
        )}
      </Row>
    </>
  );
};

export default Payment;
