import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  label: {
    margin: 0,
    fontSize: 13,
    color: 'rgba(0,0,0,0.87)',
  },
  heading: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 18,
    margin: '40px 0px 5px 0px',
    fontWeight: 500,
  },
  nested: {
    // paddingLeft: theme.spacing(4),
  },
  productImg: {
    height: 40,
    width: 40,
  },
  subText: {
    marginRight: 15,
    fontWeight: 500,
  },
  table: {
    '& td': {
      borderBottom: 'none',
    },
    '& th': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      padding: '2px 24px 2px 16px',
    },
  },
  completeIcon: {
    position: 'absolute',
    left: 5,
    top: -5,
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  pending: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  carouselDiv: {
    '& + MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  carousel: {
    '& > *:focus': {
      outline: 'none',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  customBtn: {
    position: 'absolute',
    zIndex: 44444,
    color: '#fff',
    top: 0,
    right: 0,
  },
  customTooltip: { fontSize: 12, lineHeight: '16px' },
  infoIconStyle: {
    marginLeft: 5,
    color: 'var(--primary)',
    cursor: 'pointer',
  },
}));

export default useStyles;
