import React from "react";
import AdminBase from "modules/admin/admin-base";
import OfferDetails from "./OfferDetails";

interface IProps { }
const OfferDetailsPage: React.FC<IProps> = (props) => {
  return (
    <AdminBase>
      <OfferDetails />
    </AdminBase>
  );
};
export default OfferDetailsPage;
