import * as React from "react";

export default (props) => (
  <svg
    width={28}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      maskUnits="userSpaceOnUse"
      x={-0.366}
      y={1.586}
      width={17}
      height={18}
      fill="#000"
    >
      <path fill="#fff" d="M-.366 1.586h17v18h-17z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.634 4.43v6.94A5.967 5.967 0 0 0 7.6 17.338h6.91V4.43l-.652-.844-1.188.844-.785-.844-1.402.844-1.189-.844-1.283.844-.665-.844-1.022.844-.618-.844-.832.844-.784-.844-.66.844-1.022-.844-.775.844Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.634 4.43v6.94A5.967 5.967 0 0 0 7.6 17.338h6.91V4.43l-.652-.844-1.188.844-.785-.844-1.402.844-1.189-.844-1.283.844-.665-.844-1.022.844-.618-.844-.832.844-.784-.844-.66.844-1.022-.844-.775.844Z"
      fill="#DDF7BB"
    />
    <path
      d="m1.634 4.43-.958-.88a1.3 1.3 0 0 0-.342.88h1.3ZM14.51 17.338v1.3a1.3 1.3 0 0 0 1.3-1.3h-1.3Zm0-12.908h1.3a1.3 1.3 0 0 0-.271-.795l-1.03.795Zm-.652-.844 1.03-.795a1.3 1.3 0 0 0-1.782-.265l.752 1.06Zm-1.188.844-.953.885a1.3 1.3 0 0 0 1.705.175l-.752-1.06Zm-.785-.844.952-.885a1.3 1.3 0 0 0-1.622-.23l.67 1.115Zm-1.402.844L9.73 5.49c.421.299.98.32 1.423.054l-.67-1.114Zm-1.189-.844.753-1.06A1.3 1.3 0 0 0 8.58 2.5l.714 1.086Zm-1.283.844-1.02.805a1.3 1.3 0 0 0 1.735.28L8.012 4.43Zm-.665-.844 1.02-.805a1.3 1.3 0 0 0-1.848-.198l.828 1.003Zm-1.022.844-1.05.768a1.3 1.3 0 0 0 1.877.234L6.325 4.43Zm-.618-.844 1.049-.768a1.3 1.3 0 0 0-1.975-.145l.926.913Zm-.832.844-.952.885a1.3 1.3 0 0 0 1.878.027l-.926-.912Zm-.784-.844.952-.885a1.3 1.3 0 0 0-1.977.085l1.025.8Zm-.66.844-.827 1.002a1.3 1.3 0 0 0 1.853-.202l-1.025-.8ZM2.41 3.586l.828-1.003a1.3 1.3 0 0 0-1.785.123l.957.88Zm.525 2.248V4.43h-2.6v1.404h2.6Zm-2.6 0v.096h2.6v-.096h-2.6Zm0 .096v5.44h2.6V5.93h-2.6Zm0 5.44A7.267 7.267 0 0 0 7.6 18.638v-2.6a4.667 4.667 0 0 1-4.667-4.668h-2.6ZM7.6 18.638h6.91v-2.6H7.6v2.6Zm8.21-1.3V5.93h-2.6v11.408h2.6Zm0-11.408v-.096h-2.6v.096h2.6Zm-2.6-1.5v1.404h2.6V4.43h-2.6Zm-.38-.05.65.844 2.059-1.589-.652-.844L12.83 4.38Zm.592 1.11 1.189-.844-1.505-2.12-1.19.844 1.506 2.12Zm-2.49-1.02.785.845 1.905-1.77-.785-.844-1.904 1.77Zm.221 1.074 1.402-.845-1.34-2.227-1.403.844 1.341 2.228Zm-2.612-.898 1.189.844 1.505-2.12-1.188-.844-1.506 2.12Zm.185.87 1.283-.844L8.58 2.5l-1.282.844 1.429 2.172ZM6.326 4.39l.666.844 2.041-1.61-.666-.844-2.041 1.61Zm.827 1.041 1.021-.844L6.52 2.583l-1.022.845 1.655 2.004ZM4.657 4.354l.618.844 2.098-1.536-.618-.844-2.098 1.536Zm1.143.988.832-.844L4.78 2.673l-.832.844 1.852 1.825Zm-2.663-.871.785.844 1.904-1.77-.784-.844-1.905 1.77Zm1.319.759.658-.844-2.05-1.6-.658.844 2.05 1.6Zm-2.875-.642 1.022.844L4.26 3.427l-1.022-.844-1.655 2.005Zm1.01.721.774-.844-1.914-1.759-.776.844 1.915 1.76Z"
      fill="#000"
      mask="url(#a)"
    />
    <path
      d="M10.49 5.184a.65.65 0 0 0-.612.428l-1.5 4.126a.65.65 0 0 0 .61.873h3.613l.24 13.677a.65.65 0 0 0 .17.429l.776.844a.65.65 0 0 0 .892.062l.508-.42.248.318a.65.65 0 0 0 .988.043l.322-.347.355.36a.65.65 0 0 0 .988-.072l.21-.288.49.405a.65.65 0 0 0 .925-.098l.297-.376.784.516a.65.65 0 0 0 .734-.013l.841-.598 1.038.625a.65.65 0 0 0 .812-.114l.396-.427.724.514a.65.65 0 0 0 .891-.133l.652-.844a.65.65 0 0 0 .135-.397V11.802A6.617 6.617 0 0 0 20.4 5.184h-9.911Z"
      fill="#DDF7BB"
      stroke="#000"
      strokeWidth={1.3}
      strokeLinejoin="round"
    />
    <path
      d="M9.99 5.834a2.75 2.75 0 0 0-2.751 2.751v7.127c0 1.126-1.104 1.626-1.517 1.626h7.269V8.585a2.75 2.75 0 0 0-2.751-2.75h-.25Z"
      fill="#6FD100"
    />
    <path
      d="M5.221 17.713c.789 0 2.017-.75 2.017-2.626V8.21A2.75 2.75 0 0 1 9.99 5.46h.25a2.75 2.75 0 0 1 2.751 2.75v9.253"
      stroke="#000"
      strokeWidth={1.3}
      strokeLinejoin="round"
    />
    <path
      d="M2.884 8.335h4.002M3.134 13.839h2.751M20.907 16.898c.47.059 1.136.194 1.598.091.352-.078.49-.269.893-.269.325 0 .634.072.961.072.439 0 .873-.072 1.317-.072M15.427 16.877H19.199M25.676 13.44H15.387M25.334 10.55c-1.665 0-3.302-.214-4.967-.214h-4.636M25.334 20.552c-1.665 0-3.302-.213-4.967-.213h-4.636"
      stroke="#000"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
    <path
      d="M12.887 11.086c-.1-4.201-3.459-4.418-5.126-4.001l-.375-.375c.25-.209.775-.65.875-.75.1-.1.959-.376 1.375-.5l1.626.124 1.25 1.126.625 1.5c-.041 2.71-.15 7.077-.25 2.876Z"
      fill="#000"
    />
    <path
      d="M16.871 7.974c.293-.119.48-.275.695-.502.085-.09.22-.318.369-.25.392.178.903.212 1.329.212.412 0 .82-.11 1.218-.037.473.086.96.075 1.445.075M4.092 11.136c.523.21.895 0 1.402 0 .402 0 .824.122 1.235.122"
      stroke="#050504"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
  </svg>
);
