import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  comment: {
    '&:hover': {
      backgroundColor: 'rgb(239, 242, 245, .4)',
    },
    paddingRight: 20,
    paddingLeft: 30,
    // paddingTop: 2,
    paddingBottom: 2,
    // marginTop: 10,
    marginBottom: 10,
  },
  divisor: {
    borderBottom: '1px solid #f0f0f0',
  },
  editBtn: {
    position: 'relative',
    top: 40,
    left: '96%',
    zIndex: 1
    /* marginRight: 8,
    marginLeft: 30,
    marginBottom: 6 */
  },
  header: {
    paddingRight: 20,
    paddingLeft: 18,
    paddingBottom: 10,
    borderBottom: '1px solid #f0f0f0',
  },
}));

export default useStyles;
