import { Avatar } from "antd";
import {
  SPACING_0_25,
  SPACING_0_5,
  SPACING_2,
} from "modules/merchantV2/constants";
import useColors from "modules/merchantV2/hooks/useColors";
import React from "react";
import { IPostUser } from "types/post.interface";
import SwayText from "../../SwayText";
import SwayPostCardLoadingBlock from "./SwayPostCardLoadingBlock";

interface ISwayPostCardUserData {
  profileImage?: string;
  tiktokHandle?: string;
  avatarUrl?: string;
}
interface IProps {
  userTiktokInfo?: ISwayPostCardUserData;
  loading: boolean;
}

export enum ViewKey {}

const SwayPostCardUser: React.FC<IProps> = (props) => {
  const { userTiktokInfo, loading } = props;
  const colors = useColors();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        zIndex: 5,
        padding: SPACING_0_5,
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-end",
        flexGrow: 0,
        flexShrink: 1,
      }}
    >
      <Avatar
        size={32}
        src={userTiktokInfo?.avatarUrl}
        style={{
          borderWidth: 2,
          borderColor: colors.background,
          backgroundColor: colors.background,
          borderStyle: "solid",
        }}
      />

      {/* User name loading area */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginLeft: 6,
          alignContent: "center",
          justifyContent: "center",
          height: SPACING_2,
        }}
      >
        {loading ? (
          <>
            <SwayPostCardLoadingBlock
              width="40%"
              backgroundColor={colors.white}
            />
            <div style={{ height: SPACING_0_25 }} />
            <SwayPostCardLoadingBlock
              width="25%"
              backgroundColor={colors.white}
            />
          </>
        ) : (
          <>
            <SwayText size="tiny" color={colors.white}>
              @{userTiktokInfo.tiktokHandle}
            </SwayText>
          </>
        )}
      </div>
    </div>
  );
};

export default SwayPostCardUser;
