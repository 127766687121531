import { Input, Row, Table } from "antd";
import React, { ComponentProps, ComponentPropsWithRef } from "react";
import { LeftArrowIcon, RightArrowIcon } from "../../../assets/icons";
import { SPACING } from "../constants";

type Props = ComponentPropsWithRef<typeof Table> & {
  filterSection?: React.ReactNode;
};

export default function SwayTable(props: Props) {
  const { pagination, style, ...remainingProps } = props;
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <LeftArrowIcon></LeftArrowIcon>;
    }
    if (type === "next") {
      return <RightArrowIcon></RightArrowIcon>;
    }
    return originalElement;
  };

  return (
    <Row
      style={{
        paddingTop: 10,
        paddingBottom: 16,
        paddingRight: 0,
        paddingLeft: 0,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 15,
        boxShadow: "1px 2px 2px rgba(0, 0, 0, 0.03)",
        borderColor: "rgba(120, 120, 120, 0.2)",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        {props.filterSection}
      </div>
      <Table
        bordered={true}
        size="middle"
        pagination={{
          itemRender,
          defaultPageSize: 10,
          showSizeChanger: false,
          position: ["bottomCenter"],
          style: { fontFamily: "Space Bold", fontWeight: 700, ...style },
          ...pagination,
        }}
        {...remainingProps}
      ></Table>
    </Row>
  );
}
