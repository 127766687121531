import OfferTableAnt from "component/admin-v2-tables/Offers/offer-table-ant";
import AdminBase from "modules/admin/admin-base";
import useStyles from "modules/admin/admin-base/style";
import React from "react";
const Purchases: React.FC = () => {
  const classes = useStyles();
  return (
    <AdminBase>
      <OfferTableAnt />
    </AdminBase>
  );
};

export default Purchases;
