export default (props) => (
  <svg
    width={29}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12.799 10.962-.457-.114a.835.835 0 0 0-.477.022c-1.214.423-3.285 1.134-3.415 1.134-.174 0-3.65 4.52-3.824 4.693-.125.125.492 3.08.913 4.978.077.35.37.608.726.645l5.751.584c.895.091 1.026-.544.242-.984-1.158-.649-2.23-1.591-2.591-2.964-.591-1.477 1.12-1.908 2.572-2.04a.868.868 0 0 0 .796-.774l.391-4.297a.833.833 0 0 0-.627-.883ZM25.76 8.646l-1.096-.242a.833.833 0 0 0-1.012.813v1.023a.833.833 0 0 1-.164.496l-.25.338c-.387.522-.052 1.249.598 1.266.872.024 1.835.017 2.241-.074.592-.133.487-1.797.31-2.965a.8.8 0 0 0-.626-.655Z"
      fill="#6FD100"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="m19.885 2.677-.504-.205a.833.833 0 0 0-1.146.783l.086 6.089v11.152a.833.833 0 0 1-.062.315l-1.202 2.945a.832.832 0 0 1-.379.42l-2.947 1.574a.838.838 0 0 1-.284.091c-2.022.268-4.7.73-.244.576 4.567-.159 7.277-3.904 7.54-5.757l.459-13.59a.836.836 0 0 0-.02-.21l-.797-3.592a.833.833 0 0 0-.5-.59Z"
      fill="#6FD100"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M21.234 11.562c.531.143 1.076.261 1.607.376l.351.076c.308.069.512-.046.602-.34.094-.287.152-.585.174-.886.036-.649.041-1.31.044-1.948l.001-.049a.41.41 0 0 1 .007-.082l-.022-.5-.221-.118-.232-.012a.088.088 0 0 1-.026.038c-.033.022-.127.005-.194-.01a4.652 4.652 0 0 1-1.803-.846l.063.018c-.59-.51-1.501-.83-1.87-1.22 0 0 .01.074-.001.06a14.9 14.9 0 0 0-.555-.75c-.427-.528-.46-1.78-.553-2.493-.112-.332-.582-.537-.582-.537l-.015-.017a1.524 1.524 0 0 0-.41-.061c-.82.022-1.637.069-2.257.104-.343.021-.38.105-.383.425l-.015 1.897-.023 2.965c-.003.31-.004.62-.005.93-.003.811-.007 1.65-.039 2.474a140.9 140.9 0 0 1-.13 2.58c-.041.72-.083 1.464-.115 2.196-.022.52-.031 1.05-.04 1.562-.008.512-.016 1.05-.04 1.575-.05 1.143-.45 1.969-1.225 2.524a1.274 1.274 0 0 0-.299.313.74.74 0 0 1-.478.3c-.418.088-.829.058-1.263.022l-.292-.021c-.652-.042-1.697-.168-2.254-.695a1.223 1.223 0 0 1-.153-.108 7.005 7.005 0 0 1-1.237-1.377l-.047-.1c-.738-.932-.588-2.049-.022-3.102.683-1.271 1.754-1.898 3.185-1.866.175.008.35.033.52.073.203.041.45.046.557-.298.081-.232.128-.475.14-.72.012-.548-.005-.485-.007-1.023l-.003-.584c.001-.046-.002-.092-.006-.155v-.028h.039l.067-.857c-.02-.469-.212-.614-.791-.715-2.522-.444-4.613.284-6.217 2.17-1.674 1.966-2.374 3.668-2.109 6.273.222 2.184 1.208 3.918 2.931 5.156l.077.055c.162.1.306.227.428.375.493.35 1.027.637 1.59.857a1.217 1.217 0 0 1-.076-.047c1.33.508 3.127 1.268 4.618.867 1.542-.416 2.497-.874 3.61-1.793 1.168-.968 1.895-2.606 2.21-4.37.177-1.003.2-2.656.207-3.653l.003-.32c.012-1.368.016-2.758.02-4.107a2067.433 2067.433 0 0 1 .008-1.801l.076.052c.09.061.177.121.26.182.488.287.993.54 1.514.76l.033.01c.345.123.706.247 1.068.344Z"
      fill="#DDF7BB"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M11.211 15.25c.214.345.405.741.669 1.048.099.115.342.202.342.371M18.14 21.77c.439.102.914.264 1.37.264M18.572 18.137c.52.022 1.045.096 1.564.096.06 0 .122.024.192.024"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </svg>
);
