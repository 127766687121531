import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StoresIcon from "@material-ui/icons/Store";
import StarBorder from "@material-ui/icons/StarBorder";
import OrderIcon from "@material-ui/icons/ListAlt";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import PeopleIcon from "@material-ui/icons/People";
import { Routes } from "modules/common/Sidebar";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import StorefrontIcon from "@material-ui/icons/Storefront";
import TagIcon from "@material-ui/icons/LocalOffer";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const routes: Routes[] = [
  {
    route: "/admin/purchases",
    key: "/admin/purchases",
    text: "PURCHASES",
    icon: <LocalMallIcon />,
    active: false,
  },

  {
    route: "/admin/users",
    key: "/admin/users",
    text: "USERS",
    icon: <PeopleIcon />,
    active: false,
  },
  {
    route: "/admin/retailers",
    key: "/admin/retailers",
    text: "RETAILERS",
    icon: <StorefrontIcon />,
    active: false,
  },
  {
    route: "/admin/brands",
    key: "/admin/brands",
    text: "BRANDS",
    icon: <BrandingWatermarkIcon />,
    active: false,
  },
  {
    route: "/admin/requested-brands",
    key: "/admin/requested-brands",
    text: "REQUESTED BRANDS",
    icon: <AssignmentIndIcon />,
    active: false,
  },
  {
    route: "/admin/urgent/cashouts",
    key: "/admin/urgent/cashouts",
    text: "URGENT",
    icon: <PriorityHighIcon />,
    active: false,
  },
  {
    route: "/admin/tags/users",
    key: "/admin/tags/users",
    text: "Tags",
    icon: <TagIcon />,
    active: false,
  },
  {
    route: "/admin/offers",
    key: "/admin/offers",
    text: "OFFERS",
    icon: <AttachMoneyIcon />,
    active: false,
  },
];

export default routes;
