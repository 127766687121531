import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import postService from "services/post.service";
import { IContentPost, IPostService } from "types/post.interface";
import SwayPostCard from "../components/SwayPostCard/SwayPostCard";
import { SPACING } from "../constants";
import SettingsBase from "../SettingsBase/SettingsBase";

interface IProps {}

export enum ViewKey {}

const DownloadsPage: React.FC<IProps> = (props) => {
  const service: IPostService = postService;

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<IContentPost[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const loadPosts = async () => {
    setLoading(true);

    const { error, downloads } = await service.getDownloads(currentPage);
    setLoading(false);

    if (error) {
      console.log("load downloads error: ", error);
      return;
    }

    setPosts(downloads);
  };

  useEffect(() => {
    loadPosts();
  }, []);

  const postClicked = (post: IContentPost) => {
    const { downloadInfo } = post;
    if (!downloadInfo) {
      return;
    }

    window.open(downloadInfo.downloadUrl, "_blank");
  };

  return (
    <SettingsBase title="Downloads">
      {posts.length > 0 ? (
        <Row
          style={{
            width: "100%",
            padding: SPACING,
          }}
          gutter={[SPACING, SPACING]}
        >
          {posts.map((p) => (
            <Col key={p.id} style={{ width: 218 }}>
              <SwayPostCard
                post={p}
                showDownload
                loading={loading}
                postClicked={postClicked}
              />
            </Col>
          ))}
        </Row>
      ) : null}
    </SettingsBase>
  );
};

export default DownloadsPage;
