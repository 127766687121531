import React, { useEffect } from 'react';
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn,
  //@ts-ignore
  DebounceTableSearch,
} from 'mui-datatables';
import useStyles from './style';
import moment, { Moment } from 'moment';
import Datetime from 'react-datetime';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
  Container,
  Chip,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import handleChangeInit from 'helper/handle-change-init';
import httpClient from 'services/http-client';
import authService from 'services/auth.service';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ProgressBar from 'component/progressBar';
import { useHistory } from 'react-router-dom';
import download from 'downloadjs';
import { formatCurrency } from 'helper/locale.helper';
import Loading from 'component/loading';
import parseSortParams from 'helper/parse-sort-params';
import CustomModal from 'component/customModal';
import OrderDetail from 'component/order-detail';
import RestoreIcon from '@material-ui/icons/Restore';
export const DATE_FORMAT = 'MM/DD/YYYY';

const ALL_STORES = { _id: 'ALL', name: 'SELECT ALL' };
const orderFiltersSchema = {
  fromDate: moment().subtract(30, 'days'),
  toDate: moment(),
  postStatus: 'all',
  selectedStores: [],
};
interface Props {
  stores: any[];
  title: string;
  role: 'admin' | 'merchant';
  fetchURL: string;
}
const formatPriceForCSV = (price: number) => {
  return formatCurrency(price);
};
const formatTableCSVData = (cols: any[], orders) => {
  const formmatedOrders = [];
  for (let i = 0; i < orders.length; i++) {
    const order = { data: [] };
    formmatedOrders.push(order);
    for (let j = 0; j < cols.length; j++) {
      switch (cols[j].name) {
        case 'createdAt':
          order.data.push(moment.utc(orders[i].createdAt).format(DATE_FORMAT));
          break;
        case 'discount':
          order.data.push(formatPriceForCSV(orders[i].discount));
          break;
        case 'amount':
          order.data.push(formatPriceForCSV(orders[i].amount));
          break;
        case 'paidAmount':
          order.data.push(formatPriceForCSV(orders[i].paidAmount));
          break;
        case 'arePostsCompleted':
          order.data.push(
            orders[i].arePostsCompleted ? 'Completed' : 'Pending'
          );
          break;
        default:
          order.data.push(orders[i][cols[j].name]);
          break;
      }
    }
  }
  return formmatedOrders;
};

const OrderMuiTable: React.FC<Props> = (props) => {
  const [orders, setOrders] = React.useState([]);
  const history = useHistory();
  const [totalCount, setTotalCount] = React.useState(0);
  const [currentSort, setCurrentSort] = React.useState(
    'sortBy=desc(createdAt)'
  );
  const [rowPerPage, setRowPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const [orderDetailsModal, setOrderDetailsModal] = React.useState('');
  const [allChecked, setAllChecked] = React.useState(true);
  const searchRef = React.useRef(null);
  const [orderFilters, setOrderFilters] = React.useState(orderFiltersSchema);
  const [stores, setStores] = React.useState([]);
  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (searchRef.current) {
      searchRef.current.searchField.value = '';
    }
    setOrderFilters({ ...orderFilters, [name]: value });
  };
  const handleDate = (m: Moment, key: string) => {
    if (searchRef.current) {
      searchRef.current.searchField.value = '';
    }
    setOrderFilters({ ...orderFilters, [key]: m });
  };
  const downloadCSV = async (
    columns: any[],
    buildHead: any,
    buildBody: any
  ) => {
    const orders = await getOrders(currentSort, 'true');
    const formattedOrders = formatTableCSVData(columns, orders); //need existing buildHead & body to work
    download(
      '\uFEFF' + buildHead(columns) + buildBody(formattedOrders),
      `${orderFilters.fromDate.format(
        DATE_FORMAT
      )}_to_${orderFilters.toDate.format(DATE_FORMAT)}_Orders`,
      'text/csv'
    );
  };
  const classes = useStyles();
  const columns: MUIDataTableColumn[] = [
    {
      name: '_id',
      options: {
        display: 'false',
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        print: false,
      },
    },
    {
      name: 'posts',
      options: {
        display: 'false',
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        print: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Order Date',
      options: {
        customBodyRender: (value: string) => {
          return moment.utc(value).format(DATE_FORMAT);
        },
      },
    },
    {
      name: 'storeName',
      label: 'Brand',
    },
    {
      name: 'email',
      label: 'Customer Email',
    },
    {
      name: 'igUsername',
      label: ' IG handle',
    },
    {
      name: 'amount',
      label: 'Cart Value',
      options: {
        customBodyRender: (value: number) => {
          return formatCurrency(value);
        },
      },
    },
    {
      name: 'discount',
      label: 'Discount',
      options: {
        customBodyRender: (value: number) => {
          return formatCurrency(value);
        },
      },
    },
    {
      name: 'refOrder',
      label: 'Shipping Status',
      options: {
        customBodyRender: (refOrder: any) => {
          const fulfillments = refOrder.fulfillments;
          let shippingStatus = 'Not shipped';
          if (fulfillments && fulfillments.length !== 0) {
            shippingStatus = 'Shipped';
            fulfillments.forEach((item) => {
              if (item.shipment_status === 'delivered') {
                shippingStatus = 'Delivered';
              }
            });
          }
          return <Chip className={`chipStatusHeight`} label={shippingStatus} />;
        },
      },
    },

    {
      name: 'arePostsCompleted',
      label: 'Post Status',
      options: {
        customBodyRender: (value: boolean, { rowData }) => {
          const hasActionRequired = rowData[1].find(
            (post) => post.status === 'submitted'
          );
          if (hasActionRequired) {
            return (
              <Chip
                className={`${classes.pending} chipStatusHeight`}
                label={
                  props.role === 'admin'
                    ? 'Action Required'
                    : 'Pending Swaypay Approval'
                }
              />
            );
          }
          return (
            <>
              {value ? (
                <Chip
                  className={`${classes.completed} chipStatusHeight`}
                  label='Completed'
                />
              ) : (
                <Chip className={`chipStatusHeight`} label='Pending' />
              )}
            </>
          );
        },
      },
    },
  ];
  const currentSortValue = parseSortParams(currentSort);
  const options: MUIDataTableOptions = {
    count: totalCount,
    //@ts-ignore
    sortOrder: currentSortValue,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowPerPage(numberOfRows);
    },
    onRowClick: ([_id]) => {
      setOrderDetailsModal(_id);
    },
    setTableProps: () => ({
      size: 'small',
      className: classes.rightAlignTable,
    }),
    onDownload: (buildHead, buildBody, columns, rows) => {
      downloadCSV(columns, buildHead, buildBody);
      return false;
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      handleSearch.current = handleSearch;
      return (
        <DebounceTableSearch
          searchText={searchText}
          ref={searchRef}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          debounceWait={300}
        />
      );
    },
    serverSide: true,
    searchPlaceholder: 'Search on Email, Instgram Handle or Shopify Order No.',
    searchOpen: true,
    onTableChange: (action, tableState: any) => {
      const actions = new Set([
        'changePage',
        'changeRowsPerPage',
        'search',
        'sort',
      ]);
      if (actions.has(action)) {
        let sortQuery = 'sortBy=desc(createdAt)';
        let searchQuery = '';
        const limit = `&limit=${tableState.rowsPerPage}&page=${tableState.page}`;
        if (tableState.sortOrder.name) {
          sortQuery = `sortBy=${tableState.sortOrder.direction}(${tableState.sortOrder.name})`;
          setCurrentSort(sortQuery);
        }
        if (tableState.searchText) {
          searchQuery = `&search=${tableState.searchText}`;
        }
        const query = sortQuery + limit + searchQuery;
        getOrders(query);
      }
    },

    filter: false,
    selectableRows: 'none',
  };
  const getOrders = async (query = '', complete = '') => {
    setLoading(true);
    const res = await httpClient(`${props.fetchURL}?${query}`, {
      auth: true,
      method: 'GET',
      headers: {
        from_date: moment.utc(orderFilters.fromDate).startOf('day').format(),
        to_date: moment.utc(orderFilters.toDate).endOf('day').format(),
        post_status: orderFilters.postStatus,
        stores: JSON.stringify(
          orderFilters.selectedStores
            .filter((store) => store._id !== 'ALL')
            .map((store) => store._id)
        ),
        complete,
      },
    });
    setLoading(false);
    if (complete) {
      return res.data.orders;
    }
    if (initLoading) {
      setInitLoading(false);
    }
    setOrders(res.data.orders);
    setTotalCount(res.data.totalCount);
  };
  const handleSelect = ({ target: { value } }: any) => {
    if (
      orderFilters.selectedStores[0] &&
      orderFilters.selectedStores[0]._id === 'ALL' &&
      value[0] &&
      value[0]._id !== 'ALL'
    ) {
      setOrderFilters({
        ...orderFilters,
        selectedStores: [],
      });
    } else {
      if (
        value[0] &&
        value[0]._id === 'ALL' &&
        orderFilters.selectedStores.length < value.length
      ) {
        console.log('ALLL', value);
        setOrderFilters({
          ...orderFilters,
          selectedStores: [ALL_STORES, ...props.stores],
        });
      } else {
        if (props.stores.length === value.length && value[0]._id !== 'ALL') {
          console.log('SAME LENGTH');
          setOrderFilters({
            ...orderFilters,
            selectedStores: [ALL_STORES, ...props.stores],
          });
        } else {
          console.log('ELSE');
          setOrderFilters({
            ...orderFilters,
            selectedStores: value.filter((item) => item._id !== 'ALL'),
          });
        }
      }
    }
  };
  const resetStores = () => {
    setOrderFilters({
      ...orderFiltersSchema,
      selectedStores: [ALL_STORES, ...props.stores],
    });
    setInitLoading(true);
    setStores([ALL_STORES, ...props.stores]);
    localStorage.removeItem('store_filters');
  };
  React.useEffect(() => {
    if (!initLoading)
      localStorage.setItem('store_filters', JSON.stringify(orderFilters));
    if (orderFilters.selectedStores.length !== 0) {
      getOrders(`${currentSort}&limit=${10}`);
    } else {
      setOrders([]);
    }
  }, [orderFilters]);
  React.useEffect(() => {
    if (props.stores.length !== 0) {
      const cachedFilters = JSON.parse(localStorage.getItem('store_filters'));
      if (cachedFilters) {
        setOrderFilters({
          ...cachedFilters,
          fromDate: moment(cachedFilters.fromDate),
          toDate: moment(cachedFilters.toDate),
        });
      } else {
        setOrderFilters({
          ...orderFilters,
          selectedStores: [ALL_STORES, ...props.stores],
        });
      }

      setInitLoading(true);
      setStores([ALL_STORES, ...props.stores]);
    } else {
      setInitLoading(false);
    }
  }, [props.stores]);

  return (
    <Container
      style={{
        marginBottom: 60,
      }}
      className={loading && classes.muiTableLoading}
    >
      {initLoading && <Loading />}
      {loading && <ProgressBar />}
      {orderDetailsModal && (
        <CustomModal
          dialogStyle={{ margin: 20 }}
          fullScreen
          state={Boolean(orderDetailsModal)}
          closeHandler={() => setOrderDetailsModal('')}
        >
          <OrderDetail role={props.role} orderId={orderDetailsModal} />
        </CustomModal>
      )}
      <Grid container spacing={3} style={{ marginBottom: 5 }}>
        <Grid item md={3} xs={12}>
          <Typography className={classes.label}>From Date</Typography>
          <Datetime
            dateFormat='MM/DD/YYYY'
            timeFormat={false}
            closeOnSelect
            value={orderFilters.fromDate}
            onChange={(e: Moment) => handleDate(e, 'fromDate')}
            inputProps={{ placeholder: 'FROM DATE' }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography className={classes.label}>To Date</Typography>
          <Datetime
            dateFormat='MM/DD/YYYY'
            timeFormat={false}
            closeOnSelect
            value={orderFilters.toDate}
            onChange={(e: Moment) => handleDate(e, 'toDate')}
            inputProps={{ placeholder: 'TO DATE' }}
          />
        </Grid>
        <Grid
          item
          style={{ alignSelf: 'flex-end', padding: 0 }}
          onClick={() => resetStores()}
        >
          <Tooltip title='Reset Filters' arrow placement='top'>
            <IconButton aria-label='restore'>
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 20 }} spacing={3}>
        <Grid item md={3} xs={12}>
          <Typography className={classes.label}>Stores</Typography>
          <Select
            fullWidth
            labelId='demo-mutiple-checkbox-label'
            id='demo-mutiple-checkbox'
            multiple
            MenuProps={{ getContentAnchorEl: null }}
            onSelect={(e) => console.log(e)}
            value={orderFilters.selectedStores}
            onChange={handleSelect}
            name='selectedStores'
            input={<Input />}
            renderValue={(selected: any) =>
              selected
                .map((store) => store.name)
                .filter((name) => name !== 'SELECT ALL')
                .join(', ')
            }
          >
            {stores.map((store) => (
              <MenuItem key={store._id} value={store}>
                <Checkbox
                  checked={
                    orderFilters.selectedStores
                      .map((store: any) => store._id)
                      .indexOf(store._id) > -1
                  }
                />
                <ListItemText primary={store.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography className={classes.label}>Post Status</Typography>
          <Select
            fullWidth
            value={orderFilters.postStatus}
            name='postStatus'
            onChange={handleChange}
          >
            <MenuItem value='all'>ALL</MenuItem>
            <MenuItem value='submitted'>
              {props.role === 'admin'
                ? 'Action Required'
                : 'Pending Swaypay Approval'}
            </MenuItem>
            <MenuItem value='pending'>Pending</MenuItem>
            <MenuItem value='completed'>Completed</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <div style={{ marginBottom: 10 }}></div>
      {React.useMemo(
        () => (
          <MUIDataTable
            title={props.title}
            data={orders}
            columns={columns}
            options={options}
          />
        ),
        [orders, totalCount]
      )}
    </Container>
  );
};

export default OrderMuiTable;
