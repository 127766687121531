import { Col, Row } from "antd";
import {
  SPACING,
  SPACING_0_25,
  SPACING_0_5,
  SPACING_0_75,
} from "modules/merchantV2/constants";
import { useContext, useEffect, useRef, useState } from "react";
import postService from "services/post.service";
import {
  IPostService,
  IContentPost,
  IContentPostPricingTier,
} from "types/post.interface";
import PurchasePost from "../PurchasePost/PurchasePost";
import ContentPageFilter from "assets/images/content-page-filter.png";
import SamplePostAlertIcon from "assets/images/sample-post-alert-icon.png";
import SwayCard from "modules/merchantV2/components/SwayCard";
import SwayText from "modules/merchantV2/components/SwayText";
import useColors from "modules/merchantV2/hooks/useColors";
import SwayButton from "modules/merchantV2/components/SwayButton";
import { ChevronDown, XIcon } from "assets/icons";
import SwaySelect, { Option } from "modules/merchantV2/components/SwaySelect";
import PricingTierIcon from "modules/merchantV2/icons/PricingTier";
import {
  formatAPIQuery,
  generateUserUrl,
  IFilter,
  parseParamsFromUrl,
} from "helper/v2-helpers/formatAPIQuery";
import SwayPostCard from "../../../components/SwayPostCard/SwayPostCard";
import { useAnalytics } from "../../../AnalyticsProvider";
import getPageNameFromRoute from "../../../../../helper/getPageNameFromRoute";
import { MerchantContext } from "../../../MerchantBaseProvider";
import { useLocation } from "react-router-dom";

interface ISortOption {
  title: string;
  value: string;
}

interface IFilterOption {
  title: string;
  label: string;
  value: string;
  icon: React.ReactNode;
}

const ContentPostList: React.FC = () => {
  const colors = useColors();
  const service: IPostService = postService;

  const [loading, setLoading] = useState(false);
  const [pricingTiers, setPricingTiers] = useState<IContentPostPricingTier[]>(
    []
  );
  const location = useLocation();
  const { retailer, loading: isMerchantContextLoading } =
    useContext(MerchantContext);
  const analytics = useAnalytics();
  const [posts, setPosts] = useState<IContentPost[]>([]);
  const mounted = useRef(false);
  const [samplePosts, setSamplePosts] = useState<IContentPost[]>([]);
  const [selectedPostInfo, setSelectedPostInfo] = useState<{
    post: IContentPost;
    isSamplePost: boolean;
  }>({ post: undefined, isSamplePost: false });
  const DISMISSED_KEY_LOCALSTORAGE = "swaypay_dismissed_sample_helper";
  const hasPreviouslyDismissed =
    localStorage.getItem(DISMISSED_KEY_LOCALSTORAGE) !== null;
  const [dismissedSampleHelper, setDismissedSampleHelper] = useState(
    hasPreviouslyDismissed
  );

  const setupInitialFilter = () => {
    const existingFilter = parseParamsFromUrl();
    let output: { sortValue?: string; pricingTiers?: string[] } = {};

    if (existingFilter.sort && existingFilter.sort != undefined) {
      const columnKey = existingFilter.sort.columnKey;
      const order = existingFilter.sort.order;
      if (columnKey === "createdAt") {
        output.sortValue = order === "ascend" ? "oldest" : "newest";
      } else if (columnKey === "price") {
        output.sortValue = order === "ascend" ? "price-asc" : "price-desc";
      }
    } else {
      output.sortValue = "newest";
    }

    if (existingFilter.pricingTiers as string[]) {
      output.pricingTiers = existingFilter.pricingTiers || [];
    } else {
      output.pricingTiers = [];
    }

    return output;
  };
  const initialFilter = setupInitialFilter();

  useEffect(() => {
    mounted.current = true;
    loadPosts();
    return () => {
      mounted.current = false;
    };
  }, []);

  const loadPosts = async () => {
    // Grab current filter state, load from api, update current list
    if (loading) {
      return;
    }
    setLoading(true);
    const sort = sorterFromValue(selectedSortValue);
    const filter: IFilter = {
      pricingTiers: selectedPricingTiers,
    };
    const queryString = formatAPIQuery(filter, sort);
    const updatedUrl = generateUserUrl(filter, sort);

    let tiers = pricingTiers;
    if (!tiers) {
      const { error: tiersError, pricingTiers: responseTiers } =
        await service.getPricingTiers();
      if (tiersError) {
        setLoading(false);
        return;
      }

      tiers = responseTiers;
      setPricingTiers(responseTiers);
    }
    // Using the response tiers so we don't get any weird race conditions with useState
    const { posts: responsePosts, error: postsError } = await service.getPosts(
      queryString
    );
    setLoading(false);
    if (!mounted.current) return;
    window.history.replaceState({ path: updatedUrl }, "", updatedUrl);

    if (postsError) {
      return;
    }

    setPosts(responsePosts);

    const { examplePosts, error: sampleError } = await service.getSamplePosts(
      queryString
    );

    if (sampleError) {
      console.log(sampleError);
      return;
    }

    setSamplePosts(examplePosts);
  };

  const postClicked = (post: IContentPost, isSamplePost?: boolean) => {
    if (!isSamplePost) {
      analytics.track("Button Clicked", {
        name: "buy",
        pageLocation: getPageNameFromRoute(location.pathname),
        retailer: retailer.name,
        creatorId: post?.user?.id,
        priceLevel: post?.purchaseInfo?.pricingTier?.name?.toLowerCase(),
        tiktokURL: post?.postLongUrl,
        purchaseId: post?.purchase?.id,
        contentViews: post?.latestPostStats?.viewCount,
      });
    }
    setSelectedPostInfo({ post, isSamplePost });
  };

  const postPurchaseCanceled = () => {
    setSelectedPostInfo({ post: undefined, isSamplePost: false });
  };

  const selectorIconStyles = {
    width: 20,
    height: 20,
  };

  const renderSampleInfoAlert = () => {
    return (
      <SwayCard
        style={{
          backgroundColor: colors.lightButtonBackground,
          display: "flex",
          flexGrow: 1,
        }}
        bodyStyle={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={SamplePostAlertIcon}
            style={{
              width: 32,
              height: 32,
              marginRight: SPACING_0_5,
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SwayText size="small" weight="bold">
              What am I looking at?
            </SwayText>
            <SwayText size="small">
              These are example Swaypay posts. Once you get launched, you’ll
              start seeing posts for your brand that you can buy.
            </SwayText>
          </div>
          <div
            style={{
              flexGrow: 1,
            }}
          />
          <SwayButton
            style={{
              marginLeft: SPACING_0_5,
              borderWidth: 0,
              padding: 0,
            }}
            ghost
            classType={null}
            onClick={() => {
              hideSampleHelper();
            }}
          >
            <XIcon />
          </SwayButton>
        </div>
      </SwayCard>
    );
  };

  const hideSampleHelper = async () => {
    localStorage.setItem(DISMISSED_KEY_LOCALSTORAGE, "true");
    setDismissedSampleHelper(true);
  };

  const filterOptions: IFilterOption[] = [
    {
      title: "Bronze",
      label: "Bronze",
      value: "bronze",
      icon: <PricingTierIcon pricingTier="bronze" />,
    },
    {
      title: "Silver",
      label: "Silver",
      value: "silver",
      icon: <PricingTierIcon pricingTier="silver" />,
    },
    {
      title: "Gold",
      label: "Gold",
      value: "gold",
      icon: <PricingTierIcon pricingTier="gold" />,
    },
  ];

  const [selectedPricingTiers, setSelectedPricingTiers] = useState<string[]>(
    initialFilter.pricingTiers || []
  );
  const [unselectedTiers, setUnselectedTiers] = useState<IFilterOption[]>(
    filterOptions.filter((o) => !selectedPricingTiers.includes(o.value))
  );

  useEffect(() => {
    const unselected = filterOptions.filter(
      (o) => !selectedPricingTiers.includes(o.value)
    );
    setUnselectedTiers(unselected);
  }, [selectedPricingTiers]);
  const [selectedSortValue, setSelectedSortValue] = useState<string>(
    initialFilter.sortValue
  );
  const sorterFromValue = (value: string) => {
    let val: { columnKey: string; order: "ascend" | "descend" } | undefined =
      undefined;
    switch (value) {
      case "oldest":
        val = { columnKey: "createdAt", order: "ascend" };
        break;
      case "price-asc":
        val = { columnKey: "price", order: "ascend" };
        break;
      case "price-desc":
        val = { columnKey: "createdAt", order: "descend" };
        break;
      default:
        // Newest also returns undefined, so that we can keep the url cleaner at default state
        return undefined;
    }
    return val;
  };
  const sortOptions: ISortOption[] = [
    { title: "Newest", value: "newest" },
    { title: "Oldest", value: "oldest" },
    // { title: "Price - Low to High", value: "price-asc" },
    // { title: "Price - High to Low", value: "price-desc" },
  ];

  useEffect(() => {
    loadPosts();
  }, [selectedPricingTiers, selectedSortValue]);

  const filterTokenClicked = (value: string) => {
    const updated = selectedPricingTiers.filter((t) => t !== value);
    setSelectedPricingTiers(updated);
  };

  const renderFilterToken = (key: string, value: string) => {
    if (value.length === 0) {
      return null;
    }
    // We need the name of the tier in title case
    const tokenTitle = value.slice(0, 1).toUpperCase() + value.slice(1);
    return (
      <div
        key={value}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: `2px 12px`,
          borderRadius: 16,
          backgroundColor: colors.filterToken,
        }}
      >
        <SwayText weight="bold" size="tiny">
          {key}
          {":"}
        </SwayText>
        <SwayText
          size="tiny"
          style={{
            marginLeft: 2,
          }}
        >
          {tokenTitle}
        </SwayText>
        <SwayButton
          classType={null}
          ghost
          size="small"
          onClick={() => {
            filterTokenClicked(value);
          }}
          style={{
            borderWidth: 0,
            padding: 0,
            marginLeft: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <XIcon
              style={{
                fontSize: 16,
              }}
            />
          </div>
        </SwayButton>
      </div>
    );
  };

  return (
    <>
      {selectedPostInfo.post ? (
        <PurchasePost
          post={selectedPostInfo.post}
          isSamplePost={selectedPostInfo.isSamplePost}
          onCancel={() => {
            postPurchaseCanceled();
          }}
        />
      ) : null}
      <Row style={{ width: "100%", padding: `${SPACING}px ${SPACING}px 0` }}>
        {/* <Col span={5}>
          <div className="price-level">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "absolute",
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                pointerEvents: "none",
              }}
            >
              <img
                src={ContentPageFilter}
                style={{
                  ...selectorIconStyles,
                  marginLeft: SPACING_0_75,
                  marginRight: SPACING_0_5,
                }}
              />
              Price Level
              <div
                style={{
                  flexGrow: 1,
                }}
              />
              <ChevronDown
                style={{
                  width: 12,
                  height: 8,
                  marginRight: SPACING_0_75,
                }}
                viewBox="0 0 12 8"
              />
            </div>
            <SwaySelect
              showSearch={false}
              defaultValue={initialFilter.pricingTiers}
              value={selectedPricingTiers}
              mode="multiple"
              onChange={(value) => {
                const resultValue = value as string[];
                if (!resultValue) {
                  return;
                }

                setSelectedPricingTiers(resultValue);
              }}
            >
              {unselectedTiers.map((o) => (
                <Option value={o.value} key={o.title}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {o.icon}
                    <div style={{ width: SPACING_0_25 }}></div>
                    {o.title}
                  </div>
                </Option>
              ))}
            </SwaySelect>
          </div>
        </Col> */}
        <div
          style={{
            flexGrow: 1,
          }}
        />

        <Col span={4}>
          <SwaySelect
            defaultValue={initialFilter.sortValue || "newest"}
            onChange={(value) => {
              const option = value as string;
              if (option) {
                setSelectedSortValue(option);
              }
            }}
          >
            {sortOptions.map((o) => (
              <Option value={o.value} key={o.value}>
                {o.title}
              </Option>
            ))}
          </SwaySelect>
        </Col>
        {selectedPricingTiers.length > 0 ? (
          <Col span={24} style={{ marginTop: SPACING }}>
            <div style={{ display: "flex", width: "100%" }}>
              {selectedPricingTiers.map((t) => {
                return renderFilterToken("Price Level", t);
              })}
            </div>
          </Col>
        ) : null}
      </Row>
      {posts.length > 0 ? (
        <Row
          style={{
            width: "100%",
            padding: SPACING,
          }}
          gutter={[SPACING, SPACING]}
        >
          {posts.map((p) => (
            // Hard set width pulled from figma
            <Col key={p.id} style={{ width: 218 }}>
              <SwayPostCard
                post={p}
                loading={loading}
                postClicked={postClicked}
              />
            </Col>
          ))}
        </Row>
      ) : null}
      {/* Show alert at top of page when sample posts are used */}
      {samplePosts.length > 0 && !dismissedSampleHelper ? (
        <Row style={{ width: "100%", padding: `${SPACING}px ${SPACING}px 0` }}>
          {renderSampleInfoAlert()}
        </Row>
      ) : null}
      {samplePosts.length > 0 ? (
        <Row
          style={{
            width: "100%",
            padding: SPACING,
          }}
          gutter={[SPACING, SPACING]}
        >
          <Col span={24}>
            <SwayText weight="bold">Example Posts</SwayText>
          </Col>
          {samplePosts.map((p) => (
            <Col key={p.id} style={{ width: 218 }}>
              <SwayPostCard
                post={p}
                loading={loading}
                postClicked={postClicked}
                isSamplePost={true}
              />
            </Col>
          ))}
        </Row>
      ) : null}
    </>
  );
};

export default ContentPostList;
