import validatePassword from './validate-password';
import validateEmail from './validate_email';
import { gTagCustomEvent } from './gtag';

const handleError = (
  key: string,
  value: string,
  errorState: any,
  setErrorState: (errorState: any) => void,
  currentState?: any
) => {
  const errorObj = {
    error: false,
    msg: '',
  };
  switch (key) {
    case 'password':
      if (!validatePassword(value)) {
        if (value.length > 7) {
          gTagCustomEvent('Password Fail')({
            EA: 'Password Change Event',
            EL: value,
          });
        }
        errorObj.error = true;
        errorObj.msg = 'Invalid Password.';
      }
      setErrorState({ ...errorState, password: { ...errorObj } });
      break;
    case 'name':
      if (value.trim().length < 1) {
        errorObj.error = true;
        errorObj.msg = 'Name is required.';
      }
      setErrorState({ ...errorState, name: { ...errorObj } });
      break;
    case 'email':
      if (!validateEmail(value)) {
        errorObj.error = true;
        errorObj.msg = 'Invalid email.';
      }
      setErrorState({ ...errorState, email: { ...errorObj } });
      break;
    case 'confirmPassword':
      if (value !== currentState.password) {
        errorObj.error = true;
        errorObj.msg = 'Password and confirm password does not match.';
      }
      setErrorState({ ...errorState, confirmPassword: { ...errorObj } });
      break;
    case 'mediumThreshold':
    case 'highThreshold':
      if (!value || parseInt(value) < 10) {
        errorObj.error = true;
        errorObj.msg = 'Follower cannot be set less than 10.';
      }
      if (!value || parseInt(value) > 10000) {
        errorObj.error = true;
        errorObj.msg = 'Follower cannot be set greater than 10k.';
      }
      if (
        key === 'mediumThreshold' &&
        parseInt(value) > parseInt(currentState.highThreshold)
      ) {
        errorObj.error = true;
        errorObj.msg =
          'Follower count cannot be greater than high upper threshold.';
      }
      if (
        key === 'highThreshold' &&
        parseInt(value) < parseInt(currentState.mediumThreshold)
      ) {
        errorObj.error = true;
        errorObj.msg =
          'Follower count cannot be less than high lower threshold.';
      }
      setErrorState({
        ...errorState,
        [key]: { ...errorObj },
      });
      break;
    case 'lowDiscount':
    case 'mediumDiscount':
    case 'highDiscount':
      if (!value || parseInt(value) < 5) {
        errorObj.error = true;
        errorObj.msg = 'Discounts cannot be less than 5%.';
      }
      setErrorState({ ...errorState, [key]: { ...errorObj } });
      break;
  }
};
// type ErrorSchema<T extends object> = Record<keyof T, {
//    msg: string;
//    error: boolean;
// }>;

// function makeErrorHandler<T extends object>(schema: T): ErrorSchema<T>
function makeErrorHandler<T>(schema: T): any {
  const errorSchema: any = {};
  for (const [key, value] of Object.entries(schema)) {
    if (typeof value !== 'string') continue;
    errorSchema[key] = {
      error: false,
      msg: '',
    };
  }
  return errorSchema;
}

export { makeErrorHandler, handleError };
