import { SetupIntent } from "@stripe/stripe-js";
import isSuccessResponse from "../helper/v2-helpers/isSuccessResponse";
import httpClientV2 from "./http-clientV2";

class StripeService {
  /**
   * getStripeIntent
   * get client intent for a retailer
   * @returns
   */
  public async getStripeIntent(
    retailerId: number
  ): Promise<{ stripeIntent?: SetupIntent; error?: Error }> {
    try {
      const response = await httpClientV2(
        `/merchant-users/me/retailers/${retailerId}/stripeIntent`,
        {
          apiVersion: "v2",
          method: "GET",
          auth: true,
        }
      );
      if (!isSuccessResponse(response)) {
        return { error: new Error(response.error) };
      }
      return { stripeIntent: response.data.stripeIntent };
    } catch (err) {
      return { error: err };
    }
  }
}

export default new StripeService();
