import React, { useEffect, useRef, useState } from "react";
import { IContentPost } from "types/post.interface";
import { SPACING, SPACING_4 } from "../../constants";
import useColors from "../../hooks/useColors";
import SwayCard from "../SwayCard";
import PostGradientCover from "assets/images/post-gradient-cover.png";
import SwayPostCardStats from "./components/SwayPostCardStats";
import SwayPostCardPurchase from "./components/SwayPostCardPurchase";
import SwayPostCardUser from "./components/SwayPostCardUser";
import SwayPostCardDownload from "./components/SwayPostCardDownload";

interface IProps {
  post?: IContentPost;
  loading: boolean;
  showDownload?: boolean;
  isSamplePost?: boolean;
  postClicked?: (post: IContentPost, isSamplePost: boolean) => void;
}

export enum ViewKey {}

const SwayPostCard: React.FC<IProps> = ({
  post,
  loading,
  showDownload = false,
  isSamplePost,
  postClicked,
}) => {
  const colors = useColors();

  const [videoHeight, setVideoHeight] = useState(SPACING_4);

  const videoContainerRef = useRef(null);

  useEffect(() => {
    const videoWidth = videoContainerRef.current.clientWidth;
    // 178x225 design for image only!
    const fitHeight = (videoWidth * 225) / 178;

    if (fitHeight !== videoHeight) {
      setVideoHeight(fitHeight);
    }
  }, [videoHeight]);

  return (
    <SwayCard bodyStyle={{ padding: 0 }}>
      {/* Start with just the creator section in the upper part */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Image section */}
        <div
          ref={videoContainerRef}
          style={{
            overflow: "hidden",
            borderTopLeftRadius: SPACING,
            borderTopRightRadius: SPACING,
            backgroundColor: colors.inactiveDotColor,
            height: videoHeight,
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          <img
            src={post.coverImageUrl}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1,
              borderTopLeftRadius: SPACING,
              borderTopRightRadius: SPACING,
              objectFit: "cover",
            }}
          />
          {/* Image gradient cover, over image, under info */}
          <img
            src={PostGradientCover}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 2,
              borderTopLeftRadius: SPACING,
              borderTopRightRadius: SPACING,
              objectFit: "cover",
            }}
          />
          {/* User info */}
          <SwayPostCardUser
            loading={loading}
            userTiktokInfo={post?.userTiktokInfo}
          />
        </div>
        {/* Show download info if available, other subviews if not */}
        {showDownload ? (
          <SwayPostCardDownload
            downloadInfo={post.downloadInfo}
            loading={loading}
            buttonClicked={() => {
              postClicked?.(post, false);
            }}
          />
        ) : (
          <>
            {/* Stats section */}
            <SwayPostCardStats
              loading={loading}
              stats={post?.latestPostStats}
            />
            {/* Purchase section, if eligible. How to pass purchase eligibility */}
            <SwayPostCardPurchase
              loading={loading}
              purchaseInfo={post?.purchaseInfo}
              isSamplePost={isSamplePost}
              buttonClicked={() => {
                postClicked?.(post, isSamplePost);
              }}
            />
          </>
        )}
      </div>
    </SwayCard>
  );
};

export default SwayPostCard;
