import React, { ErrorInfo } from "react";

import Root from "./modules/routing.component";
import { ThemeProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import "antd/dist/antd.less";
import { DEFAULT_LOCALE, translationMessages } from "i18n";
import theme from "./theme/index";
import CssBaseline from "@material-ui/core/CssBaseline";
interface IState {
  error: Error;
  errorInfo: ErrorInfo;
  locale: string;
}

class App extends React.Component<{}, IState> {
  state: IState = {
    error: null,
    errorInfo: null,
    locale: DEFAULT_LOCALE,
  };
  setLocale = (e: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ locale: e.target.value as string });
  };
  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider
          locale={this.state.locale}
          messages={translationMessages[this.state.locale]}
        >
          <Helmet>
            <title>Home | Swaypay</title>
            <meta
              name="description"
              content="Pay for products you love with social posts featuring those products."
            />
          </Helmet>
          <Root locale={this.state.locale} setLocale={this.setLocale} />
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

export default App;
