import Config from "config";
import { gTagCustomEvent } from "helper/gtag";
import isSuccessResponse from "helper/v2-helpers/isSuccessResponse";

interface RequestConfig extends Omit<RequestInit, "body"> {
  auth?: boolean;
  body?: object;
  isPup?: boolean;
  follow?: number;
  redirect?: RequestRedirect;
  apiVersion?: "v1" | "v2";
}
export default async (
  input: string,
  settings: RequestConfig = {},
  absoluteUrl?: boolean
) => {
  const {
    headers,
    auth,
    body,
    isPup,
    apiVersion = "v1",
    ...remainingSettings
  } = settings;
  try {
    const response = await fetch(
      absoluteUrl
        ? input
        : (isPup
            ? process.env.REACT_APP_PUPPETEER_SERVER_URL
            : process.env.REACT_APP_SERVER_URL) +
            "/" +
            apiVersion +
            input,
      {
        headers: {
          "Content-Type": "application/json",
          deviceOS: "web",
          ...(auth && {
            Authorization:
              "Bearer " +
              JSON.parse(String(localStorage.getItem("swaypay_auth"))).token,
          }),
          ...headers,
        },
        ...(body && { body: JSON.stringify(body) }),
        credentials: "include",
        ...remainingSettings,
      }
    );
    return await response.json();
  } catch (e) {
    gTagCustomEvent("SysSubmitPostReposnse")({
      EA: `${window.location.pathname}`,
      EL: `body=${JSON.stringify(body)};headers=${JSON.stringify(
        headers
      )};url=${input}method=${settings.method};`,
      nonInteractiveHit: true,
    });
    return e;
  }
};
