import { makeStyles } from "@material-ui/core";
import {
  SPACING,
  SPACING_0_5,
  SPACING_0_75,
} from "modules/merchantV2/constants";
import useColors from "modules/merchantV2/hooks/useColors";
import React from "react";
import { IContentPostDownloadInfo } from "types/post.interface";
import SwayButton from "../../SwayButton";

interface IProps {
  downloadInfo?: IContentPostDownloadInfo;
  loading: boolean;
  buttonClicked: () => void;
}

export enum ViewKey {}

const useSwayPostCardDownloadStyles = makeStyles((theme) => {
  const colors = useColors();
  return {
    downloadButton: {
      "&:hover": {
        color: colors.backgroundInverted,
      },
    },
  };
});

const SwayPostCardDownload: React.FC<IProps> = (props) => {
  const { downloadInfo, loading, buttonClicked } = props;
  const colors = useColors();
  const classes = useSwayPostCardDownloadStyles();

  const buttonDisabled = loading || !downloadInfo;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: `${SPACING_0_75}px ${SPACING_0_5}px`,
      }}
    >
      <SwayButton
        disabled={buttonDisabled}
        classType={null}
        className={classes.downloadButton}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: SPACING,
          paddingRight: SPACING,
          height: 35,
          fontSize: 14,
          color: colors.backgroundInverted,
          backgroundColor: colors.inactiveButtonBg,
        }}
        onClick={() => {
          buttonClicked();
        }}
      >
        Download
      </SwayButton>
    </div>
  );
};

export default SwayPostCardDownload;
