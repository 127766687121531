import { Typography } from "antd";
import React, { ComponentPropsWithoutRef } from "react";
import useColors from "../hooks/useColors";

const { Title, Text } = Typography;

export const fontMapping = {
  primary: {
    normal: "SuisseIntl-Regular",
    bold: "SuisseIntl-Bold",
  },
  numbers: {
    normal: "Space Mono",
    bold: "Space Bold",
  },
} as const;

const fontSizes = {
  body: { tiny: 12, small: 14, medium: 16, large: 19 },
  title: { tiny: 14, small: 16, medium: 21, large: 28 },
} as const;

type IProps = Omit<ComponentPropsWithoutRef<typeof Title>, "type"> & {
  type?: keyof typeof fontSizes;
  size?: keyof typeof fontSizes.body;
  font?: keyof typeof fontMapping;
  weight?: keyof typeof fontMapping.primary;
  color?: string;
  children: React.ReactNode;
};

const SwayText: React.FC<IProps> = (props) => {
  const colors = useColors();
  const {
    size = "medium",
    type = "body",
    font = "primary",
    weight = "normal",
    color,
    children,
    style,
    ...remainingProps
  } = props;
  const commonStyles = {
    color: color || colors.text,
    fontSize: fontSizes[type][size],
    fontFamily: fontMapping[font][weight],
  };
  if (type === "title")
    return (
      <Title style={{ ...commonStyles, ...style }} {...remainingProps}>
        {children}
      </Title>
    );

  return (
    <Text style={{ ...commonStyles, ...style }} {...remainingProps}>
      {children}
    </Text>
  );
};
export default SwayText;
