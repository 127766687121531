import { Divider, Form, Row, Upload, UploadProps } from "antd";
import Config from "config";
import fileToBase64 from "helper/fileToBase64";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import authService from "services/auth.service";
import httpClientV2 from "services/http-clientV2";
import retailerService from "services/retailer.service";
import SwayButton from "../components/SwayButton";
import SwayCard from "../components/SwayCard";
import SwayText from "../components/SwayText";
import SwayTextInput from "../components/SwayTextInput";
import { SPACING, SPACING_2 } from "../constants";
import DashboardBase from "../DashboardBase/DashboardBase";
import useColors from "../hooks/useColors";
import MerchantBaseProvider from "../MerchantBaseProvider";

type Props = {};

const AccountPage = (props: Props) => {
  const colors = useColors();
  const [form] = Form.useForm();
  const name = Form.useWatch("name", form);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [logoImg, setLogoImg] = useState<null | string>(null);

  const uploadProps: UploadProps = {
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      setLogoImg((await fileToBase64(file)) as string);
      return false;
    },
  };
  useEffect(() => {
    (async () => {
      const { error, merchantUser } =
        await retailerService.getMerchantDetails();
      if (error) {
      } else {
        form.setFieldsValue({
          name: merchantUser.name,
          email: merchantUser.email,
          phoneNo: merchantUser.phoneNo,
        });
        setLogoImg(merchantUser.imgUrl);
      }
    })();
  }, []);
  const onFinish = async (data) => {
    setLoading(true);
    const { error } = await retailerService.updateMerchantDetails({
      ...data,
      imgDataBase64: logoImg,
    });
    if (error) {
    } else {
    }
    setLoading(false);
  };
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar={false}>
        <div
          style={{ width: "100%", padding: `${SPACING}px ${SPACING}px 0px` }}
        >
          <SwayText type="title">Account Settings</SwayText>
        </div>
        <Divider style={{ margin: 0 }} />

        <SwayCard
          style={{ maxWidth: 600, margin: "25px auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <SwayText
            type="title"
            style={{ padding: SPACING, paddingBottom: 0, marginBottom: 0 }}
          >
            Basic Information
          </SwayText>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <div style={{ padding: SPACING }}>
            <Form
              form={form}
              name="brand-intake-form"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: SPACING,
                }}
              >
                <img
                  src={logoImg}
                  alt="Logo"
                  style={{
                    width: 40,
                    objectFit: "cover",
                    height: 40,
                    marginLeft: -3,
                    marginRight: -3,
                    borderRadius: 20,

                    border: `1px solid ${colors.greyBorder}`,
                  }}
                />
                <SwayText
                  style={{
                    marginLeft: SPACING,
                    marginRight: SPACING,
                    fontWeight: "bold",
                  }}
                >
                  {name}
                </SwayText>
                <Form.Item name="imgUrl" style={{ marginBottom: 0 }}>
                  <Upload {...uploadProps}>
                    <SwayButton classType="grey">Edit Photo</SwayButton>
                  </Upload>
                </Form.Item>
              </div>

              <Form.Item label="Name" name="name">
                <SwayTextInput placeholder="First & last name" />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <SwayTextInput placeholder="Email" />
              </Form.Item>
              <SwayButton
                htmlType="button"
                classType="grey"
                onClick={() => history.push("/merchant/forgot-password")}
                style={{ marginBottom: SPACING_2 }}
              >
                Reset Password
              </SwayButton>
              <Form.Item label="Phone" name="phoneNo">
                <SwayTextInput placeholder="Phone" />
              </Form.Item>
              <SwayButton
                loading={loading}
                disabled={loading}
                htmlType="submit"
              >
                Save
              </SwayButton>
            </Form>
          </div>
        </SwayCard>
      </DashboardBase>
    </MerchantBaseProvider>
  );
};

export default AccountPage;
