import { ReactComponent as CartSVG } from "./images/cart.svg";
import { ReactComponent as XSVG } from "./images/x-icon.svg";
import { ReactComponent as EditSVG } from "./images/edit.svg";
import { ReactComponent as HelpChat } from "./images/help-chat.svg";
import { ReactComponent as ChevronDownSVG } from "./images/chevron-down.svg";
import { ReactComponent as LeftArrow } from "./images/arrow-left.svg";
import { ReactComponent as RightArrow } from "./images/arrow-right.svg";
import { ReactComponent as CreditCard } from "./images/credit-card.svg";
import IconMaker from "./icon-maker.hoc";

export const CartIcon = IconMaker(CartSVG);
export const XIcon = IconMaker(XSVG);
export const EditIcon = IconMaker(EditSVG);
export const HelpChatIcon = IconMaker(HelpChat);
export const ChevronDown = IconMaker(ChevronDownSVG);
export const LeftArrowIcon = IconMaker(LeftArrow);
export const RightArrowIcon = IconMaker(RightArrow);
export const CreditCardIcon = IconMaker(CreditCard);
