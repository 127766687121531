import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import SwayCard from "modules/merchantV2/components/SwayCard";
import ChartLegend from "./ChartLegend";

interface IProps {
  chartContext: {
    data: {
      date: string;
      value: number;
    }[];
    color: string;
    chartType: "line" | "area";
    legendName?: string;
  }[];
  showYAxis?: boolean;
  legendAlign?: "start" | "center" | "end";
  legendTitle?: string;
}

const SwayChart: React.FC<IProps> = ({
  chartContext,
  showYAxis,
  legendAlign,
  legendTitle,
}) => {
  const [combinedData, setCombinedData] = useState([]);
  const [legendData, setLegendData] = useState([]);
  const svgRef = useRef(null);

  const getCombinedData = () => {
    const combineData = chartContext.map((context) => context.data).flat();
    setCombinedData(combineData);
  };

  const getLegendData = () => {
    const legendData = chartContext.map(({ color, legendName }) => ({
      name: legendName,
      color,
    }));
    setLegendData(legendData);
  };

  useEffect(() => {
    getCombinedData();
    getLegendData();
  }, [chartContext]);

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 25, left: 20 };
    const width =
      svgRef.current.parentElement.offsetWidth - margin.left - margin.right;
    const height = 250 - margin.top - margin.bottom;

    const xScale = d3
      .scaleUtc()
      .domain(d3.extent(combinedData, (d) => new Date(d.date)))
      .range([margin.left, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(combinedData, (d) => d.value)])
      .range([height, 0])
      .nice(4);

    const yAxis = d3
      .axisLeft(yScale)
      .tickSize(-width)
      .tickPadding(10)
      .tickFormat((d, i) => (i === 0 ? "" : d3.format(".0f")(d)))
      .tickValues(
        d3.range(0, Math.ceil(yScale.domain()[1] / 50) * 50 + 50, 50)
      );

    const area = d3
      .area()
      .x((d) => xScale(new Date(d.date)))
      .y0(yScale(0))
      .y1((d) => yScale(d.value))
      .curve(d3.curveLinear);

    const line = d3
      .line()
      .x((d) => xScale(new Date(d.date)))
      .y((d) => yScale(d.value))
      .curve(d3.curveLinear);

    const svg = d3.select(svgRef.current);

    svg.selectAll("*").remove();

    svg
      .attr(
        "viewBox",
        `${showYAxis ? -20 : 0} -40 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom + 20
        }`
      )
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    chartContext.map((d) => {
      const sortedData = d.data.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      return svg
        .append("path")
        .datum(sortedData)
        .attr("class", d.chartType)
        .attr("stroke", d.color)
        .attr("stroke-width", "2px")
        .attr("fill", d.chartType === "line" ? "none" : d.color)
        .attr("d", d.chartType === "line" ? line : area);
    });

    svg
      .append("g")
      .attr("transform", `translate(0,${height + 5})`)
      .style("font-family", "SuisseIntl-bold")
      .style("font-size", "12px")
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat(d3.timeFormat("%-m/%-d"))
          .tickSize(-height)
      )
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll("text").style("fill", "#727272"))
      .call((g) => g.selectAll(".tick line").attr("stroke", "#e5e5e5"));

    showYAxis && svg.selectAll("line").remove();
    showYAxis &&
      svg
        .append("g")
        .attr("transform", `translate(${margin.left}, 0)`)
        .style("font-family", "SuisseIntl-bold")
        .style("font-size", "12px")
        .call(yAxis)
        .call((g) => g.select(".domain").remove())
        .call((g) => g.selectAll("line").remove())
        .selectAll("text")
        .style("fill", "#727272");
  }, [combinedData]);

  return (
    <SwayCard>
      <>
        <ChartLegend
          legnedContent={legendData}
          legendAlign={legendAlign}
          legendTitle={legendTitle}
        />
        <svg ref={svgRef} />
      </>
    </SwayCard>
  );
};

export default SwayChart;
