import { makeStyles } from "@material-ui/core";
import {
  SPACING,
  SPACING_0_5,
  SPACING_0_75,
} from "modules/merchantV2/constants";
import useColors from "modules/merchantV2/hooks/useColors";
import PricingTierIcon from "modules/merchantV2/icons/PricingTier";
import React from "react";
import {
  IContentPostPurchaseInfo,
  POST_PURCHASE_STATUS,
} from "types/post.interface";
import SwayButton from "../../SwayButton";
import SwayText from "../../SwayText";
import SwayPostCardLoadingBlock from "./SwayPostCardLoadingBlock";

interface IProps {
  purchaseInfo?: IContentPostPurchaseInfo;
  loading: boolean;
  isSamplePost?: boolean;
  buttonClicked: () => void;
}

export enum ViewKey {}

const useSwayPostCardPurchaseStyles = makeStyles((theme) => {
  const colors = useColors();
  return {
    buyButton: {
      "&:hover": {
        color: colors.backgroundInverted,
      },
    },
  };
});

const SwayPostCardPurchase: React.FC<IProps> = (props) => {
  const { purchaseInfo, loading, buttonClicked, isSamplePost } = props;
  const classes = useSwayPostCardPurchaseStyles();
  const colors = useColors();

  const renderPricingToken = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {loading ||
        !purchaseInfo.status ||
        purchaseInfo.status !== POST_PURCHASE_STATUS.AVAILABLE ? (
          <>
            <SwayText style={{ color: colors.inactiveButtonBg }}>$</SwayText>

            <SwayPostCardLoadingBlock width={SPACING_0_75} />
            <SwayPostCardLoadingBlock width={SPACING_0_75} />
          </>
        ) : (
          <>
            <SwayText weight="bold" style={{ marginRight: SPACING_0_5 }}>
              {purchaseInfo.priceString}
            </SwayText>
            <PricingTierIcon pricingTier={purchaseInfo.pricingTier.name} />
          </>
        )}
      </div>
    );
  };

  const buttonDisabled =
    loading ||
    (!isSamplePost &&
      (!purchaseInfo.status ||
        purchaseInfo.status !== POST_PURCHASE_STATUS.AVAILABLE));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: SPACING_0_5,
        borderTopWidth: 1,
        borderTopColor: colors.brandCardBorder,
        borderTopStyle: "solid",
      }}
    >
      {/* temporary addition */}
      {!isSamplePost && (
        <SwayButton
          disabled={false}
          classType={null}
          className={classes.buyButton}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: SPACING,
            paddingRight: SPACING,
            marginRight: SPACING_0_5,
            borderWidth: 2,
            height: 35,
            fontSize: 14,
            color: colors.backgroundInverted,
            backgroundColor: colors.primary,
            borderColor: colors.backgroundInverted,
          }}
          onClick={() => {
            buttonClicked();
          }}
        >
          {"View"}
        </SwayButton>
      )}
      <SwayButton
        disabled={buttonDisabled}
        classType={null}
        className={classes.buyButton}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: SPACING,
          paddingRight: SPACING,
          borderWidth: 2,
          height: 35,
          fontSize: 14,
          color: buttonDisabled ? colors.background : colors.backgroundInverted,
          backgroundColor: buttonDisabled
            ? colors.inactiveButtonBg
            : colors.primary,
          borderColor: buttonDisabled
            ? colors.inactiveButtonBg
            : colors.backgroundInverted,
        }}
        onClick={() => {
          buttonClicked();
        }}
      >
        {isSamplePost ? "View" : "Buy"}
      </SwayButton>
      <div style={{ flexGrow: 1 }}></div>
      {renderPricingToken()}
    </div>
  );
};

export default SwayPostCardPurchase;
