import React from "react";
import { Button, DatePicker, Modal, Radio, Table } from "antd";
import httpClient from "services/http-client";
import moment, { Moment } from "moment";
import useStyles from "./styles";
import { AdminContext } from "modules/admin/admin-base";
import { Tag } from "antd";
import tagsService from "services/tags.service";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
import { formatCurrency } from "helper/locale.helper";
import urgentTableService from "services/urgent-table.service";
import showStandardNotficationError from "helper/v2-helpers/showStandardNotficationError";
import Config from "config";
import { ICashout } from "types/cashout.interface";
const { CheckableTag } = Tag;
interface IProps {}
const options = [
  { label: "Pending Cashouts", value: false },
  { label: "Completed Cashouts", value: true },
];
const Cashout: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rangePicker, setRangePicker] = React.useState<[Moment, Moment]>([
    moment.utc().subtract(30, "days").startOf("day"),
    moment.utc().endOf("day"),
  ]);
  const [isCashoutProcessed, setIsCashoutProcessed] = React.useState(false);
  const adminContext = React.useContext(AdminContext);
  const handleRangePicker = (dates) => {
    setRangePicker(dates);
  };
  const [currentSelectedCashout, setCurrentSelectedCashout] =
    React.useState<ICashout | null>(null);
  const handleCashout = async () => {
    setLoading(true);

    const [error] = await urgentTableService.patchCashout(
      currentSelectedCashout.id,
      {
        isCashoutProcessed: true,
        cashoutProcessedAt: moment.utc().format(),
      }
    );
    if (error) {
      showStandardNotficationError(error);
    } else {
      setData(data.filter((item) => item.id !== currentSelectedCashout.id));
      setCurrentSelectedCashout(null);
    }

    setLoading(false);
  };
  const handleCashoutChange = (e) => {
    setIsCashoutProcessed(e.target.value);
  };
  const columns = [
    {
      title: "Date Requested",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "PayPal",
      dataIndex: "paypalEmail",
      key: "paypalEmail",
    },
    {
      title: "Venmo Handle",
      dataIndex: "venmoHandle",
      key: "venmoHandle",
    },
    {
      title: "Venmo Phone No.",
      dataIndex: "venmoPhoneNo",
      key: "venmoPhoneNo",
    },
    {
      title: "Amount",
      dataIndex: "amountInCents",
      key: "amountInCents",
      render: (value) => formatCurrency(formatCentsToDollars(value)),
    },
    {
      title: "User ",
      dataIndex: "user",
      key: "user",
      render: (user) => (
        <Button
          onClick={() => window.open(`/admin/users/${user.id}`)}
          type="link"
        >
          {user?.id}
        </Button>
      ),
    },
    isCashoutProcessed === false
      ? {
          title: "Actions",
          dataIndex: "id",
          key: "id",
          render: (id, data) => (
            <Button
              type="primary"
              onClick={() => setCurrentSelectedCashout(data)}
            >
              PROCESS CASHOUT
            </Button>
          ),
        }
      : {
          title: "Completed Date",
          dataIndex: "cashoutProcessedAt",
          key: "cashoutProcessedAt",
          render: (value, data) =>
            moment
              .utc(value || data.updatedAt)
              .format(Config.DEFAULT_DATE_FORMAT),
        },
  ];
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      // const skip = reset ? 0 : data.length;
      const [error, cashouts] = await urgentTableService.getUrgentCashouts(
        rangePicker,
        isCashoutProcessed
      );
      if (error) {
        showStandardNotficationError(error);
      } else {
        setData(cashouts);
      }
      setLoading(false);
    };
    getData();
  }, [rangePicker, isCashoutProcessed]);
  const classes = useStyles();
  const { RangePicker } = DatePicker;
  return (
    <div>
      {currentSelectedCashout ? (
        <Modal
          title="Cashout Processed?"
          okText="Yes"
          confirmLoading={loading}
          cancelText="No"
          visible={currentSelectedCashout !== null}
          onOk={handleCashout}
          onCancel={() => setCurrentSelectedCashout(null)}
        >
          <p>
            Are you sure that you have processed the cashout for the user{" "}
            <b>{currentSelectedCashout.user.username}</b> via{" "}
            {currentSelectedCashout.paypalEmail
              ? `PayPal email at ${currentSelectedCashout.paypalEmail}`
              : `Venmo at ${currentSelectedCashout.venmoHandle}`}{" "}
            for the amount of{" "}
            <b>
              {formatCurrency(
                formatCentsToDollars(currentSelectedCashout.amountInCents)
              )}
            </b>
            ?
          </p>
        </Modal>
      ) : null}
      <RangePicker
        value={rangePicker}
        onChange={handleRangePicker}
        style={{ margin: "15px 0px" }}
      />
      <div>
        <Radio.Group
          style={{ margin: "8px 0px 12px 0px" }}
          options={options}
          onChange={handleCashoutChange}
          value={isCashoutProcessed}
          optionType="button"
          buttonStyle="solid"
        />
      </div>

      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};
export default Cashout;
