import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import SwayText from "modules/merchantV2/components/SwayText";
import SwayStatistic from "modules/merchantV2/components/SwayStatistic";
import {
  StatsDollar,
  StatsSales,
  StatsOfferClaims,
  StatsOfferExpired,
  StatsTikTok,
  StatsReceipt,
} from "../../icons";
import SwayChart from "./SwayChart";
import SwaySelect, { Option } from "modules/merchantV2/components/SwaySelect";
import SwayButton from "modules/merchantV2/components/SwayButton";
import useColors from "modules/merchantV2/hooks/useColors";
interface Props {}

//temporary dummy data
const data1 = [
  { date: "2022-01-01", value: 105 },
  { date: "2022-02-01", value: 30 },
  { date: "2022-03-01", value: 75 },
  { date: "2022-04-01", value: 130 },
  { date: "2022-05-01", value: 130 },
  { date: "2022-06-01", value: 20 },
  { date: "2022-07-01", value: 90 },
  { date: "2022-08-01", value: 80 },
  { date: "2022-09-01", value: 103 },
  { date: "2022-10-01", value: 20 },
  { date: "2022-11-01", value: 25 },
  { date: "2022-12-01", value: 140 },
];

const data2 = [
  { date: "2022-01-01", value: 90 },
  { date: "2022-02-01", value: 160 },
  { date: "2022-03-01", value: 100 },
  { date: "2022-04-01", value: 150 },
  { date: "2022-05-01", value: 170 },
  { date: "2022-06-01", value: 180 },
  { date: "2022-07-01", value: 160 },
  { date: "2022-08-01", value: 200 },
  { date: "2022-09-01", value: 110 },
  { date: "2022-10-01", value: 210 },
  { date: "2022-11-01", value: 220 },
  { date: "2022-12-01", value: 300 },
];

const Impressions = [
  { date: "2022-01-01", value: 30 },
  { date: "2022-02-01", value: 200 },
  { date: "2022-03-01", value: 120 },
  { date: "2022-04-01", value: 210 },
  { date: "2022-05-01", value: 80 },
  { date: "2022-06-01", value: 140 },
  { date: "2022-07-01", value: 100 },
  { date: "2022-08-01", value: 100 },
  { date: "2022-09-01", value: 250 },
  { date: "2022-10-01", value: 270 },
  { date: "2022-11-01", value: 270 },
  { date: "2022-12-01", value: 290 },
];

const data4 = [
  { date: "2022-01-01", value: 50 },
  { date: "2022-02-01", value: 20 },
  { date: "2022-03-01", value: 50 },
  { date: "2022-04-01", value: 30 },
  { date: "2022-05-01", value: 80 },
  { date: "2022-06-01", value: 40 },
  { date: "2022-07-01", value: 30 },
  { date: "2022-08-01", value: 45 },
  { date: "2022-09-01", value: 20 },
  { date: "2022-10-01", value: 90 },
  { date: "2022-11-01", value: 60 },
  { date: "2022-12-01", value: 110 },
];

const data5 = [
  { date: "2022-01-01", value: 30 },
  { date: "2022-02-01", value: 20 },
  { date: "2022-03-01", value: 50 },
  { date: "2022-04-01", value: 30 },
  { date: "2022-05-01", value: 80 },
  { date: "2022-06-01", value: 40 },
  { date: "2022-07-01", value: 30 },
  { date: "2022-08-01", value: 45 },
  { date: "2022-09-01", value: 20 },
  { date: "2022-10-01", value: 90 },
  { date: "2022-11-01", value: 60 },
  { date: "2022-12-01", value: 110 },
];

const InsightChartContainer: React.FC<Props> = () => {
  const colors = useColors();

  return (
    <>
      <Row align="middle">
        <Col span={4}>
          <SwaySelect defaultValue={"All time"}>
            <Option>All time</Option>
          </SwaySelect>
        </Col>
        <div
          style={{
            flexGrow: 1,
          }}
        />
        <Col span={2}>
          <SwayButton
            classType="grey"
            disabled={true}
            style={{
              fontSize: 14,
              width: "100%",
            }}
          >
            Export
          </SwayButton>
        </Col>
      </Row>
      <SwayText type="title" size="medium" style={{ marginTop: 25 }}>
        Offers
      </SwayText>
      <Row gutter={[16, 16]}>
        <Col xl={4} lg={6} md={8}>
          <SwayStatistic
            icon={<StatsDollar />}
            titleString="Your Cost"
            valueString={"$33.06"}
            direction="horizontal"
          />
        </Col>
        <Col xl={4} lg={6} md={8}>
          <SwayStatistic
            icon={<StatsSales />}
            titleString="Sales"
            valueString={"$258.28"}
            direction="horizontal"
          />
        </Col>
        <Col xl={4} lg={6} md={8}>
          <SwayStatistic
            icon={<StatsOfferClaims />}
            titleString="Offers Claimed"
            valueString={"737"}
            direction="horizontal"
          />
        </Col>
        <Col xl={4} lg={6} md={8}>
          <SwayStatistic
            icon={<StatsOfferExpired />}
            titleString="Offers Expired"
            valueString={"685"}
            direction="horizontal"
          />
        </Col>
        <Col xl={4} lg={6} md={8}>
          <SwayStatistic
            icon={<StatsReceipt />}
            titleString="Total Purchases"
            valueString={"28"}
            direction="horizontal"
          />
        </Col>
        <Col xl={4} lg={6} md={8}>
          <SwayStatistic
            icon={<StatsTikTok />}
            titleString="Impressions"
            valueString={"1.4M"}
            direction="horizontal"
          />
        </Col>

        <Col span={24}>
          <SwayChart
            chartContext={[
              {
                data: data1,
                color: "#E3E3E3",
                chartType: "area",
                legendName: "Cost",
              },
              {
                data: data4,
                color: "#F1F1F1",
                chartType: "area",
                legendName: "Offers Claimed",
              },

              {
                data: data2,
                color: "#727272",
                chartType: "line",
                legendName: "Sales",
              },
              {
                data: Impressions,
                color: "#8CE41D",
                chartType: "line",
                legendName: "Impressions",
              },
            ]}
            showYAxis={false}
            legendAlign="center"
          />
        </Col>
        <Col xl={10} md={24}>
          <SwayChart
            chartContext={[
              {
                data: data1,
                color: "#F1F1F1",
                chartType: "area",
                legendName: "Redeemed",
              },
              {
                data: data2,
                color: "#8CE41D",
                chartType: "line",
                legendName: "Claimed",
              },
              {
                data: data4,
                color: "#727272",
                chartType: "line",
                legendName: "Expired",
              },
            ]}
            showYAxis={true}
            legendAlign="end"
            legendTitle="Offer Conversion"
          />
        </Col>
      </Row>
    </>
  );
};

export default InsightChartContainer;
