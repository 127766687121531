import React, { useRef, useState } from 'react';
import { Avatar, Button, Comment, Form, Input, List } from 'antd';
import moment from 'moment';
import useStyles from './style';
import { EditOutlined } from "@ant-design/icons";

const { TextArea } = Input;
let classes: any = null;

interface CommentItem {
  id: number;
  author: string;
  datetime: string;
  content: string,
  willBeEdit: boolean,
}

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (willBeEdit?: boolean, commentId?: number) => void;
  onCancel?: (commentId: number) => void;
  commentId?: number;
  submitting: boolean;
  value: string;
  willBeEdit: boolean;
}

const Editor = ({ onChange, onSubmit, onCancel, commentId, submitting, value, willBeEdit }: EditorProps) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={() => (willBeEdit) ? onSubmit(willBeEdit, commentId) : onSubmit()} type="primary">
        {(willBeEdit) ? 'Edit Comment' : 'Add Comment'}
      </Button>
      {
        (willBeEdit) &&
        <Button htmlType="submit" loading={submitting} onClick={() => onCancel(commentId)} type="primary" danger style={{ marginLeft: 10 }}>
          Cancel
        </Button>
      }
    </Form.Item>
  </>
);

/* 
 elementId could be userId or BrandId
*/
const App = ({ commentsDB, elementId, saveService, editService }) => {
  classes = useStyles();
  const { user } = JSON.parse(localStorage.getItem('swaypay_auth'));

  let initialCommentState = [];
  if (commentsDB && commentsDB.length > 0) {
    initialCommentState = commentsDB.map((e: any) => {
      return {
        id: e.noteId,
        author: e.author,
        content: e.content,
        datetime: moment(e.datetime).fromNow(),
        willBeEdit: false
      };
    });
  }
  const [comments, setComments] = useState<CommentItem[]>(initialCommentState);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [valueEdit, setValueEdit] = useState('');

  // Functions
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleChangeEdit = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValueEdit(e.target.value);
  };

  const handleSubmit = async (willBeEdit: boolean, commentId: number) => {
    if (!willBeEdit) {
      if (!value) return;

      setSubmitting(true);

      const bodyPayload = {
        note: value
      };
      const { data } = await saveService(bodyPayload, elementId);
      setSubmitting(false);
      setValue("");
      setComments([
        ...comments,
        {
          id: data.noteId,
          author: data.author,
          content: data.content,
          datetime: moment(data.datetime).fromNow(),
          willBeEdit: false
        },
      ]);
    } else {
      if (!valueEdit) return;

      setSubmitting(true);

      const bodyPayload = {
        note: valueEdit
      };
      const { noteInfo } = await editService(bodyPayload, commentId);
      const newCommentState = comments.map((e: any) => {
        if (e.id === commentId) {
          e.content = noteInfo.content;
          e.willBeEdit = false;
        }

        return { ...e };
      });

      setSubmitting(false);
      setComments(newCommentState);
    }
  };

  const handleSetEditComment = (props: CommentItem) => {
    const newCommentState = comments.map((e: any) => {
      if (e.willBeEdit) {
        e.willBeEdit = false;
      }

      if (e.id === props.id) {
        setValueEdit(e.content);
        e.willBeEdit = true;
      }

      return { ...e };
    });

    setComments(newCommentState);
  };

  const handleCancelEdit = (commentId: number) => {
    const newCommentState = comments.map((e: any) => {
      if (e.id === commentId) {
        e.willBeEdit = false;
      }

      return { ...e };
    });

    setComments(newCommentState);
  };

  return (
    <>
      {comments.length > 0 &&
        <>
          <div className={classes.header}>
            {`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
          </div>
          {comments.map(comment => (
            <div className={classes.divisor}>
              {comment.willBeEdit
                ?
                <Comment style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 20, }}
                  content={
                    <Editor
                      onChange={handleChangeEdit}
                      onSubmit={handleSubmit}
                      onCancel={handleCancelEdit}
                      submitting={submitting}
                      value={valueEdit}
                      willBeEdit={true}
                      commentId={comment.id}
                    />
                  }
                />
                :
                <>
                  <Button
                    onClick={() => handleSetEditComment(comment)}
                    type="primary"
                    shape="circle"
                    className={classes.editBtn}
                    icon={<EditOutlined />}
                    size="small"
                  />
                  <Comment className={classes.comment} {...comment} />
                </>
              }
            </div>
          ))}
        </>
      }
      <Comment style={{ paddingRight: 20, paddingLeft: 20 }}
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={value}
            willBeEdit={false}
          />
        }
      />
    </>
  );
};

export default App;