import isSuccessResponse from "helper/v2-helpers/isSuccessResponse";
import httpClient from "./http-client";

class OfferServiceV2 {
  getOffer = async (offerId: number) => {
    const res = await httpClient(`/admin-users/me/offers/${offerId}`, {
      apiVersion: "v2",
      auth: true,
    });
    return res.data;
  };
}

export default new OfferServiceV2();
