import httpClient from "./http-client";

class TagsService {
  postAddTag = (postId: number, tagId: number) => {
    return httpClient(`/admin/posts/${postId}/post-tags`, {
      auth: true,
      apiVersion: "v2",
      method: "PUT",
      body: {
        postTagId: tagId,
      },
    });
  };
  postDeleteTag = (postId: number, tagId: number) => {
    return httpClient(`/admin/posts/${postId}/post-tags/${tagId}`, {
      auth: true,
      apiVersion: "v2",
      method: "DELETE",
    });
  };
  userAddTag = (userId: number, tagId: number) => {
    return httpClient(`/admin/users/${userId}/user-tags`, {
      auth: true,
      apiVersion: "v2",
      method: "PUT",
      body: {
        userTagId: tagId,
      },
    });
  };
  userDeleteTag = (userId: number, tagId: number) => {
    return httpClient(`/admin/users/${userId}/user-tags/${tagId}`, {
      auth: true,
      apiVersion: "v2",
      method: "DELETE",
    });
  };
  /**
   * retailerAddTag
   * Add a retailer tag to a retailer
   * @param retailerId
   * @param tagId
   * @returns
   */
  retailerAddTag = (retailerId: number, tagId: number) => {
    return httpClient(`/admin/retailers/${retailerId}/retailer-tags`, {
      auth: true,
      apiVersion: "v2",
      method: "PUT",
      body: {
        retailerTagId: tagId,
      },
    });
  };
  /**
   * retailerDeleteTag
   * Delete a retailer tag from a retailer
   * @param retailerId
   * @param tagId
   * @returns
   */
  retailerDeleteTag = (retailerId: number, tagId: number) => {
    return httpClient(`/admin/retailers/${retailerId}/retailer-tags/${tagId}`, {
      auth: true,
      apiVersion: "v2",
      method: "DELETE",
    });
  };
  addTagUser = async (data: Object) => {
    try {
      const res = await httpClient(`/admin/tags/user-tags`, {
        auth: true,
        method: "POST",
        body: data,
        apiVersion: "v2",
      });
      return [undefined, res.data.userTag];
    } catch (e) {
      return [e, undefined];
    }
  };
  editTagUser = async (id: string, data: Object) => {
    try {
      const res = await httpClient(`/admin/tags/user-tags/${id}`, {
        auth: true,
        method: "PATCH",
        body: { id, ...data },
        apiVersion: "v2",
      });
      return [undefined, res.data.userTag];
    } catch (e) {
      return [e, undefined];
    }
  };
  addTagPost = async (data: Object) => {
    try {
      const res = await httpClient(`/admin/tags/post-tags`, {
        auth: true,
        method: "POST",
        body: data,
        apiVersion: "v2",
      });
      return [undefined, res.data.postTag];
    } catch (e) {
      return [e, undefined];
    }
  };
  editTagPost = async (id: string, data: Object) => {
    try {
      const res = await httpClient(`/admin/tags/post-tags/${id}`, {
        auth: true,
        method: "PATCH",
        body: { id, ...data },
        apiVersion: "v2",
      });
      return [undefined, res.data.postTag];
    } catch (e) {
      return [e, undefined];
    }
  };
  /**
   * addTagRetailer
   * Add new retailer tag
   * @param data
   * @returns
   */
  addTagRetailer = async (data: Object) => {
    try {
      const res = await httpClient(`/admin/tags/retailer-tags`, {
        auth: true,
        method: "POST",
        body: data,
        apiVersion: "v2",
      });
      return [undefined, res.data.retailerTag];
    } catch (e) {
      return [e, undefined];
    }
  };

  /**
   * editTagRetailer
   * Edit existing retailer tag
   * @param id
   * @param data
   * @returns
   */
  editTagRetailer = async (id: string, data: Object) => {
    try {
      const res = await httpClient(`/admin/tags/retailer-tags/${id}`, {
        auth: true,
        method: "PATCH",
        body: { id, ...data },
        apiVersion: "v2",
      });
      return [undefined, res.data.retailerTag];
    } catch (e) {
      return [e, undefined];
    }
  };
}

export default new TagsService();
