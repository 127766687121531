import { ColumnsType } from "antd/lib/table";
import { DefaultRecordType } from "rc-table/lib/interface";
import moment from "moment";
import Config from "config";
import React from "react";
import { CaretDownOutlined } from "@ant-design/icons";
export default (sortInfo): ColumnsType<DefaultRecordType> => {
  return [
    {
      title: (
        <span>
          Date Created <CaretDownOutlined style={{ color: "#8CE41D" }} />
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "Offer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Percentage Cashback",
      dataIndex: "percentageCashback",
      key: "percentageCashback",
    },
    {
      title: "Brand",
      dataIndex: ["brandRetailerAssociation", "brand", "name"],
      key: "brandRetailerAssociation.brand.name",
    },
    {
      title: "Retailer",
      dataIndex: ["brandRetailerAssociation", "retailer", "name"],
      key: "retailerRetailerAssociation.retailer.name",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive) => {
        return isActive ? "Yes" : "No";
      },
    },
  ];
};
