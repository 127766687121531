import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StarBorder from "@material-ui/icons/StarBorder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import useStyles from "../admin/admin-base/style";
export interface Routes {
  route: string | boolean;
  key: string;
  text: string;
  icon: JSX.Element;
  active: boolean;
  open?: boolean;
  children?: {
    route: string;
    key: string;
    text: string;
    icon: JSX.Element;
    active: boolean;
  }[];
}

interface Props {
  open: boolean;
  routes: Routes[];
}
const Sidebar: React.FC<Props> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const setInitRoutes = () => {
    return props.routes.map((route) => {
      if (route.route === location.pathname) {
        route.active = true;
      } else {
        route.active = false;
      }
      if (route.children) {
        route.children = route.children.map((child) => {
          if (child.route === location.pathname) {
            child.active = true;
            route.open = true;
          } else {
            child.active = false;
          }
          return child;
        });
      }
      return route;
    });
  };
  const [routes, setRoutes] = React.useState([]);

  React.useEffect(() => {
    setRoutes(setInitRoutes());
  }, [location]);

  const handleExpander = (key: string) => () => {
    const updateRoutesState = routes.map((route) => {
      if (route.children && route.key === key) {
        route.open = !route.open;
      }
      return route;
    });
    setRoutes(updateRoutesState);
  };
  const changeRoute = (route: string) => () => {
    history.push(route);
    setRoutes(setInitRoutes());
  };
  return (
    <>
      {routes.map((route, i) => (
        <React.Fragment key={route.key}>
          {route.route ? (
            <ListItem
              key={route.key}
              selected={route.active}
              button
              onClick={changeRoute(route.route)}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={route.text} />
              {route.children && props.open && (
                <ListItemSecondaryAction>
                  <IconButton onClick={handleExpander(route.key)} edge='end'>
                    {route.open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ) : (
            <ListItem
              key={route.key}
              onClick={handleExpander(route.key)}
              button
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={route.text} />
              {route.children && (route.open ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
          )}
          {route.children && (
            <Collapse in={route.open} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {route.children.map((child) => (
                  <ListItem
                    className={classes.nested}
                    selected={child.active}
                    onClick={changeRoute(child.route)}
                    key={child.key}
                    button
                  >
                    <ListItemIcon>{child.icon}</ListItemIcon>
                    <ListItemText primary={child.text} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default Sidebar;
