export const SPACING = 16;
export const SPACING_0_25 = SPACING / 4;
export const SPACING_0_5 = SPACING / 2;
export const SPACING_0_75 = (SPACING * 3) / 4;
export const SPACING_1_5 = SPACING * 1.5;
export const SPACING_2 = SPACING * 2;
export const SPACING_3 = SPACING * 3;
export const SPACING_4 = SPACING * 4;

const colors = {
  green: "#8CE41D",
  lightGreen: "#B4F15F",
  lightestGreen: "#DDF7BB",
  darkGreen: "#6CC100",
  purple: "#8C0CF1",
  lightPurple: "#DDB6FB",
  red: "#F04533",
  black: "#000",
  white: "#fff",
  gray: "#727272",
  lightGray: "rgba(209, 209, 209, 1)",
  yellow: "#FFCF52",
};

export const colorsMapping = {
  light: {
    // Variables
    primary: colors.green,
    primaryLight: colors.lightGreen,
    primaryLightest: colors.lightestGreen,
    primaryDark: colors.darkGreen,
    secondary: colors.purple,
    secondaryLight: colors.lightPurple,
    error: colors.red,
    background: colors.white,
    backgroundInverted: colors.black,
    text: colors.black,
    textGray: colors.gray,
    textColored: colors.purple,
    cardBackground: colors.white,
    cardBackgroundLight: "#F7F7F7",
    cardBackgroundDark: "#F2F2F2",
    cardBorder: "#E6E6E6",
    cardBgFilter: "rgba(0,0,0,0.55)",
    backgroundProgressBar: "#F2F2F2",
    buttonText: colors.black,
    buttonBackground: colors.green,
    navBackIcon: colors.black,
    hr: "#F2F2F2",
    cardHr: "#F2F2F2",
    inactiveDotColor: "rgba(230, 230, 230, 1)",
    lightButtonBackground: "#F1F1F1",
    tikTokForeground: colors.white,
    gmailBlue: "#4285F4",
    inactiveButtonBg: "#E1E1E1",

    // Refactor and remove
    grey: "#F9F9F9",
    default: "rgba(249, 249, 249, 1)",
    greyBorder: "rgba(0, 0, 0, 0.12)",
    lightGreyBorder: "rgba(0, 0, 0, 0.06)",
    dropShadowDark: "rgba(0, 0, 0, 0.3)",

    brandCardBorder: "rgba(120, 120, 120, 0.2)",
    filterToken: "#F7F7F7",

    // TODO make these default to above's `const colors` (dark mode too) w/o needing to reference them differently
    // Constants
    green: colors.green,
    lightGreen: colors.lightGreen,
    purple: colors.purple,
    lightPurple: colors.lightPurple,
    red: colors.red,
    black: colors.black,
    white: colors.white,
    gray: colors.gray,
    lightGray: colors.lightGray,
    yellow: colors.yellow,
    clearRed: "rgba(255,0,0,0)",
    clearBlack: "rgba(0,0,0,0)",
    lessDark: "rgba(40, 43, 47, 1)",
  },
  dark: {
    // Variables
    primary: colors.green,
    primaryLight: colors.lightGreen,
    primaryLightest: colors.lightestGreen,
    primaryDark: colors.darkGreen,
    secondary: colors.purple,
    secondaryLight: colors.lightPurple,
    error: colors.red,
    background: "#151617",
    backgroundInverted: colors.white,
    text: colors.white,
    textGray: colors.gray,
    textColored: colors.lightPurple,
    cardBackground: "#282B2F",
    cardBackgroundDark: "#282B2F",
    cardBorder: "#f0f0f0",
    cardBgFilter: "rgba(0,0,0,0.55)",
    backgroundProgressBar: colors.gray,
    buttonText: colors.black,
    buttonBackground: colors.green,
    navBackIcon: colors.white,
    hr: "#282B2F",
    cardHr: colors.black,
    inactiveDotColor: "#afafaf",
    lightButtonBackground: "#282B2F",
    tikTokForeground: colors.black,
    gmailBlue: "#4285F4",
    inactiveButtonBg: "#E1E1E1",

    // Refactor and remove
    grey: "#3E3E3E",
    default: "rgba(249, 249, 249, 1)",
    greyBorder: "rgba(0, 0, 0, 0.12)",
    lightGreyBorder: "rgba(0, 0, 0, 0.06)",
    dropShadowDark: "rgba(0, 0, 0, 0.3)",

    brandCardBorder: "rgba(120, 120, 120, 0.2)",
    filterToken: "#F7F7F7",

    // Constants
    green: colors.green,
    lightGreen: colors.lightGreen,
    purple: colors.purple,
    lightPurple: colors.lightPurple,
    red: colors.red,
    black: colors.black,
    white: colors.white,
    gray: colors.gray,
    lightGray: colors.lightGray,
    yellow: colors.yellow,
    clearRed: "rgba(255,0,0,0)",
    clearBlack: "rgba(0,0,0,0)",
  },
};
