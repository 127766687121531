import { Alert, Form, message } from "antd";
import { SPACING, SPACING_1_5 } from "../constants";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import authService from "services/auth.service";
import SwayButton from "../components/SwayButton";
import SwayCard from "../components/SwayCard";
import SwayLink from "../components/SwayLink";
import SwayText from "../components/SwayText";
import SwayTextInput from "../components/SwayTextInput";
import useSearchParams from "../hooks/useSearchParams";
import OnboardingBase from "./OnboardingBase";
import useOnboardingBaseStyles from "./useOnboardingBaseStyles";
interface IProps {}

const ResetPasswordPage: React.FC<IProps> = (props) => {
  const classes = useOnboardingBaseStyles();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useSearchParams();
  const [passwordResetError, setPasswordResetError] = useState(false);
  const onFinish = async (formData) => {
    setLoading(true);
    const res = await authService.resetPasswordMerchant({
      password: formData.password,
      token: params.get("token"),
    });
    setLoading(false);
    if (res.success) {
      message.info(`Password changed successfully`);
      history.push("/merchant/login");
    } else {
      setPasswordResetError(true);
    }
  };
  return (
    <OnboardingBase>
      <div className={classes.header}></div>
      <SwayCard className={classes.card}>
        <SwayText
          type="title"
          style={{ marginBottom: SPACING_1_5, textAlign: "center" }}
        >
          Reset your password
        </SwayText>
        {passwordResetError && (
          <Alert
            style={{
              lineHeight: 1.2,
              marginBottom: 15,
            }}
            showIcon
            message="Some error occured while changing your password. Please try again
			after sometime."
            type="error"
          />
        )}
        <Form
          name="basic"
          style={{ display: "flex", flexDirection: "column" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password length should be more than 14 characters!",
                min: 14,
              },
            ]}
          >
            <SwayTextInput placeholder="Password" type="password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <SwayTextInput placeholder="Confirm password" type="password" />
          </Form.Item>

          <SwayButton
            htmlType="submit"
            loading={loading}
            disabled={loading}
            style={{ height: 49 }}
          >
            Reset Password
          </SwayButton>
        </Form>
      </SwayCard>
      <div />
    </OnboardingBase>
  );
};
export default ResetPasswordPage;
