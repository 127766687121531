import { Container, Grid, LinearProgress, Link } from "@material-ui/core";
import CustomModal from "component/customModal";
import Loading from "component/loading";
import ProgressBar from "component/progressBar";
import { formatCurrency } from "helper/locale.helper";
import parseSortParams from "helper/parse-sort-params";
import moment, { Moment } from "moment";
import MUIDataTable, {
  //@ts-ignore
  DebounceTableSearch,
  MUIDataTableColumn,
  MUIDataTableOptions,
} from "mui-datatables";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import httpClient from "services/http-client";
import useStyles from "modules/common/commonAntTableStyles";
import { DatePicker, Input, Select, Table } from "antd";
import Config from "config";
import getColumns from "./getColumns";
import tableV2Service from "services/table-v2.service";
import Label from "component/Label/Label";
import { SearchOutlined } from "@material-ui/icons";
import { AdminContext } from "modules/admin/admin-base";

const ALL_STORES = { _id: "ALL", name: "SELECT ALL" };
const retailerFilerSchema = {
  startDate: "" as any,
  endDate: "" as any,
  tags: [],
  search: "",
};

const defaultSort = {
  order: "descend",
  columnKey: "name",
};
interface Props {}
const keys = Object.keys(retailerFilerSchema);
const { RangePicker } = DatePicker;
const formatUrlQuery = tableV2Service.formatUserUrl(keys);
const BrandTable: React.FC<Props> = (props) => {
  const [data, setData] = React.useState([]);
  const history = useHistory();
  const [sortInfo, setSortInfo] = React.useState<any>(defaultSort);
  const [pagination, setPagination] = React.useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [waiting, setWaiting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [initLoading, setInitLoading] = React.useState(true);
  const searchRef = React.useRef(null);
  const [filters, setFilters] = React.useState(retailerFilerSchema);
  const defaultFilterMapping = keys.reduce(
    (prev, next) => ({ ...prev, [next]: filters[next] }),
    {}
  );
  const classes = useStyles();
  const columns = getColumns(sortInfo);

  const getData = async ({ query, init = false }) => {
    setLoading(true);
    const res = await tableV2Service.getBrandListAdmin({
      query,
      init,
    });
    setLoading(false);
    // if (init) {
    //   //To fetch brands other stuff in first request.
    //   setAllBrands(res.data.retailers);
    // }
    if (initLoading) {
      setInitLoading(false);
    }
    setPagination((pagination) => ({
      ...pagination,
      total: res.data.totalCount,
    }));
    setData(res.data.brands);
  };
  React.useEffect(() => {
    if (!initLoading && !waiting) {
      getData({
        query: tableV2Service.formatQueryAPI(
          sortInfo,
          pagination,
          defaultFilterMapping
        ),
      });

      const url = formatUrlQuery(sortInfo, { ...filters });
      window.history.replaceState({ path: url }, "", url);
    }
  }, [filters]);
  React.useEffect(() => {
    tableV2Service.onMount({
      sortInfo,
      setSortInfo,
      pagination,
      setPagination,
      filters,
      setFilters,
      getData,
      defaultFilterMapping,
    });
  }, []);
  const handleTableChange = tableV2Service.handleTableChange({
    sortInfo,
    formatUrlQuery,
    filters,
    setSortInfo,
    setPagination,
    getData,
    defaultFilterMapping,
  });
  const handleSearch = tableV2Service.handleSearch({
    waiting,
    setWaiting,
    setFilters,
  });
  return (
    <Container
      style={{
        marginBottom: 60,
      }}
      className={loading && classes.muiTableLoading}
    >
      {initLoading ? <Loading /> : null}
      {loading ? <ProgressBar /> : null}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Label>Brands</Label>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search on Brands"
                value={filters.search}
                allowClear
                onChange={handleSearch("search")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginBottom: 20 }}></div>
      {React.useMemo(
        () => (
          <Table
            rowKey="id"
            onRow={(record) => ({
              onClick: (e) => {
                const route = `/admin/brands/${record.id}`;
                // check for both windows or mac
                if (e.ctrlKey || e.metaKey) {
                  window.open(route, "_blank");
                } else {
                  history.push(route);
                }
              },
            })}
            onChange={handleTableChange}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
          />
        ),
        [data, pagination]
      )}
    </Container>
  );
};

export default BrandTable;
