import { Row, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import moment from "moment";
import React, { useState } from "react";
import Loading from "../../../../../component/loading";
import Config from "../../../../../config";
import { formatCentsToDollars } from "../../../../../helper/v2-helpers/currency";
import transactionService from "../../../../../services/transaction.service";
import { IRetailer } from "../../../../../types/retailer.interface";
import { IMerchantTransaction } from "../../../../../types/transaction.interface";
import SwayPagination from "../../../components/SwayPagination";
import SwayTable from "../../../components/SwayTable";
import SwayText from "../../../components/SwayText";
import InvoiceListEmptyState from "./InvoiceListEmptyState";
import SwayTag from "modules/merchantV2/components/SwayTag";

interface Props {}

const InvoiceList: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, data) =>
        moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "Invoice Status",
      dataIndex: "type",
      key: "type",
      render: (value) => {
        let color: string;
        let status: string;
        switch (value) {
          case "debit":
            color = "green";
            status = "paid";
            break;
          default:
            color = "white";
            status = "pending";
        }
        return <SwayTag color={color}>{status}</SwayTag>;
      },
    },
    {
      title: "Amount Due",
      dataIndex: "amountInCents",
      key: "amountInCents",
      render: (value) => "$" + formatCentsToDollars(value),
    },
  ];

  const [transactions, setTransactions] = useState<IMerchantTransaction[]>([]);
  const [error, setError] = useState({
    hasError: false,
    errorMessage: "",
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => {
    getMerchantTransactions(
      pagination.pageSize,
      (pagination.current - 1 || 0) * pagination.pageSize,
      pagination
    );
  };

  const getMerchantTransactions = async (
    limit: number = 0,
    skip: number = 0,
    pagination: TablePaginationConfig
  ) => {
    setLoading(true);
    try {
      let { transactions, totalCount, error } =
        await transactionService.getMerchantTransactions(limit, skip);

      setTransactions(transactions);
      setTotalCount(totalCount);
      setPagination({ ...pagination, current: pagination.current++ });
    } catch (e) {
      setError({
        hasError: true,
        errorMessage: "Error loading payment methods",
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getMerchantTransactions(10, 0, pagination);
  }, []);

  if (loading) {
    return <Loading></Loading>;
  }
  return (
    <>
      <Row>
        <SwayText type="title" size="small">
          Invoices
        </SwayText>
        <SwayTable
          rowKey="id"
          columns={columns}
          dataSource={transactions || []}
          onChange={handleTableChange}
          pagination={{ total: totalCount, ...pagination }}
          locale={{
            emptyText: <InvoiceListEmptyState></InvoiceListEmptyState>,
          }}
          style={{ width: "100%", height: "80vh" }}
        />
      </Row>
    </>
  );
};

export default InvoiceList;
