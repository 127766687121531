import { Tag } from "antd";
import { ComponentPropsWithRef } from "react";
import { SPACING } from "../constants";
import useColors from "../hooks/useColors";

type Props = ComponentPropsWithRef<typeof Tag> & {};

export default function SwayTag(props: Props) {
  const { style, ...remainingProps } = props;
  const colors = useColors();

  const commonStyle = {
    borderRadius: SPACING,
    color: colors.black,
    borderColor: colors.gray,
  };

  return (
    <Tag style={{ ...commonStyle, ...style }} {...remainingProps}>
      {props.children}
    </Tag>
  );
}
