import { useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import { Result } from "antd";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getPageNameFromRoute from "../../../../../helper/getPageNameFromRoute";
import getUserIdForSegment from "../../../../../helper/v2-helpers/getUserIdForSegment";
import onboardingFormsService from "../../../../../services/onboarding-forms.service";
import paymentService from "../../../../../services/payment.service";
import { useAnalytics } from "../../../AnalyticsProvider";
import SwayButton from "../../../components/SwayButton";
import { MerchantContext } from "../../../MerchantBaseProvider";
const PaymentStatus = () => {
  const stripe = useStripe();
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(false);
  const history = useHistory();
  const analytics = useAnalytics();
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>(
    []
  );
  const {
    retailer,
    setRetailer,
    loading: isMerchantContextLoading,
  } = useContext(MerchantContext);
  const retrieveSetupIntent = async () => {
    const setupIntentclientSecret = new URLSearchParams(
      window.location.search
    ).get("setup_intent_client_secret");
    const { setupIntent, error } = await stripe.retrieveSetupIntent(
      setupIntentclientSecret
    );
    const { paymentMethods } =
      await paymentService.getPaymentMethodsForRetailer(retailer.id);

    setPaymentMethods(paymentMethods);
    if (error) {
      setError(true);
    }
    setStatus(setupIntent.status);
    if (status === "succeeded") {
      analytics.track("Payment Updated", {
        createdAt: new Date().toISOString(),
        lastFour: paymentMethods[0]?.card?.last4,
        cardType: paymentMethods[0]?.card?.brand,
      });
    }
  };

  const updateOnboardingProgress = async () => {
    const { onboardingProgress, progress, error } =
      await onboardingFormsService.updateOnboardingFormProgress(retailer.id, {
        addCreditCardCompleted: true,
      });
    setRetailer({ ...retailer, progress });
    if (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }
    // Retrieve the SetupIntent
    retrieveSetupIntent();
  }, [stripe]);

  useEffect(() => {
    if (status === "succeeded" || status === "processing") {
      updateOnboardingProgress();
    }
  }, [status]);

  if (status === "succeeded") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Result
          style={{ marginTop: 100 }}
          status="success"
          title="Success! Your payment method has been saved."
          subTitle=""
          extra={[]}
        />
        <SwayButton
          style={{ width: 100 }}
          type="primary"
          onClick={() => history.replace("/merchant/billing")}
        >
          Back
        </SwayButton>
      </div>
    );
  }
  if (status === "processing") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Result
          style={{ marginTop: 100 }}
          status="success"
          title="Processing payment details. We'll update you when processing is complete."
          subTitle=""
          extra={[]}
        />
        <SwayButton
          style={{ width: 100 }}
          type="primary"
          onClick={() => history.replace("/merchant/payments")}
        >
          Back
        </SwayButton>
      </div>
    );
  }
  if (status === "requires_payment_method")
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Result
          style={{ marginTop: 100 }}
          status="error"
          title="Failed to process payment details. Please try another payment method."
          subTitle=""
          extra={[]}
        />
        <SwayButton
          style={{ width: 100 }}
          type="primary"
          onClick={() => history.replace("/merchant/payments")}
        >
          Back
        </SwayButton>
      </div>
    );

  return null;
};

export default PaymentStatus;
