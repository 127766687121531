import { TreeSelect } from "antd";
import { Tag } from "modules/admin/admin-base";
import React, { useState } from "react";

const { SHOW_PARENT } = TreeSelect;

export default ({
  tags,
  handleChange,
  placeholder = "Please select",
}: {
  tags: Tag[];
  handleChange: (value: Tag[]) => void;
  placeholder: string;
}) => {
  const [value, setValue] = useState([]);
  const groupIds = React.useMemo(
    () => tags.map((tag) => tag.id + "_group"),
    [tags]
  );
  const onChange = (newValue: string[], _, item) => {
    const updatedValue = [];
    console.log(newValue);
    for (const val of newValue) {
      if (typeof val === "string" && val.includes("_group")) {
        const group = tags.find(
          (tag) => tag.id === parseInt(val.split("_")[0])
        );
        updatedValue.push(...group.children.map((item) => item.id));
      } else {
        updatedValue.push(val);
      }
    }

    setValue(updatedValue);
    handleChange(updatedValue);
  };
  const treeData = React.useMemo(() => {
    return tags.map((tag) => ({
      title: tag.name,
      value: tag.id + "_group",
      key: tag.id,
      children: tag.children.map((child) => ({
        title: child.name,
        value: child.id,
        key: child.id,
      })),
    }));
  }, [tags]);
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder,
    style: {
      width: "100%",
    },
  };

  return (
    <TreeSelect
      {...tProps}
      allowClear
      showArrow
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  );
};
