import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartDataset,
} from "chart.js";

import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  labels: string[];
  datasets: ChartDataset<"line", string[]>[];
}
const LineChart: React.FC<IProps> = ({ labels, datasets }) => {
  const data: ChartData<"line", string[], string> = {
    labels,
    datasets,
  };
  return (
    <Line
      data={data}
      options={{
        plugins: {
          legend: {
            display: true,
            labels: {
              usePointStyle: true,
            },
          },
        },
      }}
    />
  );
};
export default LineChart;
