export default {
  "modules.login.header.title": "Login",
  "modules.login.form.heading": "Login",
  "modules.login.form.submit": "LOGIN{loadingText}",
  "modules.admin.login.heading": "Sign in",
  "modules.admin.login.email.label": "Email",
  "modules.admin.login.password.label": "Password",
  "modules.admin.login.otp.label": "OTP",
  "modules.admin.login.button_send_otp.text": "Send OTP",
  "modules.admin.login.checkbox.label": "Remember me",
  "modules.admin.login.forget_password.text": "Forgot password?",
  "modules.admin.login.button_sign_in.text": "Sign In",
  "modules.admin.forget_password.heading": "Forgot your password?",
  "modules.admin.forget_password.sub_heading":
    "Enter your email address and we will send you instructions on how to reset your password.",
  "modules.admin.forget_password.email.label": "Email",
  "modules.admin.forget_password.login_link.text": "Log In",
  "modules.admin.forget_password.submit_button.text": "Submit",
  "modules.admin.reset_password.heading": "Reset your password",
  "modules.admin.reset_password.password.label": "Password",
  "modules.admin.reset_password.confirm_password.label": "Confirm Password",
  "modules.admin.reset_password.submit_button.text": "Reset Password",
  "modules.merchant.login.heading": "Merchant Log In",
  "modules.merchant.login.email.label": "Email",
  "modules.merchant.login.password.label": "Password",
  "modules.merchant.login.checkbox.label": "Remember me",
  "modules.merchant.login.sign_up.text": "New to Swaypay? Sign up",
  "modules.merchant.login.forget_password.text": "Forgot password?",
  "modules.merchant.login.button_sign_in.text": "Log In",
  "modules.merchant.signup.heading": "Create your free Swaypay account",
  "modules.merchant.signup.name.label": "Name",
  "modules.merchant.signup.first_name.label": "First Name",
  "modules.merchant.signup.last_name.label": "Last Name",
  "modules.merchant.signup.email.label": "Email",
  "modules.merchant.signup.password.label": "Password",
  "modules.merchant.signup.confirm_password.label": "Confirm Password",
  "modules.merchant.signup.checkbox.label": "Remember me",
  "modules.merchant.signup.login_link.text": "Existing user? Login",
  "modules.merchant.signup.sign_in_button.text": "Get started",
  "modules.merchant.signup.merchant_agreement_link.text": "Merchant Agreement",
  "modules.merchant.signup.privacy_policy_link.text": "Privacy Policy",
  "modules.merchant.forget_password.heading": "Forgot your password?",
  "modules.merchant.forget_password.sub_heading":
    "Enter your email address and we will send you instructions on how to reset your password.",
  "modules.merchant.forget_password.email.label": "Email",
  "modules.merchant.forget_password.login_link.text": "Log In",
  "modules.merchant.forget_password.submit_button.text": "Submit",
  "modules.merchant.reset_password.heading": "Reset your password",
  "modules.merchant.reset_password.password.label": "Password",
  "modules.merchant.reset_password.confirm_password.label": "Confirm Password",
  "modules.merchant.reset_password.submit_button.text": "Reset Password",
  "modules.merchant.dashboard.email_verification_button.text": "Verify",
  "modules.merchant.dashboard.no_stores_connected.text":
    "You have no stores connected",
  "modules.merchant.dashboard.add_store_button.text": "Connect a new store",
  "modules.merchant.dashboard.request_access_to_store_button.text":
    "Request access to store",
  "modules.merchant.dashboard.email_verification_success.text":
    "An email has been sent on your registered email id with a link to verify",
  "modules.merchant.dashboard.email_verification_warning.text":
    "Your email is not verified",
  "modules.merchant.signup.login.text": "Login Instead?",
  "modules.merchant.platform_connect.shopify_connect_cta_button.text": "Submit",
  "modules.merchant.platform_connect.shopify_domain_text_field.label":
    "Shopify domain",
  "modules.merchant.platform_connect.shopify_domain_text_field.helper_text":
    "eg. put-a-pet-on-it.myshopify.com",
  "modules.voting_store.heading_text": ` Text a store name to 847-915-1271 or vote below to choose which stores
  we partner with next! We'll text you when they launch.`,
  "modules.voting_store.btn": "VOTE",
  "modules.admin.vote_stores.gird.total": "TOTAL",
  "modules.admin.vote_stores.gird.votes": "VOTES",
  "modules.admin.vote_stores.add_btn": "ADD STORE",
  "modules.admin.vote_stores.modal_heading": "ADD VOTING STORE",
  "modules.admin.vote_stores.input.name": "Name",
  "modules.admin.vote_stores.input.image_url": "Image URL",
  "modules.admin.vote_stores.save_btn": "SAVE STORE",
  "modules.admin.vote_stores.no_stores_error":
    "No existing stores added for voting",
  "modules.customer.privacy_text": "Privacy & Terms",
  "modules.customer.signup.name.label": "Name",
  "modules.customer.login.email_input": "Email",
  "modules.customer.login.password_input": "Password",
  "modules.customer.login.magic_login_input": "Login Code",
  "modules.customer.login.login_btn": "LOG IN",
  "modules.customer.login.continue_btn": "CONTINUE",
  "modules.customer.forgot_password.email_input": "Email",
  "modules.customer.forgot_password.reset_password": "Reset your password",
  "modules.customer.forgot_password.reset_password_sub_heading":
    "We will email you instructions to reset your password.",
  "modules.customer.forgot_password.forgot_btn": "Reset Password",
  "modules.customer.forgot_password.login_btn": "Return to log in",

  // ADMIN
  "modules.admin.purchase_table.purchase_break_up": "Purchases Breakdown",
  "modules.admin.purchase_table.total_purchases": "All Purchases:",
  "modules.admin.purchase_table.money_give_so_far": "Cashback:",

  // TIKTOK
  "modules.tiktok-connect.success_message_title":
    "Successfully connected your TikTok account",
  "modules.tiktok-connect.failure_message_tiktok_connected_another_user":
    "This tiktok account is already linked to an existing Swaypay account",
  "modules.tiktok-connect.failure_message_scopes_not_accepted":
    "Please accept all the scopes requested",
  "modules.tiktok-connect.failure_message_title":
    "Something went wrong while connecting your TikTok account",
  "modules.tiktok-connect.close_browser_message":
    "Please close the browser and return to the app",

  //MERCHANT
  "modules.merchant.purchase_table.post_break_up": "Posts Breakdown",
  "modules.merchant.purchase_table.total_posts": "TOTAL POSTS:",

  // Brand landing
  "modules.brand_landing.earnings_estimator.heading":
    "Estimate your TikTok post earnings",
  "modules.brand_landing.earnings_estimator.post_views": "Post Views",
  "modules.brand_landing.earnings_estimator.estimated_earnings":
    "Estimated Earnings*",
  "modules.brand_landing.earnings_estimator.order_price_example":
    "Order Price Example",
  "modules.brand_landing.earnings_estimator.disclaimer":
    "*This is not guaranteed earnings. We also factor in your post quality to calculate your cash back.",
  "modules.brand_landing.test_me_instructions.heading": "Text me instructions",
  "modules.brand_landing.test_me_instructions.success_message":
    "Invitation Sent!",
  "modules.brand_landing.test_me_instructions.error_message":
    "Please try again later",
  "modules.brand_landing.faq.title": "FAQ",
};
