import { SaveOutlined } from "@ant-design/icons";
import {
  Container,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Button as MuiButton,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import useStyles from "modules/admin/styles/commonDetailsPageStyle";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import httpClient from "services/http-client";
import userV2Service from "services/user-v2.service";
import tagsService from "../../../services/tags.service";
import { AdminContext } from "../admin-base";
import _ from "lodash";
//comment
import { CancelOutlined, CheckCircleOutlined } from "@material-ui/icons";
import { Alert, Button, Checkbox, Input, List, message, Radio } from "antd";
import Comment from "../../../component/comment";
import Loading from "component/loading";
import httpClientBinary from "services/http-client-binary";
interface IProps {
  brandId?: string;
}

const checkMetricsEmpty = (metrics) => {
  if (!metrics) return false;
  for (const val of Object.values(metrics)) {
    if (val === undefined || val === null || val === "") return false;
  }

  return true;
};
const BrandDetails: React.FC<IProps> = (props) => {
  const adminContext = React.useContext(AdminContext);

  const params = useParams<any>();
  const brandId = props.brandId ? props.brandId : params.brandId;
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isAllBrandMetricsSet, setIsAllBrandMetricsSet] = React.useState(false);
  const [commentsDB, setCommentsDB] = React.useState(null);
  const history = useHistory();
  const fetchBrand = async () => {
    setLoading(true);
    const res = await httpClient(`/admin/brands/${brandId}`, {
      apiVersion: "v2",
      auth: true,
    });
    setLoading(false);
    setIsAllBrandMetricsSet(checkMetricsEmpty(res.data.brand.socialMetrics));
    setData(res.data);
  };

  const getComments = async () => {
    const comments = await userV2Service.getBrandComments(brandId);
    setCommentsDB(comments);
  };

  React.useEffect(() => {
    fetchBrand();
    getComments();
  }, []);
  const handleChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({
        ...data,
        brand: {
          ...data.brand,
          socialMetrics: {
            ...data.brand.socialMetrics,
            [key]: e.target.value.trim(),
          },
        },
      });
    };

  const handleChangeRetailer =
    (key: string, reatilerId: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setData({
        ...data,
        brand: {
          ...data.brand,
          brandRetailerAssociations: data.brand.brandRetailerAssociations.map(
            (brand) => {
              if (brand.retailer.id === reatilerId) {
                return {
                  ...brand,
                  [key]: e.target.value.trim(),
                };
              }
              return brand;
            }
          ),
        },
      });
    };
  const handleDisplayVideoUpload = async (e) => {
    if (e.target?.files[0]) {
      setLoading(true);
      var formData = new FormData();
      formData.append("displayVideoBinary", e.target.files[0]);
      const res = await httpClientBinary(
        `/admin/brands/${data.brand.id}/social-metrics`,
        {
          auth: true,
          apiVersion: "v2",
          method: "PUT",
          body: formData,
        }
      );
      setData({
        ...data,
        brand: {
          ...data.brand,
          socialMetrics: res.data.socialMetrics,
        },
      });
      setIsAllBrandMetricsSet(checkMetricsEmpty(res.data.socialMetrics));
      setLoading(false);
    }
    e.target.value = "";
  };
  const handleSaveRetailerAssociation = async (
    brandRetailerAssociationId: string,
    landingPageUrl: string
  ) => {
    setLoading(true);
    await httpClient(
      `/admin/brand-retailers-association/${brandRetailerAssociationId}`,
      {
        auth: true,
        apiVersion: "v2",
        method: "PATCH",
        body: {
          landingPageUrl,
        },
      }
    );
    message.success("Landing page URL updated successfully.");
    setLoading(false);
  };
  const handleSavetiktokMetrics = async () => {
    setLoading(true);
    const {
      tiktokHandle,
      tiktokViewCount,
      tiktokFollowerCount,
      tiktokLikeCount,
    } = data.brand.socialMetrics;
    const res = await httpClient(
      `/admin/brands/${data.brand.id}/social-metrics`,
      {
        auth: true,
        apiVersion: "v2",
        method: "PUT",
        body: {
          tiktokHandle,
          tiktokViewCount,
          tiktokFollowerCount,
          tiktokLikeCount,
        },
      }
    );
    setLoading(false);
  };
  const handleBrandActive = async (e) => {
    setLoading(true);
    await httpClient(`/admin/brands/${data.brand.id}`, {
      method: "PATCH",
      apiVersion: "v2",
      auth: true,
      body: {
        isEnabled: e.target.value,
      },
    });
    setData({
      ...data,
      brand: {
        ...data.brand,
        isEnabled: e.target.value,
      },
    });
    setLoading(false);
  };
  const isAllRetailersOnboarded = (() => {
    let onboarded = true;
    if (!data) return;
    for (const item of data.brand.brandRetailerAssociations) {
      onboarded = item.retailer.onboardingProgress?.addCreditCardCompleted;
    }
    return onboarded;
  })();
  const listData = [
    <div>
      {isAllBrandMetricsSet ? (
        <CheckCircleOutlined color="primary" />
      ) : (
        <CancelOutlined color="error" />
      )}
      <span> Social Metrics must set before enabling a brand.</span>
    </div>,
    <div>
      {isAllRetailersOnboarded ? (
        <CheckCircleOutlined color="primary" />
      ) : (
        <CancelOutlined color="error" />
      )}
      <span>
        {" "}
        All retailer must be onboarding complete before enabling the brand.
      </span>
    </div>,
  ];
  return (
    data && (
      <Container
        style={{
          marginBottom: 60,
          marginTop: 20,
        }}
      >
        {loading && <Loading />}
        <Paper style={{ padding: "20px 10px" }}>
          {props.brandId && (
            <Tooltip title="Open in a new tab" placement="top" arrow>
              <Link
                href={`/admin/retailers/${props.brandId}`}
                target="_blank"
                style={{ marginRight: 5 }}
              >
                <OpenInNewIcon />
              </Link>
            </Tooltip>
          )}
          <Typography>
            Brand: <b>{data.brand.name}</b>
          </Typography>
          <Typography>
            Retailer Count: <b>{data.brand.brandRetailerAssociations.length}</b>
          </Typography>
          <Typography>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              Brand Social Metrics:
            </div>
            <Grid
              container
              style={{ alignItems: "center", marginBottom: 15 }}
              spacing={2}
            >
              <Grid item>
                <Input
                  value={data.brand.socialMetrics.tiktokHandle}
                  addonBefore="@"
                  onChange={handleChange("tiktokHandle")}
                  style={{ width: 300 }}
                  addonAfter="Tiktok"
                />
              </Grid>
              <Grid item>
                <Input
                  value={data.brand.socialMetrics.tiktokViewCount}
                  onChange={handleChange("tiktokViewCount")}
                  className={classes.tiktokInputWidth}
                  addonAfter="Views"
                />
              </Grid>
              <Grid item>
                <Input
                  value={data.brand.socialMetrics.tiktokFollowerCount}
                  onChange={handleChange("tiktokFollowerCount")}
                  className={classes.tiktokInputWidth}
                  addonAfter="Followers"
                />
              </Grid>
              <Grid item>
                <Input
                  value={data.brand.socialMetrics.tiktokLikeCount}
                  onChange={handleChange("tiktokLikeCount")}
                  className={classes.tiktokInputWidth}
                  addonAfter="Likes"
                />
              </Grid>

              <Grid item>
                <Button
                  onClick={handleSavetiktokMetrics}
                  type="primary"
                  shape="circle"
                  icon={<SaveOutlined />}
                  size="large"
                />
              </Grid>
            </Grid>
          </Typography>
          <Grid>
            Brand Display Video:{" "}
            {data.brand.socialMetrics.tiktokDisplayVideo ? (
              <Link
                href={data.brand.socialMetrics.tiktokDisplayVideo}
                target="_blank"
              >
                Play Video
              </Link>
            ) : (
              <span style={{ color: "red" }}>
                Missing <CancelOutlined color="error" />
              </span>
            )}
            <MuiButton
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              component="label"
            >
              Upload
              <input
                onChange={handleDisplayVideoUpload}
                hidden
                accept="video/mp4,video/x-m4v,video/*"
                type="file"
              />
            </MuiButton>
          </Grid>
        </Paper>

        <Paper style={{ paddingBottom: 10 }}>
          <Typography className={classes.heading}>Retailers</Typography>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Retailer Name</TableCell>
                <TableCell>Website</TableCell>
                <TableCell>Landing Page URL</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  Onboarding Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(data.brand.brandRetailerAssociations.length) ? (
                data.brand.brandRetailerAssociations.map((item: any, i) => (
                  <TableRow key={item.retailer.id}>
                    <TableCell>
                      <Link href={`/admin/retailers/${item.retailer.id}`}>
                        {item.retailer.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link href={item.retailer.website}>
                        {item.retailer.website}
                      </Link>
                    </TableCell>
                    <TableCell style={{ width: 400 }}>
                      <Input
                        value={item.landingPageUrl}
                        onChange={handleChangeRetailer(
                          "landingPageUrl",
                          item.retailer.id
                        )}
                        addonAfter={
                          <Button
                            onClick={() =>
                              handleSaveRetailerAssociation(
                                item.id,
                                item.landingPageUrl
                              )
                            }
                            type="primary"
                            shape="circle"
                            icon={<SaveOutlined />}
                            size="small"
                          />
                        }
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {item.retailer.onboardingProgress
                        ?.addCreditCardCompleted ? (
                        <CheckCircleOutlined color="primary" />
                      ) : (
                        <CancelOutlined color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    align="center"
                    style={{ fontSize: 16 }}
                  >
                    No Retailers found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
        <Paper style={{ paddingBottom: "20px", marginTop: 20 }}>
          <List
            size="small"
            header={
              <Typography className={classes.heading}>Checklist</Typography>
            }
            // bordered
            dataSource={listData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Alert
              style={{ marginBottom: 20, marginTop: 15 }}
              message="Disabling brand will disable all the reatilers associated with it."
              type="warning"
            />
            <Radio.Group
              disabled={!isAllBrandMetricsSet || !isAllRetailersOnboarded}
              onChange={handleBrandActive}
              value={data.brand.isEnabled}
              buttonStyle="solid"
            >
              <Radio.Button value={true}>Enable</Radio.Button>
              <Radio.Button value={false}>Disable</Radio.Button>
            </Radio.Group>
          </div>
        </Paper>
        <Paper style={{ paddingBottom: 10, marginTop: 20 }}>
          <Typography className={classes.heading}>Comments</Typography>
          <Comment
            commentsDB={commentsDB}
            elementId={brandId}
            saveService={userV2Service.saveBrandComment}
            editService={userV2Service.editBrandComment}
          />
        </Paper>
      </Container>
    )
  );
};
export default BrandDetails;
