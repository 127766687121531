import { ColumnsType } from "antd/lib/table";
import { DefaultRecordType } from "rc-table/lib/interface";
import moment from "moment";
import Config from "config";
import React from "react";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import { Button, Tag } from "antd";
import { formatCurrency } from "helper/locale.helper";
import { Link } from "react-router-dom";
import numberFormatter from "helper/numberFormatter";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
export default (sortInfo): ColumnsType<DefaultRecordType> => {
  return [
    {
      sorter: false,
      title: "User ID",
      dataIndex: "id",
      key: "id",
    },
    {
      sorter: true,
      title: "Apply Date",
      filterSearch: true,
      dataIndex: "createdAt",
      key: "createdAt",
      sortOrder: sortInfo.columnKey === "createdAt" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value, data) =>
        moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    // {
    //   sorter: true,
    //   title: "Approval Date",
    //   filterSearch: true,
    //   dataIndex: "approvalDate",
    //   key: "approvalDate",
    //   sortOrder: sortInfo.columnKey === "approvalDate" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   render: (value, data) =>
    //     value && moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    // },
    {
      sorter: true,
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sortOrder: sortInfo.columnKey === "firstName" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    // {
    //   sorter: true,
    //   sortOrder: sortInfo.columnKey === "tiktokHandle" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   title: "Tiktok",
    //   dataIndex: "tiktokHandle",
    //   key: "tiktokHandle",
    //   render: (handle, data) => (
    //     <>
    //       <Button
    //         onClick={() => window.open(`https://www.tiktok.com/@${handle}`)}
    //         type="link"
    //       >
    //         {handle}
    //       </Button>
    //     </>
    //   ),
    // },
    // {
    //   sorter: false,
    //   sortOrder: sortInfo.columnKey === "tiktokHandle" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   title: "Connected Tiktok",
    //   dataIndex: "tiktokCredentials",
    //   key: "tiktokCredentials",
    //   render: (value) => {
    //     const connectedTiktokAccount = value?.find((tiktokCredential) => {
    //       return tiktokCredential?.connected === true;
    //     });
    //     if (connectedTiktokAccount) {
    //       return (
    //         <>
    //           <Button
    //             onClick={() =>
    //               window.open(
    //                 `https://www.tiktok.com/@${connectedTiktokAccount.tiktokHandle}`
    //               )
    //             }
    //             type="link"
    //           >
    //             {connectedTiktokAccount.tiktokHandle}
    //           </Button>
    //         </>
    //       );
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    // {
    //   sorter: true,
    //   sortOrder: sortInfo.columnKey === "tiktokFollowers" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   title: "Followers count",
    //   dataIndex: "tiktokFollowers",
    //   key: "tiktokFollowers",
    //   render: (tiktokFollowers) => numberFormatter(tiktokFollowers),
    // },
    // {
    //   sorter: true,
    //   sortOrder: sortInfo.columnKey === "tiktokLikes" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   title: "Profile likes count",
    //   dataIndex: "tiktokLikes",
    //   key: "tiktokLikes",
    //   render: (tiktokLikes) => numberFormatter(tiktokLikes),
    // },
    // {
    //   sorter: true,
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   sortOrder: sortInfo.columnKey === "email" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },
    // {
    //   sorter: true,
    //   title: "Phone",
    //   dataIndex: "phoneNo",
    //   key: "phoneNo",
    //   sortOrder: sortInfo.columnKey === "phoneNo" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },
    // {
    //   sorter: true,
    //   title: "Source",
    //   dataIndex: "source",
    //   key: "source",
    //   sortOrder: sortInfo.columnKey === "source" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },
    // {
    //   sorter: true,
    //   title: "Campaign",
    //   dataIndex: "campaign",
    //   key: "campaign",
    //   sortOrder: sortInfo.columnKey === "campaign" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },

    // {
    //   sorter: true,
    //   title: "Current Bal.",
    //   dataIndex: "availableBalanceInCents",
    //   key: "availableBalanceInCents",
    //   sortOrder:
    //     sortInfo.columnKey === "availableBalanceInCents" && sortInfo.order,
    //   sortDirections: ["ascend", "descend", "ascend"],
    //   render: (availableBalanceInCents) =>
    //     "$" + formatCentsToDollars(availableBalanceInCents),
    // },
    // {
    //   title: "Tags",
    //   dataIndex: "userTags",
    //   key: "userTags",
    //   render: (tags) => {
    //     return tags.map((tag) => <Tag color="blue">{tag.userTag.name}</Tag>);
    //   },
    // },
  ];
};
