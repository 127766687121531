import * as React from "react";
import useColors from "../hooks/useColors";
import { LoadableIconProps } from "./icons.interface";

interface IProps extends LoadableIconProps {}
function StatsEyeIcon(props: IProps) {
  const { loading } = props;
  const colors = useColors();

  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_222_4984)">
        <path
          d="M0.68335 7.00004C0.68335 7.00004 3.01668 2.33337 7.10002 2.33337C11.1834 2.33337 13.5167 7.00004 13.5167 7.00004C13.5167 7.00004 11.1834 11.6667 7.10002 11.6667C3.01668 11.6667 0.68335 7.00004 0.68335 7.00004Z"
          stroke={loading ? "clear" : "black"}
          fill={loading ? colors.inactiveButtonBg : "clear"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.10001 8.75C8.0665 8.75 8.85001 7.9665 8.85001 7C8.85001 6.0335 8.0665 5.25 7.10001 5.25C6.13351 5.25 5.35001 6.0335 5.35001 7C5.35001 7.9665 6.13351 8.75 7.10001 8.75Z"
          stroke={loading ? "clear" : "black"}
          fill={colors.white}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_222_4984">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.100006)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StatsEyeIcon;
