import { Card } from "antd";
import React, { ComponentProps } from "react";
import { SPACING } from "../constants";

type Props = ComponentProps<typeof Card> & {
  children: React.ReactNode;
};

export default function SwayCard({
  style,
  children,
  bodyStyle,
  ...rest
}: Props) {
  return (
    <Card
      bodyStyle={{ padding: SPACING, ...bodyStyle }}
      style={{ borderRadius: SPACING, ...style }}
      {...rest}
    >
      {children}
    </Card>
  );
}
