import {
  SPACING_0_25,
  SPACING_0_5,
  SPACING_0_75,
} from "modules/merchantV2/constants";
import {
  StatsEyeIcon,
  StatsHeartIcon,
  StatsMessageIcon,
} from "modules/merchantV2/icons";
import React from "react";
import { IPostStat } from "types/post.interface";
import SwayText from "../../SwayText";
import SwayPostCardLoadingBlock from "./SwayPostCardLoadingBlock";

interface IProps {
  stats?: IPostStat;
  loading: boolean;
}

export enum ViewKey {}

const SwayPostCardStats: React.FC<IProps> = (props) => {
  const { stats, loading } = props;

  //this is a temporary function
  const formatValue = (value) => {
    const suffix = value.slice(-1);
    const num =
      suffix === "k" || suffix === "M"
        ? parseInt(value) * 1000
        : parseInt(value);

    if (num > 1000) {
      return `${(num / 1000).toFixed(0)}${suffix}`;
    } else {
      return value;
    }
  };

  const renderStat = (icon: React.ReactNode, value?: string) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          alignItems: "center",
          marginRight: SPACING_0_5,
        }}
      >
        {icon}
        {loading ? (
          <SwayPostCardLoadingBlock />
        ) : (
          <SwayText
            size="tiny"
            weight="bold"
            style={{
              marginLeft: SPACING_0_25,
            }}
          >
            {formatValue(value)}
          </SwayText>
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: SPACING_0_75,
        paddingBottom: SPACING_0_75,
        paddingLeft: SPACING_0_5,
        alignItems: "center",
      }}
    >
      {renderStat(<StatsEyeIcon loading={loading} />, stats?.viewCountString)}
      {renderStat(<StatsHeartIcon loading={loading} />, stats?.likeCountString)}
      {renderStat(
        <StatsMessageIcon loading={loading} />,
        stats?.commentCountString
      )}
    </div>
  );
};

export default SwayPostCardStats;
