import { Button, notification, Radio } from "antd";
import useStyles from "./styles";
import React from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import CustomModal from "component/customModal";
import httpClient from "services/http-client";
import userV2Service from "services/user-v2.service";
import Text from "antd/lib/typography/Text";
interface IProps {
  user: any;
  updateCallback: (data: {
    applicationStatus: string;
    reason?: string;
  }) => void;
}
const statusToModalTitleMapping = {
  accepted: "Accept user",
  rejected: "Reject user",
  waiting_list: "Add user to waitlist",
};
const HandleUserStatus: React.FC<IProps> = ({ user, updateCallback }) => {
  const classes = useStyles();
  const [reason, setReason] = React.useState("");
  const [radioValue, setRadioValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [applicationStatus, setApplicationStatus] = React.useState("");
  const handleUserApplicationUpdate = (status: string) => {
    if (status === "accepted" && user.userTags.length === 0) {
      return notification.error({
        message: "Cannot accept user",
        description: "A user cannot be accepted with empty user tags",
      });
    }
    setApplicationStatus(status);
  };
  const closeHandler = () => {
    setApplicationStatus("");
    setRadioValue("");
    setReason("");
  };
  const closeModalAndUpdateStatus = async () => {
    const data = {
      reason,
      applicationStatus: applicationStatus,
    };

    const [error] = await userV2Service.patchUser(user.id, data);
    if (error) {
      notification.error({
        message: "Something went wrong",
        description: error,
      });
    } else {
      closeHandler();
      updateCallback(data);
    }
  };
  const handleUnclear = async () => {
    setLoading(true);
    const [error] = await userV2Service.triggerApplicationStatusUnclear(
      user.id
    );
    if (error) {
      notification.error({
        message: "Something went wrong",
        description: error,
      });
    } else {
      updateCallback({ applicationStatus: "unclear" });
      notification.success({
        message: "Mail has been sent to Kaeya",
        description:
          "Kaeya has been informed about this user going forward she will be taking appropriate actions regardng this user thanks.",
      });
    }

    setLoading(false);
  };
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setReason(e.target.value);
  };
  const renderBtnBasedOnStatusType = (status) => {
    switch (status) {
      case "accepted":
        return (
          <Button
            type="primary"
            danger
            onClick={handleUserApplicationUpdate.bind(undefined, "banned")}
          >
            BAN USER
          </Button>
        );
      default:
        return (
          <>
            <Button
              type="primary"
              onClick={handleUserApplicationUpdate.bind(undefined, "accepted")}
            >
              ACCEPT
            </Button>
            <Button
              type="primary"
              onClick={handleUserApplicationUpdate.bind(
                undefined,
                "waiting_list"
              )}
            >
              WAITLIST
            </Button>
            <Button
              type="primary"
              danger
              onClick={handleUserApplicationUpdate.bind(undefined, "rejected")}
            >
              REJECT
            </Button>
            {/* {status === "unclear" ? (
              <Text strong>(user marked unclear)</Text>
            ) : (
              <Button ghost type="primary" danger onClick={handleUnclear}>
                UNCLEAR
              </Button>
            )} */}
          </>
        );
    }
  };
  return (
    <>
      <CustomModal
        state={applicationStatus !== ""}
        title={statusToModalTitleMapping[applicationStatus]}
        closeHandler={closeHandler}
        actions={
          <Button
            type="primary"
            loading={loading}
            onClick={closeModalAndUpdateStatus}
          >
            SUBMIT
          </Button>
        }
      >
        {/* only non accepted user currently have pre defined radio select */}
        {applicationStatus !== "accepted" && (
          <Radio.Group
            style={{ margin: "8px 0px 12px 0px" }}
            onChange={handleRadioChange}
            value={radioValue}
          >
            {applicationStatus === "rejected" && (
              <>
                <Radio value="Inappropriate">Inappropriate</Radio>
                <Radio value="">other</Radio>
              </>
            )}
            {applicationStatus === "waiting_list" && (
              <>
                <Radio value="Invalid TikTok">Invalid TikTok</Radio>
                <Radio value="">other</Radio>
              </>
            )}
            {applicationStatus === "banned" && (
              <>
                <Radio value="bad behaviour">bad behaviour</Radio>
                <Radio value="">other</Radio>
              </>
            )}
          </Radio.Group>
        )}

        <TextField
          placeholder="Reason for that selecting that status"
          label="Reason"
          variant="outlined"
          fullWidth
          name="Reason"
          disabled={radioValue !== ""}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        />
      </CustomModal>

      <div className={classes.btns}>
        {renderBtnBasedOnStatusType(user.applicationStatus)}
      </div>
    </>
  );
};
export default HandleUserStatus;
