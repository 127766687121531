import en from "translations/en";
import { useIntl } from "react-intl";
export type ValidI18nKey = keyof typeof en;

/**
 * References:
 * https://sakarisson.com/posts/i18n-intellisense
 * https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object/58436959#58436959
 */

/**
 * Convenience passthrough method for localizing text through I18n.
 * Offers code completion for valid keys.
 * @param key A LocalizationKey from locale/en.json
 * @returns string for the current locale
 */
export const useSwayLocalize = () => {
  const t = useIntl();
  //@ts-ignore
  t.format = (key: ValidI18nKey) => {
    return t.formatMessage({ id: key });
  };
  return t as unknown as { format: (key: ValidI18nKey) => string };
};
