import React from "react";

interface Props {
  userImg?: string;
}

const UserProfileIcon: React.FC<Props> = ({ userImg }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="white" stroke="#D9D9D9" />
      {userImg ? (
        <image href={userImg} width="100%" height="100%" />
      ) : (
        <g>
          <path
            d="M20.0489 22.9998H11.4511C10.0376 22.9998 9.07105 21.5722 9.59602 20.2598C10.1017 18.9955 11.3262 18.1665 12.6879 18.1665H18.8121C20.1738 18.1665 21.3983 18.9955 21.904 20.2598C22.4289 21.5722 21.4624 22.9998 20.0489 22.9998Z"
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M15.7501 14.9444C17.5296 14.9444 18.9723 13.5018 18.9723 11.7222C18.9723 9.94264 17.5296 8.5 15.7501 8.5C13.9705 8.5 12.5278 9.94264 12.5278 11.7222C12.5278 13.5018 13.9705 14.9444 15.7501 14.9444Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      )}
    </svg>
  );
};

export default UserProfileIcon;
