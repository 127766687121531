import React from "react";
import { SPACING, SPACING_0_25, SPACING_0_5 } from "../constants";
import useColors from "../hooks/useColors";
import SwayCard from "./SwayCard";
import SwayText from "./SwayText";

export interface SwayStatisticProps {
  icon: React.ReactNode;
  titleString: string;
  valueString: string;
  direction?: "vertical" | "horizontal";
  helperString?: string;
}

export enum ViewKey {}

const SwayStatistic: React.FC<SwayStatisticProps> = (props) => {
  const {
    icon,
    titleString,
    valueString,
    helperString,
    direction = "vertical",
  } = props;
  const colors = useColors();
  const isVertical = direction === "vertical";
  const flexDirection = isVertical ? "column" : "row";
  const textAlign = isVertical ? "center" : "left";
  return (
    <SwayCard>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: isVertical ? "center" : "flex-start",
          flexDirection,
        }}
      >
        <div
          style={{
            display: "flex",
            width: isVertical ? 34 : 44,
            height: isVertical ? 34 : 44,
            borderRadius: "50%",
            backgroundColor: colors.primaryLightest,
            marginRight: isVertical ? 0 : SPACING_0_5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: isVertical ? "center" : "left",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <SwayText
            style={{
              marginTop: isVertical ? SPACING_0_5 : 0,
              fontSize: isVertical ? 14 : 12,
              width: "100%",
              lineHeight: isVertical ? undefined : 1.2,
              textAlign,
            }}
          >
            {titleString}
          </SwayText>

          <SwayText
            font="numbers"
            weight="bold"
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: 24,
              lineHeight: isVertical ? undefined : 1.2,
              textAlign,
            }}
          >
            {valueString}
          </SwayText>
          {helperString ? (
            <SwayText
              style={{
                fontSize: 12,
                color: colors.inactiveButtonBg,
                textAlign,
                width: "100%",
              }}
            >
              {helperString}
            </SwayText>
          ) : null}
        </div>
      </div>
    </SwayCard>
  );
};

export default SwayStatistic;
