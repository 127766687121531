import { CloudUploadOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  message,
  Modal,
  Popconfirm,
  Upload,
  UploadProps,
} from "antd";
import SwayRetailerTagScreenshot from "assets/images/swaypay-app-hashtag.gif";
import SwayRetailerScreenshot from "assets/images/swaypay-retailer-app.png";
import Config from "config";
import { useEffect, useState, useCallback } from "react";
import authService from "services/auth.service";
import onboardingFormsService from "services/onboarding-forms.service";
import SwayButton from "../components/SwayButton";
import SwayTextInput from "../components/SwayTextInput";
import { SPACING } from "../constants";
import {
  CommonFormProps,
  preferredContentOptions,
  formSteps,
} from "./formConstants";
import _ from "lodash";

const useStyles = makeStyles({
  checkboxes: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& > label": {
      marginLeft: `0px !important`,
    },
  },
});
const formatObjData = (data, noOfBrands: number) => {
  const arr = [];
  for (let i = 0; i < noOfBrands; i++) {
    const obj = {};
    for (const key in data) {
      const [ogKey, brandInd] = key.split("_");
      if (parseInt(brandInd) === i) {
        obj[ogKey] = data[key];
      }
    }
    arr.push(obj);
  }
  return arr;
};
export default function PostGuidelinesForm(props: CommonFormProps) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [noOfBrands, setNoOfBrands] = useState(1);
  const [form] = Form.useForm();
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (data) => {
    const formattedData = formatObjData(data, noOfBrands);
    setLoading(true);
    const { error } = await onboardingFormsService.updateOnboardingForm(
      props.retailer.id,
      props.retailer.onboardingForm.id,
      { [formSteps.postGuidelines]: formattedData }
    );
    setLoading(false);
    if (!error) {
      props.updateFormProgress(formSteps.postGuidelines);
      setIsModalOpen(false);
    } else {
      message.error(error.message);
    }
  };
  const uploadProps: UploadProps = {
    action: Config.SERVER_URL_V2 + "/merchants/onboarding/upload",
    headers: {
      Authorization: "Bearer " + authService._auth.token,
    },
    onChange(info) {
      const { response } = info.file;
      if (response?.data?.status === "done") {
        form.setFieldsValue({ [response.data.key]: response.data.url });
        message.success(`file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`file upload failed.`);
      } else if (info.file?.status === "removed") {
        form.setFieldsValue({ logoImage: null });
      }
    },
    maxCount: 1,
    multiple: false,
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }

      return isJpgOrPng && isLt2M;
    },
  };
  useEffect(() => {
    const savedFormData = localStorage.getItem(
      formSteps.postGuidelines + "_formdata"
    );
    form.setFieldsValue(JSON.parse(savedFormData));
  }, []);
  const saveDataToLocalStorage = useCallback(
    _.debounce(function () {
      localStorage.setItem(
        formSteps.postGuidelines + "_formdata",
        JSON.stringify(form.getFieldsValue())
      );
    }, 5000),
    [form]
  );
  return (
    <div>
      <Modal
        okText="Submit"
        style={{
          top: 20,
        }}
        afterClose={props.handleClose}
        title="Set up your Swaypay post guidelines"
        visible={isModalOpen}
        onOk={form.submit}
        okButtonProps={{
          loading,
          disabled: loading,
          className: `sway-merchant-btn-primary`,
          style: {
            fontWeight: "bold",
            color: "#000",
          },
        }}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onChange={saveDataToLocalStorage}
          name="post-intake-form"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          {Array.from({ length: noOfBrands }).map((_, i) => (
            <>
              <Form.Item label="Q1: How would you like your brand name to be displayed on the Swaypay app? (i.e. Urban Outfitters)">
                {/* <div style={{ textAlign: "center" }}>
                  <img
                    src={SwayRetailerScreenshot}
                    style={{
                      height: 400,
                      width: 300,
                      objectFit: "contain",
                    }}
                  />
                </div> */}
                <Form.Item
                  name={`brandName_${i}`}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Brand name is required",
                    },
                  ]}
                >
                  <SwayTextInput placeholder="Your brand name" />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name={`logoImage_${i}`}
                label="Q2: Please upload your logo (#1 above). It should be 500x500 pixels and no larger than 100 KB and it should be in a circular format (similar to what you would use for you FB profile pic for your brand)."
                rules={[
                  {
                    required: true,
                    message: "Logo is required",
                  },
                ]}
              >
                <Upload name={`logoImage_${i}`} {...uploadProps}>
                  <Button icon={<CloudUploadOutlined />}>Add File</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                name={`headerImage_${i}`}
                label="Q3: Please upload a header image (#2 above). It should be 1200x800 pixels and no larger than 300 KB"
                rules={[
                  {
                    required: true,
                    message: "Header image is required",
                  },
                ]}
              >
                <Upload name={`headerImage_${i}`} {...uploadProps}>
                  <Button icon={<CloudUploadOutlined />}>Add File</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Q4: What hashtags and tags should be included in your posts? (ie: #nike @nike )"
                rules={[
                  {
                    required: true,
                    message: "Hashtags and tags are required",
                  },
                ]}
              >
                <div style={{ textAlign: "center", margin: SPACING }}>
                  <img
                    src={SwayRetailerTagScreenshot}
                    style={{
                      height: 400,
                      width: 300,
                      objectFit: "contain",
                    }}
                  />
                </div>
                <Form.Item
                  name={`hashtagsAndTags_${i}`}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Hashtags and tags are required",
                    },
                  ]}
                >
                  <SwayTextInput placeholder="#nike @nike" />
                </Form.Item>
              </Form.Item>

              <Form.Item label="Q5: Any specific requests or restrictions to add?">
                <Form.Item
                  label="Q5: Any specific requests or restrictions to add?"
                  name={`requestsRestrictions_${i}`}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <SwayTextInput placeholder="Requests or restrictions" />
                </Form.Item>
              </Form.Item>

              {/* <Form.Item
                name={`preferredContent_${i}`}
                label="Swaypay has an army of vetted TikTok content creators who are eager to shop from brands and earn cash back rewards for TikToking them. Pick the one or two types of content you'd love the most from our Swaypayers."
              >
                <Checkbox.Group
                  className="sway-form"
                  options={preferredContentOptions}
                />
              </Form.Item> */}
              <Divider />
            </>
          ))}
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            {noOfBrands > 1 && (
              <Popconfirm
                overlayStyle={{ zIndex: 100000 }}
                overlayInnerStyle={{ padding: SPACING }}
                placement="topLeft"
                title="Are you sure want to delete this brand?"
                onConfirm={() => setNoOfBrands(noOfBrands - 1)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Delete this Brand</Button>
              </Popconfirm>
            )}
          </div>

          <SwayButton onClick={() => setNoOfBrands(noOfBrands + 1)}>
            Add another brand
          </SwayButton>
        </div>
      </Modal>
    </div>
  );
}
