import packageJson from "../../package.json";

const API_VERSION = "v1";

const Config = {
  get PUBLIC_URL() {
    return process.env.PUBLIC_URL;
  },
  get SERVER_URL() {
    return `${process.env.REACT_APP_SERVER_URL}/${API_VERSION}`;
  },
  get SERVER_URL_V2() {
    return `${process.env.REACT_APP_SERVER_URL}/v2`;
  },
  get PUP_SERVER_URL() {
    return `${process.env.REACT_APP_PUPPETEER_SERVER_URL}/${API_VERSION}`;
  },
  get NODE_ENV() {
    return process.env.NODE_ENV;
  },
  get DEBUG() {
    return process.env.NODE_ENV !== "production";
  },
  get VERSION() {
    return packageJson.version;
  },
  get FB_APPID() {
    return process.env.REACT_APP_FB_APPID;
  },
  get GOOGLE_CLIENT_ID() {
    return process.env.REACT_APP_GOOGLE_CLIENT_ID;
  },
  get GTAG_ID() {
    return process.env.REACT_APP_GTAG_ID;
  },
  get GA_ID() {
    return process.env.REACT_APP_GA_ID;
  },
  get PLACE_API_KEY() {
    return process.env.REACT_APP_PLACE_API_KEY;
  },
  get STRIPE_CLIENT_ID() {
    return process.env.REACT_APP_STRIPE_CLIENT_ID;
  },
  get STRIPE_KEY() {
    return process.env.REACT_APP_STRIPE_KEY;
  },
  get HOST_URL() {
    return process.env.REACT_APP_HOST_URL;
  },
  get HOTJAR_SITE_ID() {
    return Number(process.env.REACT_APP_HOTJAR_SITE_ID);
  },
  get HOTJAR_SNIPPET_VERSION() {
    return Number(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  },
  get DEFAULT_DATE_FORMAT() {
    return "MM/DD/YYYY";
  },
  get INTERCOM_APP_ID() {
    return process.env.REACT_APP_INTERCOM_APP_ID;
  },
  get INTERCOM_HELP_CENTER_URL() {
    return process.env.REACT_APP_INTERCOM_HELP_CENTER_URL;
  },
};

export default Config;
