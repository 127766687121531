import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btns: {
    display: "inline-block",
    "& > *": {
      marginRight: 12,
    },
  },
}));

export default useStyles;
