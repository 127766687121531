import { SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import moment from "moment";

export interface IFilter {
  [key: string]: string | string[] | number[];
}

/**
 *
 * @param sort SorterResult for any table
 * @param filter
 * @param pagination
 */
export const formatAPIQuery = (
  filter: IFilter,
  sort?: SorterResult<any>,
  pagination?: TablePaginationConfig
) => {
  const directionMapping = {
    ascend: "asc",
    descend: "desc",
  };
  let query = `?`;
  let queryStarted = false;
  if (sort) {
    const direction = directionMapping[sort.order];
    query = `sortBy=${direction}(${sort.columnKey})`;
    queryStarted = true;
  }
  if (pagination) {
    if (queryStarted) {
      query += "&";
    } else {
      queryStarted = true;
    }
    query += `skip=${(pagination.current - 1) * pagination.pageSize}&limit=${
      pagination.pageSize
    }`;
  }

  for (const [key, val] of Object.entries(filter)) {
    // If the query has already been started at this point, we need to include the &
    query += generateQueryStringBasedOnVal(key, val, false, queryStarted);
    // If there is more than one key and the query hadn't been started yet, it has been at this point, so we need this to be true for the next round
    queryStarted = true;
  }
  return query;
};

/**
 * Use for each column in the provided filter
 * @param key
 * @param val
 * @param checkArrNonZero pass true to skip the empty array check and append the key anyway
 * @returns
 */
const generateQueryStringBasedOnVal = (
  key: string,
  val: string | any[],
  checkArrNonZero = false,
  includeAnd = true
) => {
  let str = includeAnd ? "&" : "";
  if (
    (typeof val === "string" && val.trim() !== "") ||
    typeof val === "number"
  ) {
    str += `${key}=${val}`;
  } else if (Array.isArray(val) && (checkArrNonZero || val.length !== 0)) {
    str += `${key}=${JSON.stringify(val)}`;
  } else if (key.toLowerCase().includes("startdate") && val) {
    str += `${key}=${moment.utc(val).startOf("day").format()}`;
  } else if (key.toLowerCase().includes("enddate") && val) {
    str += `${key}=${moment.utc(val).endOf("day").format()}`;
  }
  return str;
};

export const generateUserUrl = (
  filter: IFilter,
  sort?: SorterResult<any>,
  pagination?: TablePaginationConfig
) => {
  // Always base it off of window? Will this cause issues for embedded lists?
  let newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  let queryStarted = false;
  if (sort) {
    if (!queryStarted) {
      newUrl += "?";
      queryStarted = true;
    }
    newUrl += `sort=${JSON.stringify(sort)}`;
  }
  if (pagination) {
    if (!queryStarted) {
      newUrl += "?";
      queryStarted = true;
    }
    newUrl += `&pagination=${JSON.stringify(pagination)}`;
  }
  for (const [key, val] of Object.entries(filter)) {
    const newQuerySection = generateQueryStringBasedOnVal(
      key,
      val,
      false,
      queryStarted
    );
    if (!queryStarted && newQuerySection.length > 0) {
      newUrl += "?";
      queryStarted = true;
    }
    newUrl += newQuerySection;
  }
  return newUrl;
};

export const parseParamsFromUrl: any = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const parsedObj = {};
  for (const [key, val] of urlSearchParams.entries()) {
    try {
      if (key.includes("Date")) {
        parsedObj[key] = moment.utc(val);
      } else {
        parsedObj[key] = JSON.parse(val);
      }
    } catch (e) {
      parsedObj[key] = val;
    }
  }
  return parsedObj;
};
