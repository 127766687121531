import * as React from "react";

const SwayCreditCard = (props: { active?: boolean }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 4.13837H3C1.89543 4.13837 1 5.0338 1 6.13837V18.1384C1 19.2429 1.89543 20.1384 3 20.1384H21C22.1046 20.1384 23 19.2429 23 18.1384V6.13837C23 5.0338 22.1046 4.13837 21 4.13837Z"
      fill={props.active ? "var(--primary)" : "#fff"}
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 10.1384H23"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SwayCreditCard;
