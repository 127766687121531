import { Button, Modal, Table } from "antd";
import React, { useContext, useState } from "react";
import tagsService from "services/tags.service";
import Input from "antd/lib/input";
import { AdminContext } from "../admin-base";
import EditIcon from "@material-ui/icons//Edit";
import { EditOutlined } from "@ant-design/icons";
import showStandardNotficationError from "helper/v2-helpers/showStandardNotficationError";
import Label from "component/Label/Label";
interface IProps {}

const UserTags: React.FC<IProps> = (props) => {
  const adminContext = useContext(AdminContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
    description: "",
  });
  const [editTagId, setEditTagId] = useState("");
  const [currentTagMode, setCurrentTagMode] = React.useState<"add" | "edit">(
    "add"
  );
  const handleTagSave = async () => {
    if (currentTagMode === "add") {
      const [err, tag] = await tagsService.addTagUser(inputData);
      if (err) {
        showStandardNotficationError();
      } else {
        adminContext.setUserTags((tags) => [...tags, tag]);
      }
    } else {
      const [err, tag] = await tagsService.editTagUser(editTagId, inputData);
      if (err) {
        showStandardNotficationError();
      } else {
        adminContext.setUserTags((tags) =>
          tags.map((oldTag) => {
            if (oldTag.id === tag.id) return tag;
            return oldTag;
          })
        );
      }
    }
    setIsModalVisible(false);
  };
  const handleEdit = (item: any) => {
    setCurrentTagMode("edit");
    setIsModalVisible(true);
    setInputData({ name: item.name, description: item.description });
    setEditTagId(item.id);
  };
  const handleAdd = () => {
    setCurrentTagMode("add");
    setIsModalVisible(true);
    setInputData({ name: "", description: "" });
  };
  const handleChange = (key) => (e) => {
    setInputData({ ...inputData, [key]: e.target.value });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "tag",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "tag",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id, item) => {
        return (
          <Button
            onClick={() => handleEdit(item)}
            type="primary"
            shape="circle"
            icon={<EditIcon />}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Add Tag
      </Button>
      <Modal
        title={currentTagMode === "add" ? "Add Tag" : "Edit Tag"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleTagSave}
      >
        <Label>Name</Label>
        <Input
          placeholder="Name"
          value={inputData.name}
          style={{ marginBottom: 20 }}
          onChange={handleChange("name")}
        />
        <Label>Description</Label>
        <Input
          placeholder="Description"
          value={inputData.description}
          onChange={handleChange("description")}
        />
      </Modal>
      <Table
        rowKey="id"
        columns={columns}
        pagination={false}
        dataSource={adminContext.userTags}
      />
    </div>
  );
};
export default UserTags;
