import { AnalyticsBrowser } from "@segment/analytics-next";
import React, { useEffect, useState } from "react";
import Loading from "../../component/loading";
import getUserIdForSegment from "../../helper/v2-helpers/getUserIdForSegment";
import authService from "../../services/auth.service";

interface IProps {
  children?: React.ReactNode;
  scope?: "private" | "public";
}

export const AnalyticsContext = React.createContext<AnalyticsBrowser>(
  undefined!
);

export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};

const AnalyticsContextProvider: React.FC<IProps> = (props) => {
  const analytics = React.useMemo(
    () =>
      AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_KEY }),
    []
  );

  useEffect(() => {
    // Segment identification
    if (authService._auth) {
      analytics.identify(
        getUserIdForSegment(authService._auth.user.id, authService._auth.role)
      );
    }
    if (!authService._auth) {
      analytics.reset();
    }
  }, [authService._auth]);
  return (
    <AnalyticsContext.Provider value={analytics}>
      <>{props.children}</>
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
