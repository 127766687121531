import { Breadcrumb, Layout, Menu, Tabs } from "antd";
import UserTags from "./UserTags";
import PostTags from "./PostTags";
import React from "react";
import { Header, Content, Footer } from "antd/lib/layout/layout";
import AdminBase from "modules/admin/admin-base";
import { useParams } from "react-router-dom";
import RetailerTags from "./RetailerTags";
const { TabPane } = Tabs;

function callback(key) {
  const url =
    window.location.protocol +
    "//" +
    window.location.host +
    `/admin/tags/${key}`; //change url
  window.history.replaceState({ path: url }, "", url);
}

const Tags = () => {
  const { table } = useParams<any>();
  return (
    <AdminBase>
      <Tabs
        style={{ width: "100%" }}
        hideAdd
        defaultActiveKey={table}
        onChange={callback}
      >
        <TabPane tab="Users Tags" key="users">
          <UserTags />
        </TabPane>
        <TabPane tab="Post Tags" key="posts">
          <PostTags />
        </TabPane>
        <TabPane tab="Retailer Tags" key="retailers">
          <RetailerTags />
        </TabPane>
      </Tabs>
    </AdminBase>
  );
};
export default Tags;
