export default ({ purchase, setPurchase }) => {
  purchase.posts.forEach(async (post) => {
    try {
      const postData = await (
        await fetch(`https://www.tiktok.com/oembed?url=${post.postUrl}`)
      ).json();
      if (postData.status_msg === "Something went wrong") {
        post.embed = "Something went wrong";
      } else {
        post.embed = postData.html.replace(
          '<script async src="https://www.tiktok.com/embed.js"></script>',
          ""
        );
      }
    } catch {
      post.embed = "Something went wrong";
    }
    setPurchase((tempPurchase) => {
      tempPurchase.posts = tempPurchase.posts.map((tempPost) => {
        if (tempPost.id === post.id) {
          return post;
        }
        return tempPost;
      });

      return { ...tempPurchase };
    });
  });
};
