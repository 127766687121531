import { Checkbox, Form, message, Modal, Radio } from "antd";
import { useEffect, useState, useCallback } from "react";
import onboardingFormsService from "services/onboarding-forms.service";
import generateUuidV4 from "../../../helper/generateUuidV4";
import SwayText from "../components/SwayText";
import SwayTextInput from "../components/SwayTextInput";
import { SPACING, SPACING_2 } from "../constants";
import useColors from "../hooks/useColors";
import {
  arrOptions,
  avgDiscountOptions,
  aovOptions,
  retailerCat,
  CommonFormProps,
  formSteps,
  spGoalsOptions,
  digitalMarketingAnnualBudgetOptions,
  monthlyOvOptions,
  demoAgeOptions,
  demoGenderBaseOptions,
  demoRegion,
  demoEthnicityOptions,
} from "./formConstants";
import SwayRetailerTagScreenshot from "assets/images/swaypay-app-hashtag.gif";
import _ from "lodash";
export default function BrandIntakeForm(props: CommonFormProps) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const colors = useColors();
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (data) => {
    setLoading(true);
    const { error } = await onboardingFormsService.updateOnboardingForm(
      props.retailer.id,
      props.retailer.onboardingForm.id,
      { [formSteps.brandIntake]: data }
    );
    setLoading(false);
    if (!error) {
      props.updateFormProgress(formSteps.brandIntake);
      setIsModalOpen(false);
    } else {
      message.error(error.message);
    }
  };
  useEffect(() => {
    const savedFormData = localStorage.getItem(
      formSteps.brandIntake + "_formdata"
    );
    form.setFieldsValue(JSON.parse(savedFormData));
  }, []);
  const saveDataToLocalStorage = useCallback(
    _.debounce(function () {
      localStorage.setItem(
        formSteps.brandIntake + "_formdata",
        JSON.stringify(form.getFieldsValue())
      );
    }, 5000),
    [form]
  );
  return (
    <div>
      <Modal
        afterClose={props.handleClose}
        title="Swaypay Brand Intake Form"
        visible={isModalOpen}
        onOk={() => form.submit()}
        okButtonProps={{
          loading,
          disabled: loading,
        }}
        onCancel={handleCancel}
      >
        <SwayText
          size="small"
          color={colors.textGray}
          style={{
            paddingBottom: SPACING_2,
            display: "block",
          }}
        >
          Please fill out this intake form to get you set up and ready to launch
          in the app. Reach out to sales@swaypayit.com if you have any
          questions.
        </SwayText>
        <Form
          form={form}
          onChange={saveDataToLocalStorage}
          name="brand-intake-form"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="formId"
            label="form id"
            initialValue={generateUuidV4()}
            noStyle
          >
            <SwayTextInput type="hidden" />
          </Form.Item>
          <Form.Item
            name="retailerName"
            label="Q1: Company name? (ie: Urban Outfitters)"
            rules={[
              {
                required: true,
                message: "Company name is required",
              },
            ]}
          >
            <SwayTextInput placeholder="Company" />
          </Form.Item>

          <Form.Item
            name="aov"
            label="Q2: Average Order Value? (max 1)"
            rules={[
              { required: true, message: "Average Order Value is required" },
            ]}
          >
            <Radio.Group className="sway-form">
              {aovOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="arr"
            label="Q3: Annual Revenue Range? (max 1)"
            rules={[
              {
                required: true,
                message: "Annual Revenue Range is required",
              },
            ]}
          >
            <Radio.Group className="sway-form">
              {arrOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="digitalMarketingAnnualBudget"
            label="Q4: Estimated Annual Digital Marketing Spend? (max 1)"
            rules={[
              {
                required: true,
                message: "Estimated Annual Digital Marketing Spend is required",
              },
            ]}
          >
            <Radio.Group className="sway-form">
              {digitalMarketingAnnualBudgetOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="demoEthnicity"
            label="Q5: Do you service a primary target ethnicity? (max 1)"
            rules={[
              {
                required: true,
                message: "Target ethnicity is required",
              },
            ]}
          >
            <Radio.Group className="sway-form">
              {demoEthnicityOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="demoAge"
            label="Q6: What's the primary age range of your shopper base? (max 1)"
            rules={[
              {
                required: true,
                message: "Primary age range is required",
              },
            ]}
          >
            <Radio.Group className="sway-form">
              {demoAgeOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="demoGender"
            label="Q7: What's the primary gender of your shopper base? (max 1)"
            rules={[
              {
                required: true,
                message: "Primary gender is required",
              },
            ]}
          >
            <Radio.Group className="sway-form">
              {demoGenderBaseOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="categoryBestSuited"
            label="Q8: Which category best describes your product? (max 1)"
            rules={[
              {
                required: true,
                message: "Category is required",
              },
            ]}
          >
            <Radio.Group className="sway-form">
              {retailerCat.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="orderEmail"
            label="Q9: Email from which customers receive order confirmation email"
            rules={[
              {
                required: true,
                message: "Email address is required",
              },
              {
                required: true,
                pattern:
                  /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/,
                message: "We accept business email only",
              },
            ]}
          >
            <SwayTextInput placeholder="order@brand.com" />
          </Form.Item>

          {/* <Form.Item
            name={`restrictedHashtagsAndTags`}
            label="What hashtags would you like to restrict?"
          >
            <SwayTextInput placeholder="" />
          </Form.Item>

          <Form.Item
            name="hasTiktokMarketingSpend"
            label="Do you currently have marketing spend budgeted for TikTok?"
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="spGoals"
            label="Pick your top goals in using Swaypay"
          >
            <Checkbox.Group className="sway-form" options={spGoalsOptions} />
          </Form.Item>
          <Form.Item name="monthlyOv" label="Monthly order volume">
            <Radio.Group className="sway-form">
              {monthlyOvOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="avgDiscount"
            label="Average discount you provide shoppers"
          >
            <Radio.Group className="sway-form">
              {avgDiscountOptions.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="regionsBestSuited"
            label="Is your product best suited for particular regions? (Ie: sunscreen for west coasters)"
          >
            <Radio.Group className="sway-form">
              {demoRegion.map((item) => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
}
