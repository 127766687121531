import React from "react";
import { IPropTip } from "types/proTip.interface";
import DefaultProTipCard from "./DefaultProTipCard";

type Props = {
  proTip?: IPropTip;
};
const proTipCardMapping = {
  1: DefaultProTipCard,
};
const ProTipBaseSelector = ({ proTip }: Props) => {
  if (proTip?.isActive === false) return null;
  if (!proTip || !proTipCardMapping[proTip.id]) return <DefaultProTipCard />;

  return React.createElement(proTipCardMapping[proTip.id]);
};

export default ProTipBaseSelector;
