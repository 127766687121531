import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="50%"
      height="50%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_339_8866)">
        <path
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
          stroke="black"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.25146 4.5V13.3636"
          stroke="black"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.305 5.66296H7.84754C7.41962 5.66296 7.00923 5.83516 6.70665 6.14168C6.40406 6.44819 6.23407 6.86392 6.23407 7.29739C6.23407 7.73087 6.40406 8.14659 6.70665 8.45311C7.00923 8.75962 7.41962 8.93182 7.84754 8.93182H10.1525C10.5804 8.93182 10.9908 9.10402 11.2934 9.41054C11.596 9.71705 11.766 10.1328 11.766 10.5663C11.766 10.9997 11.596 11.4155 11.2934 11.722C10.9908 12.0285 10.5804 12.2007 10.1525 12.2007H6.23407"
          stroke="black"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_339_8866">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
