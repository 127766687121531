import { notification } from "antd";
import ProgressBar from "component/progressBar";
import Config from "config";
import { gTagInit, gTagPageView } from "helper/gtag";
import React, { Suspense } from "react";
import { hotjar } from "react-hotjar";
import {
  matchPath,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { AuthService } from "services";
import httpClient from "services/http-client";
import Admin from "./admin";
import Error from "./common/error";
import MerchantV2 from "./merchantV2";
import AnalyticsContextProvider from "./merchantV2/AnalyticsProvider";
const Common = React.lazy(() => import("modules/common"));

export const PrivateRoute = ({
  component: Component,
  authRedirect,
  role,
  ...rest
}) => {
  const render = (props) => {
    if (!AuthService.isAuthenticated(role)) {
      AuthService.isRedirectedURL = window.location.pathname;
      return <Redirect to={authRedirect} />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};

export const RestrictedRoute = ({
  component: Component,
  authRedirect,
  role,
  ...rest
}) => {
  const render = (props) => {
    if (AuthService.isAuthenticated(role)) {
      return <Redirect to={authRedirect} />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};

interface Props extends RouteComponentProps {
  locale: String;
  setLocale: (e: React.ChangeEvent<{ value: unknown }>) => void;
}
class Root extends React.Component<Props> {
  componentDidMount() {
    gTagInit();
    const excludePatterns = [
      "/merchant/stores/:storeId",
      "/customer/dashboard",
      "/customer/posts",
      "/customer/checkout",
      "/customer/shipping",
    ];
    //@ts-ignore
    this.props.history.listen((location) => {
      let matched = false;
      excludePatterns.forEach((path) => {
        if (matchPath(location.pathname, path)) {
          matched = true;
        }
      });
      if (!matched) {
        gTagPageView({});
      }
    });
    hotjar.initialize(Config.HOTJAR_SITE_ID, Config.HOTJAR_SNIPPET_VERSION);
    const refreshToken = async () => {
      let authData: any = localStorage.getItem("swaypay_auth");
      if (authData) {
        const isV2 = "id" in JSON.parse(authData).user;
        const res = await httpClient(
          isV2 ? "/admin/auth/refresh-token" : "/auth/refresh-token",
          {
            method: "GET",
            apiVersion: isV2 ? "v2" : "v1",
          }
        );
        if (!res || !res.success) {
          return;
          // return localStorage.removeItem("swaypay_auth");
        }

        localStorage.setItem("swaypay_auth", JSON.stringify(res.data));
      }
    };
    const patternMatched = excludePatterns.some((path) =>
      matchPath(window.location.pathname, path)
    );
    if (!patternMatched) {
      gTagPageView({});
    }
    // setInterval(refreshToken, 600000); //10 mins
    // refreshToken();
  }
  componentDidCatch(error, errorInfo: React.ErrorInfo): void {
    notification.error({
      message: "Something went wrong",
      description: error,
    });
  }
  render() {
    return (
      <>
        <Suspense fallback={<ProgressBar />}>
          <IntercomProvider appId={Config.INTERCOM_APP_ID}>
            <AnalyticsContextProvider>
              <Switch>
                <Route path="/merchant" component={MerchantV2} />
                <Route path="/admin" component={Admin} />
                <Route path="/failure" component={Error} />
                <Route path="" component={Common} />
              </Switch>
            </AnalyticsContextProvider>
          </IntercomProvider>
        </Suspense>
        {/* <Footer locale={this.props.locale} setLocale={this.props.setLocale} /> */}
      </>
    );
  }
}

export default withRouter(Root);
