import React, { useLayoutEffect, useState } from "react";
import { IPropTip } from "types/proTip.interface";
import Loading from "../../component/loading";
import retailerService from "../../services/retailer.service";
import { IRetailer } from "../../types/retailer.interface";
import { AnalyticsBrowser } from "@segment/analytics-next";
import authService from "services/auth.service";
import { notification } from "antd";
import { useHistory } from "react-router-dom";

interface IProps {
  children?: React.ReactNode;
  scope?: "private" | "public";
}

export const MerchantContext = React.createContext<{
  loading: boolean;
  retailer: IRetailer;
  proTip: IPropTip;
  setRetailer: (retailer: React.SetStateAction<IRetailer>) => void;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}>({
  loading: true,
  retailer: null,
  error: {
    hasError: false,
    errorMessage: "",
  },
  proTip: null,
  setRetailer: () => {},
});

const MerchantBaseProvider: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const history = useHistory();
  const [retailer, setRetailer] = useState<IRetailer>();
  const [proTip, setProTip] = useState<IPropTip>();

  useLayoutEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { error, retailers, proTip } =
          await retailerService.getRetailersForMerchant();
        if (retailers) {
          switch (retailers.length) {
            case 0:
              setError({
                hasError: true,
                errorMessage:
                  "Please wait for Swaypay team to enable the account",
              });
              break;
            case 1:
              setRetailer(retailers[0]);
              break;
            default:
              setError({
                hasError: true,
                errorMessage:
                  "Something wrong with your account. Please contact support",
              });
          }
        } else {
          if (error.message === "Unauthorized request") {
            authService.logout();
            history.push("/merchant/login");
            notification.error({
              message: "Session Expried",
              description: "Please login again.",
            });
            return;
          }
          setError({
            hasError: true,
            errorMessage: "Something went wrong",
          });
        }
      } catch (e) {
        setError({
          hasError: true,
          errorMessage: "Something went wrong",
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <Loading></Loading>;

  return (
    <MerchantContext.Provider
      value={{ loading, error, retailer, setRetailer, proTip }}
    >
      <>{props.children}</>
    </MerchantContext.Provider>
  );
};

export default MerchantBaseProvider;
