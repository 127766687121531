import { IUser } from "./user.interface";

export enum ROLE {
  ADMIN = "admin",
  MERCHANT = "merchant",
  CUSTOMER = "customer",
}

export interface IAuthInfo {
  token: string;
  exp: number;
  user: IUser;
  role: ROLE;
}

export interface IMerchantAuthResponseJson extends IAuthInfo {}

export interface IMerchantAuthResponse {
  authInfo?: IAuthInfo;
  error?: Error;
}
