import {
  Chip,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CloudUploadOutlined } from "@ant-design/icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Loading from "component/loading";
import { formatCurrency } from "helper/locale.helper";
import moment from "moment";
import { Radio } from "antd";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import httpClient from "services/http-client";
import useStyles from "modules/admin/styles/commonDetailsPageStyle";
import TagSelector from "../../../component/TagSelector/TagSelector";
import { AdminContext } from "../admin-base";
import tagsService from "../../../services/tags.service";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
import { Tag, Card, Descriptions, Steps, Upload, Button } from "antd";
import { convertToTitleCase } from "../../../helper/convertToTitleCase";
import StatusMapping from "helper/v2-helpers/StatusMapping";
import ReceiptStatusMapping from "helper/v2-helpers/ReceiptStatusMapping";
interface IProps {
  retailerId?: string;
}

const { Step } = Steps;

const optionsWithDisabled = [
  { label: "Apple", value: "Apple" },
  { label: "Pear", value: "Pear", color: "danger" },
  // { label: "Orange", value: "Orange", disabled: true, },
];
const RetailerDetails: React.FC<IProps> = (props) => {
  const adminContext = React.useContext(AdminContext);
  const params = useParams<any>();
  const retailerId = props.retailerId ? props.retailerId : params.retailerId;
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [brandIntakeformKeys, setBrandIntakeFormKeys] = React.useState([]);
  const [postGuildines, setpostGuildines] = React.useState([]);
  const [postGuildinesKeys, setpostGuildinesKeys] = React.useState([]);

  const fetchBrand = async () => {
    const res = await httpClient(`/admin/retailers/${retailerId}`, {
      apiVersion: "v2",
      auth: true,
    });

    setData(res.data);
    const onboardingForm = res.data.retailer.onboardingForm;
    if (onboardingForm?.data?.brandIntake) {
      setBrandIntakeFormKeys(Object.keys(onboardingForm?.data?.brandIntake));
    }
    console.log("HIT", onboardingForm?.data);
    if (onboardingForm?.data?.postGuidelines.length > 0) {
      setpostGuildines(onboardingForm?.data?.postGuidelines);
      setpostGuildinesKeys(
        Object.keys(onboardingForm?.data?.postGuidelines[0])
      );
    }
    setLoading(false);
  };

  const handleChangeTag = async (tagId: number, checked: boolean) => {
    try {
      let res = null;
      setLoading(true);
      if (checked) {
        res = await tagsService.retailerAddTag(data.retailer.id, tagId);
      } else {
        res = await tagsService.retailerDeleteTag(data.retailer.id, tagId);
      }
      fetchBrand();
    } catch (e) {}
  };

  React.useEffect(() => {
    fetchBrand();
  }, []);
  const onboardingForm = data && data.retailer.onboardingForm;
  const onboardingProgress = data && data.retailer.onboardingProgress;
  return (
    data && (
      <Container
        style={{
          marginBottom: 60,
          marginTop: 20,
        }}
      >
        {loading && <Loading />}
        <Paper style={{ padding: "20px 10px" }}>
          {props.retailerId && (
            <Tooltip title="Open in a new tab" placement="top" arrow>
              <Link
                href={`/admin/retailers/${props.retailerId}`}
                target="_blank"
                style={{ marginRight: 5 }}
              >
                <OpenInNewIcon />
              </Link>
            </Tooltip>
          )}
          <Typography>
            Retailer: <b>{data.retailer.name}</b>
          </Typography>
          <Typography>
            Brands:{" "}
            <b>
              {data.retailer.brandRetailerAssociations.map((item, i) => (
                <>
                  <Link href={`/admin/brands/${item.brand.id}`}>
                    {item.brand.name}
                  </Link>
                  {data.retailer.brandRetailerAssociations.length - 1 !== i &&
                    ", "}
                </>
              ))}
            </b>
          </Typography>
          <Typography>
            Active: <b>{data.retailer.isEnabled ? "Yes" : "No"}</b>
          </Typography>
          <Typography>
            Total Posts: <b>{data.postStats.totalPosts}</b> (
            <span style={{ color: "var(--success)" }}>
              Approved : <b>{data.postStats.approvedPosts}</b>,
            </span>{" "}
            <span style={{ color: "var(--warning)" }}>
              Pending : <b>{data.postStats.pendingPosts}</b>,
            </span>{" "}
            <span style={{ color: "var(--danger)" }}>
              Reject : <b>{data.postStats.rejectedPosts}</b>
            </span>
            )
          </Typography>
          <Typography>
            Total Spends: <b>{formatCurrency(data.postStats.totalRewards)}</b>
          </Typography>
          <Typography>
            Total Purchase value:{" "}
            <b>{formatCurrency(data.postStats.totalPurchaseValue)}</b>
          </Typography>
        </Paper>
        <Paper style={{ marginTop: 20, padding: "20px 10px" }}>
          <h2>Retailer Onboarding progress</h2>
          <Steps size="small" labelPlacement="vertical">
            <Step
              description="Brand Intake"
              status={
                onboardingProgress.brandIntakeCompleted ? "finish" : "wait"
              }
            />
            <Step
              description="Post Guidelines Accepted"
              status={
                onboardingProgress.postGuidelinesCompleted ? "finish" : "wait"
              }
            />
            <Step
              description="Contract Signed"
              status={
                onboardingProgress.signContractCompleted ? "finish" : "wait"
              }
            />
            <Step
              description="Credit Card Added"
              status={
                onboardingProgress.addCreditCardCompleted ? "finish" : "wait"
              }
            />
          </Steps>
        </Paper>
        <div className={classes.tagDiv}>
          <Typography style={{ marginRight: 16, fontSize: "16px" }}>
            Retailer Tags:
          </Typography>
          <TagSelector
            options={adminContext.retailerTags}
            selected={data.retailer.retailerTags.map((retailerTags) => ({
              id: retailerTags.retailerTag.id,
              name: retailerTags.retailerTag.name,
              description: retailerTags.retailerTag.description,
              tagGroupType: retailerTags.retailerTag.tagGroupType,
            }))}
            onChange={(tagId, isSelected) => handleChangeTag(tagId, isSelected)}
          />
        </div>
        <Paper style={{ paddingBottom: 10 }}>
          <Typography className={classes.heading}>Purchases</Typography>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Date Posted</TableCell>

                <TableCell>Status</TableCell>
                <TableCell>Receipt Status</TableCell>
                <TableCell>Cashback Earned</TableCell>
                <TableCell style={{ textAlign: "right" }}>Tags</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(data.purchases.length) ? (
                data.purchases.map((purchase: any, i) => (
                  <TableRow key={purchase.id}>
                    <TableCell>
                      <Link href={`/admin/purchases/${purchase.id}`}>
                        <b>{moment(purchase.createdAt).format("DD MMM")}</b>
                      </Link>
                    </TableCell>

                    <TableCell>
                      <Chip
                        className="chipStatusHeight"
                        label={
                          purchase.status
                            ? StatusMapping.find(
                                (status) => status.id === purchase.status
                              )?.name
                            : "PENDING"
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        className="chipStatusHeight"
                        label={
                          ReceiptStatusMapping.find(
                            (status) => status.id === purchase.receiptStatus
                          )?.name
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {purchase.status === "cashback_claimed" &&
                        "$" +
                          formatCentsToDollars(
                            purchase.posts.find(
                              (post) => post.postStatus === "CLOSED"
                            )?.cashbackEarnedInCents
                          )}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {purchase.posts.map((post) =>
                        post.tags.map((tag) => <Tag>{tag.name}</Tag>)
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ fontSize: 16 }}
                  >
                    No purchases yet.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>

        <Card style={{ marginTop: 10 }}>
          {onboardingForm.data.brandIntake ? (
            <Descriptions
              title="Retailer Intake Form Info"
              labelStyle={{
                background: "transparent",
                fontWeight: 900,
              }}
              bordered={true}
            >
              {brandIntakeformKeys.length > 1 &&
                brandIntakeformKeys.map((key, index) => {
                  return (
                    <Descriptions.Item
                      label={`${convertToTitleCase(key)} :`}
                      span={2}
                    >
                      {String(
                        data.retailer.onboardingForm.data.brandIntake[key]
                      )
                        .split(":")
                        .pop()}
                    </Descriptions.Item>
                  );
                })}
            </Descriptions>
          ) : (
            <p>Retailer Form Info Absent</p>
          )}
        </Card>
        <Card style={{ marginTop: 10 }}>
          {postGuildines.length > 0 ? (
            <Descriptions
              title="Retailer Post Guidelines Info"
              labelStyle={{
                background: "transparent",
                fontWeight: 900,
                textAlign: "center",
                width: "35%",
                fontSize: 15,
              }}
              bordered={true}
            >
              {postGuildines.map((answer) => {
                return (
                  <Descriptions.Item
                    style={{ padding: 0, textAlign: "left" }}
                    label={`Brand Name: ${answer.brandName}`}
                    span={3}
                  >
                    <ul style={{ lineHeight: 3 }}>
                      {postGuildinesKeys.map((key) => {
                        if (key === "brandName") {
                          return;
                        } else if (answer[key].indexOf("http") == 0) {
                          return (
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <b>{`${convertToTitleCase(key)} :`}</b>
                                <div>
                                  <img
                                    src={answer[key]}
                                    style={{
                                      height: 30,
                                      marginRight: 20,
                                      marginLeft: 20,
                                    }}
                                  />
                                  <a href={answer[key]} target="_blank">
                                    View
                                  </a>
                                </div>
                              </div>
                            </li>
                          );
                        } else if (Array.isArray(answer[key])) {
                          return (
                            <li>
                              <strong>{`${convertToTitleCase(
                                key
                              )} :  `}</strong>
                              <ol style={{ lineHeight: 2 }}>
                                {answer[key].map((item: string) => (
                                  <li>{item.split(":").pop()}</li>
                                ))}
                              </ol>
                            </li>
                          );
                        } else {
                          return (
                            <li>
                              <strong>{`${convertToTitleCase(
                                key
                              )} :  `}</strong>
                              {answer[key]}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          ) : (
            <p>Retailer Post Guildlies Absent</p>
          )}
        </Card>
      </Container>
    )
  );
};
export default RetailerDetails;
