import { Avatar, Modal } from "antd";
import { XIcon } from "assets/icons";
import SwayButton from "modules/merchantV2/components/SwayButton";
import SwayCard from "modules/merchantV2/components/SwayCard";
import {
  SPACING,
  SPACING_0_25,
  SPACING_0_75,
  SPACING_1_5,
} from "modules/merchantV2/constants";
import useColors from "modules/merchantV2/hooks/useColors";
import React, { useState } from "react";
import { IContentPost } from "types/post.interface";
import BetaIcon from "assets/images/beta-icon.png";
import SwayText from "modules/merchantV2/components/SwayText";
import { StatsHeartIcon, StatsMessageIcon } from "modules/merchantV2/icons";
import SharePurchaseIcon from "assets/images/share-purchase.png";
import PlayVideoIcon from "assets/images/play-video-icon.png";
import HelpTooltipIcon from "assets/images/help-tooltip.png";
import LicenseCheckIcon from "assets/images/license-check.png";
import OpenProfileIcon from "assets/images/open-profile.png";
import PricingTierIcon from "modules/merchantV2/icons/PricingTier";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import SwayLink from "modules/merchantV2/components/SwayLink";
import postService from "services/post.service";
import { TikTokEmbed } from "react-social-media-embed";

interface IProps {
  post: IContentPost;
  isSamplePost: boolean;
  onCancel: () => void;
}

export enum ViewKey {}

const PurchasePost: React.FC<IProps> = (props) => {
  const { post, isSamplePost } = props;

  const colors = useColors();
  const [termsApproved, setTermsApproved] = useState(false);
  const service = postService;

  const rowSizeStyles = { display: "flex", width: "100%" };
  const videoHelperIconSize = 32;
  const videoHelperColumnStyles = {
    width: videoHelperIconSize,
    minWidth: videoHelperIconSize,
    maxWidth: videoHelperIconSize,
  };
  const videoHelperIconStyles = {
    width: videoHelperIconSize,
    minWidth: videoHelperIconSize,
    maxWidth: videoHelperIconSize,
    height: videoHelperIconSize,
    minHeight: videoHelperIconSize,
    maxHeight: videoHelperIconSize,
    backgroundColor: colors.background,
    borderRadius: videoHelperIconSize / 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const renderVideoHelperStat = (
    icon: React.ReactNode,
    value: string,
    isFirst: boolean
  ) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: isFirst ? 0 : SPACING,
        }}
      >
        <div style={{ ...videoHelperIconStyles }}>{icon}</div>
        <SwayText
          weight="bold"
          style={{
            textAlign: "center",
            flexGrow: 1,
            marginTop: SPACING_0_25,
          }}
          color={colors.background}
          size="tiny"
        >
          {value}
        </SwayText>
      </div>
    );
  };

  const termsCheckboxChanged = (e: CheckboxChangeEvent) => {
    setTermsApproved(e.target.checked);
  };

  const purchaseButtonClicked = async () => {
    const { downloadUrl, error } = await service.purchasePost(post.id);
    if (error) {
      // Show error, card info? several error types could happen here
      return;
    }

    // Download immediately or redirect to downloads page?
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    }
  };

  return (
    <Modal
      visible={post !== undefined}
      footer={null}
      onCancel={() => {
        props.onCancel();
      }}
      mask
      maskClosable
      width={900}
      style={{ borderRadius: SPACING, top: 20 }}
      bodyStyle={{ overflow: "hidden", padding: 0, height: 800 }}
      closable={false}
      centered
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "50%",
            backgroundColor: colors.backgroundInverted,
            borderTopLeftRadius: SPACING,
            borderBottomLeftRadius: SPACING,
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <div style={{ flexGrow: 1 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: SPACING_1_5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Fill center column with post video, how to ensure aspect ratio? 
            235/416*/}
            <div
              style={{
                position: "relative",
                //height: 800,
                backgroundColor: colors.primary,
                borderRadius: SPACING,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TikTokEmbed url={post?.postLongUrl} width={325} />
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            padding: `${SPACING_0_75}px ${SPACING}px`,
            overflow: "auto",
          }}
        >
          <div
            style={{
              flexDirection: "row-reverse",
              ...rowSizeStyles,
            }}
          >
            <SwayButton
              classType={null}
              ghost
              onClick={() => {
                props.onCancel();
              }}
              style={{
                borderWidth: 0,
                padding: 0,
              }}
            >
              <XIcon />
            </SwayButton>
          </div>
          <SwayCard
            style={{
              backgroundColor: colors.lightButtonBackground,
              display: "flex",
            }}
            bodyStyle={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={BetaIcon}
                style={{
                  width: 56,
                  height: 23,
                  marginRight: SPACING,
                }}
              />
            </div>
            <SwayText size="small" style={{ flexShrink: 1 }}>
              Content pricing is still in beta. Pricing levels may update as we
              build the best ecosystem for Swaypay users and brands.
            </SwayText>
          </SwayCard>
          {!isSamplePost ? (
            <SwayCard
              style={{
                width: "100%",
                marginTop: SPACING,
              }}
            >
              {/* <SwayText
                type="title"
                weight="bold"
                style={{
                  fontSize: 32,
                  textAlign: "center",
                }}
              >
                {post.purchaseInfo.priceString}
              </SwayText> */}

              {/* <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <PricingTierIcon
                  pricingTier={post.purchaseInfo.pricingTier.name}
                />

                <SwayText size="small" style={{ marginLeft: 10 }}>
                  This content is priced at a{" "}
                  {post.purchaseInfo.pricingTier.name} level.
                </SwayText>
                <div style={{ flexGrow: 1 }} />
                <img
                  src={HelpTooltipIcon}
                  style={{
                    width: 18,
                    height: 18,
                    marginLeft: SPACING_0_75,
                  }}
                />
              </div> */}
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  display: "flex",
                  marginTop: SPACING,
                  marginBottom: SPACING,
                }}
              >
                <img
                  src={LicenseCheckIcon}
                  style={{
                    minWidth: 18,
                    width: 18,
                    height: 18,
                    marginRight: SPACING_0_75,
                  }}
                />

                <SwayText size="small">
                  License: Use for advertising, and promotion of same. Unlimited
                  web distribution and usage.
                </SwayText>
              </div>
              <div
                style={{
                  minWidth: "100%",
                  height: 1,
                  backgroundColor: colors.cardBorder,
                  marginRight: -SPACING,
                  marginLeft: -SPACING,
                  flexGrow: 1,
                }}
              />
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  marginTop: SPACING,
                  marginBottom: SPACING,
                }}
              >
                <Checkbox
                  style={{
                    marginRight: 14,
                  }}
                  onChange={(e) => {
                    termsCheckboxChanged(e);
                  }}
                />

                <SwayText size="small">
                  {/* Add links to terms and conditions */}
                  You have read and agree to our license
                  <SwayLink href="https://brands.swaypayit.com/terms-of-service">
                    {" "}
                    terms and conditions
                  </SwayLink>
                </SwayText>
              </div>
              <SwayButton
                classType={null}
                // disabled={!termsApproved}
                disabled={true}
                onClick={() => {
                  purchaseButtonClicked();
                }}
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  //temp changes
                  color: colors.background,
                  backgroundColor: colors.inactiveButtonBg,
                  borderColor: colors.inactiveButtonBg,
                  width: "100%",
                  borderWidth: 2,
                }}
              >
                Buy and Download
              </SwayButton>
            </SwayCard>
          ) : (
            <></>
          )}

          <SwayCard
            style={{
              width: "100%",
              marginTop: SPACING,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                paddingBottom: SPACING,
              }}
            >
              <Avatar
                size={32}
                src={post.userTiktokInfo.avatarUrl}
                style={{
                  borderWidth: 2,
                  borderColor: colors.backgroundInverted,
                  backgroundColor: colors.background,
                  borderStyle: "solid",
                  marginRight: SPACING,
                }}
              />
              <div style={{ flexDirection: "column", display: "flex" }}>
                <SwayText size="small" weight="bold">
                  {post.userTiktokInfo.tiktokHandle}
                </SwayText>
                <SwayText size="small">
                  {post.userTiktokInfo.displayName}
                </SwayText>
              </div>
              <div style={{ flexGrow: 1 }} />
              <img
                src={OpenProfileIcon}
                onClick={() =>
                  window.open(post?.userTiktokInfo?.profileDeepLink, "_blank")
                }
                style={{
                  minWidth: 18,
                  width: 18,
                  height: 18,
                  marginRight: SPACING_0_75,
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: SPACING,
                marginBottom: SPACING,
              }}
            >
              <SwayText
                size="small"
                weight="bold"
                style={{
                  marginRight: 6,
                }}
              >
                {post.userTiktokInfo.followingCountString}
              </SwayText>
              <SwayText size="small">Following</SwayText>
              <SwayText
                size="small"
                weight="bold"
                style={{
                  marginLeft: SPACING_0_75,
                  marginRight: 6,
                }}
              >
                {post.userTiktokInfo.followerCountString}
              </SwayText>
              <SwayText size="small">Followers</SwayText>
              <SwayText
                size="small"
                weight="bold"
                style={{
                  marginLeft: SPACING_0_75,
                  marginRight: 6,
                }}
              >
                {post.userTiktokInfo.likeCountString}
              </SwayText>
              <SwayText size="small">Likes</SwayText>
            </div>
            <SwayText size="small">{post.userTiktokInfo.tiktokBio}</SwayText>
          </SwayCard>
        </div>
      </div>
    </Modal>
  );
};

export default PurchasePost;
