import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="50%"
      height="50%"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5996 22.5C18.1225 22.5 22.5996 18.0228 22.5996 12.5C22.5996 6.97715 18.1225 2.5 12.5996 2.5C7.07676 2.5 2.59961 6.97715 2.59961 12.5C2.59961 18.0228 7.07676 22.5 12.5996 22.5Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5996 6.5V12.5L16.5996 14.5"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
