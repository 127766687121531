import { ColumnsType } from "antd/lib/table";
import { DefaultRecordType } from "rc-table/lib/interface";
import moment from "moment";
import Config from "config";
import React from "react";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
import StatusMapping from "helper/v2-helpers/StatusMapping";
import Posts from "modules/admin/urgent-tables/Post";
import numberFormatter from "helper/numberFormatter";
export default (sortInfo): ColumnsType<DefaultRecordType> => {
  return [
    {
      sorter: true,
      title: "Brand",
      dataIndex: "name",
      key: "name",
      sortOrder: sortInfo.columnKey === "name" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      sorter: false,
      title: "Retailer count",
      dataIndex: "retailerCount",
      key: "retailerCount",
      sortOrder: sortInfo.columnKey === "retailerCount" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];
};
