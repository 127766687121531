import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row, Spin } from "antd";
import Title from "antd/lib/typography/Title";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface Props {
  type?: "section" | "page";
}

const Loading: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { type = "page" } = props;

  if (type === "section")
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin style={{ margin: "auto", minHeight: 300 }} size="large" />
      </div>
    );
  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loading;
