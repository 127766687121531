import { notification, Select } from "antd";
import React, { useState } from "react";
const { Option } = Select;
interface IProps {
  options: any[];
  selected: any[];
  onChange: (tadId: number, isSelected: boolean) => void;
  disabled?: boolean;
}
const TagSelectorNew: React.FC<IProps> = ({
  options,
  selected = [],
  onChange,
  disabled,
}) => {
  const selectedIds = selected?.map(
    (option) =>
      option.tagGroupType.name +
      ":" +
      option.name +
      ":" +
      option.id +
      ":" +
      option.tagGroupType.maxCount
  );
  const [val, setVal] = useState(selectedIds);
  const handleChange = (e: any[]) => {
    const isSelected = e.length > selected.length;
    const lastEl = e[e.length - 1];
    let tag;
    if (isSelected) {
      tag = e.find((id) => !selectedIds.includes(id));
    } else {
      tag = selectedIds.find((id) => !e.includes(id));
    }
    if (!lastEl) {
      setVal([]);
    } else {
      const [group, , , count] = lastEl.split(":");

      const filterd = e.filter((item) => item.startsWith(group));
      if (filterd.length > parseInt(count)) {
        return notification.error({
          message: "Maximum tag can be selected for this category is " + count,
        });
      }
    }
    const [, , tagId] = tag.split(":");
    onChange(parseInt(tagId), isSelected);
    setVal(e);
  };
  return (
    <Select
      mode="multiple"
      allowClear
      value={val}
      getPopupContainer={(trigger) => trigger.parentNode}
      style={{ width: "100%" }}
      disabled={disabled}
      placeholder="Search for Tag"
      filterOption={(input, option) => {
        return (
          (option.label as string).toLowerCase().indexOf(input.toLowerCase()) >=
          0
        );
      }}
      onChange={handleChange}
      optionLabelProp="label"
    >
      {options.map((option) => (
        <>
          <Option disabled value={option.name} label={option.name}>
            <span style={{ color: "red" }}>
              {option.name} (upto: {option.maxCount || "no limit"})
            </span>
          </Option>
          {option.children.map((op) => (
            <Option
              value={
                option.name +
                ":" +
                op.name +
                ":" +
                op.id +
                ":" +
                option.maxCount
              }
              label={op.name}
            >
              <div
                style={{ marginLeft: 20 }}
                className="demo-option-label-item"
              >
                {op.name}
              </div>
            </Option>
          ))}
        </>
      ))}
    </Select>
  );
};
export default TagSelectorNew;
