import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  muiTableLoading: {
    '& *': {
      cursor: 'progress !important',
    },
  },
  rightAlignTable: {
    '& th:last-child span': {
      justifyContent: 'flex-end',
    },
    '& td:last-child': {
      textAlign: 'right',
    },
    '& tr': {
      cursor: 'pointer',
    },
    // '& td': {
    //   padding: '0px 24px 0px 16px',
    // },
  },
  label: {
    margin: 0,
    fontSize: 13,
    color: 'rgba(0,0,0,0.87)',
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  pending: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
}));

export default useStyles;
