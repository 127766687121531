const pageName = {
  "/merchant/content": "Context Page",
  "/merchant/purchases": "Purchase Page",
  "/merchant/billing": "Billing Page",
};

const getPageNameFromRoute = (route: string) => {
  console.log(route);
  return pageName[route] || "";
};

export default getPageNameFromRoute;
