import React from "react";
import SwayText from "../components/SwayText";
import { SPACING, SPACING_2 } from "../constants";
import DashboardBase from "../DashboardBase/DashboardBase";
import useColors from "../hooks/useColors";
import MerchantBaseProvider from "../MerchantBaseProvider";

interface IProps {
  title: string;
  children?: React.ReactNode;
}

export enum ViewKey {}

const SettingsBase: React.FC<IProps> = (props) => {
  const { title, children } = props;

  const colors = useColors();
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar={false}>
        {/* Basic content view with a title at the top to be shared between settings pages */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            padding: `${SPACING_2}px ${SPACING}px`,
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: colors.brandCardBorder,
          }}
        >
          <SwayText weight="bold">{title}</SwayText>
        </div>
        {children}
      </DashboardBase>
    </MerchantBaseProvider>
  );
};

export default SettingsBase;
