import * as React from "react";

const SwayInsightsSvg = (props: { active?: boolean }) => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13.8616V17.8616H4.5V13.8616H1Z"
      fill={props.active ? "var(--primary)" : "#fff"}
      stroke="black"
      stroke-width="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 7.86157V17.8616H12V7.86157H8.5Z"
      fill={props.active ? "var(--primary)" : "#fff"}
      stroke="black"
      stroke-width="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 1.86157V17.8616H19.5V1.86157H16Z"
      fill={props.active ? "var(--primary)" : "#fff"}
      stroke="black"
      stroke-width="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SwayInsightsSvg;
