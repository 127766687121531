import { Col, Row } from "antd";
import { formatCurrency } from "helper/locale.helper";
import numberFormatter from "helper/numberFormatter";
import React, { useEffect, useState } from "react";
import { IMerchantSidebarStats, IStatsService } from "types/stats.interface";
import SwayStatistic from "../components/SwayStatistic";
import SwayText from "../components/SwayText";
import { SPACING, SPACING_0_5, SPACING_3 } from "../constants";
import {
  StatsDollar,
  StatsEyeIcon,
  StatsHeartIcon,
  StatsReceipt,
  StatsTikTok,
} from "../icons";
import moment, { Moment } from "moment";
import { StatsService } from "services";
import SwaySelect, { Option } from "../components/SwaySelect";
import { formatAPIQuery, IFilter } from "helper/v2-helpers/formatAPIQuery";

interface IProps {}

export enum ViewKey {}

interface IStatsFilter {
  startDate?: string;
  endDate?: string;
}

const StatsSidebar: React.FC<IProps> = (props) => {
  const service: IStatsService = StatsService;
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment>();
  const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment>();
  const timeFrameOptions = [
    { title: "Today", value: "today" },
    // Hiding these options for launch to avoid posts vs purchases confusion
    // { title: "Yesterday", value: "yesterday" },
    // { title: "This Week", value: "week" },
    { title: "This Month", value: "month" },
    { title: "All Time", value: "all" },
  ];
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("allTime");
  const [stats, setStats] = useState<IMerchantSidebarStats>();
  const getStats = async () => {
    const rawFilter = filterFromSelectedValue();
    let filter: IFilter = {};
    if (rawFilter.startDate) {
      filter.startDate = rawFilter.startDate;
    }
    if (rawFilter.endDate) {
      filter.endDate = rawFilter.endDate;
    }
    const query = formatAPIQuery(filter);

    // To hit the actual API for stats
    setLoading(true);
    const { error, stats: serverStats } = await service.getSidebarStats(query);
    setLoading(false);
    if (error) {
      // Retry here?
      return;
    }
    setStats(serverStats);
  };

  const filterFromSelectedValue: () => IStatsFilter = () => {
    const now = moment();
    switch (selectedTimeFrame) {
      case "today":
        return { startDate: now.subtract(1, "day").format() };
      case "yesterday":
        return {
          startDate: now.subtract(1, "day").format(),
          endDate: now.subtract(1, "day").format(),
        };
      case "week":
        return { startDate: now.subtract(1, "week").format() };
      case "month":
        return { startDate: now.subtract(1, "month").format() };
      default:
        return {};
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    getStats();
  }, [selectedTimeFrame]);

  return (
    <div style={{ padding: `${SPACING}px 0` }}>
      {stats ? (
        <>
          <Row
            style={{
              width: "100%",
              alignItems: "center",
              marginBottom: SPACING,
              marginLeft: 0,
              marginRight: 0,
            }}
            gutter={SPACING_0_5}
          >
            {/* Title and date filter */}
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <SwayText
                  weight="bold"
                  style={{
                    textAlign: "left",
                    fontSize: 16,
                  }}
                >
                  Analytics
                </SwayText>
                <div
                  style={{
                    flexGrow: 1,
                    flexBasis: SPACING_3,
                  }}
                />
                {/* 
                temporary remove
                <SwaySelect
                  defaultValue="today"
                  onChange={(value) => {
                    const option = value as string;
                    if (option) {
                      setSelectedTimeFrame(option);
                    }
                  }}
                >
                  {timeFrameOptions.map((o) => (
                    <Option value={o.value} key={o.value}>
                      {o.title}
                    </Option>
                  ))}
                </SwaySelect> */}
              </div>
            </Col>
          </Row>
          <Row
            style={{ width: "100%", marginLeft: 0, marginRight: 0 }}
            gutter={[SPACING_0_5, SPACING_0_5]}
          >
            {/* Daily cost */}
            <Col span={24}>
              <SwayStatistic
                icon={<StatsDollar />}
                titleString="Your Cost"
                valueString={formatCurrency(stats.balanceDue)}
                helperString="Autopays daily"
              />
            </Col>
            {/* Posts and purchases */}
            <Col span={12}>
              <SwayStatistic
                icon={<StatsTikTok />}
                titleString="TikTok Posts"
                valueString={numberFormatter(stats.tiktokPostCount)}
              />
            </Col>
            <Col span={12}>
              <SwayStatistic
                icon={<StatsReceipt />}
                titleString="Purchases"
                valueString={numberFormatter(stats.purchaseCount)}
              />
            </Col>
            {/* Views and likes */}
            <Col span={12}>
              <SwayStatistic
                icon={<StatsEyeIcon loading={false} />}
                titleString="TikTok Views"
                valueString={numberFormatter(stats.tiktokViewCount)}
              />
            </Col>
            <Col span={12}>
              <SwayStatistic
                icon={<StatsHeartIcon loading={false} />}
                titleString="TikTok Likes"
                valueString={numberFormatter(stats.tiktokLikeCount)}
              />
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
};

export default StatsSidebar;
