import React from "react";

interface IProps {
  pricingTier: string;
}

export enum ViewKey {}

const PricingTierIcon: React.FC<IProps> = (props) => {
  const { pricingTier } = props;
  const tierColors = () => {
    let coinColor: string, shineColor: string, shadowColor: string;
    switch (pricingTier) {
      case "bronze":
        coinColor = "#CE974A";
        shineColor = "#ECBC78";
        shadowColor = "#793E03";
        break;
      case "silver":
        coinColor = "#D0D0D0";
        shineColor = "#EDEDED";
        shadowColor = "#5C5C5C";
        break;
      case "gold":
        coinColor = "#BFAC4C";
        shineColor = "#D4C765";
        shadowColor = "#8E7A1B";
        break;
    }

    return { coinColor, shineColor, shadowColor };
  };

  const colors = tierColors();
  if (!colors.coinColor) {
    return null;
  }
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.89999 0.5H11.1C15.5183 0.5 19.1 4.08172 19.1 8.5C19.1 12.9183 15.5183 16.5 11.1 16.5H7.89999V0.5Z"
        fill={colors.shadowColor}
      />
      <rect
        x="0.299988"
        y="0.5"
        width="16"
        height="16"
        rx="8"
        fill={colors.coinColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2951 8.21847L8.81573 16.4837C8.64522 16.4946 8.47325 16.5001 8.29999 16.5001C6.79103 16.5001 5.37965 16.0823 4.1752 15.3561L14.7107 3.71362C15.6565 4.97826 16.2368 6.53205 16.2951 8.21847Z"
        fill={colors.shineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.69999 8.49998C2.69999 11.5928 5.2072 14.1 8.29999 14.1C11.3928 14.1 13.9 11.5928 13.9 8.49998C13.9 5.40718 11.3928 2.89998 8.29999 2.89998C5.2072 2.89998 2.69999 5.40718 2.69999 8.49998ZM8.29999 2.09998C4.76537 2.09998 1.89999 4.96535 1.89999 8.49998C1.89999 12.0346 4.76537 14.9 8.29999 14.9C11.8346 14.9 14.7 12.0346 14.7 8.49998C14.7 4.96535 11.8346 2.09998 8.29999 2.09998Z"
        fill={colors.shadowColor}
      />
    </svg>
  );
};

export default PricingTierIcon;
