import { Typography } from "antd";
import React, { ComponentProps } from "react";

type Props = ComponentProps<typeof Link> & {
  children: React.ReactNode;
};
const { Text, Link } = Typography;
const SwayLink = (props: Props) => {
  return <Link {...props}></Link>;
};

export default SwayLink;
