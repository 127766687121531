import * as React from "react";
import useColors from "../hooks/useColors";
import { LoadableIconProps } from "./icons.interface";

interface IProps extends LoadableIconProps {}
function StatsMessageIcon(props: IProps) {
  const { loading } = props;
  const colors = useColors();

  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4167 6.70835C12.4187 7.47827 12.2388 8.23778 11.8917 8.92502C11.4801 9.74853 10.8474 10.4412 10.0644 10.9254C9.28136 11.4097 8.379 11.6663 7.45835 11.6667C6.68843 11.6687 5.92892 11.4888 5.24169 11.1417L1.91669 12.25L3.02502 8.92502C2.6779 8.23778 2.49801 7.47827 2.50002 6.70835C2.50038 5.78771 2.75704 4.88535 3.24127 4.10234C3.7255 3.31933 4.41817 2.6866 5.24169 2.27502C5.92892 1.92789 6.68843 1.74801 7.45835 1.75002H7.75002C8.96589 1.8171 10.1143 2.33029 10.9754 3.19135C11.8364 4.05241 12.3496 5.20081 12.4167 6.41668V6.70835Z"
        stroke={loading ? "clear" : "black"}
        fill={loading ? colors.inactiveButtonBg : "clear"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StatsMessageIcon;
