import { Breadcrumb, Layout, Menu, Tabs } from "antd";
import Users from "./Users";
import React from "react";
import { Header, Content, Footer } from "antd/lib/layout/layout";
import AdminBase from "modules/admin/admin-base";
import Posts from "./Post";
import Cashout from "./Cashout";
import { useParams } from "react-router-dom";
import ReceiptUpload from "./ReceiptUpload";
const { TabPane } = Tabs;

function callback(key) {
  const url =
    window.location.protocol +
    "//" +
    window.location.host +
    `/admin/urgent/${key}`; //change url
  window.history.replaceState({ path: url }, "", url);
}

const UrgentTables = () => {
  const { table } = useParams<any>();
  return (
    <AdminBase>
      <Tabs
        style={{ width: "100%" }}
        hideAdd
        defaultActiveKey={table}
        onChange={callback}
      >
        <TabPane tab="Cashouts" key="cashouts">
          <Cashout />
        </TabPane>
        <TabPane tab="Posts" key="posts">
          <Posts />
        </TabPane>
        <TabPane tab="Users" key="users">
          <Users />
        </TabPane>
        <TabPane tab="Receipt Uploaded" key="receipt_uploaded">
          <ReceiptUpload />
        </TabPane>
      </Tabs>
    </AdminBase>
  );
};
export default UrgentTables;
