import isSuccessResponse from "helper/v2-helpers/isSuccessResponse";
import httpClient from "./http-client";

class UserServiceV2 {
  getUserDetails = async (userId: number, query?: string) => {
    const res = await httpClient(`/admin/users/${userId}?${query}`, {
      apiVersion: "v2",
      auth: true,
    });
    return res.data;
  };
  getTransactionSummary = async (userId: number) => {
    const res = await httpClient(
      `/admin/users/${userId}/transactions/summary`,
      {
        apiVersion: "v2",
        auth: true,
      }
    );
    return res.data.summary;
  };
  getTransactions = async (userId: number) => {
    const res = await httpClient(`/admin/users/${userId}/transactions`, {
      apiVersion: "v2",
      auth: true,
    });
    return res.data.transactions;
  };
  getLinkedAccounts = async (userId: number) => {
    const res = await httpClient(`/admin/users/${userId}/linked-accounts`, {
      apiVersion: "v2",
      auth: true,
    });
    return res.data.linkedAccounts;
  };
  patchUser = async (userId: number, body: any) => {
    const response = await httpClient(`/admin/users/${userId}`, {
      apiVersion: "v2",
      method: "PATCH",
      body,
      auth: true,
    });
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return [undefined, response.user];
  };
  triggerApplicationStatusUnclear = async (userId) => {
    const response = await httpClient(
      `/admin/user/${userId}/applicationStatus/unclear`,
      {
        auth: true,
        apiVersion: "v2",
        method: "POST",
      }
    );
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return [undefined, response.message];
  };

  /**
   * getInstructionsViaText
   * Get onboarding instructions via text
   * @param phoneNoWithCountryCode
   */
  public async getOnboardingInstructionsViaText(
    shop: string,
    phoneNoWithCountryCode: string
  ): Promise<any> {
    try {
      const response = await httpClient(
        `/users/onboarding-instructions/phone/${phoneNoWithCountryCode}?shop=${shop}`,
        {
          apiVersion: "v2",
          method: "GET",
        }
      );

      return response;
    } catch (err) {
      return err;
    }
  }

  /**
   * getInstructionsViaText
   * Get onboarding instructions via email
   * @param email
   */
  public async getOnboardingInstructionsViaEmail(shop: string, email: string) {
    try {
      const response = await httpClient(
        `/users/onboarding-instructions/email/${email}`,
        {
          apiVersion: "v2",
          method: "GET",
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  /**
   * update single attribute for a user
   * not all the values are allowed to be updated by the API
   * @param userId
   * @param keyToUpdate
   * @param valueToUpdate
   */
  public updateUserAttribute = async (
    userId: number,
    keyToUpdate: string,
    valueToUpdate: any
  ) => {
    const response = await httpClient(`/admin/users/${userId}/${keyToUpdate}`, {
      method: "PUT",
      auth: true,
      apiVersion: "v2",
      body: {
        [keyToUpdate]: valueToUpdate,
      },
    });
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return [undefined, response.data.user];
  };

  /**
   * delete single attribute for a user
   * not all the values are allowed to be deleted by the API
   * @param userId
   * @param keyToUpdate
   * @param valueToUpdate
   */
  public deleteUserAttribute = async (userId: number, keyToUpdate: string) => {
    const response = await httpClient(`/admin/users/${userId}/${keyToUpdate}`, {
      method: "DELETE",
      auth: true,
      apiVersion: "v2",
    });
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return [undefined, response.data.user];
  };
  /**
   * delete linked account(tiktok or gmail) for indiviual users
   *
   */
  public deleteLinkedAccount = async (
    userId: string,
    platform: string,
    linkedAccountId: string
  ) => {
    const response = await httpClient(
      `/admin/users/${userId}/linked-accounts/${platform}/${linkedAccountId}`,
      {
        method: "DELETE",
        auth: true,
        apiVersion: "v2",
      }
    );
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return [undefined, response.data.user];
  };

  /* 
    Commets services for user
  */
  getUserComments = async (userId: number) => {
    const res = await httpClient(`/admin-users/me/users/${userId}/notes`, {
      apiVersion: "v2",
      auth: true,
    });
    return res.data;
  };

  saveUserComment = async (body: any, userId: number) => {
    const response = await httpClient(`/admin-users/me/users/${userId}/notes`, {
      auth: true,
      apiVersion: "v2",
      method: "POST",
      body,
    });
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return response.data;
  };

  editUserComment = async (body: any, commentId: number) => {
    const response = await httpClient(
      `/admin-users/me/users/notes/${commentId}`,
      {
        auth: true,
        apiVersion: "v2",
        method: "PUT",
        body,
      }
    );
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return response.data;
  };

  /* 
   Commets services for brand
 */
  getBrandComments = async (brandId: number) => {
    const res = await httpClient(`/admin-users/me/brands/${brandId}/notes`, {
      apiVersion: "v2",
      auth: true,
    });
    return res.data;
  };

  saveBrandComment = async (body: any, brandId: number) => {
    const response = await httpClient(
      `/admin-users/me/brands/${brandId}/notes`,
      {
        auth: true,
        apiVersion: "v2",
        method: "POST",
        body,
      }
    );
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return response.data;
  };

  editBrandComment = async (body: any, commentId: number) => {
    const response = await httpClient(
      `/admin-users/me/brands/notes/${commentId}`,
      {
        auth: true,
        apiVersion: "v2",
        method: "PUT",
        body,
      }
    );
    if (!isSuccessResponse(response)) {
      return [new Error(response.message), undefined];
    }
    return response.data;
  };
}

export default new UserServiceV2();
