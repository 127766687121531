import {
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Button,
  Link,
  TextField,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { formatCurrency } from "helper/locale.helper";
import CustomModal from "component/customModal";
import moment, { Moment } from "moment";
import React, { ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import customSnacker from "component/snackbar";

import httpClient from "services/http-client";
import useStyles from "modules/admin/styles/commonDetailsPageStyle";
import CheckIconCircle from "@material-ui/icons/CheckCircle";
import CloseIconCircle from "@material-ui/icons/Cancel";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import Loading from "component/loading";
import getTiktokEmbed from "helper/v2-helpers/getTiktokEmbed";
import { AdminContext } from "../admin-base";
import { Checkbox, DatePicker, Select, Tag } from "antd";
import tagsService from "services/tags.service";
import HandlePostStatus from "component/Post/HandlePostStatus";
import clsx from "clsx";
import TagSelector from "component/TagSelector/TagSelector";
import Config from "config";
import StatusMapping from "helper/v2-helpers/StatusMapping";
import {
  formatCentsToDollars,
  formatDollarsToCents,
} from "helper/v2-helpers/currency";
import { Link as RouterLink } from "react-router-dom";
import LineChart from "component/LineChart/LineChart";
import postService from "../../../services/post.service";
import purchaseService from "../../../services/purchase.service";
import { RECEIPT_STATUS } from "../../../types/post.interface";
import ReceiptModal from "../receipt-modal/ReceiptModal";
import ActivityTimeline from "../activity-timline/ActivityTimeline";
import { SPACING } from "constants/spaceing";
const { CheckableTag } = Tag;
interface IProps {
  purchaseId?: string;
  isMerchant?: boolean;
  purchaseUpdateCallback?: (purchase: any) => void;
}
type PostStatus = "APPROVED" | "REJECTED";
type transactionType = "CLOSED" | "REVIEWED";
const postDataSchema = {
  id: null as null | number,
  postStatus: null as null | PostStatus,
  cashbackEarned: "",
  cashbackClaimed: false,
  score: "LOW",
  rejectReason: "",
  shareCashbackClaimed: false,
  shareCashbackAmount: "",
  transactionType: "REVIEWED" as transactionType,
  receiptStatus: RECEIPT_STATUS.MISSING,
  receiptUrl: "",
};
const modalsSchema = {
  rejectModal: false,
  closeModal: false,
  verifyReceipt: false,
  verifyReceiptDisabled: false,
};
const statsScehma = { timeline: [], views: [], likes: [], comments: [] };
const PurchaseDetails: React.FC<IProps> = (props) => {
  const params = useParams<any>();
  const purchaseId = props.purchaseId ? props.purchaseId : params.purchaseId;
  const classes = useStyles();
  const [purchase, setPurchase] = React.useState(null);
  const [postStats, setPostStats] = React.useState(statsScehma);
  const [postData, setPostData] = React.useState(postDataSchema);
  const [modals, setModals] = React.useState(modalsSchema);
  const [loading, setLoading] = React.useState(false);
  const snackbar = customSnacker();

  const adminContext = React.useContext(AdminContext);
  const relativeUrl = props.isMerchant
    ? "/merchants/me/purchases"
    : "/admin/purchases";
  const fetchPurchase = async () => {
    const res = await httpClient(`${relativeUrl}/${purchaseId}`, {
      apiVersion: "v2",
      auth: true,
    });
    if (props.purchaseUpdateCallback) {
      props.purchaseUpdateCallback(res.data);
    }
    setPurchase(res.data);
    const purchase = res.data;
    const currentPost =
      purchase.posts.length === 1
        ? purchase.posts[0]
        : purchase.posts.find((post) => post.postStatus !== "REJECTED");
    if (currentPost && currentPost?.postStats?.length !== 0) {
      const postStats = {
        timeline: [],
        views: [],
        likes: [],
        comments: [],
      };
      const sortedStats = currentPost.postStats.sort(
        // @ts-ignore
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      for (const stats of sortedStats) {
        postStats.timeline.push(
          moment.utc(stats.createdAt).format("MM/DD hh:mm")
        );
        postStats.views.push(stats.viewCount);
        postStats.likes.push(stats.likeCount);
        postStats.comments.push(stats.commentCount);
      }
      setPostStats(postStats);
    }
    getTiktokEmbed({ purchase: res.data, setPurchase });
  };
  React.useEffect(() => {
    fetchPurchase();
  }, []);
  const approvedPost =
    purchase && purchase.posts.find((item) => item.postStatus === "CLOSED");
  const nonRejectedPost =
    purchase && purchase.posts.find((post) => post.postStatus !== "REJECTED");
  const handlePostSubmit = async (post = postData) => {
    setLoading(true);
    closeHandler();
    await httpClient(
      `${relativeUrl}/${purchaseId}/posts/${post.id}?transactionType=${post.transactionType}`,
      {
        apiVersion: "v2",
        auth: true,
        method: "POST",
        body: post,
      }
    );

    await fetchPurchase();
    setLoading(false);
  };

  /**
   * onSubmit handler for receipt verification
   * @param post
   */
  const handleReceiptVerification = async () => {
    setLoading(true);
    closeHandler();

    const [errorUpdatePurchase, updatedPurchase] =
      await purchaseService.updatePurchase(purchase.id, {
        totalPriceInCents: formatDollarsToCents(purchase.totalPrice),
        receiptUrl: purchase.receiptUrl,
        receiptStatus: purchase.receiptStatus,
      });

    // Backend sends an event on update post API for receipt verification with total price.
    // So it is required to update the purchase first and the update the post
    // const [errorUpdatePost, updatedPost] = await postService.updatePost(
    //   postData.id,
    //   {
    //     receiptUrl: postData.receiptUrl,
    //     receiptStatus: RECEIPT_STATUS.VERIFIED,
    //   }
    // );

    if (errorUpdatePurchase) {
      snackbar("Something went wrong", "error");
    }
    await fetchPurchase();
    setLoading(false);
  };
  const offerDetails = purchase?.offerDetails;
  const closeHandler = () => {
    // const tempPostData = { ...postDataSchema };
    // if (offer) {

    //   const cashback = (offer.percentageCashback * purchase.orderValue) / 100;
    //   const maxCashback = formatCentsToDollars(offer.maxCashbackInCents);
    //   tempPostData.cashbackEarned =
    //     cashback > maxCashback ? maxCashback.toFixed(2) : cashback.toFixed(2);
    // }
    setModals(modalsSchema);
    setPostData(postDataSchema);
  };

  const handlePurchaseChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPurchase({ ...purchase, [name]: value });
  };

  /**
   * onChange handler for receipt verified checkbox
   * @param e
   */
  const handleChangeReceiptStatus = (e) => {
    if (e.target.checked) {
      setPurchase({ ...purchase, receiptStatus: RECEIPT_STATUS.VERIFIED });
    } else {
      setPurchase({ ...purchase, receiptStatus: RECEIPT_STATUS.MISSING });
    }
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPostData({ ...postData, [name]: value });
  };
  const handleCloseDate = async (closedAt: Moment) => {
    setLoading(true);
    await httpClient(`/admin/posts/${nonRejectedPost.id}`, {
      apiVersion: "v2",
      auth: true,
      method: "PATCH",
      body: { closedAt: closedAt.utc().format() },
    });
    const updatePosts = purchase.posts.map((post) => {
      if (post.id === nonRejectedPost.id) {
        post.closedAt = closedAt.utc().format();
      }
      return post;
    });
    setLoading(false);
    setPurchase({ ...purchase, posts: updatePosts });
  };
  const handleClickPost = async (data) => {
    setLoading(true);
    await fetchPurchase();
    setLoading(false);
  };
  const handleChangeTag = async (
    postId: number,
    tagId: number,
    checked: boolean
  ) => {
    try {
      let res = null;
      setLoading(true);
      if (checked) {
        res = await tagsService.postAddTag(postId, tagId);
      } else {
        res = await tagsService.postDeleteTag(postId, tagId);
      }
      const posts = purchase.posts.map((post) => {
        if (post.id === postId) {
          return res.data.post;
        }
        return post;
      });
      setLoading(false);
      setPurchase({ ...purchase, posts });
    } catch (e) {}
  };

  return (
    purchase && (
      <div
        style={{
          marginTop: 20,
          width: "100%",
        }}
      >
        {loading && <Loading />}
        <CustomModal
          state={modals.closeModal}
          title="Close Post"
          closeHandler={closeHandler}
          actions={
            <Button
              disabled={postData?.cashbackEarned?.trim() === ""}
              color="primary"
              variant="contained"
              onClick={() => handlePostSubmit()}
            >
              SUBMIT
            </Button>
          }
        >
          <TextField
            placeholder="Cash Back Earned"
            label="Cash Back Earned"
            variant="outlined"
            fullWidth
            type="number"
            name="cashbackEarned"
            onChange={handleChange}
            value={postData.cashbackEarned}
          />
          <FormControl
            style={{ margin: "25px 0px 15px 2.5px" }}
            component="fieldset"
          >
            <FormLabel component="legend">Post Score</FormLabel>
            <RadioGroup
              name="score"
              value={postData.score}
              onChange={handleChange}
            >
              <FormControlLabel value="LOW" control={<Radio />} label="Low" />
              <FormControlLabel value="HIGH" control={<Radio />} label="High" />
            </RadioGroup>
          </FormControl>
        </CustomModal>
        <CustomModal
          state={modals.rejectModal}
          title="Reject Post"
          closeHandler={closeHandler}
          actions={
            <Button
              color="primary"
              variant="contained"
              onClick={() => handlePostSubmit()}
            >
              SUBMIT
            </Button>
          }
        >
          <TextField
            fullWidth
            label="Reject Reason"
            onChange={handleChange}
            name="rejectReason"
            value={postData.rejectReason}
            variant="outlined"
          />
        </CustomModal>

        {modals.verifyReceipt && (
          <ReceiptModal
            purchase={purchase}
            readOnly={modals.verifyReceiptDisabled}
            handleClose={closeHandler}
            handleSuccess={(data) => {
              setPurchase({
                ...purchase,
                receiptStatus: data.receiptStatus,
              });
              closeHandler();
            }}
          />
        )}
        <Paper>
          <Grid className={clsx(classes.purchaseItemsDiv)} container>
            <Grid item md={5}>
              <div className={classes.infoPaper}>
                {!props.isMerchant ? (
                  <Typography>
                    User:{" "}
                    <Link href={`/admin/users/${purchase.user.id}`}>
                      <b>{purchase.user.firstName || purchase.user.email}</b>
                    </Link>
                  </Typography>
                ) : (
                  <></>
                )}
                <Typography>
                  Purchase Date:{" "}
                  {moment(purchase.createdAt).format(
                    Config.DEFAULT_DATE_FORMAT
                  )}
                </Typography>

                {!props.isMerchant ? (
                  <Typography>
                    Purchase Status:{" "}
                    <Chip
                      className="chipStatusHeight"
                      label={
                        StatusMapping.find(
                          (status) => status.id === purchase.status
                        )?.name
                      }
                    />
                  </Typography>
                ) : (
                  <></>
                )}
                {props.isMerchant ? (
                  <>
                    <Typography>
                      Tiktok Username: {purchase.user.tiktokHandle}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
                <Typography>
                  Retailer:{" "}
                  <Link href={`/admin/retailers/${purchase.retailer.id}`}>
                    {purchase.retailer.name}
                  </Link>
                </Typography>
                <Typography>
                  Order Value:{" "}
                  {"$" + formatCentsToDollars(purchase.totalPriceInCents)}
                </Typography>
                {purchase?.lineItems?.length > 0 ? (
                  <Typography>
                    Line Items:
                    <ul>
                      {purchase.lineItems.map((item) => (
                        <li>
                          {item.name} - ${item.price}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                ) : (
                  <></>
                )}
                {!props.isMerchant ? (
                  <Typography>
                    Receipt Status:
                    {"    "}
                    {purchase.receiptStatus === RECEIPT_STATUS.UPLOADED ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setModals({
                            ...modals,
                            verifyReceipt: true,
                          });
                        }}
                      >
                        Verify receipt
                      </Button>
                    ) : (
                      <>
                        {purchase.receiptStatus}
                        {purchase.receiptStatus === RECEIPT_STATUS.VERIFIED && (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => {
                              setModals({
                                ...modals,
                                verifyReceipt: true,
                                verifyReceiptDisabled: true,
                              });
                            }}
                          >
                            View receipt
                          </Button>
                        )}
                      </>
                    )}
                  </Typography>
                ) : (
                  <></>
                )}

                <Typography>{offerDetails.title}</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <Typography style={{ marginBottom: SPACING }}>
                Offer History
              </Typography>
              <ActivityTimeline
                activities={purchase?.offerDetails?.activities}
                purchase={purchase}
              />
            </Grid>
            <Grid item md={4}>
              <Typography>Post Stats</Typography>
              <LineChart
                datasets={[
                  {
                    label: "Views",
                    data: postStats.views,
                    backgroundColor: "#8CE41D",
                    borderColor: "#8CE41D",
                  },
                  {
                    label: "Likes",
                    data: postStats.likes,
                    backgroundColor: "rgba(54, 162, 235)",
                    borderColor: "rgba(54, 162, 235)",
                  },
                  {
                    label: "Comments",
                    data: postStats.comments,
                    backgroundColor: "rgba(75, 192, 192)",
                    borderColor: "rgba(75, 192, 192)",
                  },
                ]}
                labels={postStats.timeline}
              />
            </Grid>
          </Grid>

          <Typography className={classes.heading}>
            Posts{" "}
            {purchase.posts.map((post: any, i) => (
              <Tooltip title="Open in a new tab" placement="top" arrow>
                <Link
                  href={post.postUrl}
                  target="_blank"
                  style={{ marginRight: 5 }}
                >
                  <OpenInNewIcon />
                  {purchase.posts.length > 1 && <sup>{post.postStatus}</sup>}
                </Link>
              </Tooltip>
            ))}
          </Typography>
          {Boolean(purchase.posts.length) ? (
            purchase.posts.map((post: any, i) => (
              <>
                {!props.isMerchant ? (
                  <div className={classes.tagDiv}>
                    <Typography style={{ marginRight: 16, fontSize: "16px" }}>
                      Post Tags:
                    </Typography>
                    <TagSelector
                      options={adminContext.postTags}
                      selected={post.tags}
                      onChange={(tagId, isSelected) =>
                        handleChangeTag(post.id, tagId, isSelected)
                      }
                      disabled={props.isMerchant}
                    />
                  </div>
                ) : (
                  <></>
                )}

                <Grid container key={"post-preview"}>
                  {post.embed ? (
                    <Grid item xs={6}>
                      <div dangerouslySetInnerHTML={{ __html: post.embed }} />
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <CircularProgress />
                    </Grid>
                  )}

                  <Grid item xs={6} key={"post-stats"}>
                    <Paper elevation={0} style={{ marginTop: 19, padding: 15 }}>
                      <div
                        className={clsx(classes.infoPaper, classes.postItems)}
                      >
                        <Typography
                          className={classes.subHeading}
                          style={{ marginTop: 0 }}
                        >
                          <span>Post Submission Date</span>
                          <Chip
                            style={{ marginLeft: 4 }}
                            label={moment(post.createdAt).format("DD MMM")}
                          />
                        </Typography>
                        {!props.isMerchant ? (
                          <>
                            <Typography className={classes.subHeading}>
                              <span>Post Status</span>
                              <HandlePostStatus
                                post={{ ...post, purchase }}
                                isMerchant={props.isMerchant}
                                updateCallback={handleClickPost}
                              />
                            </Typography>

                            <Typography>
                              <span>Expiration Date:</span>
                              {
                                <DatePicker
                                  disabled={
                                    props.isMerchant ||
                                    post.postStatus === "REJECTED"
                                  }
                                  allowClear={false}
                                  size="small"
                                  value={moment(post.closedAt)}
                                  onChange={handleCloseDate}
                                />
                              }
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                        {!props.isMerchant ? (
                          <Typography>
                            <span>Post Extended:</span>
                            {post.isPostExtended ? "Yes" : "No"}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Typography>
                          <span>Hashtags:</span> {post.hashtags}
                        </Typography>
                        <Typography>
                          <span>Mentions:</span> {post.mentions}
                        </Typography>
                        <Typography>
                          <span>Earnings:</span>
                          <b>
                            {post.cashbackEarned
                              ? formatCurrency(post.cashbackEarned)
                              : "Pending"}
                          </b>
                        </Typography>

                        {!props.isMerchant ? (
                          <Typography>
                            <span>Score:</span>
                            <b>{post.score ? post.score : "Pending"}</b>
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* {post.postStatus !== "SUBMITTED" ? (
                        post.postStatus === "APPROVED" && !props.isMerchant ? (
                          purchase.status === "cashback_claimed" ? (
                            <Chip
                              className="chipStatusHeight"
                              label={`Post Closed`}
                            />
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => handleClickPost("CLOSED", post.id)}
                              style={{
                                background: "var(--success)",
                                marginRight: 16,
                              }}
                              variant="contained"
                            >
                              Close Post
                            </Button>
                          )
                        ) : (
                          <Chip
                            className="chipStatusHeight"
                            label={post.postStatus}
                          />
                        )
                      ) : props.isMerchant ? (
                        <Chip className="chipStatusHeight" label="PENDING" />
                      ) : (
                        <>
                          <Button
                            color="primary"
                            onClick={() => handleClickPost("APPROVED", post.id)}
                            style={{
                              background: "var(--success)",
                              marginRight: 16,
                            }}
                            variant="contained"
                          >
                            APPROVE
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleClickPost("REJECTED", post.id)}
                            style={{ background: "var(--danger)" }}
                          >
                            REJECT
                          </Button>
                        </>
                      )} */}
                    </Paper>
                  </Grid>
                </Grid>
              </>
            ))
          ) : (
            <Typography
              className={classes.heading}
              style={{ textAlign: "center" }}
            >
              No post has been submitted yet.
            </Typography>
          )}
        </Paper>
      </div>
    )
  );
};
export default PurchaseDetails;
