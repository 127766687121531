import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      // style={{ padding: 0 }}
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{ padding: "4px 4px" }}
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ fontSize: "1.65rem" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
interface Props {
  title?: string | JSX.Element;
  state: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  display?: boolean;
  actions?: JSX.Element[] | JSX.Element;
  hideHeader?: boolean;
  fullScreen?: boolean;
  dialogStyle?: React.CSSProperties;
  closeHandler: () => void;
  children?: React.ReactNode;
}
const CustomModal: React.FC<Props> = ({ dialogStyle = {}, ...props }) => {
  return (
    <Dialog
      fullWidth
      PaperProps={{
        style: { margin: props.fullScreen ? "0px" : "0px 15px", width: "100%" },
      }}
      style={{ ...dialogStyle }}
      maxWidth={props.maxWidth || "sm"}
      onClose={props.closeHandler}
      aria-labelledby="customized-dialog-title"
      open={props.state}
      fullScreen={props.fullScreen}
    >
      {!props.hideHeader && (
        <DialogTitle id="customized-dialog-title" onClose={props.closeHandler}>
          {props.title || ""}
        </DialogTitle>
      )}
      <DialogContent dividers={props.actions ? true : false}>
        {props.children}
      </DialogContent>
      {props.actions && <DialogActions>{props.actions}</DialogActions>}
    </Dialog>
  );
};
export default CustomModal;
