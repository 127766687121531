import RequestedRetailersTableComponent from "component/admin-v2-tables/requested-retailers";
import AdminBase from "modules/admin/admin-base";
import React from "react";
const RequestRetailers: React.FC = () => {
  return (
    <AdminBase>
      <RequestedRetailersTableComponent
        title="Requested Retailers"
        localStorageKey="admin_requested_retailers"
      />
    </AdminBase>
  );
};

export default RequestRetailers;
