import { Timeline } from "antd";
import { DATE_FORMAT } from "component/order-muitable";
import { formatCurrency } from "helper/locale.helper";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
import moment from "moment";
import React from "react";
import { ACTIVITY_TYPE } from "types/activity.interface";
import { POST_STATUS } from "types/post.interface";
import { IPurchase } from "types/purchase.interface";

type Props = {
  activities: any[];
  purchase?: IPurchase;
};

export default function ActivityTimeline({ activities, purchase }: Props) {
  return (
    <Timeline style={{ paddingBottom: 0 }}>
      {activities?.map((item) => (
        <Timeline.Item key={item.id}>
          <p>{item.activityType.split("_").join(" ")}</p>
          {item.activityType === ACTIVITY_TYPE.POST_CLOSED && (
            <p>
              Earnings:{" "}
              <b>
                {formatCurrency(
                  formatCentsToDollars(
                    purchase?.posts?.find(
                      (post) => post.postStatus === POST_STATUS.CLOSED
                    )?.cashbackEarnedInCents
                  )
                )}
              </b>
            </p>
          )}
          {item.adminUser && (
            <p>
              Admin: <b>{item.adminUser.email}</b>
            </p>
          )}
          <p>{moment(item.createdAt).format(DATE_FORMAT + " HH:mm")}</p>
        </Timeline.Item>
      ))}

      {/* <Timeline.Item color="red">Post Rejected</Timeline.Item> */}
    </Timeline>
  );
}
