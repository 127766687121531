import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="50%"
      height="50%"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.900391"
        y="0.5"
        width="40"
        height="40"
        rx="20"
        fill="#DDF7BB"
      />
      <g clip-path="url(#clip0_184_54888)">
        <path
          d="M16.6434 10C15.4791 10 14.5352 10.9433 14.5352 12.107V22.6421V22.5765C14.5352 20.7509 13.0553 19.2709 11.2297 19.2709V19.2709C10.7733 19.2709 10.4033 19.6412 10.4033 20.0976C10.4033 21.5728 10.4033 22.5849 10.4033 24.2575C10.2717 29.0176 14.6719 31 18.7515 31H22.9678C27.625 31 31.4004 29.8253 31.4004 24.2575V17.8495C31.4004 16.6858 30.4565 15.7425 29.2922 15.7425C28.1279 15.7425 27.1841 16.6858 27.1841 17.8495C27.1841 16.6858 26.2403 15.7425 25.076 15.7425C23.9117 15.7425 22.9678 16.6858 22.9678 17.8495C22.9678 16.6858 22.024 15.7425 20.8597 15.7425C19.6954 15.7425 18.7515 16.6858 18.7515 17.8495V19V12.107C18.7515 10.9433 17.8077 10 16.6434 10Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_184_54888">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8.90039 8.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
