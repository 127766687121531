import { DeleteOutline } from "@material-ui/icons";
import { PaymentMethod } from "@stripe/stripe-js";
import { Col, Row, Skeleton } from "antd";
import CreditCardIcon1 from "assets/images/credit-card-icon-1.png";
import React from "react";
import getCardImage from "../../../../../../helper/getCardImage";
import SwayCard from "../../../../components/SwayCard";
import { Visa } from "react-pay-icons";
import SwayText from "../../../../components/SwayText";
interface Props {
  onClickDeleteIconHandler: (paymentMethodId) => Promise<void>;
  loading: boolean;
  paymentMethods: PaymentMethod[];
  setPaymentMethods: (retailer: React.SetStateAction<PaymentMethod[]>) => void;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const PaymentMethodList: React.FC<Props> = (props) => {
  const { onClickDeleteIconHandler } = props;
  const { paymentMethods, setPaymentMethods } = props;

  const onClickDeleteIcon = (paymentMethodId) => {
    onClickDeleteIconHandler(paymentMethodId);
  };

  return (
    <>
      <Row style={{ flexDirection: "column" }}>
        {paymentMethods.map((paymentMethod) => (
          <SwayCard
            key={paymentMethod.id}
            style={{ width: 400, marginBottom: 20 }}
          >
            <Row
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col xs={4}>
                <div style={{ width: 40 }}>
                  {getCardImage(paymentMethod.card.brand)}
                </div>
              </Col>

              <Col
                xs={18}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div>
                  <SwayText size="small">
                    **** **** **** {paymentMethod.card.last4}
                  </SwayText>
                </div>
                <div>
                  <SwayText size="small">
                    Exp. {paymentMethod.card.exp_month}/
                    {paymentMethod.card.exp_year}
                  </SwayText>
                </div>
              </Col>

              <Col xs={2}>
                <DeleteOutline
                  style={{ cursor: "pointer" }}
                  onClick={(e) => onClickDeleteIcon(paymentMethod.id)}
                />
              </Col>
            </Row>
          </SwayCard>
        ))}
      </Row>
    </>
  );
};

export default PaymentMethodList;
