import { POST_STATUS } from "component/Post/HandlePostStatus";
import moment, { Moment } from "moment";
import { ICashout } from "types/cashout.interface";
import httpClient from "./http-client";
import { POST_OPERATIONS } from "../modules/admin/urgent-tables/Post";

class UrgentTableService {
  //will update this API to later to work with backend pagination
  getUrgentUsers = async () => {
    try {
      const res = await httpClient(
        `/admin/users?&skip=0&limit=100&applicationStatus=pending&sortBy=asc(createdAt)`,
        {
          auth: true,
          method: "GET",
          apiVersion: "v2",
          headers: {},
        }
      );
      return [undefined, res.data.users];
    } catch (e) {
      return [e, undefined];
    }
  };
  //will update this API to later to work with backend pagination
  getUrgentPosts = async (defaultSelectedStatus: POST_OPERATIONS) => {
    try {
      const path =
        defaultSelectedStatus === POST_OPERATIONS.APPROVAL_PENDING
          ? `postStatus=SUBMITTED`
          : `postStatus=APPROVED&postFeature=offer&closedAt=${moment
              .utc()
              .format()}`;
      const res = await httpClient(
        `/admin/posts?skip=0&limit=50&${path}&addRelations=true`,
        {
          auth: true,
          method: "GET",
          apiVersion: "v2",
          headers: {},
        }
      );
      return [undefined, res.data.posts];
    } catch (e) {
      return [e, undefined];
    }
  };
  getUrgentCashouts = async (
    dates: [Moment, Moment],
    selectedCashout: boolean
  ) => {
    try {
      let path = `/admin/cashouts?isCashoutProcessed=${selectedCashout}`;
      if (dates) {
        path += `&startDate=${dates[0]
          .utc()
          .startOf("day")
          .format()}&endDate=${dates[1].utc().endOf("day").format()}`;
      }
      const res = await httpClient(path, {
        auth: true,
        method: "GET",
        apiVersion: "v2",
      });
      return [undefined, res.data.cashouts];
    } catch (e) {
      return [e, undefined];
    }
  };
  patchCashout = async (
    cashoutId: number,
    cashoutPatchData: Partial<ICashout>
  ) => {
    try {
      const res = await httpClient(`/admin/cashouts/${cashoutId}`, {
        auth: true,
        method: "PATCH",
        apiVersion: "v2",
        body: cashoutPatchData,
      });
      return [undefined, res.data.cashout as ICashout];
    } catch (e) {
      return [e, undefined];
    }
  };
  getPurchaseReceipts = async (query: string) => {
    try {
      const res = await httpClient(
        `/admin/purchases?skip=0&limit=50&receiptStatus=${JSON.stringify([
          "UPLOADED",
        ])}&${query}`,
        {
          auth: true,
          method: "GET",
          apiVersion: "v2",
          headers: {},
        }
      );
      return [undefined, res.data.purchases];
    } catch (e) {
      return [e, undefined];
    }
  };
}

export default new UrgentTableService();
