import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Alert, Button, Row, Typography } from "antd";
import { useState } from "react";
import SwayButton from "../../../../components/SwayButton";
import Config from "config";

const { Text } = Typography;

const hostUrl = Config.HOST_URL;
const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    console.log("handle submit called");
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${hostUrl}/merchant/payments/add-payment/status`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form
      style={{ display: "flex", flexDirection: "column", margin: "auto" }}
      onSubmit={handleSubmit}
    >
      <PaymentElement />

      <SwayButton
        classType="primary"
        size="large"
        style={{ margin: "auto" }}
        htmlType="submit"
        disabled={!stripe}
      >
        Submit
      </SwayButton>
      {errorMessage ? (
        <Alert type="error" message={errorMessage}></Alert>
      ) : (
        <></>
      )}

      <Alert
        style={{ fontStyle: "normal", maxWidth: 400, marginTop: 20 }}
        message=" Payments will be charged to the posted card daily for balances over $100
        and weekly on Fridays for anything less than $100"
        type="info"
        showIcon
      />
    </form>
  );
};

export default StripeForm;
