import { Row } from "antd";
import SwayText from "../../../components/SwayText";
import { SPACING } from "../../../constants";
import SmartMoneyIcon from "assets/images/smart-money.png";
const PurchaseListEmptyState: React.FC = () => {
  return (
    <>
      <Row
        style={{
          width: "100%",
          minHeight: 500,
          justifyContent: "center",
          padding: `${SPACING}px ${SPACING}px 0`,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={SmartMoneyIcon}
          style={{
            width: 57,
          }}
        />
        <SwayText size="tiny" type="body" style={{ fontWeight: 700 }}>
          Invoices will show here
        </SwayText>
      </Row>
    </>
  );
};

export default PurchaseListEmptyState;
