export enum ACTIVITY_TYPE {
  NONE = "NONE",
  OFFER_VIEWED = "OFFER_VIEWED",
  OFFER_CLAIMED = "OFFER_CLAIMED",
  POST_APPROVED = "POST_APPROVED",
  POST_REJECTED = "POST_REJECTED",
  RECEIPT_APPROVED = "RECEIPT_APPROVED",
  POST_SUBMITTED = "POST_SUBMITTED",
  RECEIPT_SUBMITTED = "RECEIPT_SUBMITTED",
  RECEIPT_REJECTED = "RECEIPT_REJECTED",
  OFFER_EXPIRED = "OFFER_EXPIRED",
  OFFER_COMPLETED = "OFFER_COMPLETED",
  OFFER_LOCKED = "OFFER_LOCKED",
  POST_CLOSED = "POST_CLOSED",
}
