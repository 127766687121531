import { ColumnsType } from "antd/lib/table";
import { DefaultRecordType } from "rc-table/lib/interface";
import moment from "moment";
import Config from "config";
import React from "react";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
import StatusMapping from "helper/v2-helpers/StatusMapping";
import Posts from "modules/admin/urgent-tables/Post";
import numberFormatter from "helper/numberFormatter";
import { onboardingProgressList } from "./retailer-table-ant";
export default (sortInfo): ColumnsType<DefaultRecordType> => {
  return [
    {
      sorter: true,
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sortOrder: sortInfo.columnKey === "createdAt" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value, data) =>
        moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      sorter: true,
      title: "Retailer Name",
      dataIndex: "name",
      key: "name",
      sortOrder: sortInfo.columnKey === "name" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      sorter: false,
      title: "Brand Name",
      dataIndex: "brandRetailerAssociations",
      key: "brandRetailerAssociations",
      sortOrder:
        sortInfo.columnKey === "brandRetailerAssociations" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (brandRetailerAssociations) => {
        return brandRetailerAssociations
          .map((item) => item.brand.name)
          .join(", ");
      },
    },
    {
      sorter: true,
      title: "Active",
      dataIndex: "isEnabled",
      key: "isEnabled",
      sortOrder: sortInfo.columnKey === "isEnabled" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (isActive) => {
        return isActive ? "Yes" : "No";
      },
    },
    {
      sorter: false,
      title: "Onboarding Progress",
      dataIndex: "onboardingProgress",
      key: "onboardingProgress",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (onboardingProgress) => {
        let tag = "Not Started";
        onboardingProgressList.forEach((item) => {
          if (onboardingProgress && onboardingProgress[item.id]) {
            tag = item.name;
          }
        });
        return <Tag color="blue">{tag}</Tag>;
      },
    },
    {
      sorter: false,
      title: "Post Count",
      dataIndex: "postCount",
      key: "postCount",
      sortOrder: sortInfo.columnKey === "postCount" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      sorter: false,
      title: "Retailer Spend",
      dataIndex: "retailerSpend",
      key: "retailerSpend",
      sortOrder: sortInfo.columnKey === "retailerSpend" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      sorter: false,
      title: "Purchase Value",
      dataIndex: "purchaseValue",
      key: "purchaseValue",
      sortOrder: sortInfo.columnKey === "purchaseValue" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      sorter: false,
      title: "Tags",
      dataIndex: "retailerTag",
      key: "retailerTag",
      sortOrder: sortInfo.columnKey === "retailerTag" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (val) => {
        return val.map((item) => (
          <Tag color="blue">{item.retailerTag.name}</Tag>
        ));
      },
    },
  ];
};
