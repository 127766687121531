import React from "react";
import AddPaymentStatus from "../billing-page/components/Payment/AddPaymentStatus/AddPaymentStatus";
import MerchantBaseProvider from "../MerchantBaseProvider";

const PaymentStatusPage: React.FC = () => {
  return (
    <MerchantBaseProvider>
      <AddPaymentStatus></AddPaymentStatus>
    </MerchantBaseProvider>
  );
};
export default PaymentStatusPage;
