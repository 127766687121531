import { Form, message } from "antd";
import { SPACING, SPACING_1_5 } from "../constants";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import authService from "services/auth.service";
import SwayButton from "../components/SwayButton";
import SwayCard from "../components/SwayCard";
import SwayLink from "../components/SwayLink";
import SwayText from "../components/SwayText";
import SwayTextInput from "../components/SwayTextInput";
import OnboardingBase from "./OnboardingBase";
import useOnboardingBaseStyles from "./useOnboardingBaseStyles";
import { useIntercom } from "react-use-intercom";

interface IProps {}

const LoginPage: React.FC<IProps> = (props) => {
  const classes = useOnboardingBaseStyles();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onFinish = async (formData) => {
    setLoading(true);
    const { authInfo, error } = await authService.loginMerchant(formData);
    setLoading(false);
    if (!error) {
      history.push("/merchant/content");
    } else {
      message.error(error.message);
    }
  };
  return (
    <OnboardingBase>
      <div className={classes.header}>
        {/* <SwayText
          type="title"
          style={{
            display: "inline-block",
            marginRight: SPACING,
            marginBottom: 0,
          }}
        >
          New to Swaypay?
        </SwayText> */}
        <SwayButton
          onClick={() =>
            window.open(
              "https://www.swaypayit.com/brand-getting-started",
              "_blank"
            )
          }
          classType="secondary"
        >
          Learn More
        </SwayButton>
      </div>
      <SwayCard className={classes.card}>
        <SwayText
          type="title"
          style={{ marginBottom: SPACING_1_5, textAlign: "center" }}
        >
          Log In
        </SwayText>
        <Form
          name="basic"
          style={{ display: "flex", flexDirection: "column" }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <SwayTextInput placeholder="Your email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <SwayTextInput placeholder="Password" type="password" />
          </Form.Item>

          <SwayButton
            htmlType="submit"
            loading={loading}
            disabled={loading}
            style={{ height: 49 }}
          >
            Login
          </SwayButton>
          <SwayButton
            type="link"
            classType={null}
            onClick={() => history.push("/merchant/forgot-password")}
            style={{ textAlign: "center", marginTop: SPACING }}
          >
            Forgot Password
          </SwayButton>
        </Form>
      </SwayCard>
      <div />
    </OnboardingBase>
  );
};
export default LoginPage;
