import { ColumnsType } from "antd/lib/table";
import { DefaultRecordType } from "rc-table/lib/interface";
import moment from "moment";
import Config from "config";
import React from "react";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import { formatCentsToDollars } from "helper/v2-helpers/currency";
import StatusMapping from "helper/v2-helpers/StatusMapping";
import Posts from "modules/admin/urgent-tables/Post";
import numberFormatter from "helper/numberFormatter";
import ReceiptStatusMapping from "helper/v2-helpers/ReceiptStatusMapping";
export default (sortInfo): ColumnsType<DefaultRecordType> => {
  return [
    {
      sorter: true,
      title: "Purchase Date",
      filterSearch: true,
      dataIndex: "createdAt",
      key: "createdAt",
      sortOrder: sortInfo.columnKey === "createdAt" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value, data) =>
        moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      // sorter: true,
      title: "Post Submission Date",
      filterSearch: true,
      dataIndex: "posts",
      key: "postAt",
      sortOrder: sortInfo.columnKey === "postAt" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (posts, data) => {
        if (posts.length === 0) return;
        if (posts.length === 1)
          moment.utc(posts[0].createdAt).format(Config.DEFAULT_DATE_FORMAT);
        return moment
          .utc(posts.find((post) => post.postStatus !== "REJECTED")?.createdAt)
          .format(Config.DEFAULT_DATE_FORMAT);
      },
    },
    {
      sorter: true,
      title: "Brand",
      dataIndex: ["retailer", "name"],
      key: "retailer.name",
      sortOrder: sortInfo.columnKey === "retailer.name" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      sorter: true,
      title: "Order Value",
      dataIndex: "totalPriceInCents",
      key: "totalPriceInCents",
      sortOrder: sortInfo.columnKey === "totalPriceInCents" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (subtotalPriceInCents) =>
        "$" + formatCentsToDollars(subtotalPriceInCents),
    },
    {
      sorter: true,
      title: "First Name",
      dataIndex: ["user", "firstName"],
      key: "user.firstName",
      sortOrder: sortInfo.columnKey === "user.firstName" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      sorter: true,
      title: "Email",
      dataIndex: ["user", "email"],
      key: "user.email",
      sortOrder: sortInfo.columnKey === "user.email" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      sorter: true,
      title: "Phone",
      dataIndex: ["user", "phoneNo"],
      key: "user.phoneNo",
      sortOrder: sortInfo.columnKey === "user.phoneNo" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      sorter: true,
      title: "Post Status", // need to check product as to change to post status
      dataIndex: "status",
      key: "status",
      sortOrder: sortInfo.columnKey === "status" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value) => {
        let chipText = StatusMapping.find(
          (status) => status.id === value
        )?.name;
        return <Tag>{chipText}</Tag>;
      },
    },
    {
      sorter: true,
      title: "Receipt Status",
      dataIndex: "receiptStatus",
      key: "receiptStatus",
      sortOrder: sortInfo.columnKey === "receiptStatus" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value) => {
        let chipText = ReceiptStatusMapping.find(
          (status) => status.id === value
        )?.name;
        return <Tag>{chipText}</Tag>;
      },
    },
    {
      // sorter: true,
      title: "Post Views",
      dataIndex: "posts",
      key: "postViews",
      sortOrder: sortInfo.columnKey === "postViews" && sortInfo.order,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (posts) => {
        if (posts.length === 0) return;
        if (posts.length === 1 && posts[0].postStats.length !== 0) {
          return numberFormatter(
            Math.max(...posts[0].postStats.map((stats) => stats.viewCount))
          );
        }
        return numberFormatter(
          Math.max(
            ...(posts
              .find((post) => post.postStatus !== "REJECTED")
              ?.postStats?.map((stats) => stats.viewCount) || [])
          )
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "posts",
      key: "posts",
      render: (posts) => {
        return posts.map((post) =>
          post.tags.map((tag) => <Tag color="blue">{tag.name}</Tag>)
        );
      },
    },
  ];
};
