import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btns: {
    "& > *": {
      marginRight: 8,
    },
  },
}));

export default useStyles;
