import isSuccessResponse from "../helper/v2-helpers/isSuccessResponse";
import {
  IMerchantTransactionsResponse,
  IMerchantTransactionsResponseJson,
  ITransactionService,
} from "../types/transaction.interface";
import httpClientV2 from "./http-clientV2";

class TransactionService implements ITransactionService {
  /**
   * getMerchantTransactions
   * get list and total count of merchant transactions
   * @param limit
   * @param skip
   * @returns
   */
  public async getMerchantTransactions(
    limit = 100,
    skip = 0
  ): Promise<IMerchantTransactionsResponse> {
    try {
      const response = await httpClientV2(
        `/merchant-users/me/transactions?limit=${limit}&skip=${skip}`,
        {
          apiVersion: "v2",
          method: "GET",
          auth: true,
        }
      );
      if (!isSuccessResponse(response)) {
        return { error: new Error(response.error) };
      }

      const { transactions, totalCount }: IMerchantTransactionsResponseJson =
        response.data;

      return { transactions, totalCount };
    } catch (error) {
      return { error };
    }
  }
}

export default new TransactionService();
