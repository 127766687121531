/* eslint no-extend-native: [0, { "exceptions": ["Object"] }] */

(function () {
  var defaultTransformer = function (x) { return x; };
  Array.prototype.sum = function (transformer) {
    transformer = transformer || defaultTransformer;
    return this.reduce(function (previous, current) {
      return previous + transformer(current);
    }, 0);
  };

  Array.prototype.average = function (transformer) {
    transformer = transformer || defaultTransformer;
    return this.sum(transformer) / this.length;
  };
})();