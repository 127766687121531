import React from 'react';
import Logo from 'assets/images/logo.png';
import { AppBar, Toolbar, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  logo: { width: '75px', verticalAlign: 'middle' },
  logoMobile: {
    width: '180px',
    verticalAlign: 'middle',
  },
  logoMobileDiv: {
    marginBottom: theme.spacing(3),
  },
}));

interface IProps {
  link: string;
}

const Header: React.FC<IProps> = (props) => {
  const classes = useStyle();
  return (
    <AppBar style={{ background: 'var(--appbar)' }} position='static'>
      <Toolbar>
        <Link component={RouterLink} to={props.link} style={{ margin: 'auto' }}>
          <img src={Logo} className={classes.logo} />
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
