import { makeStyles } from "@material-ui/core";
import { Button } from "antd";
import React, { ComponentProps } from "react";
import { SPACING } from "../constants";

type Props = ComponentProps<typeof Button> & {
  children: React.ReactNode;
  classType?: "primary" | "secondary" | "grey" | null;
};
export default function SwayButton({
  style,
  classType = "primary",
  ...props
}: Props) {
  return (
    <Button
      className={classType !== null && `sway-merchant-btn-${classType}`}
      style={{
        fontWeight: "bold",
        borderRadius: SPACING,
        ...style,
      }}
      size={"large"}
      {...props}
    />
  );
}
